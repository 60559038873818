import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const SolutionCard = css`
  border-radius: 3px;
  width: 100%;
  background: ${Color.PureWhite};
  border: 1.0403px solid ${Color.FogBlue};
  box-sizing: border-box;
  border-left: 4px solid ${Color.FogBlue};
  padding: 8px !important;
`;

export const SolutionLogo = css`
  line-height: 100px !important;
  display: block !important;
  margin: auto;
`;

export const SolutionLogoContainer = css`
  max-width: 170px !important;
  border-right: 1px solid ${Color.FogBlue};
`;

export const BalanceContainer = css`
  padding: 12px 20px 12px 40px !important;
`;

export const BalanceAmount = css`
  font-family: ${Typography.GillSans};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.148485px;
  color: ${Color.DeepBlack};
  padding-top: 14px;
`;

export const BalanceAmountUpdateTime = css`
  font-family: ${Typography.GillSans};
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.148485px;
  color: ${Color.MediumGrey};
  padding-top: 8px;
`;
