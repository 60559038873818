import FormComponent from './FormComponent';
import Validations from './validations';
import { FieldsOptions, InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import categories from '../../../../constants/categories';

const name = 'accountType';
const path = 'accountType';
const header = 'Account Type';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  category: categories.accountInformation,
  backToReview: true,
};

export default config;
