import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
export const validationMessages = {
  usernameRequired: `${FormStrings[FormFields.username]} is required`,
  usernameInvalid: `${FormStrings[FormFields.username]} is invalid`,
  emailRequired: `${FormStrings[FormFields.email]} is required`,
  emailInvalid: `${FormStrings[FormFields.email]} format is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.username]: Yup.string().required(validationMessages.usernameRequired),
  [FormFields.email]: Yup.string()
    .required(validationMessages.emailRequired)
    .email(validationMessages.emailInvalid),
});

export default defaultValidation;
