import React, { useState } from 'react';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueIntermediaryRetirementAction } from '../../../FormActions';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../../utils/forms';
import Input from '../../../../shared/Inputs/Input';
import * as Constants from '../../../../../constants/dates';
import DatePickerInput from '../../../../shared/DatePicker/DatePicker';
import { useHistory } from 'react-router';

const Component = (props: TForm) => {
  const history: any = useHistory();
  const key = history.location.state.id;

  const state: any = useSelector((state: any) => state.intermediary.retirementIntermediary);
  const formState = getFormState(state[state.findIndex((item: any) => item.id === key)], InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue({ ...values, id: key });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.nameOfTrust}
              label={FormStrings[FormFields.nameOfTrust]}
              value={formState[FormFields.nameOfTrust]}
            />
            <DatePickerInput
              {...form}
              name={FormFields.dateOfTrust}
              label={FormStrings[FormFields.dateOfTrust]}
              dateFormat={Constants.DATE_YEAR_FORMAT}
              defaultValue={formState[FormFields.dateOfTrust]}
            />
            <Input
              {...form}
              name={FormFields.trustTaxID}
              label={FormStrings[FormFields.trustTaxID]}
              value={formState[FormFields.trustTaxID]}
            />
            <ContinueIntermediaryRetirementAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
