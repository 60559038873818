import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  consultWithBroker: 'consultWithBroker',
  ownDecisions: 'ownDecisions',
  discussions: 'discussions',

  investmentRatioOpts: {
    small: 'small',
    medium: 'medium',
    large: 'large',
  },
  investmentRatio: 'investmentRatio',
} as any;

export const FormFieldsTypes = {
  consultWithBroker: reviewFieldTypes.Boolean,
  ownDecisions: reviewFieldTypes.Boolean,
  discussions: reviewFieldTypes.Boolean,
  [FormFields.investmentRatio]: reviewFieldTypes.RadioGroup,
} as any;

export const FormStrings = {
  decisionMakingLabel: 'Decision making (check all that apply)',
  investmentRatioLabel: 'The investments in this account will be (check one)',
  [FormFields.investmentRatio]: 'Investment Ratio',
  [FormFields.consultWithBroker]: 'I consult with my broker, investment adviser, CPA, or other financial professional',
  [FormFields.ownDecisions]: 'I generally make my own decisions and/or consult with my co-applicant(s)',
  [FormFields.discussions]: 'I discuss investment decisions with family and/or friends.',
  investmentRatioOpts: {
    [FormFields.investmentRatioOpts.small]: 'Less than 1/3 of my financial portfolio',
    [FormFields.investmentRatioOpts.medium]: 'Roughly 1/3 to 2/3 of my financial portfolio',
    [FormFields.investmentRatioOpts.large]: 'More than 2/3 of my financial portfolio',
  },
} as any;
