import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import { FormText } from '../../../../../styles/typography';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';

import { TForm } from '../../../formTypes';
import { ContinueIntermediaryJointAccountAction } from '../../../FormActions';
import * as Styles from './Style';
import { addingJointAccount } from '../../../../../actions/navigation';

const Component = (props: TForm) => {
  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    onAddClick();
    if (props.onContinue) props.onContinue();
  };
  const history = useHistory();

  const onAddClick = () => {
    const action = addingJointAccount({
      body: true,
    });
    dispatch(action);
    // history.push(`${Routes.REGISTER_INFO}/${id}`);
  };

  return (
    <Formik initialValues={InitialValues} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <div className={Styles.textBottomPadding}>
              {FormStrings.formText.map(text => {
                return <p className={FormText}>{text}</p>;
              })}
            </div>
            <ContinueIntermediaryJointAccountAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
              label="ADD NOW"
            />
            {/*<div className={Styles.ContinueWithNoJointAccounts}>*/}
            {/*  <Button*/}
            {/*    label="Continue with no joint accounts"*/}
            {/*    type={ButtonType.Link}*/}
            {/*    onClick={() => {*/}
            {/*      onSkipClick(currentForm, history, false);*/}
            {/*    }}*/}
            {/*    disabled={false}*/}
            {/*  />*/}
            {/*</div>*/}
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
