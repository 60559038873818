import produce from 'immer';
import initialState from './initial';
import { Type } from '../actions/utils';
import { actionSucceeded, actionFailed } from '../actions/types';
import { TReduxAction } from '../types/commonTypes';

export const navigation = (state: any = initialState.navigation, action: TReduxAction) => {
  switch (action.type) {
    case actionSucceeded(Type.BACK_TO_REVIEW):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.backToReview = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.ADDING_JOINT_ACCOUNT):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.addingJointAccount = action.payload.body;
      })(state, action);
    default:
      return state;
  }
};
