import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  addressLineOneRequired: `${FormStrings[FormFields.addressLineOne]} is required`,
  cityRequired: `${FormStrings[FormFields.city]} is required`,
  stateRequired: `${FormStrings[FormFields.state]} is required`,
  zipCodeRequired: `${FormStrings[FormFields.zipCode]} is required`,
  zipCodeInvalid: `${FormStrings[FormFields.zipCode]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.addressLineOne]: Yup.string().required(validationMessages.addressLineOneRequired),
  [FormFields.city]: Yup.string().required(validationMessages.cityRequired),
  [FormFields.state]: Yup.string()
    .required(validationMessages.stateRequired)
    .notOneOf(['-1'], validationMessages.stateRequired),
  [FormFields.zipCode]: Yup.number()
    .required(validationMessages.zipCodeRequired)
    .moreThan(0, validationMessages.zipCodeInvalid)
    .lessThan(100000, validationMessages.zipCodeInvalid),
});

export default defaultValidation;
