import * as Yup from 'yup';
import { FormFields } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const InvestmentObjectives: TSelectOption[] = [
  {
    label: 'Select Investment Objectives',
    value: '-1',
  },
  {
    label: 'Capital Preservation',
    value: 'CAPITAL_PRESERVATION',
  },
  {
    label: 'Income',
    value: 'INCOME',
  },
  {
    label: 'Growth',
    value: 'GROWTH',
  },
  {
    label: 'Maximum Growth',
    value: 'MAXIMUM_GROWTH',
  },
  {
    label: 'Speculation',
    value: 'SPECULATION',
  },
];

export const InitialValues = {
  [FormFields.crowdFundingRegCF]: InvestmentObjectives[0].value,
  [FormFields.iPOsAndOfferings]: InvestmentObjectives[0].value,
  [FormFields.discountTrading]: InvestmentObjectives[0].value,
  [FormFields.managedAccounts]: InvestmentObjectives[0].value,
};
export const FieldsOptions = {
  [FormFields.crowdFundingRegCF]: InvestmentObjectives,
  [FormFields.iPOsAndOfferings]: InvestmentObjectives,
  [FormFields.discountTrading]: InvestmentObjectives,
  [FormFields.managedAccounts]: InvestmentObjectives,
};
