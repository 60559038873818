import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { AuthFields, AuthStrings } from './constants';
import { InitialValues } from './schema';
import * as Styles from '../Style';
import { defaultValidation } from './validations';
import Input from '../../shared/Inputs/Input';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as AuthActions from '../../../actions';
import FormContainer from '../../shared/FormContainer/FormContainer';
import { DirectToLogin } from '../AuthAction';

const Component = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.forgotPassword);
  const { error } = state || '';
  const { resendCounter } = state.data || '';
  const stateAuthInfo = useSelector((state: any) => state.authInfo);
  const { username } = stateAuthInfo.token || '';

  useEffect(() => {
    dispatch(
      AuthActions.clearForgotPassword({
        id: 'clearError',
        body: null,
      }),
    );
  }, []);

  const submit = (values: any) => {
    dispatch(
      AuthActions.forgotPassword({
        id: 'forgotPasswordSubmit',
        body: { ...values, username },
      }),
    );
  };

  const resendCode = () => {
    dispatch(
      AuthActions.forgotPassword({
        id: 'forgotPassword',
        body: { username, resendCounter: !resendCounter ? 1 : resendCounter + 1 },
      }),
    );
  };

  return (
    <FormContainer title={'Reset your password'}>
      <Formik
        enableReinitialize
        initialValues={{ ...InitialValues }}
        validationSchema={defaultValidation}
        onSubmit={values => submit(values)}
      >
        {form => {
          return (
            <>
              {resendCounter > 0 && (
                <Row>
                  <Col>
                    <span className={Styles.resent}>A new code has been sent to your email address!</span>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <span className={Styles.errorText}>{error}</span>
                </Col>
              </Row>
              <Input
                {...form}
                name={AuthFields.code}
                label={AuthStrings[AuthFields.code]}
                value={form.initialValues[AuthFields.code]}
              />
              <Input
                {...form}
                name={AuthFields.password}
                label={AuthStrings[AuthFields.password]}
                value={form.initialValues[AuthFields.password]}
                type={'password'}
              />
              <Input
                {...form}
                name={AuthFields.repeatPassword}
                label={AuthStrings[AuthFields.repeatPassword]}
                value={form.initialValues[AuthFields.repeatPassword]}
                type={'password'}
              />
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Button onClick={() => resendCode()} label={'Resend Code'} type={ButtonType.Link} />
                </Col>
              </Row>
              <Button
                disabled={!form.isValid}
                onClick={form.submitForm}
                label={'SUBMIT'}
                type={ButtonType.Thick}
                loading={!!state.__requested ? true : false}
              />
              <Row>
                <Col span={24} className={Styles.SignInTextCSS}>
                  <span>Back to </span>
                  <DirectToLogin />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default Component;
