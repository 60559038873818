import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';

//TODO: Import from strings
export default (header: string) => {
  return {
    title: header,
    description:
      'Liquidity is the ability to quickly and easily convert to cash all, or a portion, of the investments in this account without experiencing significant loss in value from, for example, the lack of a ready market, or incurring significant costs or penalties.',
    subTitle: undefined,
    formInformationKeyValue: [
      {
        key: 'Very Important',
        value: [
          'The ability to quickly and easily convert to cash all or a portion of the investments in under 5 years in this account is very important.',
        ],
      },
      {
        key: 'Somewhat Important',
        value: [
          'The ability to quickly and easily convert to cash all or a portion of the investments in this account in over 5 years is somewhat important.',
        ],
      },
      {
        key: 'Not Important',
        value: [
          'The ability to quickly and easily convert to cash all or a portion of the investments in this account is not important.',
        ],
      },
    ],
    footer: undefined,
  };
};
