import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';

//TODO: Import from strings
export default (header: string) => {
  return {
    title: header,
    description: 'Select the time period planned to achieve a particular financial goal.',
    subTitle: undefined,
    formInformationKeyValue: [
      {
        key: 'Short',
        value: ['I plan on keeping this account open and investing for less than 4 years.'],
      },
      {
        key: 'Average',
        value: ['I plan on keeping this account open and investing for more than 5 years, but less than 7 years. '],
      },
      {
        key: 'Longest',
        value: ['I plan on keeping this account open and investing for more than 8+ years.'],
      },
    ],
    footer: undefined,
  };
};
