import * as React from 'react';
import { connect } from 'react-redux';
import CardViewTitle from '../shared/CardViewTitle/CardViewTitle';

type Props = {};

type State = {};

export class Component extends React.Component<Props, State> {
  render() {
    return <CardViewTitle title="Page Not Found"></CardViewTitle>;
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
