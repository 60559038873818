import { css } from 'emotion';
import * as Typography from '../../../styles/typography';
import * as Color from '../../../styles/color';

export const Icon = css`
  margin-top: -11px !important;
  min-height: 22px !important;
  min-width: 22px !important;
`;

export const Expandable = css`
  // padding: 12px 15px 0px;
  background: ${Color.OffWhite};
  border: 1px solid ${Color.TropicalBlue};
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  line-height: 17px;
  transform: transit('translate(0, 0)', 500, 'ease-in-out');
  margin: 16px 0px;
`;

export const Header = css`
  cursor: pointer;
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.141414px;
  color: ${Color.DeepBlack};
  // padding-bottom: 12px;
  padding: 12px 15px 12px;
`;

export const Content = css`
  padding: 23px 15px 12px;
`;

export const IndicatorRotate = css`
  transform: rotate(180deg);
`;

export const Divider = css`
  margin: 0 !important;
  color: ${Color.FogBlue} !important;
`;

export const DividerParent = css`
  padding: 0 12px !important;
`;

export const EditButton = css`
  width: 100%;
  font-family: ${Typography.GillSans} !important;
  font-style: regular !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${Color.FineBlue} !important;
  background-color: ${Color.PureWhite} !important;
  border-radius: 0 !Important;
`;

export const RightAlign = css`
  float: right;
`;
