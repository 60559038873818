import * as Yup from 'yup';
import Faker from 'faker';

import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

const data = {
  myIpo: {
    icon: 'myipo.svg',
    name: 'IPOs & Offerings',
    app: 'My IPO',
    description:
      'My IPO is leveling the playing field by giving investors access to some of the most exciting IPOs and other Offerings! Until now, only the privileged clients of investment banks, institutional investors or the wealthy had access to these opportunities.',
    field: 'myIpo',
  },
  readyRound: {
    icon: 'ready-round.svg',
    name: 'Crowdfunding',
    app: 'Ready Round',
    description:
      'Ready Round was created to give all investor s the opportunity to invest in some of the most exciting vetted Crowdfunding Offerings! It’s easy to use and equally easy to find offer s to invest in.',
    field: 'readyRound',
  },
  tradingBlock: {
    icon: 'trading-block.svg',
    name: 'Discount Trading',
    app: 'TradingBlock',
    description:
      'TradingBlock is a discount trading platform that supports trading in stocks, options, ETFs and mutual funds. Open an account and transfer your existing investments to take command of your investment portfolio! Read Disclaimer',
    field: 'tradingBlock',
    products: {},
  },
  folio: {
    icon: 'folio.svg',
    name: 'Managed Account',
    app: 'Folio Investing',
    description:
      "Cambria Folio offers access to leading professional money managers. We'll work with you to find the option that fits your needs and helps you stay on track to meeting your goals.",
    field: 'folio',
  },
};

export const InitialValues: any = {
  ...data,
};

const fakeSolutionName = Faker.random.alphaNumeric(12);

export const FakeSchema = {
  [fakeSolutionName]: {
    icon: Faker.image.business(),
    name: Faker.company.companyName(),
    description: Faker.company.catchPhraseDescriptor(),
    field: fakeSolutionName,
  },
};
