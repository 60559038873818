import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
export const validationMessages = {
  birthdayRequired: `${FormStrings[FormFields.birthday]} is required`,
  birthdayInvalid: `${FormStrings[FormFields.birthday]} is invalid`,
  ssnRequired: `${FormStrings[FormFields.ssn]} is required`,
  ssnInvalid: `${FormStrings[FormFields.ssn]} format is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.birthday]: Yup.string().required(validationMessages.birthdayRequired),
  [FormFields.ssn]: Yup.string()
    .matches(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/, validationMessages.ssnInvalid)
    .required(validationMessages.ssnRequired),
});

export default defaultValidation;
