import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  emailRequired: `${FormStrings[FormFields.email]} is required`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.email]: Yup.string().required(validationMessages.emailRequired),
});

export default defaultValidation;
