export const DeepBlue: string = '#143992';
export const TropicalBlue: string = '#0098CE';
export const DeepBlack: string = '#222222';
export const DarkGrey: string = '#545454';
export const MediumGrey: string = '#989898';
export const DarkerMediumGrey: string = '#423C3A';
export const FineBlue: string = '#077CB6';
export const FogBlue: string = '#DFE5F0';
export const OffWhite: string = '#FAFCFD';
export const PureWhite: string = '#FFFFFF';
export const LightGrey: string = '#E9E9E9';
export const YellowOrange: string = '#F6AA1A';
export const BrightRed: string = '#FF244E';
export const GoGreen: string = '#06AA68';
