export const FormFields = {
  documents: 'documents',
  subType: 'subType',
  Corporation: 'Corporation',
  Partnership: 'Partnership',
  Trust: 'Trust',
  LLC: 'LLC',
  Investment_Club: 'Investment Club',
  Sole_Proprietorship: 'Sole Proprietorship',

  articlesOfIncorporation: 'articlesOfIncorporation',
  certificateOfIncorporation: 'certificateOfIncorporation',
  corporateAgreement: 'corporateAgreement',
  bylaws: 'bylaws',
  certificateOfPartnership: 'certificateOfPartnership',
  partnershipAgreement: 'partnershipAgreement',
  trusteeCertification: 'trusteeCertification',
  trustAgreement: 'trustAgreement',
  certificateOfFormation: 'certificateOfFormation',
  operatingAgreement: 'operatingAgreement',
  uploadLater: 'uploadLater',

  investmentClubCertification: 'investmentClubCertification',
  memberValidID: 'memberValidID',
  investmentClubAgreement: 'investmentClubAgreement',
  certificateOfProprietorship: 'certificateOfProprietorship',
  proprietorshipAgreement: 'proprietorshipAgreement',
};

export const FormStrings = {
  [FormFields.articlesOfIncorporation]: 'Articles of Incorporation',
  [FormFields.certificateOfIncorporation]: 'Certificate of Incorporation',
  [FormFields.corporateAgreement]: 'Corporate Agreement',
  [FormFields.bylaws]: 'Bylaws',
  [FormFields.certificateOfPartnership]: 'Certificate of Partnership',
  [FormFields.partnershipAgreement]: 'Partnership Agreement',
  [FormFields.trusteeCertification]: 'Trustee Certification',
  [FormFields.trustAgreement]: 'Trust Agreement',
  [FormFields.certificateOfFormation]: 'Certificate of Formation',
  [FormFields.operatingAgreement]: 'Operating Agreement',
  [FormFields.uploadLater]: 'I will upload entity documents at a later time.',

  [FormFields.investmentClubCertification]: 'Investment Club Certification',
  [FormFields.memberValidID]: 'Member Valid ID',
  [FormFields.investmentClubAgreement]: 'Investment Club Agreement',
  [FormFields.certificateOfProprietorship]: 'Certificate of Proprietorship',
  [FormFields.proprietorshipAgreement]: 'Proprietorship Agreement',
};
