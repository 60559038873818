import React from 'react';
import { Formik } from 'formik';
import { map } from 'lodash';

import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';
import { ContinueAction } from '../../FormActions';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import Input from '../../../shared/Inputs/Input';
import InfoText from '../../../shared/InfoText/InfoText';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.accountFundingSource);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (_values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <InfoText>{FormStrings.infoMessage}</InfoText>
            {map(InitialValues, (value: boolean, key: string) => {
              return (
                key !== FormFields.other && (
                  <Checkbox
                    {...form}
                    name={FormFields[key]}
                    label={FormStrings[key]}
                    checked={formState[FormFields[key]]}
                  />
                )
              );
            })}
            <Input
              {...form}
              name={FormFields.other}
              label={FormStrings[FormFields.other]}
              value={formState[FormFields.other]}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
