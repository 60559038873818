import produce from 'immer';
import initialState from './initial';
import { Type } from '../actions/utils';
import { actionFailed, actionSucceeded } from '../actions/types';
import { TReduxAction } from '../types/commonTypes';
import _ from 'lodash';

export const intermediary = (state: any = initialState.intermediary, action: TReduxAction) => {
  switch (action.type) {
    case actionSucceeded(Type.SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        if (action.payload.id) {
          if (draftState.retirementIntermediary) {
            const index = draftState.retirementIntermediary.findIndex(
              (item: any) => item.id === action.payload.body.id,
            );
            if (index === -1) {
              draftState.retirementIntermediary = [
                ...draftState.retirementIntermediary,
                {
                  ...action.payload.body,
                },
              ];
            } else {
              draftState.retirementIntermediary[index] = {
                ...draftState.retirementIntermediary[index],
                ...action.payload.body,
              };
            }
          } else {
            draftState.retirementIntermediary = [{ ...action.payload.body, id: 0 }];
          }
        } else {
          draftState.retirementIntermediary = [...action.payload.body];
        }
      })(state, action);
    case actionSucceeded(Type.DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.retirementIntermediary = [
          ...draftState.retirementIntermediary.filter((item: any) => item.id !== action.payload.body.id),
        ];
      })(state, action);
    case actionSucceeded(Type.SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        if (action.payload.id) {
          if (draftState.entityAuthorizedPersonsIntermediary) {
            const index = draftState.entityAuthorizedPersonsIntermediary.findIndex(
              (item: any) => item.id === action.payload.body.id,
            );
            if (index === -1) {
              draftState.entityAuthorizedPersonsIntermediary = [
                ...draftState.entityAuthorizedPersonsIntermediary,
                {
                  ...action.payload.body,
                },
              ];
            } else {
              draftState.entityAuthorizedPersonsIntermediary[index] = {
                ...draftState.entityAuthorizedPersonsIntermediary[index],
                ...action.payload.body,
              };
            }
          } else {
            draftState.entityAuthorizedPersonsIntermediary = [{ ...action.payload.body, id: 0 }];
          }
        } else {
          draftState.entityAuthorizedPersonsIntermediary = [...action.payload.body];
        }
      })(state, action);
    case actionSucceeded(Type.DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.entityAuthorizedPersonsIntermediary = [
          ...draftState.entityAuthorizedPersonsIntermediary.filter((item: any) => item.id !== action.payload.body.id),
        ];
      })(state, action);
    case actionSucceeded(Type.SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        if (action.payload.id) {
          if (draftState.jointAccountsIntermediary) {
            const index = draftState.jointAccountsIntermediary.findIndex(
              (item: any) => item.id === action.payload.body.id,
            );
            if (index === -1) {
              draftState.jointAccountsIntermediary = [
                ...draftState.jointAccountsIntermediary,
                {
                  id: action.payload.body.id,
                  [action.payload.id]: { ...action.payload.body },
                },
              ];
            } else {
              if (action.payload.body && (action.payload.body.id || action.payload.body.id === 0)) {
                draftState.jointAccountsIntermediary[index].id = action.payload.body.id;
                draftState.jointAccountsIntermediary[index][action.payload.id] = {
                  ...action.payload.body,
                };
              }
            }
            // draftState.jointAccountsIntermediary = draftState.jointAccountsIntermediary.filter(
            //   (item: any) => item.id || item.id === 0,
            // );
          } else {
            draftState.jointAccountsIntermediary = [{ [action.payload.id]: { ...action.payload.body }, id: 0 }];
          }
        } else {
          draftState.jointAccountsIntermediary = [...action.payload.body];
        }
      })(state, action);
    case actionSucceeded(Type.DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.jointAccountsIntermediary = [
          ...draftState.jointAccountsIntermediary.filter((item: any) => item.id !== action.payload.body.id),
        ];
      })(state, action);
    default:
      return state;
  }
};
