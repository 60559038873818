import _ from 'lodash';

import Input from '../../../shared/Inputs/Input';

export default (accountInformation: any): any => ({
  accountType: {
    viewLabel: 'Account Type',
    displayValue: !_.isEmpty(accountInformation) ? accountInformation.accountType.type : '-',
    isReadOnly: false,
  },
  firstName: {
    viewLabel: 'First Name',
    displayValue: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.firstName : '-',
    isReadOnly: false,
    component: Input,
    props: {
      name: 'firstName',
      label: 'First Name',
      value: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.firstName : null,
    },
  },
  middleInitial: {
    viewLabel: 'Middle Initial',
    displayValue: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.middleInitial : null,
    isReadOnly: false,
    component: Input,
    props: {
      name: 'middleInitial',
      label: 'Middle Initial',
      value: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.middleInitial : null,
    },
  },
  lastName: {
    viewLabel: 'Last Name',
    displayValue: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.lastName : '-',
    isReadOnly: false,
    component: Input,
    props: {
      name: 'lastName',
      label: 'Last Name',
      value: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.lastName : null,
    },
  },
  phoneNumber: {
    viewLabel: 'Phone Number',
    displayValue: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.phoneNumber : null,
    isReadOnly: false,
    component: Input,
    props: {
      name: 'phoneNumber',
      label: 'Phone Number',
      value: !_.isEmpty(accountInformation) ? accountInformation.accountOwnerInfo.phoneNumber : null,
    },
  },
});
