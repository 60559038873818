import { createSelector } from 'reselect';

const loginSelector = (state: any, props: any) => state.login;
const authInfo = (state: any, props?: any) => state.authInfo;

export const fullNameSelector = createSelector([loginSelector], login => {
  return `${login.firstName} ${login.lastName}`;
});

export const isLoggedInSelector = createSelector([authInfo], info => {
  if (info.token && typeof info.token.accessToken === 'string' && info.token.accessToken.length) {
    return true;
  }
  return false;
});

export const authStateSelector = createSelector([authInfo], info => {
  return info.token && typeof info.token.authState === 'string' && info.token.authState;
});
