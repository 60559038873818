import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { JOINT } from '../../../../../constants/accountTypes';

export const name = 'jointAccountsReview';
const path = 'jointAccountsReview';
const header = 'Joint Accounts';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  backToReview: true,
  accountType: JOINT,
  whenAddJointAccount: true,
};

export default config;
