export const FormFields = {
  authorizedPersons: 'authorizedPersons',
  authorizedPersonsId: 'authorizedPersonsId',
  authorizedPersonId: 'authorizedPersonId',
  uploadLater: 'uploadLater',
};

export const FormStrings = {
  [FormFields.authorizedPersonsId]: 'Authorized Persons Id',
  [FormFields.authorizedPersonId]: 'Authorized Person Id',
  [FormFields.uploadLater]: 'I will send my ID to Cambria at a later time.',
};
