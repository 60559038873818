import { css, cx } from 'emotion';

export const Icon = css`
  margin-top: -11px !important;
  min-height: 22px !important;
  min-width: 22px !important;
`;

export const DatePicker = cx(
  // closeIcon,
  css`
    width: 100% !important;
    div {
      input {
        background: #ffffff !important;
        border: 1px solid #dfe5f0 !important;
        box-sizing: border-box !important;
        border-radius: 2px !important;
        min-height: 40px !important;
        &:focus,
        &:hover {
          border: 1px solid #0098ce !important;
          box-shadow: none !important;
        }
      }
      .anticon-close-circle {
        position: absolute !important;
        top: 50% !important;
        right: 12px !important;
        margin-top: -11px !important;
        font-size: 12px !important;
        -webkit-transition: all 0.3s !important;
        transition: all 0.3s !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        min-height: 22px !important;
        min-width: 22px !important;
        svg {
          min-height: 22px !important;
          min-width: 22px !important;
        }
      }
    }
  `,
);
