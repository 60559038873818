import { FormFields } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';
import { states } from '../../../../constants/states';

export const InitialValues = {
  [FormFields.addressLine1]: '',
  [FormFields.addressLine2]: '',
  [FormFields.city]: '',
  [FormFields.state]: '',
  [FormFields.zipCode]: '',
};

export const stateOptions: TSelectOption[] = [...states];

export const FieldsOptions = {
  [FormFields.state]: stateOptions,
};
