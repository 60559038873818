import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { EmploymentStatusValues } from './schema';

export const validationMessages = {
  employmentStatusRequired: `${FormStrings[FormFields.employmentStatus]} is required`,
  employmentStatusInvalid: `${FormStrings[FormFields.employmentStatus]} is invalid`,
  employerNameRequired: `${FormStrings[FormFields.employerName]} is required`,
  employerNameInvalid: `${FormStrings[FormFields.employerName]} is invalid`,
  employerPositionRequired: `${FormStrings[FormFields.employerPosition]} is required`,
  employerPositionInvalid: `${FormStrings[FormFields.employerPosition]} is invalid`,
  yearsEmployedRequired: `${FormStrings[FormFields.yearsEmployed]} is required`,
  yearsEmployedInvalid: `${FormStrings[FormFields.yearsEmployed]} is invalid`,
  employerAddressLine1Required: `${FormStrings[FormFields.employerAddressLine1]} is required`,
  employerAddressLine1Invalid: `${FormStrings[FormFields.employerAddressLine1]} is invalid`,
  employerAddressLine2Required: `${FormStrings[FormFields.employerAddressLine2]} is required`,
  employerAddressLine2Invalid: `${FormStrings[FormFields.employerAddressLine2]} is invalid`,
  employerCityRequired: `${FormStrings[FormFields.employerCity]} is required`,
  employerCityInvalid: `${FormStrings[FormFields.employerCity]} is invalid`,
  employerStateRequired: `${FormStrings[FormFields.employerState]} is required`,
  employerStateInvalid: `${FormStrings[FormFields.employerState]} is invalid`,
  employerZipRequired: `${FormStrings[FormFields.employerZip]} is required`,
  employerZipInvalid: `${FormStrings[FormFields.employerZip]} is invalid`,
  annualIncomeRequired: `${FormStrings[FormFields.annualIncome]} is required`,
  annualIncomeInvalid: `${FormStrings[FormFields.annualIncome]} is invalid`,
  taxRateRequired: `${FormStrings[FormFields.taxRate]} is required`,
  taxRateInvalid: `${FormStrings[FormFields.taxRate]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.employmentStatus]: Yup.string()
    .required(validationMessages.employmentStatusRequired)
    .notOneOf(['-1'], validationMessages.employmentStatusInvalid),
  [FormFields.employerName]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string().required(validationMessages.employerNameRequired),
    otherwise: Yup.string(),
  }),
  [FormFields.employerPosition]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string().required(validationMessages.employerPositionRequired),
    otherwise: Yup.string(),
  }),
  [FormFields.yearsEmployed]: Yup.number().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.number()
      .integer(validationMessages.yearsEmployedInvalid)
      .required(validationMessages.yearsEmployedRequired),
    otherwise: Yup.number(),
  }),
  [FormFields.employerAddressLine1]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string().required(validationMessages.employerAddressLine1Required),
    otherwise: Yup.string(),
  }),
  [FormFields.employerAddressLine2]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string().max(100, validationMessages.employerAddressLine2Invalid),
    otherwise: Yup.string(),
  }),
  [FormFields.employerCity]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string().required(validationMessages.employerCityRequired),
    otherwise: Yup.string(),
  }),
  [FormFields.employerState]: Yup.string().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.string()
      .required(validationMessages.employerStateRequired)
      .notOneOf(['-1'], validationMessages.employerStateRequired),
    otherwise: Yup.string(),
  }),
  [FormFields.employerZip]: Yup.number().when([FormFields.employmentStatus], {
    is: val => val === EmploymentStatusValues.Employed || val === EmploymentStatusValues['Self-Employed'],
    then: Yup.number()
      .required(validationMessages.employerZipRequired)
      .moreThan(0, validationMessages.employerZipInvalid)
      .lessThan(100000, validationMessages.employerZipInvalid),
    otherwise: Yup.number(),
  }),
  [FormFields.annualIncome]: Yup.string()
    .required(validationMessages.annualIncomeRequired)
    .notOneOf(['-1'], validationMessages.annualIncomeInvalid),
  [FormFields.taxRate]: Yup.string()
    .required(validationMessages.taxRateRequired)
    .notOneOf(['-1'], validationMessages.taxRateInvalid),
});

export default defaultValidation;
