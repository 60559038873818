import { login, register, authInfo, forgotPassword, changePassword, confirmEmail } from './auth';
import { forms } from './forms';
import { solutions } from './solutions';
import { intermediary } from './intermediary';
import { navigation } from './navigation';

import { externalAccountBalance } from './plaidSettings';
import { account } from './userManagement';

const reducer = {
  login,
  register,
  confirmEmail,
  forgotPassword,
  changePassword,
  authInfo,

  forms,
  solutions,
  intermediary,

  navigation,
  externalAccountBalance,

  account,
};

export default reducer;
