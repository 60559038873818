import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';

import { InitialValues, RiskTolerance } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import SuitabilityInfoSelects from '../../../SuitabilityInfo/SuitabilityInfoSelects';
import { useSelector } from 'react-redux';
import { getFormState, filterInitialValueFromState } from '../../../../utils/forms';
import { SolutionFormFieldsRelations } from './constants';
import useFormikChangeValuesOnFormStateChange from '../../../../hooks/useFormikChangeValuesOnFormStateChange';

const Component = (props: TForm) => {
  const formikRef: any = useRef();
  const state = useSelector((state: any) => state.forms.selectYourRiskTolerance);
  const solutions = useSelector((state: any) => state.solutions);
  const initialValues = filterInitialValueFromState(InitialValues, solutions, SolutionFormFieldsRelations);
  const formState = getFormState(state, initialValues);
  useFormikChangeValuesOnFormStateChange(formikRef, formState);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={formState}
      validationSchema={defaultValidation(formState)}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <SuitabilityInfoSelects form={form} options={RiskTolerance} />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
