const section1 = {
  title: 'Section One',
  route: 'section-one',
  questions: [
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
  ],
};

const section2 = {
  title: 'Section Two',
  route: 'section-two',
  questions: [
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
  ],
};

const section3 = {
  title: 'Section Three',
  route: 'section-three',
  questions: [
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
    {
      q: 'Question text, Elementum integer enim neque volutpat ac tincidunt vitae semper?',
      a:
        'Answer text, Elementum integer enim neque volutpat ac tincidunt vitae semper? Scelerisque fermentum dui faucibus in ornare quam viverra. Et odio pellentesque diam volutpat. Et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nibh cras pulvinar mattis nunc sed blandit libero.',
    },
  ],
};

export default [section1, section2, section3];
