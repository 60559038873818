import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues, BeneficiaryTypes } from './schema';
import defaultValidation from './validations';
import { TForm } from '../../../formTypes';

import { ContinueIntermediaryRetirementAction, ContinueRetirementAction } from '../../../FormActions';
import RadioGroup from '../../../../shared/Radio/Radio';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../../utils/forms';
import InfoText from '../../../../shared/InfoText/InfoText';
import { enabledInjectedForms } from '../../../formInjector';
import * as Routes from '../../../../../constants/routes';
import { path as AddBeneficiaryPath } from '../BeneficiaryInformation';
import { path as AddTrustPath } from '../TrustInformation';

const Component = (props: TForm) => {
  const history: any = useHistory();
  const retirement = useSelector((state: any) => state.intermediary.retirementIntermediary);
  const isContingent =
    history.location.state && history.location.state.isContingent ? history.location.state.isContingent : false;
  //TODO: replace with another key with uuid
  const [key, setKey] = useState(
    !!retirement && retirement.length > 0
      ? retirement.reduce((prev: any, current: any) => (prev.id > current.id ? prev : current)).id + 1
      : 0,
  );

  const onSubmit = (values: any) => {
    let route = '';
    if (values.type === FormFields.trust) {
      route = `${Routes.REGISTER_INFO}/${AddTrustPath}`;
    } else {
      route = `${Routes.REGISTER_INFO}/${AddBeneficiaryPath}`;
    }
    history.push(route, { ...values, id: key } as any);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={InitialValues}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <InfoText>{FormStrings.formText as any}</InfoText>
            <RadioGroup
              {...form}
              radioOptions={map(BeneficiaryTypes, (at: any, key: string) => ({
                label: at.label,
                value: at.value,
              }))}
              name={FormFields.type}
              defaultValue={form.values[FormFields.type]}
            />
            <ContinueIntermediaryRetirementAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key, isContingent }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
