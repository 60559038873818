import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { LiquidityNeeds } from './schema';

export const validationMessages = {
  iPOsAndOfferingsRequired: `${FormStrings[FormFields.iPOsAndOfferings]} is required`,
  iPOsAndOfferingsInvalid: `${FormStrings[FormFields.iPOsAndOfferings]} must be ${LiquidityNeeds[2].label} or ${
    LiquidityNeeds[3].label
  }`,
  crowdFundingRegCFRequired: `${FormStrings[FormFields.crowdFundingRegCF]} is required`,
  crowdFundingRegCFInvalid: `${FormStrings[FormFields.crowdFundingRegCF]} must be ${LiquidityNeeds[3].label}`,
  discountTradingRequired: `${FormStrings[FormFields.discountTrading]} is required`,
  discountTradingInvalid: `${FormStrings[FormFields.discountTrading]} is invalid`,
  managedAccountsRequired: `${FormStrings[FormFields.managedAccounts]} is required`,
  managedAccountsInvalid: `${FormStrings[FormFields.managedAccounts]} is invalid`,
};

export const conditionValidation = (fieldName: any) => {
  switch (fieldName) {
    case FormFields.iPOsAndOfferings:
      return {
        key: FormFields.iPOsAndOfferings,
        value: Yup.string()
          .required(validationMessages.iPOsAndOfferingsRequired)
          .notOneOf(['-1'], validationMessages.iPOsAndOfferingsRequired)
          .oneOf([LiquidityNeeds[2].value, LiquidityNeeds[3].value], validationMessages.iPOsAndOfferingsInvalid),
      };
    case FormFields.crowdFundingRegCF:
      return {
        key: [FormFields.crowdFundingRegCF],
        value: Yup.string()
          .required(validationMessages.crowdFundingRegCFRequired)
          .notOneOf(['-1'], validationMessages.crowdFundingRegCFRequired)
          .oneOf([LiquidityNeeds[3].value], validationMessages.crowdFundingRegCFInvalid),
      };
    case FormFields.discountTrading:
      return {
        key: [FormFields.discountTrading],
        value: Yup.string()
          .required(validationMessages.discountTradingRequired)
          .notOneOf(['-1'], validationMessages.discountTradingRequired),
      };
    case FormFields.managedAccounts:
      return {
        key: [FormFields.managedAccounts],
        value: Yup.string()
          .required(validationMessages.managedAccountsRequired)
          .notOneOf(['-1'], validationMessages.managedAccountsRequired),
      };
    default:
      return null;
  }
};

export const defaultValidation = (formState: any) =>
  formState
    ? Yup.object().shape(
        Object.keys(formState)
          .map((key: any) => conditionValidation(key))
          .reduce((obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }), {}),
      )
    : Yup.object().shape({});

export default defaultValidation;
