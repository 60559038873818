import { css, cx } from 'emotion';
import * as Typography from '../../../../styles/typography';
import * as Color from '../../../../styles/color';

export const RightAlign = css`
  float: right;
`;

export const Label = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
`;
export const Value = css`
  word-break: break-word;
  font-family: ${Typography.GillSans} !important;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15873px;
`;
export const Right = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  text-align: right;
`;

export const BeneficiariesGroup = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.141414px;
  color: ${Color.DeepBlack};
`;

export const BeneficiariesTotalPercentage = css`
  font-family: ${Typography.GillSans};
  font-size: 14px;
  line-height: 40px;
  text-align: right;
  letter-spacing: 0.383838px;
  color: #143992;
`;

export const BeneficiariesPersonGroup = css`
  margin-bottom: 16px;
`;

export const BeneficiariesLabel = css`
  font-family: ${Typography.GillSans};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: ${Color.DarkerMediumGrey};
`;

export const BeneficiariesDivider = css`
  border: 1px solid #e9e9e9 !important;
  transform: rotate(180deg) !important;
  margin-bottom: 17px !important;
`;
