import { createAction, Type, State } from './utils';
import { AnyAction } from 'redux';

export const addSolution = (payload: any): AnyAction => createAction(State.actionRequested(Type.ADD_SOLUTION), payload);
export const removeSolution = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.REMOVE_SOLUTION), payload);
export const getSolutions = (): AnyAction => createAction(State.actionRequested(Type.GET_SOLUTIONS), {});

export const addSubSolution = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.ADD_SUB_SOLUTION), payload);
export const removeSubSolution = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.REMOVE_SUB_SOLUTION), payload);
export const getSubSolutions = (): AnyAction => createAction(State.actionRequested(Type.GET_SUB_SOLUTIONS), {});
