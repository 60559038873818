import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  sharePercentage: 'sharePercentage',
};

export const FormFieldsTypes = {
  [FormFields.sharePercentage]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.sharePercentage]: 'Share Percentage ( 0.1 - 100 )',
};
