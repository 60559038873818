import * as Yup from 'yup';
import { AuthFields, AuthStrings } from './constants';

export const validationMessages = {
  usernameNameRequired: `${AuthStrings[AuthFields.username]} is required`,
  usernameNameInvalid: `${AuthStrings[AuthFields.username]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [AuthFields.username]: Yup.string().required(validationMessages.usernameNameRequired),
});

export default defaultValidation;
