import reviewFieldTypes from './reviewFieldTypes';

export const SuitabilityRelatedForms = {
  selectYourRiskTolerance: 'selectYourRiskTolerance',
  selectYourInvestmentObjectives: 'selectYourInvestmentObjectives',
  selectYourLiquidityNeeds: 'selectYourLiquidityNeeds',
  selectYourTimeHorizon: 'selectYourTimeHorizon',
};

export const FormFields = {
  iPOsAndOfferings: 'iPOsAndOfferings',
  crowdFundingRegCF: 'crowdFundingRegCF',
  discountTrading: 'discountTrading',
  managedAccounts: 'managedAccounts',
};

export const SolutionFields = {
  myIpo: 'myIpo',
  readyRound: 'readyRound',
  tradingBlock: 'tradingBlock',
  folio: 'folio',
};

export const SolutionFormFieldsRelations = {
  [SolutionFields.myIpo]: FormFields.iPOsAndOfferings,
  [SolutionFields.readyRound]: FormFields.crowdFundingRegCF,
  [SolutionFields.tradingBlock]: FormFields.discountTrading,
  [SolutionFields.folio]: FormFields.managedAccounts,
};

export const FormFieldsTypes = {
  [FormFields.iPOsAndOfferings]: reviewFieldTypes.SelectOptions,
  [FormFields.crowdFundingRegCF]: reviewFieldTypes.SelectOptions,
  [FormFields.discountTrading]: reviewFieldTypes.SelectOptions,
  [FormFields.managedAccounts]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.iPOsAndOfferings]: 'IPOs and Offerings',
  [FormFields.crowdFundingRegCF]: 'CrowdFunding (Reg CF)',
  [FormFields.discountTrading]: 'Discount Trading',
  [FormFields.managedAccounts]: 'Managed Accounts',
};
