import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const StatementDeliveryTypes = {
  electronic: 'electronic',
  paper: 'paper',
} as any;

export const InitialValues = {
  [FormFields.deliveryType]: '-1',
};
export const FieldsOptions = {};
