import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  accountTypesRequired: `${FormStrings[FormFields.accountTypes]} are required`,
  investmentStrategiesRequired: `${FormStrings[FormFields.investmentStrategies]} are required`,
};

let defaultValidation = Yup.object().shape({
  [FormFields.accountTypes]: Yup.object()
    .shape({
      [FormFields.cash]: Yup.boolean(),
      [FormFields.options]: Yup.boolean(),
      [FormFields.margin]: Yup.boolean(),
    })
    .test(
      'At least one checked',
      validationMessages.accountTypesRequired,
      value => value[FormFields.cash] || value[FormFields.options] || value[FormFields.margin],
    ),
  [FormFields.investmentStrategies]: Yup.object()
    .shape({
      [FormFields.stocksETFsMutualFundsBonds]: Yup.boolean(),
      [FormFields.longCallsPutsCoveredOptions]: Yup.boolean(),
      [FormFields.optionSpreads]: Yup.boolean(),
      [FormFields.putWriting]: Yup.boolean(),
      [FormFields.uncoveredCallsIndexOptions]: Yup.boolean(),
    })
    .test(
      'At least one checked',
      validationMessages.investmentStrategiesRequired,
      value =>
        value[FormFields.stocksETFsMutualFundsBonds] ||
        value[FormFields.longCallsPutsCoveredOptions] ||
        value[FormFields.optionSpreads] ||
        value[FormFields.putWriting] ||
        value[FormFields.uncoveredCallsIndexOptions],
    ),
});

export default defaultValidation;
