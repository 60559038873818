import React from 'react';
import { Button } from 'antd';
import SVGIcon from '../SVGIcon/SVGIcon';
import * as Style from './Style';
import { css, cx } from 'emotion';

export type ButtonCompType = {
  id?: string;
  name: string;
  label: any;
  icon?: string;
  disabled?: boolean;
  floatRight?: boolean;
  onClick: Function;
  isActive?: boolean;
};

const NavButton = (props: ButtonCompType) => {
  const { id, name, label, onClick, disabled, icon, floatRight, isActive } = props;

  const classname = cx(
    Style.Button,
    Style.NavButton,
    floatRight &&
      css`
        float: right !important;
      `,
    isActive && Style.ActiveNavButton,
  );

  return (
    <Button id={!!id ? id : name} name={name} onClick={() => onClick()} className={classname} disabled={disabled}>
      <div className={Style.ButtonContent}>
        {icon && <SVGIcon iconName={icon} />}
        {label}
      </div>
    </Button>
  );
};

export default NavButton;
