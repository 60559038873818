import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues, FieldsOptions } from './schema';
import { TFormConfig } from '../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import categories from '../../../../constants/categories';
import { skipToBasedOnAccountType } from './helpers';

import UploadIdentification from '../UploadIdentification';
// todo: for individual flow to skip to UploadIdentification, for other types to corresponding screen
import AddingBeneficiaries from '../Retirement/AddingBeneficiaries';

const name = 'trustedContactInfo';
const path = 'trustedContactInfo';
const header = 'Trusted Contact (Optional)';
const reviewHeader = 'Trusted Contact';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  category: categories.personalInformation,
  backToReview: true,
  reviewHeader,
  //TODO: Refactor
  skipTo: (currentForm: any, history: any, isReview: any, formState: any) =>
    skipToBasedOnAccountType(formState.accountType.type, formState.accountType.subtype || null),
};

export default config;
