import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router';
import { useDispatch, useStore } from 'react-redux';

import FormContainer from '../shared/FormContainer/FormContainer';
import FullScreenForm from '../FullScreenForm/FullScreenForm';
import { Arrow, ArrowRight } from '../shared/Icon/Index';

import { getRoutes } from './formRouter';
import { enabledInjectedForms, getFormByLocation } from './formInjector';
import { TFormConfig } from './formTypes';
import * as Colors from '../../styles/color';

import * as Routes from '../../constants/routes';
import NavButton from '../shared/NavButton/NavButton';
import Strings from '../../constants/strings';
import TwoSidedFormContainer from '../shared/TwoSidedFormContainer/TwoSidedFormContainer';
import SolutionActionButton from '../shared/SolutionActionButton/SolutionActionButton';
import { getNextReviewForm } from './formReview';
import { onSkipClick } from '../../utils/skipForm';
import { addBackToReview, addingJointAccount } from '../../actions/navigation';
import { ENTITY_SOLE_PROPRIETORSHIP } from '../../constants/accountTypes';
import { name as UploadIdentificationName } from './Registration/UploadIdentification';
import { name as JointAccountDetailName } from './Registration/Joint/JointAccountDetail';
import { name as JointAccountsReviewName } from './Registration/Joint/JointAccountsReview';
import { name as ReviewName } from './Registration/Review';
import { name as RegulatoryDisclosuresName } from './Registration/RegulatoryDisclosures';
import { name as CountryOfResidenceName } from './Registration/CountryOfResidence';
import { findHistoryLocationId } from '../../utils/forms';

const BackButton = ({ onNavigateBack }: { onNavigateBack: Function }) => (
  <NavButton
    label={
      <>
        <Arrow width="12" height="12" viewBox="0 0 12 12" fill="#0098CE" />
        <span>{Strings.actions.back}</span>
      </>
    }
    name={Strings.actions.back}
    onClick={onNavigateBack}
  />
);

const SkipButton = ({ onSkip }: { onSkip: Function }) => (
  <NavButton
    floatRight
    label={
      <>
        <span>{Strings.actions.skip}</span>
        <ArrowRight width="12" height="12" viewBox="0 0 12 12" fill="#0098CE" />
      </>
    }
    name={Strings.actions.skip}
    onClick={onSkip}
  />
);

/**
 * FormManager Component
 * Handles management of Form routing and related state
 */
export default () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history: any = useHistory();
  const location = useLocation();
  //TODO: Use form selector
  const formState = store.getState().forms;
  const jointAccountsIntermediaryState = store.getState().intermediary.jointAccountsIntermediary;
  const [reviewParentForm, setReviewParentForm] = useState('');
  const [allInjectedForms, setAllInjectedForms] = useState([enabledInjectedForms[0]]);
  const [currentForm, setCurrentForm] = useState<TFormConfig>(allInjectedForms[0]);

  // const isReview = location.search.indexOf('r=1') !== -1;
  const backToReview = store.getState().navigation.backToReview;
  const addingJointAccountState = store.getState().navigation.addingJointAccount;
  const jointKey = findHistoryLocationId(history);
  // history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;

  /**
   * Pushes or replace to route
   * @param replace
   */
  const navigateToCurrentForm = (replace: boolean) => {
    // const route = `${Routes.REGISTER_INFO}/${currentForm.path}${isReview ? '?r=1' : ''}`;
    const route = `${Routes.REGISTER_INFO}/${currentForm.path}`;
    if (replace) {
      history.replace(route, history.location.state);
    } else {
      if (history.location.pathname.indexOf(currentForm.path) === -1) {
        history.push(route, history.location.state);
      }
    }
  };

  /**
   * Filter by account type and subtype
   */
  const filterFormsBy = () => {
    switch (formState.accountType.subtype) {
      case ENTITY_SOLE_PROPRIETORSHIP:
        return enabledInjectedForms.filter(
          form => !form.accountType || (!!form.accountSubType && form.accountSubType === ENTITY_SOLE_PROPRIETORSHIP),
        );
      default:
        return enabledInjectedForms.filter(
          form => !form.accountType || form.accountType === formState.accountType.type,
        );
    }
  };

  /**
   * Update forms based on what is selected
   */
  useEffect(() => {
    setAllInjectedForms(!!formState.accountType ? [...filterFormsBy()] : [...enabledInjectedForms]);
  }, [formState.accountType.type, formState.accountType.subtype]);

  /**
   * Perform first time navigation of currentForm
   */
  useEffect(() => {
    const shouldReplaceRoute = currentForm.name === allInjectedForms[0].name;
    navigateToCurrentForm(shouldReplaceRoute);
  }, [currentForm]);

  /**
   * Change currentForm on each location.pathname change
   */
  useEffect(() => {
    const formByLocation = getFormByLocation(history.location.pathname);
    if (formByLocation) {
      setCurrentForm(formByLocation);
      if (backToReview && !reviewParentForm) {
        setReviewParentForm(currentForm.name);
      }
    }
  }, [history.location.pathname, reviewParentForm]);

  /**
   * Navigate to next form, having current form as a reference.
   * Next form is retrieved when next index is bigger and validateRender condition is fulfilled
   */
  const getNextForm = (current: TFormConfig, state?: any) => {
    const currentFormIndex = allInjectedForms.findIndex(x => x.name === current.name);
    let didFindForm = false;
    let nextFormIndex = currentFormIndex;
    let nextForm = null;
    while (!didFindForm) {
      nextFormIndex++;
      nextForm = allInjectedForms[nextFormIndex];
      if (!nextForm.validateRender || (nextForm.validateRender && nextForm.validateRender(state))) {
        didFindForm = true;
        history.push(current.name, state);
      }
    }
    return nextForm;
  };

  /**
   * Navigate to next joint form, having current form as a reference.
   * Next form is retrieved when next index is bigger and validateRender condition is fulfilled
   * having the prop whenAddJointAccount
   */
  const getNextJointForm = (current: TFormConfig, state?: any) => {
    let jointForms = allInjectedForms.filter(y => y.whenAddJointAccount);
    const currentFormIndex = jointForms.findIndex(x => x.name === current.name);
    let didFindForm = false;
    let nextFormIndex = currentFormIndex;
    let nextForm = null;
    while (!didFindForm) {
      nextFormIndex++;
      if (current.name === RegulatoryDisclosuresName) {
        nextFormIndex = jointForms.findIndex(x => x.name === JointAccountsReviewName);
        dispatch(
          addingJointAccount({
            body: false,
          }),
        );
      }
      if (current.name === JointAccountDetailName) {
        nextFormIndex = jointForms.findIndex(x => x.name === CountryOfResidenceName);
      }
      if (current.name === JointAccountsReviewName) {
        nextFormIndex = backToReview
          ? allInjectedForms.findIndex(x => x.name === ReviewName)
          : allInjectedForms.findIndex(x => x.name === UploadIdentificationName);
        nextForm = allInjectedForms[nextFormIndex];
      } else {
        nextForm = jointForms[nextFormIndex];
      }
      if (!nextForm.validateRender || (nextForm.validateRender && nextForm.validateRender(state))) {
        didFindForm = true;
        history.push(current.name, state);
      }
    }
    return nextForm;
  };

  /**
   * Get the next form and navigates to it
   */
  const onNext = (state?: any) => {
    let nextForm: any = undefined;
    if (addingJointAccountState) {
      nextForm = getNextJointForm(currentForm, state || jointAccountsIntermediaryState);
    } else if (backToReview && currentForm.backToReview) {
      nextForm = getNextReviewForm(currentForm.name, reviewParentForm);
    } else {
      nextForm = getNextForm(currentForm, state || formState);
    }
    if (nextForm) {
      setCurrentForm(nextForm);
    } else {
      if (backToReview && currentForm.backToReview && !addingJointAccountState) {
        const reviewForm = allInjectedForms.find(x => x.name === 'review');
        if (reviewForm) setCurrentForm(reviewForm);
      }
    }
    if (backToReview && currentForm.backToReview && !addingJointAccountState) {
      dispatch(
        addBackToReview({
          body: false,
        }),
      );
    }
  };

  /**
   * Navigate back in browser history
   */
  const onNavigateBack = () => {
    history.goBack();
  };

  const routes = (
    <Switch>
      {getRoutes({
        id: currentForm.name,
        onContinue: (state: any) => onNext(state),
      })}
    </Switch>
  );

  return (
    <FullScreenForm color={Colors.FogBlue} isTwoSideForm={currentForm.twoSideFormMetadata ? true : false}>
      <BackButton onNavigateBack={onNavigateBack} />
      {currentForm.skipTo && (
        <SkipButton
          onSkip={() => onSkipClick(currentForm, history, backToReview && currentForm.backToReview, formState)}
        />
      )}
      <SolutionActionButton />
      {currentForm.twoSideFormMetadata ? (
        <TwoSidedFormContainer {...currentForm.twoSideFormMetadata}>{routes}</TwoSidedFormContainer>
      ) : (
        <FormContainer title={currentForm.header}>{routes}</FormContainer>
      )}
    </FullScreenForm>
  );
};
