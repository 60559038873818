import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { ENTITY } from '../../../../../constants/accountTypes';

const name = 'authorizedPersonContact';
export const path = 'authorizedPersonContact';
const header = 'Authorized Person Contact';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  accountType: ENTITY,
};

export default config;
