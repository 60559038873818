import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Routes from '../../../../../constants/routes';

import { FieldArray, Formik } from 'formik';
import Button, { ButtonType } from '../../../../shared/Buttons/Button';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';
import * as Styles from './Style';
import * as FormActions from '../../../../../actions';

import { path as AuthorizedPersonPath } from './../AuthorizedPersonName';

import { ContinueIntermediaryEntityAuthorizedAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import { Button as AntButton, Col, Row } from 'antd';
import { Trash, Edit } from './../../../../shared/Icon/Index';

const Component = (props: TForm) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.intermediary.entityAuthorizedPersonsIntermediary);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };
  const onAddAuthorisedPersonClick = () => {
    const route = `${Routes.REGISTER_INFO}/${AuthorizedPersonPath}`;
    history.push(route);
  };

  const editAuthorisedPerson = (values: any) => {
    const route = `${Routes.REGISTER_INFO}/${AuthorizedPersonPath}`;
    history.push(route, { ...values, id: values.id } as any);
  };

  const removeAuthorisedPerson = (id: number) => {
    dispatch(
      FormActions.deleteEntityAuthorisedPersonIntermediaryFormState({
        id: id,
        body: { id: id },
      }),
    );
  };
  /*
   * Remove from store the beneficiaries created in tentative.
   */
  useMemo(() => {
    state.map((item: any) => {
      if (!item.username || item.username === null || item.username === '') {
        removeAuthorisedPerson(item.id);
      }
    });
  }, [state.length]);

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <FieldArray name={FormFields.authorizedPersons} validateOnChange>
              {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                return values.map((item: any, index: number) => (
                  <Row className={Styles.MarginBottom8}>
                    <Col span={14} className={Styles.AuthPerson}>
                      {`${item.firstName} ${item.lastName}`}
                    </Col>
                    <Col span={10}>
                      <AntButton
                        className={Styles.TrashButton}
                        size="small"
                        onClick={() => removeAuthorisedPerson(item.id)}
                      >
                        <Trash width="14" height="16" viewBox="0 0 14 16" fill="#FF244E" />
                      </AntButton>
                      <AntButton
                        className={Styles.EditButton}
                        size="small"
                        onClick={() => {
                          editAuthorisedPerson(item);
                        }}
                      >
                        <Edit width="14" height="16" viewBox="0 0 14 16" fill="#0098CE" />
                      </AntButton>
                    </Col>
                  </Row>
                ));
              }}
            </FieldArray>
            <Button
              label="Click here to add additional authorized persons"
              type={ButtonType.Link}
              onClick={() => onAddAuthorisedPersonClick()}
              disabled={false}
              className={Styles.AddAuthorizedPersons}
            />
            <ContinueIntermediaryEntityAuthorizedAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
