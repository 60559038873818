import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Divider, Row } from 'antd';
import { useHistory } from 'react-router';
import SVGIcon from '../SVGIcon/SVGIcon';

import { getNextReviewForm } from '../../forms/formReview';

import * as Styles from './Style';
import * as Routes from '../../../constants/routes';
import { addBackToReview } from '../../../actions/navigation';

export type TExpandableReview = {
  id: string;
  title?: string;
  children?: React.ReactNode;
  onEdit?: Function;
};

const ExpandableReview = (props: TExpandableReview) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, title, children, onEdit } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const togglePanel = () => setIsExpanded(!isExpanded);

  const onEditClick = () => {
    onEdit && onEdit();
    const action = addBackToReview({
      body: true,
    });
    dispatch(action);
    // histoy.push(`${Routes.REGISTER_INFO}/${id}?r=1`);
    history.push(`${Routes.REGISTER_INFO}/${id}`);
  };

  return (
    <>
      <div className={Styles.Expandable}>
        <Row onClick={togglePanel} justify="space-between" align="middle" className={Styles.Header}>
          <Col span={23}>{title}</Col>
          <Col span={1}>
            <div className={Styles.RightAlign}>
              <SVGIcon className={isExpanded ? Styles.IndicatorRotate : undefined} iconName={'toggle-indicator.svg'} />
            </div>
          </Col>
        </Row>
        {isExpanded && (
          <>
            <div className={Styles.Content}>{children}</div>
            <Row justify="center" align="middle" className={Styles.DividerParent}>
              <Divider className={Styles.Divider} />
            </Row>
            <Row justify="center" align="middle">
              <Col span={24}>
                <Button className={Styles.EditButton} onClick={() => onEditClick()} type={'link'}>
                  Edit
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ExpandableReview;
