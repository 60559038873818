import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Routes from '../../../../../constants/routes';

import { FieldArray, Formik } from 'formik';

import Button, { ButtonType } from '../../../../shared/Buttons/Button';
import { Typography } from '../../../../../styles';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';
import * as Styles from './Style';
import * as FormActions from '../../../../../actions';

import { path as AddBeneficiaryPath } from '../BeneficiaryType';

import { ContinueRetirementAction } from '../../../FormActions';

import { getFormState } from '../../../../../utils/forms';
import { BeneficiaryPercentage } from './beneficiaryPercentage';
import PercentageTotal from './percentageTotal';

const Component = (props: TForm) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.intermediary.retirementIntermediary);
  const formState = getFormState(
    {
      ...InitialValues,
      [FormFields.sharePercentage]: state,
    },
    InitialValues,
  );

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue(values.sharePercentage);
  };
  const onAddBeneficiariesClick = (isContingent: boolean, values: any) => {
    dispatch(
      FormActions.saveRetirementIntermediaryFormState({
        body: values.sharePercentage,
      }),
    );
    const route = `${Routes.REGISTER_INFO}/${AddBeneficiaryPath}`;
    history.push(route, { isContingent });
  };

  const deleteBeneficiaryIntermediate = (id: number) => {
    dispatch(
      FormActions.deleteRetirementIntermediaryFormState({
        id: id,
        body: { id: id },
      }),
    );
  };
  /*
   * Remove from store the beneficiaries created in tentative.
   */
  useMemo(() => {
    state.map((item: any) => {
      if (
        ((!item.type || item.type === 'individual') && (!item.ssn || item.ssn === null || item.ssn === '')) ||
        ((!item.type || item.type === 'trust') &&
          (!item.trustTaxID || item.trustTaxID === null || item.trustTaxID === ''))
      ) {
        deleteBeneficiaryIntermediate(item.id);
      }
    });
  }, [state.length]);

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        const setPrimary = () => {
          form.setFieldValue(
            FormFields.percentagePrimary,
            form.values[`${FormFields.sharePercentage}`]
              .map((item: any) => !item.isContingent && item.sharePercentage)
              .reduce((a: any, b: any) => a + parseFloat(b || 0), 0),
          );
        };
        const setContingent = () => {
          form.setFieldValue(
            FormFields.percentageContingent,
            form.values[`${FormFields.sharePercentage}`]
              .map((item: any) => item.isContingent && item.sharePercentage)
              .reduce((a: any, b: any) => a + parseFloat(b || 0), 0),
          );
        };
        return (
          <>
            <div className={Styles.GroupTypes}>
              <div className={Styles.GroupHeadContainer}>
                <div className={Styles.GroupHeadText}>Primary Beneficiaries</div>
                <div>
                  <Button
                    label="Add Beneficiary"
                    type={ButtonType.Link}
                    onClick={() => {
                      onAddBeneficiariesClick(false, form.values);
                    }}
                    disabled={false}
                    className={Styles.AddBeneficiary}
                  />
                </div>
              </div>
              <hr className={Typography.hr} />
              <FieldArray name={FormFields.sharePercentage} validateOnChange>
                {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                  return values[`${FormFields.sharePercentage}`].map(
                    (item: any, index: number) =>
                      !item.isContingent && (
                        <BeneficiaryPercentage
                          key={index}
                          index={index}
                          item={item}
                          form={form}
                          name={item.type === 'trust' ? item.nameOfTrust : item.firstName + ' ' + item.lastName}
                          updateTotalPercentage={() => setPrimary()}
                          removeBeneficiary={() => {
                            deleteBeneficiaryIntermediate(item.id);
                            setPrimary();
                          }}
                        />
                      ),
                  );
                }}
              </FieldArray>
              <PercentageTotal
                {...form}
                value={form.values[`${FormFields.percentagePrimary}`]}
                label={FormStrings[FormFields.percentagePrimary]}
                name={FormFields.percentagePrimary}
              />
            </div>

            <div className={Styles.GroupTypes}>
              <div className={Styles.GroupHeadContainer}>
                <div className={Styles.GroupHeadText}>Contingent Beneficiaries</div>
                <div>
                  <Button
                    label="Add Beneficiary"
                    type={ButtonType.Link}
                    onClick={() => {
                      onAddBeneficiariesClick(true, form.values);
                    }}
                    disabled={false}
                    className={Styles.AddBeneficiary}
                  />
                </div>
              </div>
              <hr className={Typography.hr} />
              <FieldArray name={FormFields.sharePercentage} validateOnChange>
                {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                  return values[`${FormFields.sharePercentage}`].map(
                    (item: any, index: number) =>
                      item.isContingent && (
                        <BeneficiaryPercentage
                          key={index}
                          index={index}
                          item={item}
                          form={form}
                          name={item.type === 'trust' ? item.nameOfTrust : item.firstName + ' ' + item.lastName}
                          updateTotalPercentage={() => setContingent()}
                          removeBeneficiary={() => {
                            deleteBeneficiaryIntermediate(item.id);
                            setContingent();
                          }}
                        />
                      ),
                  );
                }}
              </FieldArray>
              <PercentageTotal
                {...form}
                value={form.values[`${FormFields.sharePercentage}`]
                  .map((item: any) => item.isContingent && item.sharePercentage)
                  .reduce((a: any, b: any) => a + parseFloat(b || 0), 0)}
                label={FormStrings[FormFields.percentageContingent]}
                name={FormFields.percentageContingent}
              />
            </div>
            <ContinueRetirementAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
