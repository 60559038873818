import * as Yup from 'yup';
import { AuthFields, AuthStrings } from './constants';

export const validationMessages = {
  emailNameRequired: `${AuthStrings[AuthFields.email]} is required`,
  emailNameInvalid: `${AuthStrings[AuthFields.email]} is invalid`,
  dialCodeInitialRequired: `${AuthStrings[AuthFields.dialCode]} is required`,
  dialCodeInitialInvalid: `${AuthStrings[AuthFields.dialCode]} is invalid`,
  phoneNumberInitialRequired: `${AuthStrings[AuthFields.phoneNumber]} is required`,
  phoneNumberInitialInvalid: `${AuthStrings[AuthFields.phoneNumber]} is invalid`,
  passwordInitialRequired: `${AuthStrings[AuthFields.password]} is required`,
  passwordInitialInvalid: `${AuthStrings[AuthFields.password]} is invalid`,
  repeatPasswordInitialRequired: `${AuthStrings[AuthFields.repeatPassword]} is required`,
  repeatPasswordInitialInvalid: `${AuthStrings[AuthFields.repeatPassword]} does not match with password`,
};

export const defaultValidation = Yup.object().shape({
  [AuthFields.email]: Yup.string()
    .required(validationMessages.emailNameRequired)
    .email(validationMessages.emailNameInvalid),
  [AuthFields.dialCode]: Yup.string()
    .required(validationMessages.dialCodeInitialRequired)
    .notOneOf(['-1'], validationMessages.dialCodeInitialRequired),
  [AuthFields.phoneNumber]: Yup.string().required(validationMessages.phoneNumberInitialRequired),
  [AuthFields.password]: Yup.string().required(validationMessages.passwordInitialRequired),
  [AuthFields.repeatPassword]: Yup.string()
    .required(validationMessages.repeatPasswordInitialRequired)
    .oneOf([Yup.ref('password')], validationMessages.repeatPasswordInitialInvalid),
});

export default defaultValidation;
