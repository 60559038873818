import _ from 'lodash';

/**
 * This method takes in a object consisting of route metadata,
 * and returns the nested child name/key of the matchedPath being searched by.
 * @param matchedPath The matched route/path name to search by
 * @param routes The object of sibling routes sharing a parent route/path
 */
export const getRelativePathName = (matchedPath: string, routes: any): string | undefined => {
  const foundRouteKey = _.find(Object.keys(routes), routeMetaKey => matchedPath.startsWith(routes[routeMetaKey].path));

  if (_.isEmpty(foundRouteKey)) {
    return undefined;
  }
  return foundRouteKey;
};
