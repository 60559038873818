import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const DropdownButton = css`
  background: ${Color.OffWhite}!important;
  border: 1px solid ${Color.FogBlue}!important;
  border-radius: 2px !important;
  padding: 15px 18px !important;
  min-height: 48px !important;
  font-family: ${Typography.GillSans} !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: right !important;
  letter-spacing: 0.254546px !important;
  color: ${Color.DeepBlack} !important;
`;

export const DropdownMenuList = css`
  padding: 16px !important;
  min-height: 50px !important;
  color: ${Color.DeepBlack} !important;
  &:hover,
  &:focus {
    background-color: ${Color.OffWhite}!important;
  }
`;

export const DropdownOverlay = css`
  background: ${Color.PureWhite};
  border: 1px solid ${Color.FogBlue};
  box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.15);
  border-radius: 2px;
  font-family: ${Typography.GillSans} !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: right !important;
  letter-spacing: 0.254546px !important;
  color: ${Color.DeepBlack};
  .ant-dropdown-menu {
    padding: 0px !important;
  }
`;

export const LogoutIcon = css`
  margin-bottom: -2px;
  margin-right: 11px;
`;

export const LogoutText = css`
  composes: ${DropdownMenuList};
  color: ${Color.FineBlue} !important;
  border-top: 1px solid ${Color.FogBlue} !important;
  padding-top: 12px !important;
`;
