import { css, cx } from 'emotion';

export const MarginBottom8 = css`
  margin-bottom: 8px !Important;
`;
export const MarginCheckBox = css`
  margin-top: 48px !Important;
  margin-bottom: 52px !Important;
`;
export const AuthPerson = css`
  font-family: GillSans;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: #423c3a;
  margin-top: 5px;
`;

export const UploadedId = css`
  font-family: Gill Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #077cb6;
  margin-top: 5px;
`;

export const Red = css`
  color: #f5222d !Important;
`;
