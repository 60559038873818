import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';
import { states } from '../../../../../constants/states';

export const StateOptions: TSelectOption[] = [
  {
    label: 'Select state',
    value: '-1',
  },
  ...states,
];
export const InitialValues = {
  [FormFields.addressLineOne]: '',
  [FormFields.addressLineTwo]: '',
  [FormFields.city]: '',
  [FormFields.state]: StateOptions[0].value,
  [FormFields.zipCode]: '',
  [FormFields.mailAddress]: false,
};

export const FieldsOptions = {
  [FormFields.state]: StateOptions,
};
