import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueEntityAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Input, { MaskType } from '../../../../shared/Inputs/Input';
import SelectInput from '../../../../shared/Select/Select';
import { StateOptions } from '../../PhysicalAddress/schema';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import { deleteEntityMailingAddress, saveEntityMailingAddress } from '../../../../../actions/forms';
import { name as MailingAddressID } from '../EntityMailingAddress';

const Component = (props: TForm) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => (state.forms.entity ? state.forms.entity.entityPhysicalAddress : null));
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (values[FormFields.mailAddress]) {
      dispatch(
        saveEntityMailingAddress({
          id: MailingAddressID,
          body: {
            [FormFields.addressLineOne]: values[FormFields.addressLineOne],
            [FormFields.addressLineTwo]: values[FormFields.addressLineTwo],
            [FormFields.city]: values[FormFields.city],
            [FormFields.state]: values[FormFields.state],
            [FormFields.zipCode]: values[FormFields.zipCode],
          },
        }),
      );
    }
    if (props.onContinue) props.onContinue(values);
  };

  const onChangeMailingAddress = (checked: boolean) => {
    let action = null;
    if (!checked) {
      action = deleteEntityMailingAddress({
        id: MailingAddressID,
      });
      dispatch(action);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.addressLineOne}
              label={FormStrings[FormFields.addressLineOne]}
              value={formState[FormFields.addressLineOne]}
            />
            <Input
              {...form}
              name={FormFields.addressLineTwo}
              label={FormStrings[FormFields.addressLineTwo]}
              value={formState[FormFields.addressLineTwo]}
            />
            <Input
              {...form}
              name={FormFields.city}
              label={FormStrings[FormFields.city]}
              value={formState[FormFields.city]}
            />
            <SelectInput
              {...form}
              name={FormFields.state}
              label={FormStrings[FormFields.state]}
              options={StateOptions}
              defaultOption={formState[FormFields.state]}
            />
            <Input
              {...form}
              name={FormFields.zipCode}
              label={FormStrings[FormFields.zipCode]}
              value={formState[FormFields.zipCode]}
            />
            <Checkbox
              {...form}
              name={FormFields.mailAddress}
              label={FormStrings[FormFields.mailAddress]}
              checked={formState[FormFields.mailAddress]}
              onChange={onChangeMailingAddress}
            />
            <ContinueEntityAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
