import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  // [FormFields.cambriaCapital]: `${FormStrings[FormFields.cambriaCapital].name} is required`,
  // [FormFields.myIpo]: `${FormStrings[FormFields.myIpo].name} is required`,
  // [FormFields.folio]: `${FormStrings[FormFields.folio].name} is required`,
  // [FormFields.readyRound]: `${FormStrings[FormFields.readyRound].name} is required`,
  // [FormFields.tradingBlock]: `${FormStrings[FormFields.tradingBlock].name} is required`,
  // [FormFields.options]: `${FormStrings[FormFields.options].name} is required`,
  // [FormFields.margin]: `${FormStrings[FormFields.margin].name} is required`,
  // [FormFields.apex]: `${FormStrings[FormFields.apex].name} is required`,
  signature: `Signature is required`,
};

export const defaultValidation = Yup.object().shape({
  // [FormFields.cambriaCapital]: Yup.boolean().required(validationMessages[FormFields.cambriaCapital]),
  // [FormFields.myIpo]: Yup.boolean().required(validationMessages[FormFields.myIpo]),
  // [FormFields.folio]: Yup.boolean().required(validationMessages[FormFields.folio]),
  // [FormFields.readyRound]: Yup.boolean().required(validationMessages[FormFields.readyRound]),
  // [FormFields.tradingBlock]: Yup.boolean().required(validationMessages[FormFields.tradingBlock]),
  // [FormFields.options]: Yup.boolean().required(validationMessages[FormFields.options]),
  // [FormFields.margin]: Yup.boolean().required(validationMessages[FormFields.margin]),
  // [FormFields.apex]: Yup.boolean().required(validationMessages[FormFields.apex]),
  signature: Yup.string().required(validationMessages.signature),
});

export default defaultValidation;
