import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  stateOfResidency: 'stateOfResidency',
  citizenship: 'citizenship',
};

export const FormFieldsTypes = {
  [FormFields.stateOfResidency]: reviewFieldTypes.SelectOptions,
  [FormFields.citizenship]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.stateOfResidency]: 'State of Residency',
  [FormFields.citizenship]: 'Citizenship',
};
