import { createAction, Type, State } from './utils';
import { AnyAction } from 'redux';
import { actionRequested } from './types';

export const login = (payload: any): AnyAction => createAction(State.actionRequested(Type.LOGIN), payload);

export const clearLogin = (payload: any): AnyAction => createAction(State.actionRequested(Type.CLEAR_LOGIN), payload);

export const register = (payload: any): AnyAction => createAction(State.actionRequested(Type.REGISTER), payload);

export const clearRegister = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CLEAR_REGISTRATION), payload);

export const forgotPassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.FORGOT_PASSWORD), payload);

export const clearForgotPassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CLEAR_FORGOT_PASSWORD), payload);

export const confirmEmail = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CONFIRM_EMAIL), payload);

export const changePassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CHANGE_PASSWORD), payload);

export const setAuthToken = (payload: any): AnyAction => createAction(actionRequested(Type.SET_AUTH_TOKEN), payload);

export const removeAuthToken = (payload: string): AnyAction =>
  createAction(actionRequested(Type.REMOVE_AUTH_TOKEN), payload);

export const logout = (): AnyAction => createAction(actionRequested(Type.LOGOUT), {});

export const resetPassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.RESETPASSWORD), payload);
