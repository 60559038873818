import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  country: 'country',
};

export const FormFieldsTypes = {
  [FormFields.country]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.country]: 'Country',
  formText:
    'Certain products we offer are only available to residents of specific countries. Please select your country of residence so we can ensure the products you’ve selected are available.',
};
