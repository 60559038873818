import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', height = '100%', className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M5.84197 11.9047L5.31164 11.3682C5.18612 11.2412 5.18612 11.0353 5.31164 10.9083L9.46691 6.70455L0.321428 6.70455C0.143918 6.70455 -5.73402e-07 6.55895 -5.57702e-07 6.37937L-4.91369e-07 5.62061C-4.7567e-07 5.44103 0.143919 5.29542 0.321428 5.29542L9.46691 5.29543L5.31164 1.09162C5.18612 0.964641 5.18612 0.758746 5.31164 0.631735L5.84197 0.0952374C5.96749 -0.0317465 6.17101 -0.0317465 6.29655 0.0952374L11.9059 5.77006C12.0314 5.89704 12.0314 6.10294 11.9059 6.22995L6.29655 11.9048C6.17103 12.0317 5.96751 12.0317 5.84197 11.9047Z"
    />
  </svg>
);

export default SVG;
