import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  relationship: 'relationship',
  relationshipLabel: 'relationshipLabel',
  trustedText: 'trustedText',
};

export const FormFieldsTypes = {
  [FormFields.firstName]: reviewFieldTypes.RawString,
  [FormFields.lastName]: reviewFieldTypes.RawString,
  [FormFields.phoneNumber]: reviewFieldTypes.RawString,
  [FormFields.email]: reviewFieldTypes.RawString,
  [FormFields.relationship]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.firstName]: 'First Name',
  [FormFields.lastName]: 'Last Name',
  [FormFields.phoneNumber]: 'Phone Number',
  [FormFields.email]: 'Email',
  [FormFields.relationship]: 'Relationship',
  [FormFields.relationshipLabel]: 'Select Relationship',
  [FormFields.trustedText]:
    'A Trusted Contact Person (“TCP”) is someone that you tell us we can contact if we have questions about your well-being. By providing this information, you authorize us to contact the TCP and disclose information about you in order to confirm the specifics of your current contact information, health status and inquire if another person or entity has legal authority to act on your behalf (such as legal guardian, executor, trustee or holder of a power of attorney). The TCP must be at least 18 years old and will not have the ability to transact on your account.',
};
