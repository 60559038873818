import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.firstName]: '',
  [FormFields.middleInitial]: '',
  [FormFields.lastName]: '',
};
export const FieldsOptions = {};
