import { useSelector } from 'react-redux';
import { findHistoryLocationId, getFormState, jointAccountsIntermediaryFilter } from '../utils/forms';

export default (formName: string, InitialValues: any, history: any) => {
  const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  const key: any = findHistoryLocationId(history);
  const hasKey: any = key || key === 0;
  const state = useSelector((state: any) =>
    hasKey && addingJointAccount ? jointAccountsIntermediaryFilter(state, formName, key) : state.forms[formName],
  );
  const formState: any = getFormState(state, InitialValues);
  return { key, formState, hasKey };
};
