import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';
import { Col, Icon, Row, Upload } from 'antd';

import { FormText, LinkClickable } from '../../../../styles/typography';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import InfoText from '../../../shared/InfoText/InfoText';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/es/upload';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.uploadIdentification);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  const [preview, setPreview] = useState(formState[FormFields.identification]);

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        const handleFileChange = (e: UploadChangeParam<UploadFile<any>>) => {
          if (!e.file) {
            return;
          }
          // form.setFieldValue(FormFields.identification, e.file);
        };
        return (
          <>
            <InfoText>{FormStrings.identificationText}</InfoText>
            <div>
              <Row gutter={[33, 15]}>
                <Col span={24}>
                  <img src={preview !== '' ? preview : '/img/id_placeholder.png'} width={300} height={160} />
                </Col>
                <Col span={24}>
                  <Upload
                    name={FormFields.identification}
                    onChange={handleFileChange}
                    showUploadList={false}
                    beforeUpload={file => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = (e: any) => {
                        setPreview(e.target.result);
                        form.setFieldValue(FormFields.identification, e.target.result);
                      };
                      // Prevent upload
                      return false;
                    }}
                    listType={'picture'}
                    style={{ cursor: 'pointer' }}
                  >
                    <p className={LinkClickable}>{FormStrings.identification}</p>
                  </Upload>
                </Col>
              </Row>
            </div>
            <br />
            <br />
            <Checkbox
              {...form}
              name={FormFields.uploadLater}
              label={FormStrings.uploadLater}
              checked={
                formState[FormFields.uploadLater] ? formState[FormFields.uploadLater] : InitialValues.uploadLater
              }
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
