import moment, { Moment } from 'moment';
import reviewFieldTypes from '../constants/reviewFieldTypes';
import _ from 'lodash';

export const dateToString = (m: any) => {
  if (typeof m === 'string') {
    return moment(m).format('MMM D, YYYY');
  } else if (moment.isMoment(m)) {
    return m.format('MMM D, YYYY');
  } else {
    return m;
  }
};

export const booleanToString = (b: boolean) => {
  return b ? 'Yes' : 'No';
};

export const ssnToMasked = (s: string) => {
  return 'XXXX-XX-' + s.substr(s.length - 4);
};

export const stringArrayToString = (a: any) => {
  return a.join();
};

export const selectOptionValueToString = (value: any, formFieldOptions: any) => {
  if (value === '-1') {
    return '-';
  }
  let q = _.find(formFieldOptions, { value: value });
  return q && 'label' in q ? q.label : value;
};

//TODO : a proper function to get value from constants
export const radioValueToString = (value: any, formFieldOptions: any) => {
  if (value === '-1') {
    return '-';
  }
  let q = _.find(formFieldOptions, { value: value });
  return q && 'label' in q ? q.label : value;
};

export const checkSelectToReview = (value: any, formFieldOptions: any) => {
  if (value === '-1') {
    return '-';
  }
  let q = _.find(formFieldOptions, { value: value });
  return q && 'label' in q ? q.label : value;
};

export const objectsInArrayToReview = (value: any, formFieldOptions: any) => {
  if (value === '-1') {
    return '-';
  }
  let q = _.find(formFieldOptions, { value: value });
  // return q && 'label' in q ? q.label : value;
  return '';
};

export const formatReviewValues: Function = (type: string) => {
  switch (type) {
    case reviewFieldTypes.DateString:
      return dateToString;
    case reviewFieldTypes.Boolean:
      return booleanToString;
    case reviewFieldTypes.SSNString:
      return ssnToMasked;
    case reviewFieldTypes.RadioGroup:
      return radioValueToString;
    case reviewFieldTypes.SelectOptions:
      return selectOptionValueToString;
    case reviewFieldTypes.StringArray:
      return stringArrayToString;
    case reviewFieldTypes.CheckSelect:
      return checkSelectToReview;
    case reviewFieldTypes.ArrayObjects:
      return objectsInArrayToReview;
    default:
      return (a: any, b?: any) => a;
  }
};

export const produceReviewDetailRow = (
  formStrings: any,
  formFieldTypes: any,
  formFieldsOptions: any,
  key: string,
  value: any,
  form?: any,
) => {
  const formatValue: any = formatReviewValues(formFieldTypes[key]);
  const options = key in formFieldsOptions ? formFieldsOptions[key] : {};

  if (formFieldTypes[key] === reviewFieldTypes.Skip) {
    return {
      label: '',
      value: '',
    };
  }
  if (formFieldTypes[key] === reviewFieldTypes.Boolean) {
    const detail = value
      ? {
          label: formStrings[key],
          value: '',
          sub: null,
        }
      : {
          label: null,
          value: null,
          sub: null,
        };
    return detail;
  }
  if (formFieldTypes[key] === reviewFieldTypes.CheckSelect) {
    const sub = getOtherRelatedOptions(form, key, formFieldsOptions, formStrings);
    return value
      ? {
          label: formStrings[key],
          value: booleanToString(value),
          sub: sub.length === 0 ? null : sub,
        }
      : {
          label: null,
          value: null,
          sub: null,
        };
  }
  if (formFieldTypes[key] === reviewFieldTypes.ArrayObjects) {
    const sub = getOtherRelatedValues(form, key, formFieldsOptions, formStrings);
    return {
      label: formStrings[key],
      value: booleanToString(value),
      sub: sub.length === 0 ? null : sub,
    };
  }
  if (formFieldTypes[key] === reviewFieldTypes.EmptiableString) {
    const detail = value
      ? {
          label: formStrings[key],
          value: formatValue(value, options),
          sub: null,
        }
      : {
          label: null,
          value: null,
          sub: null,
        };
    return detail;
  }
  return {
    label: formStrings[key],
    value: formatValue(value, options),
    sub: null,
  };
};

const getOtherRelatedOptions = (form: any, field: string, formFieldsOptions: any, formStrings: any) => {
  return Object.keys(form)
    .filter(val => val.includes(field + '-'))
    .map(key => {
      if (key.includes(field + '-')) {
        const onlyKey = key.split('-')[1];
        const formatValue: any = formatReviewValues(reviewFieldTypes.SelectOptions);
        const opt = onlyKey in formFieldsOptions ? formFieldsOptions[onlyKey] : {};
        return {
          label: formStrings[onlyKey],
          value: formatValue(form[key], opt, form),
        };
      }
    });
};

const getOtherRelatedValues = (form: any, field: string, formFieldsOptions: any, formStrings: any) => {
  let result: any = [];
  form[field + 'Details'].map((obj: any) => {
    Object.keys(obj).map(key => {
      result.push({
        label: formStrings[key],
        value: obj[key],
      });
    });
  });
  return result;
};
