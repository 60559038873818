import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.country]: 'us',
};

export const CountryOptions: TSelectOption[] = [
  {
    label: 'United States of America',
    value: 'us',
  },
  {
    label: 'Other',
    value: 'x',
  },
];

export const FieldsOptions = { [FormFields.country]: CountryOptions };
