import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';
import { getRangeOptions } from './helpers';

export const TotalNetWorthOptions: TSelectOption[] = [
  {
    label: 'Select Range',
    value: '-1',
  },
  ...getRangeOptions(),
];

export const LiquidNetWorthOptions: TSelectOption[] = [
  {
    label: 'Select Range',
    value: '-1',
  },
  ...getRangeOptions(),
];

export const InitialValues = {
  [FormFields.totalNetWorth]: TotalNetWorthOptions[0].value,
  [FormFields.liquidNetWorth]: LiquidNetWorthOptions[0].value,
};

export const FieldsOptions = {
  [FormFields.totalNetWorth]: TotalNetWorthOptions,
  [FormFields.liquidNetWorth]: LiquidNetWorthOptions,
};
