import _ from 'lodash';

import Input from '../../../shared/Inputs/Input';

export default (contactInformation: any): any => ({
  firstName: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.firstName : '-',
    isReadOnly: true,
    props: {
      name: 'firstName',
      label: 'First Name',
      value: !_.isEmpty(contactInformation) ? contactInformation.firstName : null,
    },
  },
  lastName: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.lastName : '-',
    isReadOnly: true,
    props: {
      name: 'lastName',
      label: 'Last Name',
      value: !_.isEmpty(contactInformation) ? contactInformation.lastName : null,
    },
  },
  email: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.email : null,
    isReadOnly: true,
    props: {
      name: 'email',
      label: 'Email',
      value: !_.isEmpty(contactInformation) ? contactInformation.email : null,
    },
  },
  homePhoneNumber: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.homePhoneNumber : null,
    isReadOnly: false,
    component: Input,
    props: {
      name: 'homePhoneNumber',
      label: 'Home Phone Number',
      value: !_.isEmpty(contactInformation) ? contactInformation.homePhoneNumber : null,
    },
  },
  mobilePhoneNumber: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.mobilePhoneNumber : null,
    isReadOnly: false,
    component: Input,
    props: {
      name: 'mobilePhoneNumber',
      label: 'Mobile Phone Number',
      value: !_.isEmpty(contactInformation) ? contactInformation.mobilePhoneNumber : null,
    },
  },
  workPhoneNumber: {
    displayValue: !_.isEmpty(contactInformation) ? contactInformation.workPhoneNumber : null,
    isReadOnly: false,
    component: Input,
    props: {
      name: 'workPhoneNumber',
      label: 'Work Phone Number',
      value: !_.isEmpty(contactInformation) ? contactInformation.workPhoneNumber : null,
    },
  },
});
