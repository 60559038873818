import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import { FieldsOptions } from './schema';
import categories from '../../../../../constants/categories';
import { JOINT } from '../../../../../constants/accountTypes';

export const name = 'jointAccountDetail';
const path = 'jointAccountDetail';
const header = 'Joint Account Detail';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  backToReview: false,
  accountType: JOINT,
  whenAddJointAccount: true,
};

export default config;
