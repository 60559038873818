export const FormFields = {
  accountTypes: 'accountTypes',
  cash: `cash`,
  options: `options`,
  margin: `margin`,
  investmentStrategies: 'investmentStrategies',
  stocksETFsMutualFundsBonds: `stocksETFsMutualFundsBonds`,
  longCallsPutsCoveredOptions: `longCallsPutsCoveredOptions`,
  optionSpreads: `optionSpreads`,
  putWriting: `putWriting`,
  uncoveredCallsIndexOptions: `uncoveredCallsIndexOptions`,
};

export const FormStrings = {
  [FormFields.accountTypes]: 'Account Types',
  [FormFields.cash]: 'Cash',
  [FormFields.options]: 'Options',
  [FormFields.margin]: 'Margin',
  [FormFields.investmentStrategies]: 'Investment Strategies',
  [FormFields.stocksETFsMutualFundsBonds]: 'Stocks, ETFs, Mutual Funds, Bonds',
  [FormFields.longCallsPutsCoveredOptions]: 'Long Calls/Puts, Covered Options',
  [FormFields.optionSpreads]: 'Option Spreads',
  [FormFields.putWriting]: 'Put Writing',
  [FormFields.uncoveredCallsIndexOptions]: 'Uncovered Calls, Index Options',
};
