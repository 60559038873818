import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';

import { FormText } from '../../../../styles/typography';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, StatementDeliveryTypes } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import InfoText from '../../../shared/InfoText/InfoText';
import { Row } from 'antd';
import RadioGroup from '../../../shared/Radio/Radio';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.statementDelivery);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <InfoText>
              We provide electronic statements for free. Paper statements require extra fees. Please select your option
              below.
            </InfoText>
            <Row>
              <RadioGroup
                {...form}
                radioOptions={map(StatementDeliveryTypes, (value: string, key: string) => ({
                  label: FormStrings[key],
                  value,
                }))}
                name={FormFields.deliveryType}
                defaultValue={
                  formState[FormFields.deliveryType]
                    ? formState[FormFields.deliveryType]
                    : StatementDeliveryTypes.electronic
                }
              />
            </Row>
            <InfoText>Paper statement fees: $5 for monthly statements, $2 for trade confirmations</InfoText>
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
