import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.birthday]: undefined,
  [FormFields.ssn]: '',
};

export const FieldsOptions = {};
