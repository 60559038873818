import produce from 'immer';
import initialState from './initial';
import { Type } from '../actions/utils';
import { actionSucceeded, actionFailed } from '../actions/types';
import { TReduxAction } from '../types/commonTypes';

export const solutions = (state: any = initialState.solutions, action: TReduxAction) => {
  switch (action.type) {
    case actionSucceeded(Type.ADD_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.solutionCode] = action.payload.body;
      })(state, action);
    case actionFailed(Type.ADD_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);
    case actionSucceeded(Type.REMOVE_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        delete draftState[action.payload.solutionCode];
      })(state, action);
    case actionFailed(Type.REMOVE_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);
    case actionSucceeded(Type.GET_SOLUTIONS):
      //TODO: To be replaced when get solutions connects to the backend
      return action.payload;
    case actionFailed(Type.GET_SOLUTIONS):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    case actionSucceeded(Type.ADD_SUB_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.solutionCode]['products'] = action.payload.body;
      })(state, action);
    case actionFailed(Type.ADD_SUB_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    case actionSucceeded(Type.REMOVE_SUB_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        delete draftState['tradingBlock'].products.investmentStrategies[action.payload.solutionCode];
      })(state, action);
    case actionFailed(Type.REMOVE_SUB_SOLUTION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    case actionSucceeded(Type.GET_SUB_SOLUTIONS):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.solutionCode] = action.payload.body;
      })(state, action);
    case actionFailed(Type.GET_SUB_SOLUTIONS):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);
    default:
      return state;
  }
};
