import React from 'react';
import { Col, Row } from 'antd';
import * as Styles from './Style';

export type TReviewDetail = {
  label: string;
  value: any;
  sub?: any;
};

const ReviewDetail = (props: TReviewDetail) => {
  const { label, value, sub } = props;
  //TODO : if possible to calculate span
  const hasSubOptions = !!sub;
  // const calculateColSpans = (label: any, value: any) => {
  //   let labelX: any = ((label.length / (label.length + value.length)) * 24).toFixed();
  //   let valueX = 24 - labelX;
  //   return {
  //     lSpan: labelX,
  //     vSpan: valueX,
  //   };
  // };
  let subOption = null;
  if (hasSubOptions) {
    subOption = sub.map((obj: any) => {
      return (
        <Row justify="space-between" align="middle" gutter={[0, 10]}>
          <Col span={12} className={(Styles.Label, Styles.Right)}>
            {obj.label}
          </Col>
          <Col span={12}>
            <div className={Styles.Value}>{obj.value}</div>
          </Col>
        </Row>
      );
    });
  }
  if (label === null) return <></>;
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[0, 10]}>
        <Col span={16} className={Styles.Label}>
          {label}
        </Col>
        <Col span={8}>
          <div className={Styles.Value}>{value}</div>
        </Col>
      </Row>
      {subOption}
    </>
  );
};

export default ReviewDetail;
