import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  email: 'email',
  username: 'username',
};

export const FormFieldsTypes = {
  [FormFields.email]: reviewFieldTypes.RawString,
  [FormFields.username]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.email]: 'Email',
  [FormFields.username]: 'Username',
};
