import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';

import { FormText } from '../../../../../styles/typography';
import SelectInput from '../../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueAction } from '../../../FormActions';
import Input from '../../../../shared/Inputs/Input';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.custodialMinorName);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.firstName}
              label={FormStrings[FormFields.firstName]}
              value={formState[FormFields.firstName]}
            />
            <Input
              {...form}
              name={FormFields.middleInitial}
              label={FormStrings[FormFields.middleInitial]}
              value={formState[FormFields.middleInitial]}
            />
            <Input
              {...form}
              name={FormFields.lastName}
              label={FormStrings[FormFields.lastName]}
              value={formState[FormFields.lastName]}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
