import { css, cx } from 'emotion';
import * as Typography from '../../../../../styles/typography';
import * as Color from '../../../../../styles/color';

export const GroupHeadText = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.185185px;
  color: ${Color.DarkerMediumGrey};
`;

export const GroupHeadContainer = css`
  display: flex;
  justify-content: space-between;
`;

export const AddBeneficiary = css`
  height: 17px !important;
`;

export const GroupTypes = css`
  margin-bottom: 42px !important;
`;

export const BeneficiaryPerson = css`
  font-family: ${Typography.GillSans};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.15873px;
  color: ${Color.DarkerMediumGrey};
  display: inline-block !important;
  padding-right: 20px !important;
`;

export const LabelTotalText = css`
  font-family: GillSans !Important;
  font-size: 12px !Important;
  line-height: 14px !Important;
  letter-spacing: 0.15873px !Important;
  color: #423c3a !Important;
  margin-bottom: 4px !Important;
`;

export const TotalPercentage = css`
  font-family: ${Typography.GillSans};
  font-size: 38px;
  line-height: 40px;
  text-align: right;
  letter-spacing: 0.383838px;
  color: ${Color.DeepBlue};
`;

export const Right = css`
  text-align: right;
`;

export const Container = css`
  margin: 8px 0px !important;
  padding: 0 !important;
  display: inline-block !important;
  width: 100% !important;
`;

export const TrashButton = css`
  display: inline-block !important;
  float: right !important;
  min-width: 24px !important;
  padding-top: 3px !important;
  background-color: rgba(255, 36, 78, 0.1) !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  &:hover {
    background-color: rgba(255, 36, 78, 1) !important;
    border-color: rgba(255, 36, 78, 1) !important;
    .svg-icon path {
      fill: white;
    }
  }
`;

export const EditLinkButton = css`
  margin-left: 8px !important;
`;
