import React, { FunctionComponent, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import NavButton from '../NavButton/NavButton';
import ActionItem from './ActionItem';

import * as Styles from './Styles';
import { useSelector } from 'react-redux';
import { Settings } from '../Icon/Index';
import { FormFields, FormStrings } from '../../forms/Registration/TradingBlockProducts/constants';

const SolutionActionButton: FunctionComponent<{ initial?: boolean; solutions?: any }> = ({ initial = false }) => {
  const [visible, setVisible] = useState(initial);
  const solutions = useSelector((state: any) => state.solutions);

  const solutionsList = Object.values(solutions).map((solution: any, index: number) => {
    return (
      <>
        <Col span={24} key={index}>
          <ActionItem
            id={solution.field}
            isPrimary
            solutionName={solution.name}
            solutionCode={solution.field}
            icon={solution.icon}
            solutions={Object.keys(solutions)}
          />
        </Col>

        {solution.name === 'Discount Trading' &&
          Object.entries(solution.products.investmentStrategies).map(([key, value]) => {
            if (value) {
              return (
                <Col span={24} key={index}>
                  <ActionItem id={key} solutionName={FormStrings[key]} solutionCode={key} />
                </Col>
              );
            }
          })}
      </>
    );
  });

  const menu = (
    <Row style={{ maxWidth: '290px' }}>
      <Col span={24}>
        <span className={Styles.title}>Your Solutions</span>
        <a style={{ float: 'right' }} onClick={() => setVisible(!visible)}>
          Cancel
        </a>
      </Col>
      <Col span={24}>
        <hr style={{ border: '1px solid #DFE5F0' }} />
      </Col>
      {solutionsList}
    </Row>
  );
  return (
    <>
      <Popover
        className={Styles.popoverStyle}
        placement="bottomRight"
        content={menu}
        trigger="click"
        visible={visible}
        onVisibleChange={() => setVisible(!visible)}
      >
        <NavButton
          name={'EditSolutions'}
          onClick={() => {}}
          isActive={visible}
          label={
            <span style={{ display: 'inline-flex' }}>
              <Settings width="12" height="12" viewBox="0 0 12 12" fill={visible ? '#fff' : '#0098CE'} />
              <span>Edit Solutions</span>
            </span>
          }
          floatRight
        />
      </Popover>
    </>
  );
};

export default SolutionActionButton;
