import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AuthActions from '../../actions';
import { history } from '../../store';
import { AuthState } from './authType';
import { LOGIN, REGISTER, FORGOT_PASSWORD } from '../../constants/routes';
import Button, { ButtonType } from '../shared/Buttons/Button';

export const DirectToRegister = () => {
  const dispatch = useDispatch();
  const register = () => {
    dispatch(
      AuthActions.setAuthToken({
        authState: AuthState.SIGN_UP,
      }),
    );
    history.push(REGISTER);
  };
  return <Button onClick={() => register()} label={'Sign Up Now?'} type={ButtonType.Link} />;
};

export const DirectToForgotPassword = () => {
  const dispatch = useDispatch();
  const forgotPassword = () => {
    dispatch(
      AuthActions.setAuthToken({
        authState: AuthState.FORGOT_PASSWORD,
      }),
    );
    history.push(FORGOT_PASSWORD);
  };
  return <Button onClick={() => forgotPassword()} label={'Forgot your password'} type={ButtonType.Link} />;
};

export const DirectToLogin = () => {
  const dispatch = useDispatch();
  const login = () => {
    dispatch(
      AuthActions.setAuthToken({
        authState: AuthState.SIGN_IN,
      }),
    );
    history.push(LOGIN);
  };
  return <Button onClick={() => login()} label={'Sign In'} type={ButtonType.Link} />;
};
