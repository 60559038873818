import React from 'react';
import { Card, Form } from 'antd';
import { Typography } from './../../../styles/index';
import * as Style from './Style';
interface IProps {
  title?: string;
  children?: any;
}

const FormContainer = ({ children, title }: IProps) => (
  <Card className={Style.Card} title={<span className={Typography.Header}>{title}</span>}>
    {children}
  </Card>
);

export default FormContainer;
