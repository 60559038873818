import AuthComponent from './AuthComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { AuthStrings } from './constants';
import { TAuthConfig, AuthState } from '../authType';

export const name = 'login';
const path = 'login';
const header = 'Login';

const config: TAuthConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  AuthComponent: AuthComponent,
  cleanForm: false,
  disabled: false,
  authStrings: AuthStrings,
  validAuthStates: [AuthState.SIGN_IN, AuthState.SIGNED_OUT, AuthState.SIGNED_UP],
};

export default config;
