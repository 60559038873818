import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const page = css`
  background: ${Color.OffWhite} !important;
`;

export const container = css`
  background: ${Color.OffWhite} !important;
`;

export const headerTextContainer = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.396826px;
  color: #423c3a;
  margin-bottom: 29px;
`;
