import { ISOLATE_ACCOUNT_TYPE } from '../../config/index';
import Dummy from './Registration/_Dummy';
import AccountType from './Registration/AccountType';
import CountryOfResidence from './Registration/CountryOfResidence';
import NonUsCitizenInfo from './Registration/NonUsCitizienInfo';
import SelectInvestmentObjectives from './Registration/SelectInvestmentObjectives';
import SelectLiquidityNeeds from './Registration/SelectLiquidityNeeds';
import SelectRiskTolerance from './Registration/SelectRiskTolerance';
import PersonalInfo from './Registration/PersonalInfo';
import EmploymentInfo from './Registration/EmploymentInfo';
import SelectTimeHorizon from './Registration/SelectTimeHorizon';
import InvestorExperience from './Registration/InvestorExperience';
import Assets from './Registration/Assets';
import ExpectedExpenses from './Registration/ExpectedExpenses';
import AccountFundingSource from './Registration/AccountFundingSource';
import InvestmentStrategy from './Registration/InvestmentStrategy';
import AccountOwnerInformation from './Registration/AccountOwnerInformation';
import PhysicalAddress from './Registration/PhysicalAddress';
import MailingAddress from './Registration/MailingAddress';
import UploadIdentification from './Registration/UploadIdentification';
import TrustedContactAddress from './Registration/TrustedContactAddress';
import TrustedContactInfo from './Registration/TrustedContactInfo';
import RegulatoryDisclosures from './Registration/RegulatoryDisclosures';
import Review from './Registration/Review';
import StatementDelivery from './Registration/StatementDelivery';
import TermsAndConditions from './Registration/TermsAndConditions';

/*
 * Import Retirement forms
 */
import AddingBeneficiaries from './Registration/Retirement/AddingBeneficiaries';
import BeneficiaryType from './Registration/Retirement/BeneficiaryType';
import BeneficiaryPersonalInformation from './Registration/Retirement/BeneficiaryPersonalInformation';
import BeneficiaryInformation from './Registration/Retirement/BeneficiaryInformation';
import BeneficiarySharePercentage from './Registration/Retirement/BeneficiarySharePercentage';
import BeneficiaryPhysicalAddress from './Registration/Retirement/BeneficiaryPhysicalAddress';
import BeneficiariesSharedPercentageReview from './Registration/Retirement/BeneficiariesSharedPercentageReview';
import TrustInformation from './Registration/Retirement/TrustInformation';
import TrustSharePercentage from './Registration/Retirement/TrustSharePercentage';

/*
 * Import Custodial forms
 */
import MinorName from './Registration/Custodial/MinorName';
import MinorCitizenship from './Registration/Custodial/MinorCitizenship';
import MinorPersonalInformation from './Registration/Custodial/MinorPersonalInformation';

/*
 * Import Entity forms
 */
import EntityInformation from './Registration/Entity/EntityInformation';
import EntityPhysicalAddress from './Registration/Entity/EntityPhysicalAddress';
import EntityMailingAddress from './Registration/Entity/EntityMailingAddress';
import EntityTaxInformation from './Registration/Entity/EntityTaxInformation';
import UploadEntityDocuments from './Registration/Entity/UploadEntityDocuments';

import AuthorizedPerson from './Registration/Entity/AuthorizedPerson';
import APName from './Registration/Entity/AuthorizedPersonName';
import APEmploymentInformation from './Registration/Entity/AuthorizedPersonEmploymentInformation';
import APTradeExperience from './Registration/Entity/TradeExperience';
import APContactUsername from './Registration/Entity/ContactUsername';
import APReview from './Registration/Entity/AuthorizedPersonsReview';
import APUploadIDs from './Registration/Entity/UploadAuthorizedPersonIDs';

/*
 * Import Joint Account forms
 */
import AddJointAccounts from './Registration/Joint/AddJointAccounts';
import JointAccountDetail from './Registration/Joint/JointAccountDetail';
import JointAccountsReview from './Registration/Joint/JointAccountsReview';

/*
 * Import Trading Block questionnaires
 */
import TradingBlockSecurityQuestions from './Registration/TradingBlockSecurityQuestions';

import { TFormInjector, TFormConfig } from './formTypes';

const history: string[] = [];

const getFormsBasedOnIsolatedAccountType = () => {
  switch (ISOLATE_ACCOUNT_TYPE) {
    default:
      return allForms;
  }
};

const tradingBlockForms: TFormInjector = {
  TradingBlockSecurityQuestions,
};

const retirementForms: TFormInjector = {
  AddingBeneficiaries,
  BeneficiaryType,
  BeneficiaryInformation,
  BeneficiaryPersonalInformation,
  BeneficiaryPhysicalAddress,
  BeneficiarySharePercentage,
  TrustInformation,
  TrustSharePercentage,
  BeneficiariesSharedPercentageReview,
};

const custodialForms: TFormInjector = {
  MinorName,
  MinorCitizenship,
  MinorPersonalInformation,
};

const jointForms: TFormInjector = {
  AddJointAccounts,
  JointAccountDetail,
  JointAccountsReview,
};

const entityForms: TFormInjector = {
  EntityInformation,
  EntityPhysicalAddress,
  EntityMailingAddress,
  EntityTaxInformation,
  UploadEntityDocuments,
  AuthorizedPerson,
  APName,
  APEmploymentInformation,
  APTradeExperience,
  APContactUsername,
  APReview,
  APUploadIDs,
};

const allForms: TFormInjector = {
  ...tradingBlockForms,
  CountryOfResidence,
  AccountType,
  SelectRiskTolerance,
  SelectInvestmentObjectives,
  SelectLiquidityNeeds,
  SelectTimeHorizon,
  InvestorExperience,
  AccountOwnerInformation,
  PhysicalAddress,
  MailingAddress,
  NonUsCitizenInfo,
  PersonalInfo,
  EmploymentInfo,
  Assets,
  ExpectedExpenses,
  AccountFundingSource,
  InvestmentStrategy,
  RegulatoryDisclosures,
  TrustedContactInfo,
  TrustedContactAddress,
  ...retirementForms,
  ...custodialForms,
  ...jointForms,
  ...entityForms,
};

export const formInjector: TFormInjector = {
  Dummy,
  ...getFormsBasedOnIsolatedAccountType(),
  UploadIdentification,
  Review,
  StatementDelivery,
  TermsAndConditions,
};

/**
 * List of all injected forms
 */
export const injectedForms = Object.keys(formInjector).map(k => ({
  key: k,
  ...formInjector[k],
}));

/**
 * Filter out disabled forms (flag: disabled = true)
 */
export const enabledInjectedForms = injectedForms.filter(x => x.disabled !== true);

/**
 * Given the pathname find the form that matches the location
 */
export const getFormByLocation = (pathname: string) => enabledInjectedForms.find(x => pathname.indexOf(x.path) !== -1);

export default formInjector;
