import { FormFields } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const LiquidityNeeds: TSelectOption[] = [
  {
    label: 'Select Liquidity Needs',
    value: '-1',
  },
  {
    label: 'Very Important',
    value: 'VERY_IMPORTANT',
  },
  {
    label: 'Somewhat Important',
    value: 'SOMEWHAT_IMPORTANT',
  },
  {
    label: 'Not Important',
    value: 'NOT_IMPORTANT',
  },
];

export const InitialValues = {
  [FormFields.crowdFundingRegCF]: LiquidityNeeds[0].value,
  [FormFields.iPOsAndOfferings]: LiquidityNeeds[0].value,
  [FormFields.discountTrading]: LiquidityNeeds[0].value,
  [FormFields.managedAccounts]: LiquidityNeeds[0].value,
};
export const FieldsOptions = {
  [FormFields.crowdFundingRegCF]: LiquidityNeeds,
  [FormFields.iPOsAndOfferings]: LiquidityNeeds,
  [FormFields.discountTrading]: LiquidityNeeds,
  [FormFields.managedAccounts]: LiquidityNeeds,
};
