import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import Button, { ButtonType } from '../shared/Buttons/Button';
import Strings from '../../constants/strings';

import * as FormActions from '../../actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { findHistoryLocationId } from '../../utils/forms';

export type TContinueActionProps = {
  id: string;
  onContinue: Function;
  values: any;
  disabled?: boolean;
  isLastContinueStep?: boolean;
  label?: string;
};

export type TGetStartedActionProps = {
  disabled?: boolean;
  onClick: Function;
};

export type TNextActionProps = {
  disabled?: boolean;
  onClick: Function;
};

export const ContinueAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  let jointKey = findHistoryLocationId(history);
  useMemo(() => {
    jointKey = findHistoryLocationId(history);
  }, [history.location.state]);
  const hasKey = (jointKey || jointKey === 0) && addingJointAccount;

  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    if (addingJointAccount && hasKey) {
      dispatch(
        FormActions.addJointAccountsIntermediaryFormState({
          id: props.id,
          body: props.values,
        }),
      );
    } else {
      dispatch(
        FormActions.saveFormState({
          id: props.id,
          body: props.values,
        }),
      );
    }
  };
  const labelContent = props.isLastContinueStep
    ? Strings.actions.createAccount
    : !!props.label
    ? props.label
    : Strings.actions.continue;

  return <Button disabled={props.disabled} onClick={onContinue} label={labelContent} type={ButtonType.Thick} />;
};

export const ContinueIntermediaryEntityAuthorizedAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    if (props.id !== 'authorizedPersonsID') {
      dispatch(
        FormActions.saveEntityAuthorisedPersonIntermediaryFormState({
          id: props.id,
          body: props.values,
        }),
      );
    } else {
      dispatch(
        FormActions.saveEntityAuthorisedPersonIntermediaryFormState({
          body: props.values.authorizedPersonsId,
        }),
      );
    }
  };
  return (
    <Button disabled={props.disabled} onClick={onContinue} label={Strings.actions.continue} type={ButtonType.Thick} />
  );
};

export const ContinueIntermediaryJointAccountAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    if (!['jointAccountsReview', 'addJointAccounts'].includes(props.id)) {
      dispatch(
        FormActions.addJointAccountsIntermediaryFormState({
          id: props.id,
          body: props.values,
        }),
      );
    }
  };
  return (
    <Button disabled={props.disabled} onClick={onContinue} label={Strings.actions.continue} type={ButtonType.Thick} />
  );
};

export const ContinueIntermediaryRetirementAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    dispatch(
      FormActions.saveRetirementIntermediaryFormState({
        id: props.id,
        body: props.values,
      }),
    );
  };
  return (
    <Button disabled={props.disabled} onClick={onContinue} label={Strings.actions.continue} type={ButtonType.Thick} />
  );
};

export const ContinueRetirementAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    dispatch(
      FormActions.saveRetirementFormState({
        body: props.values.sharePercentage,
      }),
    );
  };
  const labelContent = props.isLastContinueStep
    ? Strings.actions.createAccount
    : !!props.label
    ? props.label
    : Strings.actions.continue;

  return <Button disabled={props.disabled} onClick={onContinue} label={labelContent} type={ButtonType.Thick} />;
};

export const ContinueEntityAction = (props: TContinueActionProps) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.onContinue) props.onContinue(props.values);
    dispatch(
      FormActions.saveEntityFormState({
        id: props.id,
        body: props.values,
      }),
    );
  };
  const labelContent = props.isLastContinueStep
    ? Strings.actions.createAccount
    : !!props.label
    ? props.label
    : Strings.actions.continue;

  return <Button disabled={props.disabled} onClick={onContinue} label={labelContent} type={ButtonType.Thick} />;
};

export const ContinueNextAction = (props: TNextActionProps) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={() => props.onClick()}
      label={Strings.actions.getStarted}
      type={ButtonType.Thick}
    />
  );
};

export const GetStartedAction = (props: TGetStartedActionProps) => {
  const dispatch = useDispatch();
  return (
    <Button
      disabled={props.disabled}
      onClick={() => props.onClick()}
      label={Strings.actions.getStarted}
      type={ButtonType.Thick}
    />
  );
};
