import { FormFields } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const TradesOrYears: TSelectOption[] = [
  {
    label: 'No. of trades!',
    value: -1,
  },
  {
    label: '0-5',
    value: 1,
  },
  {
    label: '6-15',
    value: 2,
  },
  {
    label: 'Over 15',
    value: 3,
  },
];

export const InitialValues = {
  [FormFields.stock]: false,
  [FormFields.option]: false,
  [FormFields.commodity]: false,
  [FormFields.bond]: false,
  [FormFields.margin]: false,
  [FormFields.mutualFundOrETF]: false,
  [FormFields.securityFuture]: false,
  [FormFields.annuity]: false,
  [FormFields.alternative]: false,
  [FormFields.reit]: false,
};

export const FieldsOptions = {
  [FormFields.tradesOrYear]: TradesOrYears,
};
