import React, { MouseEventHandler } from 'react';
import { Button } from 'antd';
import * as ButtonStyle from './Style';
import { Spin } from 'antd';

export enum ButtonType {
  Thin = 'thin',
  Thick = 'thick',
  WhiteThick = 'whiteThick',
  Link = 'link',
}

export type ButtonCompType = {
  label: any;
  type: ButtonType;
  onClick: MouseEventHandler<HTMLElement>;
  htmlType?: any;
  icon?: string;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
};

const ButtonComp = ({ label, type, htmlType, onClick, disabled, icon, className, loading }: ButtonCompType) => {
  switch (type) {
    case ButtonType.Thin:
      className = ButtonStyle.thinButton + ' ' + className;
      break;
    case ButtonType.Thick:
      className = ButtonStyle.thickButton + ' ' + className;
      break;
    case ButtonType.Link:
      className = ButtonStyle.linkButton + ' ' + className;
      break;
    case ButtonType.WhiteThick:
      className = ButtonStyle.whiteThickButton + ' ' + className;
      break;
  }
  return (
    <Button
      className={className}
      value={label}
      onClick={onClick}
      type={type === ButtonType.Link ? type : 'default'}
      htmlType={htmlType}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className={ButtonStyle.spin}>
          <Spin />
        </div>
      ) : (
        label
      )}
    </Button>
  );
};

export default ButtonComp;
