import { css, cx } from 'emotion';
import { Typography } from './../../../styles/index';

export const Label = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.8px !important;
  color: #222222 !important;
  display: inline-flex !important;
`;
export const WrapperCol = css`
  .ant-form-item-control {
    float: right !important;
    .ant-form-item-children {
      top: -13px !important;
    }
  }
`;

export const Button = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 10px !important;
  line-height: 12px !important;
  text-align: center !important;
  letter-spacing: 0.15873px !important;
  color: #ffffff !important;
  background: #0098ce !important;
  border-radius: 20px !important;
  height: 20px !important;
`;

export const ImageUpload = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ButtonUpload = css`
  position: absolute;
  width: 100%;
  left: 0;
  top: 70px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  height: 24px;
`;

export const BrowseButton = css`
  border: none;
  background: #ffffff;
  font-family: GillSans;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15873px;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: #077cb6;
  a {
    border: none;
    padding: 10px 10px;
    text-align: center;
    color: #ffffff;
    z-index: 1;
    background: #0098ce;
    border-radius: 4px;
    height: 24px;
    font-family: GillSans;
    font-size: 12px;
    letter-spacing: 0.15873px;
  }
  a:hover {
    color: #ffffff;
  }
`;

export const UploadDraggableFormItem = css`
  .ant-form-explain {
    text-align: right;
    margin-top: 5px;
  }
`;

export const Overlay = css`
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
`;
