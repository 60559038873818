import initialState from './initial';
import { Type } from '../actions/utils';
import { actionSucceeded, actionFailed } from '../actions/types';
import { TReduxAction } from '../types/commonTypes';
import produce from 'immer';
export const account = (state: any = initialState.account, action: TReduxAction) => {
  switch (action.type) {
    // ACCOUNT ERROR
    case actionSucceeded(Type.ACCOUNT_ERROR_CLEAR):
      return produce((draftState: any, _action: TReduxAction) => {
        draftState.error = null;
      })(state, action);

    // GET_CONTACT_INFO
    case actionSucceeded(Type.GET_CONTACT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.contactInformation = action.payload;
      })(state, action);
    case actionFailed(Type.GET_CONTACT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    // UPDATE_CONTACT_INFO
    case actionSucceeded(Type.UPDATE_CONTACT_INFO_CLEAR):
      return produce((draftState: any, _action: TReduxAction) => {
        draftState.contactInformationPatch = null;
      })(state, action);
    case actionSucceeded(Type.UPDATE_CONTACT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.contactInformationPatch = action.payload;
      })(state, action);
    case actionFailed(Type.UPDATE_CONTACT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    // GET_PERSONAL_INFO
    case actionSucceeded(Type.GET_PERSONAL_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.personalInformation = action.payload;
      })(state, action);
    case actionFailed(Type.GET_PERSONAL_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    // UPDATE_PERSONAL_INFORMATION
    case actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION_CLEAR):
      return produce((draftState: any, _action: TReduxAction) => {
        draftState.personalInformationPatch = null;
      })(state, action);
    case actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.personalInformationPatch = action.payload;
      })(state, action);
    case actionFailed(Type.UPDATE_PERSONAL_INFORMATION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    // GET_ACCOUNT_INFO
    case actionSucceeded(Type.GET_ACCOUNT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.accountInformation = action.payload;
      })(state, action);
    case actionFailed(Type.GET_ACCOUNT_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);

    // UPDATE_ACCOUNT_INFORMATION
    case actionSucceeded(Type.UPDATE_ACCOUNT_INFORMATION_CLEAR):
      return produce((draftState: any, _action: TReduxAction) => {
        draftState.accountInformationPatch = null;
      })(state, action);
    case actionSucceeded(Type.UPDATE_ACCOUNT_INFORMATION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.accountInformationPatch = action.payload;
      })(state, action);
    case actionFailed(Type.UPDATE_ACCOUNT_INFORMATION):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);
    default:
      return state;
  }
};
