import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import moment from 'moment';
import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';

import { TForm } from '../../../formTypes';
import { ContinueIntermediaryRetirementAction } from '../../../FormActions';
import Input, { MaskType } from '../../../../shared/Inputs/Input';
import { getFormState } from '../../../../../utils/forms';
import DatePickerInput from '../../../../shared/DatePicker/DatePicker';
import * as Constants from '../../../../../constants/dates';

const Component = (props: TForm) => {
  const history: any = useHistory();
  const key = history.location.state.id;
  const state = useSelector((state: any) =>
    state.intermediary.retirementIntermediary.filter((item: any) => item.id === key),
  );
  const formState = getFormState(state[0], InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue({ ...values, id: key });
  };

  const disabledDate = (current: any) => {
    return current && current > moment().subtract(18, 'y');
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <DatePickerInput
              {...form}
              name={FormFields.birthday}
              label={FormStrings[FormFields.birthday]}
              dateFormat={Constants.DATE_YEAR_FORMAT}
              disabledDate={disabledDate}
              defaultValue={formState[FormFields.birthday]}
            />
            <Input
              {...form}
              name={FormFields.ssn}
              label={FormStrings[FormFields.ssn]}
              mask={MaskType.Ssn}
              value={formState[FormFields.ssn]}
            />

            <ContinueIntermediaryRetirementAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
