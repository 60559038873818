import React from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import Input, { MaskType } from '../../../shared/Inputs/Input';
import { useSelector } from 'react-redux';
import { findHistoryLocationId, getFormState, jointAccountsIntermediaryFilter } from '../../../../utils/forms';
import { useHistory } from 'react-router';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';

const Component = (props: TForm) => {
  // const history: any = useHistory();
  // const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key = findHistoryLocationId(history.location);
  //
  // const state = useSelector((state: any) =>
  //   key && addingJointAccount
  //     ? jointAccountsIntermediaryFilter(state.intermediary.jointAccountsIntermediary, props.id)
  //     : state.forms[props.id],
  // );
  // const formState = getFormState(state, InitialValues);
  // const hasKey = key && addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(props.id, InitialValues, history);
  const onSubmit = (values: any) => {
    if (props.onContinue && hasKey) {
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      props.onContinue(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.firstname}
              label={FormStrings[FormFields.firstname]}
              value={formState[FormFields.firstname]}
            />
            <Input
              {...form}
              name={FormFields.middleInitial}
              label={FormStrings[FormFields.middleInitial]}
              value={formState[FormFields.middleInitial]}
            />
            <Input
              {...form}
              name={FormFields.lastname}
              label={FormStrings[FormFields.lastname]}
              value={formState[FormFields.lastname]}
            />
            <Input
              {...form}
              name={FormFields.phonenumber}
              label={FormStrings[FormFields.phonenumber]}
              value={formState[FormFields.phonenumber]}
              mask={MaskType.Phone}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
