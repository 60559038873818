import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueIntermediaryJointAccountAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Input from '../../../../shared/Inputs/Input';
import CountryOfResidence from '../../CountryOfResidence';
import * as Routes from '../../../../../constants/routes';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.intermediary.jointAccountsIntermediary);
  const history: any = useHistory();
  const [key, setKey] = useState(
    Number(
      history.location.state && typeof history.location.state.id !== 'undefined'
        ? history.location.state.id
        : !!state && state.length > 0
        ? state.reduce((prev: any, current: any) => (prev.id > current.id ? prev : current)).id + 1
        : 0,
    ),
  );

  const formState = getFormState(
    state && state[key] && state[key].jointAccountDetail ? state[key].jointAccountDetail : null,
    InitialValues,
  );
  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue({ ...values, id: key });
    history.push(`${Routes.REGISTER_INFO}/${CountryOfResidence.name}`, { ...values, id: key });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.email}
              label={FormStrings[FormFields.email]}
              value={formState[FormFields.email]}
              type="email"
            />
            <ContinueIntermediaryJointAccountAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
