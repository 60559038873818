import React from 'react';

export type LinkCompType = {
  label: string;
  linkTo: string;
  external?: boolean;
  className?: any;
};

const Link = (props: LinkCompType) => {
  const { label, linkTo, external, className } = props;
  const target = external ? { target: '_blank' } : undefined;
  return (
    <a className={className ? className : null} href={linkTo} {...target}>
      {label}
    </a>
  );
};

export default Link;
