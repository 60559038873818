import AddingBeneficiaries from '../Retirement/AddingBeneficiaries';
import UploadIdentification from '../UploadIdentification';
import MinorName from '../Custodial/MinorName';
import EntityInformation from '../Entity/EntityInformation';
import UploadEntityDocuments from '../Entity/UploadEntityDocuments';
import { ENTITY_SOLE_PROPRIETORSHIP } from '../../../../constants/accountTypes';
import JointAccounts from '../Joint/AddJointAccounts';

export const skipToBasedOnAccountType = (accountType: string, accountSubType?: string) => {
  switch (accountType) {
    case 'retirement':
      return AddingBeneficiaries.name;
    //TODO: Add other cases
    case 'joint':
      return JointAccounts.name;
    case 'entity':
      return accountSubType && accountSubType === ENTITY_SOLE_PROPRIETORSHIP
        ? UploadEntityDocuments.name
        : EntityInformation.name;
    case 'custodial':
      return MinorName.name;
    default:
      return UploadIdentification.name;
  }
};
