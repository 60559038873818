import * as Yup from 'yup';
import { AuthFields, AuthStrings } from './constants';

export const validationMessages = {
  codeRequired: `${AuthStrings[AuthFields.code]} is required`,
  codeInvalid: `${AuthStrings[AuthFields.code]} is invalid`,
  passwordInitialRequired: `${AuthStrings[AuthFields.password]} is required`,
  passwordInitialInvalid: `${AuthStrings[AuthFields.password]} is invalid`,
  repeatPasswordInitialRequired: `${AuthStrings[AuthFields.repeatPassword]} is required`,
  repeatPasswordInitialInvalid: `${AuthStrings[AuthFields.repeatPassword]} does not match with password`,
};

export const defaultValidation = Yup.object().shape({
  [AuthFields.code]: Yup.string().required(validationMessages.codeRequired),
  [AuthFields.password]: Yup.string().required(validationMessages.passwordInitialRequired),
  [AuthFields.repeatPassword]: Yup.string()
    .required(validationMessages.repeatPasswordInitialRequired)
    .oneOf([Yup.ref('password')], validationMessages.repeatPasswordInitialInvalid),
});

export default defaultValidation;
