import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Layout, Row } from 'antd';

import { FormStrings } from './constants';
import { InitialValues } from './schema';

import { GetStartedAction } from '../../FormActions';
import * as Style from './style';
import SolutionCardComponent from '../../../shared/SolutionCardComponent/SolutionCardComponent';
import * as Routes from '../../../../constants/routes';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../../../../selectors/auth';

import TradingBlockProductsModal from './TradingBlockProductsModal';
import TopNavigationBar from '../../../shared/TopNavigationBar/TopNavigationBar';

const { Content, Footer } = Layout;

const Component = () => {
  const [visibleTBProducts, setVisibleTBProducts] = useState(false);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const solutions = useSelector((state: any) => state.solutions);
  const history = useHistory();

  const onSubmit = () => {
    history.push(Routes.REGISTER_INFO);
  };

  const solutionsCards = Object.values(InitialValues).map((solution: any) => {
    return (
      <Col xs={24} sm={24} md={12} lg={12} xl={12} key={`${solution.name}_col`}>
        <SolutionCardComponent
          field={solution.field}
          icon={solution.icon}
          solutionName={solution.name}
          appName={solution.app}
          solutionDescription={solution.description}
          buttonState={solution.field in solutions}
          setVisibleProductModal={() => setVisibleTBProducts(true)}
        />
      </Col>
    );
  });

  let selectedSolutionsText = '';
  Object.keys(solutions).map((selected, index) => {
    if (index >= 1) {
      selectedSolutionsText += ', ';
    }
    selectedSolutionsText += InitialValues[selected].name;
  });

  return (
    <div className={Style.Body}>
      <TradingBlockProductsModal
        visible={visibleTBProducts}
        changeModalState={() => setVisibleTBProducts(!visibleTBProducts)}
      />
      <TopNavigationBar topLinks temporaryLoggedIn={isLoggedIn} />
      <Content className={Style.containerClass}>
        <div className="container">
          <div className={Style.headerTextContainer}>
            {FormStrings.pageHeader}
            <hr className={Style.Border} />
          </div>
          <Row gutter={[24, 24]}>{solutionsCards}</Row>
        </div>
      </Content>
      <Footer className={Style.footerClass}>
        <div className="container">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              {selectedSolutionsText !== '' && (
                <div className={Style.footerText}>
                  {selectedSolutionsText} <span className={Style.footerTextLighter}>Selected</span>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <GetStartedAction disabled={Object.keys(solutions).length === 0} onClick={onSubmit} />
            </Col>
          </Row>
        </div>
      </Footer>
    </div>
  );
};

export default Component;
