import { css, cx } from 'emotion';
import { Color } from '../../../../styles';
import React from 'react';
export const AlignCenter = css`
  text-align: center;
`;
export const SelectSignature = cx(
  AlignCenter,
  css`
    font-family: Gill Sans !Important;
    font-style: normal !Important;
    font-weight: normal !Important;
    font-size: 16px !Important;
    line-height: 18px !Important;
    color: ${Color.DeepBlack} !Important;
    margin-top: 48px !Important;
  `,
);
const CommonClass = cx(
  AlignCenter,
  css`
    font-family: Gill Sans !Important;
    font-style: normal !Important;
    font-weight: normal !Important;
    color: #077cb6 !Important;
  `,
);

export const Or = cx(
  CommonClass,
  css`
    font-size: 12px !Important;
    line-height: 14px !Important;
    margin-top: 24px !Important;
    margin-bottom: 16px !Important;
  `,
);

export const DrawYourSignature = cx(
  CommonClass,
  css`
    font-size: 14px !Important;
    line-height: 16px !Important;
    margin-bottom: 24px !Important;
  `,
);
