import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  income: 'income',
  pensionOrRetirement: 'pensionOrRetirement',
  fundsFromAnother: 'fundsFromAnother',
  gift: 'gift',
  saleOfBusiness: 'saleOfBusiness',
  insurancePayout: 'insurancePayout',
  inheritance: 'inheritance',
  socialsecurityBenefits: 'socialsecurityBenefits',
  homeEquityLineOfCredit: 'homeEquityLineOfCredit',
  other: 'other',
} as any;

export const FormFieldsTypes = {
  [FormFields.income]: reviewFieldTypes.Boolean,
  [FormFields.pensionOrRetirement]: reviewFieldTypes.Boolean,
  [FormFields.fundsFromAnother]: reviewFieldTypes.Boolean,
  [FormFields.gift]: reviewFieldTypes.Boolean,
  [FormFields.saleOfBusiness]: reviewFieldTypes.Boolean,
  [FormFields.insurancePayout]: reviewFieldTypes.Boolean,
  [FormFields.inheritance]: reviewFieldTypes.Boolean,
  [FormFields.socialsecurityBenefits]: reviewFieldTypes.Boolean,
  [FormFields.homeEquityLineOfCredit]: reviewFieldTypes.Boolean,
  [FormFields.other]: reviewFieldTypes.EmptiableString,
} as any;

export const FormStrings = {
  [FormFields.income]: 'Income',
  [FormFields.pensionOrRetirement]: 'Pension or retirement savings',
  [FormFields.fundsFromAnother]: 'Funds from another account',
  [FormFields.gift]: 'Gift',
  [FormFields.saleOfBusiness]: 'Sale of business or property',
  [FormFields.insurancePayout]: 'Insurance Payout',
  [FormFields.inheritance]: 'Inheritance',
  [FormFields.socialsecurityBenefits]: 'Social security benefits',
  [FormFields.homeEquityLineOfCredit]: 'Home equity Line of Credit/Reverse Mortgage',
  [FormFields.other]: 'Other',
  infoMessage: 'Please tell us how you are funding this account? (check all that apply)',
} as any;
