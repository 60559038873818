import { css, cx } from 'emotion';
import { Typography } from './../../../styles/index';

const buttonStyle = css`
  background: #0098ce !important;
  border-radius: 20px !important;
  text-align: center !important;
  width: 100% !important;
  margin: auto !important;
  display: block !important;
  &:disabled {
    background-color: #989898 !important;
  }
`;

export const thinButton = cx(
  buttonStyle,
  Typography.ButtonText,
  css`
    height: 20px !important;
  `,
);

export const thickButton = cx(
  buttonStyle,
  Typography.ButtonText,
  css`
    height: 40px !important;
  `,
);

export const whiteThickButton = cx(
  buttonStyle,
  Typography.ButtonText,
  css`
    height: 40px !important;
    background: #ffffff !important;
  `,
);

export const linkButton = css`
  font-family: Gill Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  letter-spacing: 0.8px !important;
  color: #077cb6 !important;
  padding: 0 !Important;
`;

export const spinWrapper = css`
  margin-top: 6px;
`;

export const spin = css`
  .ant-spin-dot-item {
    background-color: white;
  }
`;
