/**
 * Routes definition
 */
export const MAIN = '/';

/**
 * Authentication
 */
export const AUTH = '/auth';
export const LOGIN = `${AUTH}/login`;
export const REGISTER = `${AUTH}/register`;
export const CONFIRM_EMAIL = `${AUTH}/confirm-email`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const SUBMIT_PASSWORD = `${AUTH}/submit-password`;
export const REDIRECT = '/redirect';

/**
 * ResetPassword
 */
export const RESETPASSWORD = '/reset-password';
export const SETPASSWORD = '/set-password';

/**
 * Account Forms
 */
export const ACCOUNT_FORMS = '/account-form';

/**
 * Account Forms
 */
export const HOME = '/home';
export const NOT_FOUND = '/notFound';

/**
 * Register steps
 */
export const REGISTER_INFO = '/registrationInfo';
export const ACCOUNT_CREATING = '/account-creating';
export const SOLUTIONS = '/solutions';

/**
 * Dashboard
 */
export const DASHBOARD = '/dashboard';
export const NO_CONNECTED_ACCOUNTS = `${DASHBOARD}/no-connected-accounts`;
export const CONNECTED_ACCOUNTS = `${DASHBOARD}/connected-accounts`;

/**
 * Management
 */
export const MANAGEMENT = '/management';
export const MANAGEMENT_ACCOUNT_INFORMATION = `${MANAGEMENT}/account-information`;
export const MANAGEMENT_AUTHENTICATION = `${MANAGEMENT}/authentication`;

export const MANAGEMENT_ACCOUNT_INFORMATION_CONTACT_INFO = `${MANAGEMENT_ACCOUNT_INFORMATION}/contact-info`;
export const MANAGEMENT_ACCOUNT_INFORMATION_PERSONAL_INFO = `${MANAGEMENT_ACCOUNT_INFORMATION}/personal-info`;
export const MANAGEMENT_ACCOUNT_INFORMATION_ACCOUNT_INFO = `${MANAGEMENT_ACCOUNT_INFORMATION}/account-info`;

/*
 * Pages
 */
export const FAQ = '/faq';
