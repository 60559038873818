import { FormFields, FormStrings } from './constants';
import { getRangeOptions, getTaxRates } from './helpers';
import { TSelectOption } from '../../../../types/commonTypes';
import { states } from '../../../../constants/states';

export const EmploymentStatusValues = {
  Employed: 'employed',
  'Self-Employed': 'selfEmployed',
  Retired: 'retired',
  Student: 'student',
  Unemployed: 'unemployed',
  Other: 'other',
} as any;

export const EmploymentStatusOptions: TSelectOption[] = [
  {
    label: 'Select Status',
    value: '-1',
  },
  ...Object.keys(EmploymentStatusValues).map(key => {
    return { label: key, value: EmploymentStatusValues[key] };
  }),
];

export const StatesOptions: TSelectOption[] = [
  {
    label: 'Select state',
    value: '-1',
  },
  ...states,
];

export const AnnualIncomeOptions: TSelectOption[] = [
  {
    label: 'Select Range',
    value: '-1',
  },
  ...getRangeOptions(),
];

export const TaxRateOptions: TSelectOption[] = [{ label: 'Select Range', value: '-1' }, ...getTaxRates()];

export const EmployedInitialValues = {
  [FormFields.employmentStatus]: EmploymentStatusOptions[0].value,
  [FormFields.employerName]: '',
  [FormFields.employerPosition]: '',
  [FormFields.yearsEmployed]: '',
  [FormFields.employerAddressLine1]: '',
  [FormFields.employerAddressLine2]: '',
  [FormFields.employerCity]: '',
  [FormFields.employerState]: StatesOptions[0].value,
  [FormFields.employerZip]: '',
  [FormFields.annualIncome]: AnnualIncomeOptions[0].value,
  [FormFields.taxRate]: TaxRateOptions[0].value,
};

export const UnemployedInitialValues = {
  [FormFields.employmentStatus]: EmploymentStatusOptions[0].value,
  [FormFields.annualIncome]: AnnualIncomeOptions[0].value,
  [FormFields.taxRate]: TaxRateOptions[0].value,
};

export const UnemployedJointInitialValues = {
  [FormFields.employmentStatus]: EmploymentStatusOptions[0].value,
};

export const FieldsOptions = {
  [FormFields.employmentStatus]: EmploymentStatusOptions,
  [FormFields.employerState]: StatesOptions,
  [FormFields.annualIncome]: AnnualIncomeOptions,
  [FormFields.taxRate]: TaxRateOptions,
};
