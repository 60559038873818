import React from 'react';
import * as Style from './Style';

export type TProps = {
  children: string;
};

const InfoText = (props: TProps) => {
  return <p className={Style.Text}>{props.children}</p>;
};

export default InfoText;
