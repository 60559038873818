import { css } from 'emotion';

export const uploadDescription = css`
  font-family: GillSans;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #ff244e;
`;

export const outer = css`
  width: 100%;
  text-align: center;
`;

export const changeCardButton = css`
  background: rgb(0, 152, 206) !important;
  border-radius: 16px !important;
  font-family: GillSans !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.15873px !important;
  color: rgb(255, 255, 255) !important;
  margin-left: 15px !important;
`;

export const deleteCardButton = css`
  background-color: rgba(255, 36, 78, 0.1) !important;
  border-radius: 16px !important;
  font-family: GillSans !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.15873px !important;
  color: #ff244e !important;
  &:hover {
    border-color: #ff244e !important;
  }
`;

export const spin = css`
  .ant-spin-dot-item {
    background-color: #ff244e;
  }
`;

export const spinCambriaBlue = css`
  .ant-spin-dot-item {
    background-color: rgb(0, 152, 206);
  }
`;
