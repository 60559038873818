import Axios from 'axios';

export const Methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

/**
 * Http Client
 */
export const HttpClient: any = async (method: any, url: string, body?: any, authToken?: string) => {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HttpClient invalid argument: url=${url} contains a placeholder`);
  }
  return Axios({
    method,
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : ``,
    },
    data: body || undefined,
  });
};
