import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { map } from 'lodash';

import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, AccountTypeOptions, AccountTypes, AccountTypesValues, IndividualInitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import RadioGroup from '../../../shared/Radio/Radio';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.accountType);
  const formState = getFormState(state, IndividualInitialValues);
  const [defaultValues, setDefaultValues] = useState(formState);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  const onAccountTypeChange = (type: string, form: any) => {
    let defaults = {};
    let overrides = {
      [FormFields.type]: type,
    };
    defaults = type === FormFields.individual ? IndividualInitialValues : InitialValues;
    setDefaultValues({
      ...defaults,
      ...overrides,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        const isOfOtherType =
          form.values[FormFields.type] === AccountTypesValues.individual ||
          form.values[FormFields.type] === AccountTypesValues.custodial;
        return (
          <>
            <RadioGroup
              {...form}
              radioOptions={map(AccountTypes, (at: any, key: string) => ({
                label: at.label,
                value: at.value,
              }))}
              name={FormFields.type}
              defaultValue={defaultValues[FormFields.type]}
              onChangeHook={(value: string) => onAccountTypeChange(value, form)}
            />
            {!isOfOtherType && (
              <SelectInput
                {...form}
                name={FormFields.subtype}
                label={FormStrings[FormFields.subtype]}
                options={AccountTypeOptions[form.values[FormFields.type]]}
                defaultOption={defaultValues[FormFields.subtype]}
                bindValue
              />
            )}
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
