import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';

const name = 'termsAndConditions';
const path = 'termsAndConditions';
const header = 'Terms and Conditions';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
};

export default config;
