import AuthComponent from './AuthComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { AuthStrings } from './constants';
import { TAuthConfig, AuthState } from '../authType';

export const name = 'forgotPassword';
const path = 'forgot-password';
const header = 'Reset your password';

const config: TAuthConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  AuthComponent: AuthComponent,
  cleanForm: false,
  disabled: false,
  authStrings: AuthStrings,
  validAuthStates: [AuthState.FORGOT_PASSWORD],
};

export default config;
