import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.taxIdType]: '-1',
};

export const SelectTaxIDTypeOptions: TSelectOption[] = [
  {
    label: 'Select ID Type',
    value: '-1',
  },
  {
    label: 'Taxpayer Identification Number',
    value: 'TIN',
  },
  {
    label: 'Social Security Number',
    value: 'SSN',
  },
];

export const FieldsOptions = {
  [FormFields.taxIdType]: SelectTaxIDTypeOptions,
};
