import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import React, { MouseEventHandler, useState } from 'react';
import _ from 'lodash';

import { alignLeftModal, alignRightModal, editFormDescription, headerText } from '../style';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import Modal from '../../shared/Modal';

type PersonalInformationFormProps = {
  isModalVisible: boolean;
  fields: any;
  isSubmitting: boolean;
  onSubmit: Function;
  onCancel: MouseEventHandler<HTMLElement>;
  section: any;
};

const PersonalInformationForm = ({
  isModalVisible,
  fields,
  onSubmit,
  isSubmitting,
  onCancel,
  section,
}: PersonalInformationFormProps) => {
  const initialValues = Object.keys(fields)
    .map(key => ({ key: key, value: fields[key].props.value }))
    .reduce(
      (obj, keyValuePair) => ({
        ...obj,
        [keyValuePair.key]: keyValuePair.value,
      }),
      {},
    );

  let isDisabled = Object.keys(fields)
    .map(key => (fields[key].props.disabled ? true : false))
    .every(fieldDisabled => fieldDisabled === true);

  return (
    <Modal
      title={null}
      visible={isModalVisible}
      footer={null}
      centered={true}
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      style={{ marginTop: 20 }}
    >
      <div style={{ margin: '25px 45px 25px 45px' }}>
        <Row>
          <span className={headerText}>{section.sectionLabel}</span>
          <Divider style={{ margin: '0px 0px 25px 0px' }} />
          {!_.isEmpty(section.sectionDescription) && (
            <Row>
              <Col span={24}>
                <p className={editFormDescription}>{section.sectionDescription}</p>
              </Col>
            </Row>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={values => {
              onSubmit(values);
            }}
            validationSchema={section.validationSchema}
          >
            {form => {
              return (
                <>
                  {Object.keys(fields).map(key => {
                    if (fields[key].isReadOnly) {
                      return (
                        <Row style={{ margin: '0px 0px 25px 0px' }} key={`${key}_edit_item`}>
                          <Col span={12}>
                            <span className={alignLeftModal}>{fields[key].props.label}</span>
                          </Col>

                          <Col span={12}>
                            <span className={alignRightModal}>
                              {_.isEmpty(fields[key].displayValue) ? '-' : fields[key].displayValue}
                            </span>
                          </Col>
                        </Row>
                      );
                    } else {
                      const JSXRenderableComp = fields[key].component;
                      return (
                        <JSXRenderableComp {...form} {...fields[key].props} key={`${fields[key].props.name}_input`} />
                      );
                    }
                  })}

                  <Row style={{ margin: '50px 0px 10px 0px' }}>
                    <Col span={11}>
                      <Button
                        disabled={isSubmitting}
                        onClick={onCancel}
                        label={<span style={{ color: '#0098CE' }}>CANCEL</span>}
                        type={ButtonType.WhiteThick}
                      />
                    </Col>

                    <Col span={2}></Col>

                    <Col span={11}>
                      <Button
                        disabled={!form.isValid || isDisabled}
                        onClick={form.submitForm}
                        label={'SAVE'}
                        type={ButtonType.Thick}
                        loading={isSubmitting}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          </Formik>
        </Row>
      </div>
    </Modal>
  );
};

export default PersonalInformationForm;
