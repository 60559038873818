import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { RETIREMENT } from '../../../../../constants/accountTypes';

const name = 'beneficiaryPersonalInformation';
export const path = 'beneficiaryPersonalInformation';
const header = 'Beneficiary Personal Information';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  accountType: RETIREMENT,
};

export default config;
