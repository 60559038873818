import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const TimeHorizon: TSelectOption[] = [
  {
    label: 'Select Time Horizon',
    value: '-1',
  },
  {
    label: 'Short',
    value: 'SHORT',
  },
  {
    label: 'Average',
    value: 'AVERAGE',
  },
  {
    label: 'Longest',
    value: 'LONGEST',
  },
];

export const InitialValues = {
  [FormFields.crowdFundingRegCF]: TimeHorizon[0].value,
  [FormFields.iPOsAndOfferings]: TimeHorizon[0].value,
  [FormFields.discountTrading]: TimeHorizon[0].value,
  [FormFields.managedAccounts]: TimeHorizon[0].value,
};

export const FieldsOptions = {
  [FormFields.crowdFundingRegCF]: TimeHorizon,
  [FormFields.iPOsAndOfferings]: TimeHorizon,
  [FormFields.discountTrading]: TimeHorizon,
  [FormFields.managedAccounts]: TimeHorizon,
};
