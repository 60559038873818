export const vNonUSCitizenFormRender = (forms: any) => {
  return forms.countryOfResidence && forms.countryOfResidence.country !== 'us';
};

export const vMailingAddressFormRender = (state: any) => {
  return state && !state.mailAddress === true;
};

export const vEntityMailingAddressFormRender = (state: any) => {
  return state && !state.mailAddress;
};
