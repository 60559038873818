export const getFormState = (state: any, initialValues: any) => {
  if (state) {
    return state;
  } else {
    return initialValues;
  }
};

export const filterInitialValueFromState = (initialValues: any, solutionsFromState: any, relations: any) => {
  let initial: any = {};
  Object.keys(solutionsFromState).map(solution => {
    initial[relations[solution]] = initialValues[relations[solution]];
  });
  return initial;
};
// Not used, to review in future
export const getFilterFormState = (formFields: any, fieldsState: any) => {
  let stateKeys = Object.keys(fieldsState);
  let formKeys = Object.keys(formFields);
  let filteredFields: any = {};
  if (stateKeys.length > formKeys.length) {
    filteredFields = { ...fieldsState };
    stateKeys.map(field => {
      if (!formKeys.includes(field)) delete filteredFields[field];
    });
  } else if (formKeys.length > formKeys.length) {
    filteredFields = { ...fieldsState };
    formKeys.map(field => {
      if (!stateKeys.includes(field)) filteredFields[field] = '-1';
    });
  } else {
    filteredFields = { ...formFields };
    formKeys.map(field => {
      if (fieldsState[field]) {
        filteredFields[field] = fieldsState[field];
      }
    });
  }
  return filteredFields;
};

export const jointAccountsIntermediaryFilter = (state: any, key: string, id: any) => {
  const stateArray = state.intermediary.jointAccountsIntermediary;
  let filtered = stateArray.filter((item: any) => item.id === id);
  if (filtered.length > 0) {
    let item = filtered[0];
    if (item[key]) {
      return item[key];
    }
  }
  return null;
};

export const findHistoryLocationId = (history: any) => {
  return history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
};
