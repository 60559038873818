import { css, cx } from 'emotion';
import { Typography, Color } from './../../styles';

export const Container = css`
  margin: 8px 0px !important;
  padding: 0 7% !important;
  display: inline-block !important;
  width: 100% !important;
  background-color: ${Color.OffWhite} !important;
`;

export const ChartContainer = css`
  font-family: ${Typography.GillSans};
  width: 100%;
  background: ${Color.PureWhite};
  border: 1px solid ${Color.FogBlue};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 42px;
  min-height: 320px;
`;

export const Greetings = css`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.35px;
  color: ${Color.TropicalBlue};
  margin: 8px 0;
`;

export const hr = css`
  border: 1px solid ${Color.FogBlue}!important;
  margin-top: 25px !important;
`;

export const ChartRow = css`
  padding: 36px 0px !important;
`;

export const ChartWrapper = css`
  max-width: 120px !important;
  max-height: 120px !important;
  display: relative;
`;

export const TotalContainer = css`
  padding: 32px 0 !important;
`;

export const Total = css`
  font-family: ${Typography.GillSans};
  font-size: 48px;
  line-height: 40px;
  letter-spacing: 0.383838px;
  color: ${Color.DeepBlack};
`;

export const TotalText = css`
  font-family: ${Typography.GillSans};
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.148485px;
  color: ${Color.MediumGrey};
  padding-top: 20px;
`;

export const TotalUpdatedText = css`
  font-family: ${Typography.GillSans};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.162037px;
  color: ${Color.MediumGrey};
  padding-top: 16px;
  font-style: Italic;
`;

export const Legend = css`
  font-family: ${Typography.GillSans};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.162037px;
  color: ${Color.MediumGrey};
  padding-top: 16px;
`;

export const LegendRow = css`
  font-family: ${Typography.GillSans};
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.148485px;
  color: ${Color.DeepBlack};
  padding: 0;
  padding-top: 10px;
  overflow-x: hidden;
  list-style: none;
`;

export const LegendRowSolutionLine = css`
  padding-top: 10px;
  &:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
  }
`;

export const LegendRowSolutionName = css`
  background: white;
  color: ${Color.DeepBlack};
`;

export const LegendRowSolutionPercentage = css`
  float: right;
  overflow: hidden;
  background: white;
`;

export const Filter = css`
  padding: 20px 0;
  font-family: ${Typography.GillSans};
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.35px;
  color: ${Color.DeepBlack};
`;

export const FilterSelect = css`
  font-family: ${Typography.GillSans}!important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.254546px !important;
  color: ${Color.FineBlue} !important;
  border: none !important;
  background: none !important;
  &.ant-select {
    border: none !important;
  }
  .ant-select-selection {
    border: none !important;
    &:active {
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    .ant-select-arrow {
      color: ${Color.FineBlue} !important;
    }
  }
`;
