import { toCurrency } from '../../../../utils/currencyUtils';

export const ranges = [
  [-1, 25000, 1],
  [25000, 50000, 2],
  [50000, 200000, 3],
  [200000, 500000, 4],
  [500000, 1000000, 5],
  [1000000, 2000000, 6],
  [2000000, 3000000, 7],
  [3000000, -1, 8],
];

export const getRangeOptions = () => {
  return ranges.map(i => {
    let label =
      i[0] === -1 && i[1] !== -1
        ? `$0 to ${toCurrency(i[1] as number)}`
        : `${toCurrency((i[0] + 1) as number)} to  ${toCurrency(i[1] as number)}`;
    if (i[1] === -1) {
      label = `Greater than  ${toCurrency((i[0] + 1) as number)}`;
    }
    return {
      label,
      value: i[2],
    };
  });
};
