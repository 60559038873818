import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const RelationshipOptions = [
  {
    label: 'Select relationship',
    value: '-1',
  },
  {
    label: 'Spouse',
    value: 'S',
  },
  {
    label: 'Financial Advisor',
    value: 'A',
  },
  {
    label: 'Guardian',
    value: 'G',
  },
  {
    label: 'Family Member',
    value: 'F',
  },
  {
    label: 'Lawyer/Attorney',
    value: 'L',
  },
  {
    label: 'Other',
    value: 'O',
  },
];

export const InitialValues = {
  [FormFields.firstName]: undefined,
  [FormFields.middleInitial]: undefined,
  [FormFields.lastName]: undefined,
  [FormFields.relationship]: RelationshipOptions[0].value,
};
