import { call, put, takeEvery } from 'redux-saga/effects';
import * as Url from '../constants/url';
import { Type, State } from '../actions/utils';
import { HttpClient } from '../helpers/httpClient';
import { TReduxAction } from '../types/commonTypes';

export function* saveFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_FORM_STATE), error: error.message });
  }
}

export function* saveRetirementFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_RETIREMENT_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_RETIREMENT_FORM_STATE), error: error.message });
  }
}

export function* deleteRetirementFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.DELETE_RETIREMENT_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.DELETE_RETIREMENT_FORM_STATE), error: error.message });
  }
}

export function* removeSuitabilityInfo(action: TReduxAction) {
  try {
    if (!action.payload.solutionCode) throw new Error("Can't find solution to remove!");
    yield put({ type: State.actionSucceeded(Type.REMOVE_SUITABILITY_INFO), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.REMOVE_SUITABILITY_INFO), error: error.message });
  }
}

export function* saveMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

export function* deleteMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.DELETE_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.DELETE_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

export function* saveEntityFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_ENTITY_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_ENTITY_FORM_STATE), error: error.message });
  }
}

export function* saveEntityMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

export function* deleteEntityMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

export function* saveJointFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_JOINT_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_JOINT_FORM_STATE), error: error.message });
  }
}

export function* saveJointMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_JOINT_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_JOINT_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

export function* deleteJointMailingAddress(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.DELETE_JOINT_MAILING_ADDRESS_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.DELETE_JOINT_MAILING_ADDRESS_FORM_STATE), error: error.message });
  }
}

/**
 * Forms sagas
 */
export default function* saga() {
  yield takeEvery(State.actionRequested(Type.SAVE_FORM_STATE), saveFormState);
  yield takeEvery(State.actionRequested(Type.SAVE_RETIREMENT_FORM_STATE), saveRetirementFormState);
  yield takeEvery(State.actionRequested(Type.DELETE_RETIREMENT_FORM_STATE), deleteRetirementFormState);
  yield takeEvery(State.actionRequested(Type.REMOVE_SUITABILITY_INFO), removeSuitabilityInfo);
  yield takeEvery(State.actionRequested(Type.SAVE_MAILING_ADDRESS_FORM_STATE), saveMailingAddress);
  yield takeEvery(State.actionRequested(Type.DELETE_MAILING_ADDRESS_FORM_STATE), deleteMailingAddress);
  yield takeEvery(State.actionRequested(Type.SAVE_ENTITY_FORM_STATE), saveEntityFormState);
  yield takeEvery(State.actionRequested(Type.SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE), saveEntityMailingAddress);
  yield takeEvery(State.actionRequested(Type.DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE), deleteEntityMailingAddress);
  yield takeEvery(State.actionRequested(Type.SAVE_JOINT_FORM_STATE), saveJointFormState);
  yield takeEvery(State.actionRequested(Type.SAVE_JOINT_MAILING_ADDRESS_FORM_STATE), saveJointMailingAddress);
  yield takeEvery(State.actionRequested(Type.DELETE_JOINT_MAILING_ADDRESS_FORM_STATE), deleteJointMailingAddress);
}
