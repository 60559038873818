import React from 'react';
import { Layout, Row, Col } from 'antd';

import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';

import * as Style from './FullScreenFormStyle';
import LogoImage from '../../assets/img/logo.svg';

const { Content } = Layout;

const FullScreenForm: React.FunctionComponent<RowProps & ColProps & { isTwoSideForm?: boolean }> = ({
  type,
  justify,
  span,
  children,
  isTwoSideForm,
  ...shared
}) => {
  return (
    <Content className={Style.container}>
      <div className="container">
        {/* Logo */}
        <div className={Style.logoContainer}>
          <img className={Style.logo} alt="logo" src={LogoImage} />
        </div>
        {/* Content */}
        <Row type={type} justify={justify}>
          <Col xs={24} sm={24} md={isTwoSideForm ? 22 : 20} lg={isTwoSideForm ? 22 : 14} xl={isTwoSideForm ? 20 : 12}>
            {children}
          </Col>
        </Row>
      </div>
    </Content>
  );
};

FullScreenForm.defaultProps = {
  type: 'flex',
  justify: 'center',
  span: 12,
};

export default FullScreenForm;
