import { FormFields } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';
import { states } from '../../../../../constants/states';

export const InitialValues = {
  [FormFields.addressLineOne]: '',
  [FormFields.addressLineTwo]: '',
  [FormFields.city]: '',
  [FormFields.state]: '-1',
  [FormFields.zipCode]: '',
};

export const stateOptions: TSelectOption[] = [
  {
    label: 'Select state',
    value: '-1',
  },
  ...states,
];

export const FieldsOptions = {
  [FormFields.state]: stateOptions,
};
