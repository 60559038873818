import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues, FieldsOptions } from './schema';
import { TFormConfig } from '../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import categories from '../../../../constants/categories';

export const name = 'countryOfResidence';
const path = 'countryOfResidence';
const header = 'Country of Residence';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations(''),
  FormComponent: FormComponent,
  cleanForm: false,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  category: categories.personalInformation,
  backToReview: true,
  whenAddJointAccount: true,
};

export default config;
