import React from 'react';
import { Form, Input as AntInput, Popover } from 'antd';
import * as Styles from './Style';

interface percentageTotalType {
  name: string;
  label: string;
  value: number;
  errors?: any;
  handleSubmit?: Function;
  setFieldValue: Function;
  setFieldTouched: Function;
  handleBlur?: Function;
}

const percentageTotal = (props: percentageTotalType) => {
  const { name, label, value, errors, handleSubmit, setFieldValue, setFieldTouched, handleBlur } = props;
  return (
    <>
      <Form.Item
        hasFeedback={!!errors && !!errors[name]}
        validateStatus={!!errors && errors[name] && 'error'}
        help={!!errors && errors[name]}
        className="inputLabel"
        colon={false}
      >
        <div className={Styles.Right}>
          <div className={Styles.LabelTotalText}>{label}</div>
          <div className={Styles.TotalPercentage} onChange={() => setFieldValue(props.name, value)}>
            {value}%
          </div>
        </div>
      </Form.Item>
    </>
  );
};

export default percentageTotal;
