import Col from 'antd/lib/col';
import Row from 'antd/lib/grid/row';
import Menu from 'antd/lib/menu';
import React, { useState } from 'react';
import { menuItem } from '../style';

const Authentication: React.FC = () => {
  const divStyle = {
    backgroundColor: '#FAFCFD',
    width: '75%',
    fontWeight: 66,
  };

  const view = () => {
    return 'Lorem Ipsum';
  };

  const handleMenuClick = ({ key }: any) => {
    console.log(key);
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Menu onClick={handleMenuClick} defaultSelectedKeys={['1']} style={divStyle}>
            <Menu.Item key="1">
              <span className={menuItem}>Authorization 1</span>
            </Menu.Item>
            <Menu.Item key="2">
              <span className={menuItem}>Authorization 2</span>
            </Menu.Item>
          </Menu>
        </Col>

        <Col span={12} offset={2}>
          {view()}
        </Col>
      </Row>
    </>
  );
};

export default Authentication;
