import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FieldArray, Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueEntityAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import { UploadDoc } from './UploadDoc';
import * as Styles from './Style';

const Component = (props: TForm) => {
  const accountSubType = useSelector((state: any) => state.forms.accountType.subtype);
  const state = useSelector((state: any) => (state.forms.entity ? state.forms.entity.uploadEntityDocuments : null));
  let formState = getFormState(state ? (state[FormFields.subType] === accountSubType ? state : null) : null, {
    [FormFields.documents]: InitialValues[accountSubType],
    [FormFields.uploadLater]: InitialValues[FormFields.uploadLater],
    [FormFields.subType]: accountSubType,
  });

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation[accountSubType]}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <FieldArray name={FormFields.documents} validateOnChange>
              {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                return Object.keys(values[FormFields.documents]).map((item: any, index: number) => (
                  <UploadDoc key={index} item={item} form={form} />
                ));
              }}
            </FieldArray>
            <div className={Styles.MarginCheckBox}>
              <Checkbox
                {...form}
                name={FormFields.uploadLater}
                label={FormStrings.uploadLater}
                checked={
                  formState[FormFields.uploadLater] ? formState[FormFields.uploadLater] : InitialValues.uploadLater
                }
              />
            </div>
            <ContinueEntityAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
