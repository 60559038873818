import { Col, Row, Menu } from 'antd';
import React, { useState } from 'react';
import _ from 'lodash';
import { menuItem } from '../style';
import Button, { ButtonType } from '../../shared/Buttons/Button';

import {
  MANAGEMENT_ACCOUNT_INFORMATION_ACCOUNT_INFO,
  MANAGEMENT_ACCOUNT_INFORMATION_CONTACT_INFO,
  MANAGEMENT_ACCOUNT_INFORMATION_PERSONAL_INFO,
} from '../../../constants/routes';

import { getRelativePathName } from '../../../utils/routerUtils';
import ContactInformation from './ContactInformation';
import PersonalInformation from './PersonalInformation';
import AccountInformationComp from './AccountInformation/index';

const AccountInformation: React.FC = (props: any) => {
  const internalRoutes: any = {
    'contact-info': {
      path: MANAGEMENT_ACCOUNT_INFORMATION_CONTACT_INFO,
      component: ContactInformation,
      menuItemName: 'Contact Information',
    },
    'personal-info': {
      path: MANAGEMENT_ACCOUNT_INFORMATION_PERSONAL_INFO,
      component: PersonalInformation,
      menuItemName: 'Personal Information',
    },
    'account-info': {
      path: MANAGEMENT_ACCOUNT_INFORMATION_ACCOUNT_INFO,
      component: AccountInformationComp,
      menuItemName: 'Account Information',
    },
  };
  const stringMatchedPath = getRelativePathName(props.match.path, internalRoutes) ?? 'contact-info';
  const [current, setCurrent] = useState(`${stringMatchedPath}_menu`);

  const divStyle = {
    backgroundColor: '#FAFCFD',
    width: '75%',
    fontWeight: 66,
  };

  const Comp = internalRoutes[current.replace('_menu', '')].component;
  return (
    <>
      <Row>
        <Col span={6}>
          <Menu
            onClick={event => setCurrent(event.key)}
            mode="inline"
            defaultSelectedKeys={[`${stringMatchedPath}_menu`]}
            style={divStyle}
          >
            {Object.keys(internalRoutes).map(routeMetaKey => (
              <Menu.Item key={`${routeMetaKey}_menu`}>
                <span className={menuItem}>{internalRoutes[routeMetaKey].menuItemName}</span>
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col span={16}>
          <Comp />
        </Col>
      </Row>
    </>
  );
};

export default AccountInformation;
