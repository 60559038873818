import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router';
import { useStore } from 'react-redux';
import { Spin } from 'antd';

import FormContainer from '../shared/FormContainer/FormContainer';
import FullScreenForm from '../FullScreenForm/FullScreenForm';

import * as Colors from '../../styles/color';
import * as Styles from './style';

export default () => {
  return (
    <FullScreenForm color={Colors.FogBlue}>
      <FormContainer title="Creating account">
        <div className={Styles.spinnerContainer}>
          <Spin size="large" spinning={true} className={Styles.spinner} />
        </div>
        <b className={Styles.textEmphasised}>Please wait until account is created...</b>
        <p className={Styles.text}>You will be redirected once the process is completed</p>
      </FormContainer>
    </FullScreenForm>
  );
};
