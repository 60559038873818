import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import { FieldsOptions } from './schema';
import categories from '../../../../../constants/categories';
import { ENTITY } from '../../../../../constants/accountTypes';

const name = 'entityTaxInformation';
const path = 'entityTaxInformation';
const header = 'Entity Tax Information';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  category: categories.entityInformation,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  accountType: ENTITY,
  backToReview: true,
};

export default config;
