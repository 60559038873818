import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Switch, Route } from 'react-router';
import { Formik } from 'formik';

import { FormText } from '../../../../../styles/typography';
import SelectInput from '../../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, FieldsOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.custodialMinorCitizenship);
  const formState = getFormState(state, InitialValues);

  const onSubmit = () => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit()}
    >
      {form => {
        return (
          <>
            <SelectInput
              {...form}
              name={FormFields.stateOfResidency}
              label={FormStrings[FormFields.stateOfResidency]}
              options={FieldsOptions[FormFields.stateOfResidency]}
              defaultOption={formState[FormFields.stateOfResidency]}
            />
            <SelectInput
              {...form}
              name={FormFields.citizenship}
              label={FormStrings[FormFields.citizenship]}
              options={FieldsOptions[FormFields.citizenship]}
              defaultOption={formState[FormFields.citizenship]}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
