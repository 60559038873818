import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  taxId: 'taxId',
  taxIdType: 'taxIdType',
};

export const FormFieldsTypes = {
  [FormFields.taxId]: reviewFieldTypes.SSNString,
  [FormFields.taxIdType]: reviewFieldTypes.SelectOptions,
} as any;

export const FormStrings = {
  [FormFields.taxId]: 'Tax ID',
  [FormFields.taxIdType]: 'Tax ID Type',
};
