import React from 'react';
import { Route } from 'react-router';

import * as Routes from '../../constants/routes';
import Config from './formInjector';
import { TFormConfig, TForm } from './formTypes';

export const getRouteBasedOnConfig = (formConfig: TFormConfig, props: TForm) => {
  const { name, path, FormComponent } = formConfig;
  return (
    <Route exact key={name} path={`${Routes.REGISTER_INFO}/${path}`} render={() => <FormComponent {...props} />} />
  );
};

export const getRoutes = (props: TForm) => {
  return Object.keys(Config).map((key: string) => {
    return getRouteBasedOnConfig(Config[key], props);
  });
};
