import { css, cx } from 'emotion';
import { Typography, Color } from './../../../../styles';

export const Body = css`
  background: ${Color.OffWhite} !important;
  min-height: 100vh;
`;
export const container = css`
  background: ${Color.OffWhite} !important;
  min-height: 100vh;
`;
export const containerClass = css`
  background: ${Color.OffWhite} !important;
`;

export const headerTextContainer = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.396826px;
  color: #423c3a;
  margin-bottom: 29px;
`;

export const footerClass = css`
  background: #ffffff !important;
  border: 1px solid #dfe5f0;
  box-shadow: 0px -12px 40px rgba(223, 229, 240, 0.3);
  min-height: 106px;
  margin-top: 20px;
`;

export const footerText = css`
  font-family: ${Typography.GillSans};
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.15873px;
  color: #000000;
  font-weight: 600;
  vertical-align: middle;
  line-height: 40px;
`;

export const footerTextLighter = css`
  font-family: ${Typography.GillSans};
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.15873px;
  color: #000000;
  font-weight: 500;
`;

export const TBPModal = css`
  min-width: 890px;
  margin-top: 10px !important;
  .ant-modal-body {
    padding: 0px;
  }
`;

export const Border = css`
  border: 1px solid #e9e9e9 !Important;
`;
