import {
  FormFields as CFormFields,
  FormFieldsTypes as CFormFieldsTypes,
  FormStrings as CFormStrings,
  SolutionFields as CSolutionFields,
  SolutionFormFieldsRelations as CSolutionFormFieldsRelations,
} from '../../../../constants/suitabilityInfo';

export const SolutionFields = CSolutionFields;
export const SolutionFormFieldsRelations = CSolutionFormFieldsRelations;

export const FormFields = {
  ...CFormFields,
};

export const FormFieldsTypes = {
  ...CFormFieldsTypes,
};

export const FormStrings = {
  ...CFormStrings,
};
