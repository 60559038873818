import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { ENTITY, ENTITY_SOLE_PROPRIETORSHIP } from '../../../../../constants/accountTypes';

const name = 'uploadEntityDocuments';
export const path = 'uploadEntityDocuments';
const header = 'Upload Entity Documents';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations as any,
  FormComponent: FormComponent,
  disabled: false,
  accountType: ENTITY,
  accountSubType: ENTITY_SOLE_PROPRIETORSHIP,
};

export default config;
