import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  employmentStatus: 'employmentStatus',
  employerName: 'employerName',
  employerPosition: 'employerPosition',
  yearsEmployed: 'yearsEmployed',
  employerAddressLine1: 'employerAddressLine1',
  employerAddressLine2: 'employerAddressLine2',
  employerCity: 'employerCity',
  employerState: 'employerState',
  employerZip: 'employerZip',
  annualIncome: 'annualIncome',
  taxRate: 'taxRate',
};

export const FormFieldsTypes = {
  [FormFields.employmentStatus]: reviewFieldTypes.SelectOptions,
  [FormFields.employerName]: reviewFieldTypes.RawString,
  [FormFields.employerPosition]: reviewFieldTypes.RawString,
  [FormFields.yearsEmployed]: reviewFieldTypes.Number,
  [FormFields.employerAddressLine1]: reviewFieldTypes.RawString,
  [FormFields.employerAddressLine2]: reviewFieldTypes.RawString,
  [FormFields.employerCity]: reviewFieldTypes.RawString,
  [FormFields.employerState]: reviewFieldTypes.SelectOptions,
  [FormFields.employerZip]: reviewFieldTypes.RawString,
  [FormFields.annualIncome]: reviewFieldTypes.SelectOptions,
  [FormFields.taxRate]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.employmentStatus]: 'Employment Status',
  [FormFields.employerName]: 'Employer Name',
  [FormFields.employerPosition]: 'Employer Position (i.e. job title/responsibility)',
  [FormFields.yearsEmployed]: 'Years Employed',
  [FormFields.employerAddressLine1]: 'Employer Address Line 1',
  [FormFields.employerAddressLine2]: 'Employer Address Line 2',
  [FormFields.employerCity]: 'Employer City',
  [FormFields.employerState]: 'Employer State',
  [FormFields.employerZip]: 'Employer ZIP Code',
  [FormFields.annualIncome]: 'Annual Income',
  [FormFields.taxRate]: 'Tax Rate',
  selectRange: 'Select Range',
};
