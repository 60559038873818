import { css } from 'emotion';
import { Typography, Color } from './../../../styles/index';

export const ButtonContent = css`
  font-family: ${Typography.GillSans} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #0098ce !important;
  text-align: center !important;
  display: inline-flex;
  margin-bottom: 4px;
  span {
    margin-right: 4px;
    margin-left: 4px;
    margin-top: -2px;
  }
  i {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

export const Button = css`
  background: #ffffff !important;
  border-color: #ffffff !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  &:disabled {
    background-color: #989898 !important;
  }
`;

export const NavButton = css`
  border-radius: 20px !important;
  margin-bottom: 10px;
  border: none !important;
`;

export const ActiveNavButton = css`
  background-color: ${Color.TropicalBlue}!important;
  span {
    color: white !important;
  }
`;
