import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  birthday: 'birthday',
  ssn: 'ssn',
};

export const FormFieldsTypes = {
  [FormFields.birthday]: reviewFieldTypes.DateString,
  [FormFields.ssn]: reviewFieldTypes.SSNString,
};

export const FormStrings = {
  [FormFields.birthday]: 'Birthday',
  [FormFields.ssn]: 'Social Security #',
};
