import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  nameOfTrustRequired: `${FormStrings[FormFields.nameOfTrust]} is required`,
  nameOfTrustInvalid: `${FormStrings[FormFields.nameOfTrust]} is invalid`,
  dateOfTrustRequired: `${FormStrings[FormFields.dateOfTrust]} is required`,
  dateOfTrustInvalid: `${FormStrings[FormFields.dateOfTrust]} is invalid`,
  trustTaxIDRequired: `${FormStrings[FormFields.trustTaxID]} is required`,
  trustTaxIDInvalid: `${FormStrings[FormFields.trustTaxID]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.nameOfTrust]: Yup.string().required(validationMessages.nameOfTrustRequired),
  [FormFields.dateOfTrust]: Yup.string().required(validationMessages.dateOfTrustRequired),
  [FormFields.trustTaxID]: Yup.string().required(validationMessages.trustTaxIDInvalid),
});

export default defaultValidation;
