import { css, cx } from 'emotion';
import { Typography, Color } from './../../styles';

export const Container = css`
  margin: 0px 0px !important;
  padding: 0 7% !important;
  display: inline-block !important;
  width: 100% !important;
  background-color: ${Color.OffWhite} !important;
`;

export const QuestionContainer = css`
  margin: 28px 0px !important;
`;

export const PageHeaderText = css`
  font-family: ${Typography.CormorantGaramond} !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.396826px;
  color: ${Color.DarkerMediumGrey} !important;
`;

export const PageHeaderInfoText = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  font-weight: 300;
  color: ${Color.DarkerMediumGrey} !important;
`;

export const SectionTitle = css`
  font-family: ${Typography.CormorantGaramond} !important;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: ${Color.DeepBlack} !important;
`;

export const Hr = css`
  border: 1px solid ${Color.MediumGrey} !important;
  margin: 9px 0px !important;
`;

export const QuestionBlock = css`
  margin-bottom: 16px;
`;

export const Question = css`
  font-family: ${Typography.GillSans} !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: ${Color.DarkerMediumGrey} !important;
  margin-bottom: 6px;
`;

export const Answer = css`
  font-family: ${Typography.GillSans} !important;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: ${Color.DarkerMediumGrey} !important;
`;

export const Anchor = css`
  .ant-anchor-ink {
    display: none !important;
  }
  .ant-anchor-link {
    padding: 3px 0 3px;
    line-height: 1.143;
  }
`;

export const AnchorLink = css`
  .ant-anchor-link-title {
    font-family: ${Typography.GillSans} !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: ${Color.FineBlue} !important;
  }
`;

export const marginRight12 = css`
  margin-right: 12px !important;
`;
