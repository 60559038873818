import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { DirectToLogin } from '../AuthAction';
import { AuthFields, AuthStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import * as AuthActions from '../../../actions';
import Input from '../../shared/Inputs/Input';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import FormContainer from '../../shared/FormContainer/FormContainer';
import * as Styles from '../Style';

const Component = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.forgotPassword);
  const { error } = state || '';

  useEffect(() => {
    dispatch(
      AuthActions.clearForgotPassword({
        id: 'clearError',
        body: null,
      }),
    );
  }, []);

  const onSubmit = (values: any) => {
    dispatch(
      AuthActions.forgotPassword({
        id: 'forgotPassword',
        body: values,
      }),
    );
  };

  return (
    <FormContainer title={'Reset your password'}>
      <Formik
        enableReinitialize
        initialValues={{ ...InitialValues }}
        validationSchema={defaultValidation}
        onSubmit={values => onSubmit(values)}
      >
        {form => {
          return (
            <>
              <Row>
                <Col>
                  <span className={Styles.errorText}>{error}</span>
                </Col>
              </Row>
              <Input
                {...form}
                name={AuthFields.username}
                label={AuthStrings[AuthFields.username]}
                value={form.initialValues[AuthFields.username]}
                type={'email'}
              />
              <Button
                disabled={!form.isValid}
                onClick={form.submitForm}
                label={'SEND RESET CODE'}
                type={ButtonType.Thick}
                loading={!!state.__requested ? true : false}
              />
              <Row>
                <Col span={24} className={Styles.SignInTextCSS}>
                  <span>Back to </span>
                  <DirectToLogin />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default Component;
