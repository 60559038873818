import React from 'react';
import { Card, Row, Col } from 'antd';
import { Typography } from './../../../styles/index';
import * as Styles from './Style';
import SVGIcon from '../SVGIcon/SVGIcon';

export interface TFormInformationKeyValue {
  key: string;
  value: string[];
}

interface IProps {
  title?: string;
  description?: React.ReactNode;
  subTitle?: string;
  formInformationKeyValue: TFormInformationKeyValue[];
  footer?: string;
  children?: any;
  logo?: string;
}

const TwoSidedFormContainer = (Props: IProps) => {
  const { title, description, subTitle, formInformationKeyValue, footer, children, logo } = Props;
  return (
    <Card
      className={Styles.Card}
      title={
        <span className={Styles.Title}>
          {logo ? (
            <>
              <span>{title}</span>
              <SVGIcon iconName={logo} width={140} />
            </>
          ) : (
            title
          )}
        </span>
      }
    >
      <Row gutter={[160, 80]}>
        <Col span={12}>
          {description && <div className={Styles.Description}>{description}</div>}
          {subTitle && <div className={Styles.SubTitle}>{subTitle}</div>}
          {formInformationKeyValue.map((item, index) => (
            <>
              <div key={index} className={Styles.Key}>
                {item.key}
              </div>
              {item.value.map((str, index, array) => (
                <div key={index} className={Styles.Value}>
                  {str}
                </div>
              ))}
            </>
          ))}
        </Col>
        <Col span={12}>{children}</Col>
      </Row>
      {footer && (
        <Row>
          <Col span={24} className={Styles.Footer}>
            {footer}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default TwoSidedFormContainer;
