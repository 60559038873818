import React from 'react';
import { Formik } from 'formik';

import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, AnnualExpensesOptions, SpecialExpensesOptions, TimeFrameSpecialExpenses } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';
import { ContinueAction } from '../../FormActions';
import InfoText from '../../../shared/InfoText/InfoText';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.expectedExpenses);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (_values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <SelectInput
              {...form}
              name={FormFields.annualExpenses}
              label={FormStrings[FormFields.annualExpenses]}
              options={AnnualExpensesOptions}
              defaultOption={formState[FormFields.annualExpenses]}
            />
            <InfoText>{FormStrings.annualExpensesDisclaimer}</InfoText>
            <SelectInput
              {...form}
              name={FormFields.specialExpenses}
              label={FormStrings[FormFields.specialExpenses]}
              options={SpecialExpensesOptions}
              defaultOption={formState[FormFields.specialExpenses]}
            />
            <InfoText>{FormStrings.specialExpensesDisclaimer}</InfoText>
            <SelectInput
              {...form}
              name={FormFields.timeFrameSpecialExpenses}
              label={FormStrings[FormFields.timeFrameSpecialExpenses]}
              options={TimeFrameSpecialExpenses}
              defaultOption={formState[FormFields.timeFrameSpecialExpenses]}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
