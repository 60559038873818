import React from 'react';

const SVG = ({ style = {}, fill = '#989898', width = '14', height = '16', className = '', viewBox = '0 0 14 16' }) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M5.09091 14.5449C5.49229 14.5449 5.81845 14.8717 5.81845 15.2725C5.81845 15.6738 5.49229 16 5.09091 16H2.18197C1.88931 16 1.60361 15.9425 1.33623 15.8306C1.07581 15.7231 0.8432 15.5676 0.642194 15.3704C0.44245 15.1713 0.283159 14.94 0.173821 14.6827C0.0581497 14.4141 0 14.1296 0 13.837V2.1826C0 1.88742 0.0581529 1.60235 0.172559 1.33371C0.28191 1.07328 0.439925 0.839413 0.638407 0.639669C0.840044 0.439925 1.07265 0.281913 1.33245 0.171928C1.60235 0.058784 1.88805 0 2.18197 0H5.09091C5.49229 0 5.81845 0.326154 5.81845 0.727535C5.81845 1.13018 5.49229 1.45507 5.09091 1.45507H2.18197C1.78059 1.45507 1.45444 1.78122 1.45444 2.1826V13.837C1.45444 14.227 1.78059 14.5449 2.18197 14.5449L5.09091 14.5449ZM13.638 7.36506C13.8358 7.49843 13.9622 7.71524 13.9622 7.99968C13.9622 8.28664 13.8358 8.50219 13.638 8.63556L11.3915 10.882C11.0799 11.193 10.6121 11.193 10.3011 10.882C9.98951 10.5704 9.98951 10.1027 10.3011 9.79231L11.3131 8.77907H6.17355C5.70581 8.77907 5.39419 8.46808 5.39419 7.99971C5.39419 7.53323 5.70581 7.22161 6.17355 7.22161H11.3131L10.3011 6.20963C9.98951 5.89738 9.98951 5.43027 10.3011 5.11802C10.6121 4.8064 11.0799 4.8064 11.3915 5.11802L13.638 7.36506Z"
    />
  </svg>
);

export default SVG;
