import React, { useState } from 'react';
import { Button } from 'antd';
import * as ButtonStyle from './Style';

export type ButtonCompType = {
  name: string;
  onClick?: Function;
  disabled?: boolean;
  isActive: boolean;
  setFieldValue?: Function;
  setFieldTouched?: Function;
};

const StatefulButton = (props: ButtonCompType) => {
  const { name, onClick, disabled, isActive, setFieldValue, setFieldTouched } = props;

  const [active, setActive] = useState(isActive);

  const onButtonClick = () => {
    if (onClick) {
      onClick();
    }
    if (setFieldValue) {
      setFieldValue(name, !active);
    }
    setActive(!active);
  };

  let className = '';
  let label = '';
  switch (active) {
    case false:
      className = ButtonStyle.button;
      label = 'ADD SOLUTION';
      break;
    case true:
      className = ButtonStyle.active;
      label = 'REMOVE SOLUTION';
      break;
  }

  return (
    <Button
      name={name}
      className={className}
      value={label}
      onClick={() => onButtonClick()}
      type="default"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default StatefulButton;
