import { FormFields, FormStrings } from './constants';

export const InitialValues = {
  decisionMaking: {
    [FormFields.consultWithBroker]: false,
    [FormFields.ownDecisions]: false,
    [FormFields.discussions]: false,
  },
  investmentRatio: 'small',
};

export const InvestmentRatioOptions = [
  {
    label: 'Less than 1/3 of my financial portfolio',
    value: 'small',
  },
  {
    label: 'Roughly 1/3 to 2/3 of my financial portfolio',
    value: 'medium',
  },
  {
    label: 'More than 2/3 of my financial portfolio',
    value: 'large',
  },
];

export const FieldsOptions = {
  [FormFields.investmentRatio]: InvestmentRatioOptions,
};
