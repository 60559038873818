import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  firstNameRequired: `${FormStrings[FormFields.firstName]} is required`,
  firstNameInvalid: `${FormStrings[FormFields.firstName]} is invalid`,
  middleInitialRequired: `${FormStrings[FormFields.middleInitial]} is required`,
  middleInitialInvalid: `${FormStrings[FormFields.middleInitial]} is invalid`,
  lastNameRequired: `${FormStrings[FormFields.lastName]} is required`,
  lastNameInvalid: `${FormStrings[FormFields.lastName]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.firstName]: Yup.string().required(validationMessages.firstNameRequired),
  [FormFields.middleInitial]: Yup.string().notRequired(),
  [FormFields.middleInitial]: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]$/, validationMessages.middleInitialInvalid)
    .notRequired(),
  [FormFields.lastName]: Yup.string().required(validationMessages.lastNameRequired),
});

export default defaultValidation;
