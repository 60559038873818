import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  sharePercentageRequired: `${FormStrings[FormFields.sharePercentage]} is required`,
  sharePercentageInvalid: `${FormStrings[FormFields.sharePercentage]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.sharePercentage]: Yup.number()
    .required(validationMessages.sharePercentageRequired)
    .max(100, validationMessages.sharePercentageInvalid)
    .min(0.1, validationMessages.sharePercentageInvalid),
});

export default defaultValidation;
