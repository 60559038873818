import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import UploadIdentification from '../../UploadIdentification';
import { RETIREMENT } from '../../../../../constants/accountTypes';

const name = 'trustSharePercentage';
const path = 'trustSharePercentage';
const header = 'Trust Share Percentage';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  skipTo: () => UploadIdentification.name,
  disabled: false,
  accountType: RETIREMENT,
};

export default config;
