import { createAction, Type, State } from './utils';
import { AnyAction } from 'redux';

export const saveRetirementIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE), payload);
export const deleteRetirementIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE), payload);

export const saveEntityAuthorisedPersonIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE), payload);
export const deleteEntityAuthorisedPersonIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE), payload);

export const addJointAccountsIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE), payload);
export const deleteJointAccountsIntermediaryFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE), payload);
