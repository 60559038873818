import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
export const validationMessages = {
  questionOneRequired: `${FormStrings[FormFields.questionOne]} is required`,
  questionOneInvalid: `${FormStrings[FormFields.questionOne]} is invalid`,
  questionTwoRequired: `${FormStrings[FormFields.questionTwo]} is required`,
  questionTwoInvalid: `${FormStrings[FormFields.questionTwo]} is invalid`,
  questionThreeRequired: `${FormStrings[FormFields.questionThree]} is required`,
  questionThreeInvalid: `${FormStrings[FormFields.questionThree]} is invalid`,
  answerOneRequired: `${FormStrings[FormFields.answerOne]} is required`,
  answerOneInvalid: `${FormStrings[FormFields.answerOne]} is invalid`,
  answerTwoRequired: `${FormStrings[FormFields.answerTwo]} is required`,
  answerTwoInvalid: `${FormStrings[FormFields.answerTwo]} is invalid`,
  answerThreeRequired: `${FormStrings[FormFields.answerThree]} is required`,
  answerThreeInvalid: `${FormStrings[FormFields.answerThree]} is invalid`,
};
export const defaultValidation = Yup.object().shape({
  [FormFields.questionOne]: Yup.string()
    .required(validationMessages.questionOneRequired)
    .notOneOf(['-1'], validationMessages.questionOneRequired),
  [FormFields.questionTwo]: Yup.string()
    .required(validationMessages.questionTwoRequired)
    .notOneOf(['-1'], validationMessages.questionTwoRequired)
    .notOneOf([Yup.ref(FormFields.questionOne)], validationMessages.questionTwoInvalid),
  [FormFields.questionThree]: Yup.string()
    .required(validationMessages.questionThreeRequired)
    .notOneOf(['-1'], validationMessages.questionThreeRequired)
    .notOneOf(
      [Yup.ref(FormFields.questionOne), Yup.ref(FormFields.questionTwo)],
      validationMessages.questionThreeInvalid,
    ),
  [FormFields.answerOne]: Yup.string().required(validationMessages.answerOneRequired),
  [FormFields.answerTwo]: Yup.string().required(validationMessages.answerTwoRequired),
  [FormFields.answerThree]: Yup.string().required(validationMessages.answerThreeRequired),
});

export default defaultValidation;
