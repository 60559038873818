import { call, put, takeEvery } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import * as Url from '../constants/url';
import { Type, State } from '../actions/utils';
import { HttpClient } from '../helpers/httpClient';
import { actionSucceeded, actionRequested } from '../actions/types';
import { AuthState } from '../components/Auth/authType';

export function* login(action: any) {
  const username = action.payload.body.email;
  const { password } = action.payload.body;
  try {
    const response = yield Auth.signIn(username, action.payload.body.password);
    yield put({ type: State.actionSucceeded(Type.LOGIN), payload: response });
    yield put({
      type: actionSucceeded(Type.SET_AUTH_TOKEN),
      payload: {
        accessToken: response.storage[`${response.keyPrefix}.${response.attributes.sub}.accessToken`],
        clockDrift: response.storage[`${response.keyPrefix}.${response.attributes.sub}.clockDrift`],
        idToken: response.storage[`${response.keyPrefix}.${response.attributes.sub}.idToken`],
        refreshToken: response.storage[`${response.keyPrefix}.${response.attributes.sub}.refreshToken`],
        userData: response.storage[`${response.keyPrefix}.${response.attributes.sub}.userData`],
        username,
        authState: AuthState.SIGNED_IN,
      },
    });
  } catch (error) {
    if (error.code === 'UserNotConfirmedException') {
      yield put({
        type: State.actionSucceeded(Type.SET_AUTH_TOKEN),
        payload: { username, password, authState: AuthState.CONFIRM_SIGN_UP },
      });
    } else {
      yield put({ type: State.actionFailed(Type.LOGIN), error: error.message });
    }
  }
}

export function* loginClearError(action: any) {
  yield put({ type: actionSucceeded(Type.CLEAR_LOGIN), payload: action.payload });
}

export function* register(action: any) {
  try {
    const { username, password } = action.payload.body;
    const response = yield Auth.signUp(action.payload.body);
    yield put({ type: State.actionSucceeded(Type.REGISTER), payload: response });
    yield put({
      type: State.actionSucceeded(Type.SET_AUTH_TOKEN),
      payload: { username, password, authState: AuthState.CONFIRM_SIGN_UP },
    });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.REGISTER), error: error.message });
  }
}

export function* registerClearError(action: any) {
  yield put({ type: actionSucceeded(Type.CLEAR_REGISTRATION), payload: action.payload });
}

export function* confirmEmail(action: any) {
  const { username, code } = action.payload.body;
  try {
    const response = yield Auth.confirmSignUp(username, code);
    yield put({ type: State.actionSucceeded(Type.CONFIRM_EMAIL), payload: response });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.CONFIRM_EMAIL), error: error.message });
  }
}

export function* forgotPassword(action: any) {
  const { username, code, password, resendCounter } = action.payload.body;
  try {
    let response = '';
    if (action.payload.id === 'forgotPasswordSubmit') {
      response = yield Auth.forgotPasswordSubmit(username, code, password);
      yield put({ type: State.actionSucceeded(Type.FORGOT_PASSWORD), payload: response });
      yield put({
        type: State.actionSucceeded(Type.SET_AUTH_TOKEN),
        payload: { authState: AuthState.SIGN_IN, username },
      });
    } else {
      response = yield Auth.forgotPassword(username);
      yield put({
        type: State.actionSucceeded(Type.FORGOT_PASSWORD),
        payload: { response, resendCounter: resendCounter || 0 },
      });
      yield put({
        type: State.actionSucceeded(Type.SET_AUTH_TOKEN),
        payload: { authState: AuthState.SUBMIT_PASSWORD, username },
      });
    }
  } catch (error) {
    yield put({ type: State.actionSucceeded(Type.SET_AUTH_TOKEN), payload: { username } });
    yield put({ type: State.actionFailed(Type.FORGOT_PASSWORD), error: error.message });
  }
}

export function* forgotPasswordClear(action: any) {
  yield put({ type: actionSucceeded(Type.CLEAR_FORGOT_PASSWORD), payload: action.payload });
}

export function* changePassword(action: any) {
  const { oldPassword, newPassword } = action.payload.body;
  try {
    const response = yield Auth.currentAuthenticatedUser().then(user => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
    yield put({ type: State.actionSucceeded(Type.CHANGE_PASSWORD), response });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.CHANGE_PASSWORD), error: error.message });
  }
}

export function* setAuthToken(action: any) {
  yield put({ type: actionSucceeded(Type.SET_AUTH_TOKEN), payload: action.payload });
}

export function* removeAuthToken(action: any) {
  yield put({ type: actionSucceeded(Type.REMOVE_AUTH_TOKEN), payload: action.payload });
}

export function* logout(action: any) {
  try {
    const response = yield Auth.signOut();
    yield put({ type: State.actionSucceeded(Type.LOGOUT), payload: response });
    yield put({
      type: State.actionSucceeded(Type.SET_AUTH_TOKEN),
      payload: { authState: AuthState.SIGNED_OUT },
    });
    yield put({ type: actionSucceeded(Type.CLEAR_LOGIN), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.LOGOUT), error: error.message });
  }
}

export function* resetPassword() {
  try {
    const response = yield call(HttpClient, 'get', Url.RESETPASSWORD_URL);
    yield put({ type: State.actionSucceeded(Type.RESETPASSWORD), payload: response.data });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.RESETPASSWORD), error: error.message });
  }
}

/**
 * Authentication sagas
 */
export default function* authSaga() {
  yield takeEvery(State.actionRequested(Type.LOGIN), login);
  yield takeEvery(State.actionRequested(Type.CLEAR_LOGIN), loginClearError);
  yield takeEvery(State.actionRequested(Type.REGISTER), register);
  yield takeEvery(State.actionRequested(Type.CLEAR_REGISTRATION), registerClearError);
  yield takeEvery(State.actionRequested(Type.CONFIRM_EMAIL), confirmEmail);
  yield takeEvery(State.actionRequested(Type.FORGOT_PASSWORD), forgotPassword);
  yield takeEvery(State.actionRequested(Type.CLEAR_FORGOT_PASSWORD), forgotPasswordClear);
  yield takeEvery(State.actionRequested(Type.CHANGE_PASSWORD), changePassword);
  yield takeEvery(State.actionRequested(Type.LOGOUT), logout);
  yield takeEvery(State.actionRequested(Type.SET_AUTH_TOKEN), setAuthToken);
  yield takeEvery(State.actionRequested(Type.REMOVE_AUTH_TOKEN), removeAuthToken);
  yield takeEvery(State.actionRequested(Type.RESETPASSWORD), resetPassword);
}
