import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  sharePercentageInvalid: `${FormStrings[FormFields.sharePercentage]}  is invalid`,
  sharePercentageRequired: `${FormStrings[FormFields.sharePercentage]} is required`,
  percentagePrimaryRequired: `${FormStrings[FormFields.percentagePrimary]} sum is required to be 100%`,
  percentageContingentRequired: `${FormStrings[FormFields.percentageContingent]} sum is required to be 100%`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.percentagePrimary]: Yup.number().oneOf([100], validationMessages.percentagePrimaryRequired),
  [FormFields.percentageContingent]: Yup.number().oneOf([0, 100], validationMessages.percentageContingentRequired),
  [FormFields.sharePercentage]: Yup.array().of(
    Yup.object().shape({
      sharePercentage: Yup.number()
        .required(validationMessages.sharePercentageRequired)
        .max(100, validationMessages.sharePercentageInvalid)
        .min(0.1, validationMessages.sharePercentageInvalid),
    }),
  ),
});
export default defaultValidation;
