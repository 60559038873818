import * as Yup from 'yup';
import Moment from 'moment';
import { FormFields, FormStrings } from './constants';
import { StateOptions, CitizenshipOptions } from './schema';

export const validationMessages = {
  addressLineOneRequired: `${FormStrings[FormFields.addressLineOne]} is required`,
  addressLineOneInvalid: `${FormStrings[FormFields.addressLineOne]} is invalid`,
  addressLineTwoInvalid: `${FormStrings[FormFields.addressLineTwo]} is invalid`,
  cityRequired: `${FormStrings[FormFields.city]} is required`,
  cityInvalid: `${FormStrings[FormFields.city]} is invalid`,
  stateRequired: `${FormStrings[FormFields.state]} is required`,
  zipCodeRequired: `${FormStrings[FormFields.zipCode]} is required`,
  zipCodeInvalid: `${FormStrings[FormFields.zipCode]} is invalid`,
  citizenshipRequired: `${FormStrings[FormFields.citizenship]} is required`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.addressLineOne]: Yup.string()
    .required(validationMessages.addressLineOneRequired)
    .max(100, validationMessages.addressLineOneInvalid),
  [FormFields.addressLineTwo]: Yup.string().max(100, validationMessages.addressLineTwoInvalid),
  [FormFields.city]: Yup.string()
    .required(validationMessages.cityRequired)
    .max(100, validationMessages.cityInvalid),
  [FormFields.state]: Yup.string()
    .required(validationMessages.stateRequired)
    .notOneOf(['-1'], validationMessages.stateRequired),
  [FormFields.zipCode]: Yup.number()
    .required(validationMessages.zipCodeRequired)
    .moreThan(0, validationMessages.zipCodeInvalid)
    .lessThan(100000, validationMessages.zipCodeInvalid),
  [FormFields.citizenship]: Yup.string()
    .required(validationMessages.citizenshipRequired)
    .notOneOf(['-1'], validationMessages.citizenshipRequired),
});

export default defaultValidation;
