import { Col, Divider, Icon, Row, Spin, Button as ButtonAnt, message } from 'antd';
import _, { get } from 'lodash';
import { connect } from 'react-redux';

import React, { Fragment, useEffect, useState } from 'react';
import { useImage } from 'react-image';
import { alignLeft, alignRight, headerTextModal, spinner, spinnerContainer } from '../../style';

import { uploadDescription, outer, changeCardButton, deleteCardButton, spin, spinCambriaBlue } from './style';

import Button, { ButtonType } from '../../../shared/Buttons/Button';
import FormComponent from '../FormComponent';

import { getPersonalInformation, updatePersonalInformation } from '../../../../actions/index';
import {
  countryOfResidentsValidation,
  physicalAddressValidation,
  mailingAddressValidation,
  cardIdValidation,
} from './validations';

import getFields from './fields';

type PersonalInformationProps = {
  personalInformation: any;
  personalInformationPatch?: boolean;
  error?: string;
  getPersonalInformation: Function;
  updatePersonalInformation: Function;
};

const PersonalInformationComp: React.FC<PersonalInformationProps> = ({
  personalInformation,
  personalInformationPatch,
  error,
  getPersonalInformation,
  updatePersonalInformation,
}: PersonalInformationProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pickedSectionForEditing, setPickedSectionForEditing]: [any, any] = useState({});
  const [imageSource, setImageSource] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  useImage({
    srcList: personalInformation.cardId,
    useSuspense: false,
    imgPromise: async (imgSource: string) => {
      if (!_.isEmpty(imgSource)) {
        // Induced Wait
        setTimeout(() => {
          setImageSource(imgSource);
          setIsImageLoading(false);
        }, 500);
      }
    },
  });

  useEffect(() => {
    if (_.isEmpty(personalInformation) && _.isEmpty(error) && !isFetching) {
      setIsFetching(true);
      getPersonalInformation();
    }
  }, []);

  useEffect(() => {
    if (!isSubmitting && isFetching) {
      setIsFetching(false);
    } else if (isSubmitting && isFetching) {
      setIsFetching(false);
      setIsSubmitting(false);
      setIsModalVisible(false);
      setPickedSectionForEditing({});
      message.success({ content: 'Information successfully updated ' });
    }
    return () => {
      message.destroy();
    };
  }, [personalInformation]);

  useEffect(() => {
    if (isSubmitting) {
      if (personalInformationPatch === false) {
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});

        message.error({ content: 'Something went wrong and the edited information could not be saved.' });
      } else if (personalInformationPatch === true) {
        // TODO: Comment out on real fetch
        // setIsFetching(true);
        //  getPersonalInformation();

        // TODO: Remove on real fetch
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});
        message.success({ content: 'Information successfully updated ' });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [personalInformationPatch]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      // Fetch Error
      if (!isSubmitting && isFetching) {
        setIsFetching(false);
        message.error({ content: error });
      }
      // Patch Error
      if (isSubmitting && !isFetching) {
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});
        message.error({ content: error });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [error]);

  const onSubmit = async (values: any) => {
    let updatedData = { ...personalInformation, ...values };
    setIsSubmitting(true);
    await updatePersonalInformation(updatedData);
  };

  const deleteImage = async () => {
    let updatedData = { ...personalInformation, cardId: null };
    setIsSubmitting(true);
    await updatePersonalInformation(updatedData);
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setPickedSectionForEditing({});
  };

  if ((isFetching === true && !isSubmitting) || _.isEmpty(personalInformation)) {
    return (
      <div className={spinnerContainer}>
        <div className={spinner}>
          <Spin />
        </div>
      </div>
    );
  }

  const fields: any = getFields(personalInformation);

  const sections: Array<any> = [
    {
      sectionLabel: 'Country of Residence',
      validationSchema: countryOfResidentsValidation,
      fieldKeys: ['countryOfResidence'],
      sectionDescription:
        'Certain products we offer are only available to residents of specific countries. Please select your country of residence so we can ensure the products you’ve selected are available.',
    },
    {
      sectionLabel: 'Physical Address',
      fieldKeys: [
        'physicalAddressAddressLineOne',
        'physicalAddressAddressLineTwo',
        'physicalAddressCity',
        'physicalAddressState',
        'physicalAddressZipCode',
        'physicalAddressSameAsMailing',
        'physicalAddressCitizenship',
        'physicalAddressPermanentUsResident',
      ],
      sectionDescription: 'Street addresses only, we do not accept PO Boxes.',
      validationSchema: physicalAddressValidation,
    },
    {
      sectionLabel: 'Mailing Address',
      validationSchema: mailingAddressValidation,
      fieldKeys: [
        'mailingAddressAddressLineOne',
        'mailingAddressAddressLineTwo',
        'mailingAddressCity',
        'mailingAddressState',
        'mailingAddressZipCode',
      ],
      sectionDescription: personalInformation.physicalAddress.mailingSame
        ? 'Your mailing address is the same as your physical address. If you wish to change it, please edit your physical address section, and denote the mailing address to not be the same as your physical one.'
        : 'Street addresses only, we do not accept PO Boxes.',
    },
  ];

  const cardIdSection: any = {
    sectionLabel: 'ID Card Upload',
    validationSchema: cardIdValidation,
    fieldKeys: ['cardId'],
    sectionDescription: '',
  };

  console.log('isImageLoading', isImageLoading);
  return (
    <>
      <Row>
        <p className={headerTextModal}>Personal Information</p>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Col span={12}>
          <span className={alignLeft} style={{ color: '#989898' }}>
            Card ID
          </span>
        </Col>
        <Divider />
      </Row>

      {_.isEmpty(personalInformation.cardId) ? (
        <>
          <Row>
            <Col span={8}></Col>
            <Col span={8}>
              <p className={uploadDescription}>
                <Icon
                  type="exclamation-circle"
                  theme="twoTone"
                  twoToneColor="#FF244E"
                  style={{ fontSize: '13px', marginRight: '10px' }}
                />
                You need to upload the Card ID image
              </p>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Row>
            <Col span={10}></Col>
            <Col span={4}>
              <Button
                onClick={() => {
                  setPickedSectionForEditing(cardIdSection);
                  setIsModalVisible(true);
                }}
                label={'Upload'}
                type={ButtonType.Thick}
              />
            </Col>
            <Col span={10}></Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={8}></Col>
            <Col span={8}>
              <div className={outer}>
                {isImageLoading ? (
                  <>
                    <Spin className={spinCambriaBlue} size="small" />
                    <span style={{ marginLeft: 5 }}>Rendering image</span>
                  </>
                ) : (
                  <img src={imageSource} width="100%" />
                )}
              </div>
            </Col>
            <Col span={8}></Col>
          </Row>
          {!isImageLoading && (
            <Row style={{ marginTop: '20px', marginBottom: '40px' }}>
              <Col>
                <div className={outer}>
                  <ButtonAnt
                    className={deleteCardButton}
                    disabled={isSubmitting}
                    onClick={() => {
                      deleteImage();
                    }}
                  >
                    <span>
                      {isSubmitting ? (
                        <Spin className={spin} size="small" />
                      ) : (
                        <Icon type="delete" theme="twoTone" twoToneColor="#FF244E" />
                      )}
                      <span style={{ marginLeft: 5 }}>{isSubmitting ? 'Deleting Image' : 'Delete Image'}</span>
                    </span>
                  </ButtonAnt>
                  <ButtonAnt
                    className={changeCardButton}
                    disabled={isSubmitting}
                    onClick={() => {
                      setPickedSectionForEditing(cardIdSection);
                      setIsModalVisible(true);
                    }}
                  >
                    <Icon type="sync" />
                    Change Image
                  </ButtonAnt>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}

      {sections.map((section: any) => (
        <Fragment key={`${_.snakeCase(section.sectionLabel)}_fragment`}>
          <Row key={`${_.snakeCase(section.sectionLabel)}_section`} style={{ marginTop: '20px' }}>
            <Col span={12}>
              <span className={alignLeft} style={{ color: '#989898' }}>
                {section.sectionLabel}
              </span>
            </Col>

            <Col span={12}>
              <a
                className={alignRight}
                type="primary"
                onClick={() => {
                  setPickedSectionForEditing(section);
                  setIsModalVisible(true);
                }}
                style={{ color: '#0098CE' }}
              >
                Edit
              </a>
            </Col>
            <Divider />
          </Row>
          {Object.keys(fields)
            .filter(key => section.fieldKeys.includes(key))
            .map(key => {
              if (fields[key].showDisplayValue) {
                return (
                  <Row key={`${key}_input`}>
                    <Col span={12}>
                      <span className={alignLeft}>{fields[key].viewLabel}</span>
                    </Col>

                    <Col span={12}>
                      <span className={alignRight}>
                        {_.isEmpty(fields[key].displayValue) ? '-' : fields[key].displayValue}
                      </span>
                    </Col>
                    <Divider />
                  </Row>
                );
              }
            })}
        </Fragment>
      ))}

      <FormComponent
        isModalVisible={isModalVisible && !_.isEmpty(pickedSectionForEditing)}
        fields={Object.keys(fields)
          .filter(key => {
            if (_.isEmpty(pickedSectionForEditing)) {
              return false;
            }
            const section: any = pickedSectionForEditing;
            return section.fieldKeys.includes(key);
          })
          .map(key => ({ key: key, value: fields[key] }))
          .reduce(
            (obj, keyValuePair) => ({
              ...obj,
              [keyValuePair.key]: keyValuePair.value,
            }),
            {},
          )}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        onCancel={onCancel}
        section={pickedSectionForEditing}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  personalInformation: get(state, 'account.personalInformation'),
  personalInformationPatch: get(state, 'account.personalInformationPatch'),
  error: get(state, 'account.error'),
});

const mapDispatchToProps = {
  getPersonalInformation,
  updatePersonalInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationComp);
