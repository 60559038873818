import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues, StateOptions, CitizenshipOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import Input from '../../../shared/Inputs/Input';
import SelectInput from '../../../shared/Select/Select';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getFormState, jointAccountsIntermediaryFilter } from '../../../../utils/forms';
import { saveMailingAddress, deleteMailingAddress } from '../../../../actions/forms';
import {
  addJointAccountsIntermediaryFormState,
  deleteJointAccountsIntermediaryFormState,
} from '../../../../actions/intermediary';
import { name as MailingAddressID } from '../MailingAddress/index';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';

const Component = (props: TForm) => {
  const dispatch = useDispatch();
  // const history: any = useHistory();
  //
  const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key =
  //   history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
  //
  // const state = useSelector((state: any) =>
  //   history.location.state && typeof history.location.state.id !== 'undefined' && addingJointAccount
  //     ? jointAccountsIntermediaryFilter(state.intermediary.jointAccountsIntermediary, props.id)
  //     : state.forms.physicalAddress,
  // );
  // const formState = getFormState(state, InitialValues);
  // const hasKey = key || key === 0 || addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(props.id, InitialValues, history);
  const onSubmit = (values: any) => {
    let mailingAddressPayload = {
      id: MailingAddressID,
      body: {
        [FormFields.addressLineOne]: values[FormFields.addressLineOne],
        [FormFields.addressLineTwo]: values[FormFields.addressLineTwo],
        [FormFields.city]: values[FormFields.city],
        [FormFields.state]: values[FormFields.state],
        [FormFields.zipCode]: values[FormFields.zipCode],
      },
    };
    if (props.onContinue && hasKey) {
      if (values[FormFields.mailAddress]) {
        mailingAddressPayload.body.id = key;
        dispatch(addJointAccountsIntermediaryFormState(mailingAddressPayload));
      }
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      if (values[FormFields.mailAddress]) {
        dispatch(saveMailingAddress(mailingAddressPayload));
      }
      props.onContinue(values);
    }
  };

  const onChangeMailingAddress = (checked: boolean) => {
    let action = null;
    // if (!checked && addingJointAccount) {
    //   action = deleteJointAccountsIntermediaryFormState({
    //     id: MailingAddressID,
    //   });
    // } else
    if (!checked && !addingJointAccount) {
      action = deleteMailingAddress({
        id: MailingAddressID,
      });
      dispatch(action);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.addressLineOne}
              label={FormStrings[FormFields.addressLineOne]}
              value={formState[FormFields.addressLineOne]}
            />
            <Input
              {...form}
              name={FormFields.addressLineTwo}
              label={FormStrings[FormFields.addressLineTwo]}
              value={formState[FormFields.addressLineTwo]}
            />
            <Input
              {...form}
              name={FormFields.city}
              label={FormStrings[FormFields.city]}
              value={formState[FormFields.city]}
            />
            <SelectInput
              {...form}
              name={FormFields.state}
              label={FormStrings[FormFields.state]}
              options={StateOptions}
              defaultOption={formState[FormFields.state]}
            />
            <Input
              {...form}
              name={FormFields.zipCode}
              label={FormStrings[FormFields.zipCode]}
              value={formState[FormFields.zipCode]}
            />
            <Checkbox
              {...form}
              name={FormFields.mailAddress}
              label={FormStrings[FormFields.mailAddress]}
              checked={formState[FormFields.mailAddress]}
              onChange={onChangeMailingAddress}
            />
            <SelectInput
              {...form}
              name={FormFields.citizenship}
              label={FormStrings[FormFields.citizenship]}
              options={CitizenshipOptions}
              defaultOption={formState[FormFields.citizenship]}
            />
            {form.values.citizenship > 0 && (
              <Checkbox
                {...form}
                name={FormFields.permanentUSResident}
                label={FormStrings[FormFields.permanentUSResident]}
                checked={formState[FormFields.permanentUSResident]}
              />
            )}

            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default Component;
