import React, { useState } from 'react';
import { Formik } from 'formik';

import { FormText } from '../../../../styles/typography';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import SelectInput from '../../../shared/Select/Select';
import Input from '../../../shared/Inputs/Input';

import { FormFields, FormStrings } from './constants';
import { InitialValues, TradesOrYears } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';
import { map } from 'lodash';
import { marginRight12 } from './../../../Faq/style';

interface IProps {
  fieldName: string;
  form: any;
}

const YearsTradingsComp = (props: IProps) => (
  <Row justify="space-around">
    <Col span={8} className={marginRight12}>
      <Input
        {...props.form}
        name={`${props.fieldName}-${FormFields.yearsTrading}`}
        label={FormStrings[FormFields.yearsTrading]}
        value={props.form.initialValues[`${props.fieldName}-${FormFields.yearsTrading}`]}
        type={'number'}
        min={0}
        max={100}
        step={1}
      />
    </Col>
    <Col span={8}>
      <SelectInput
        {...props.form}
        name={`${props.fieldName}-${FormFields.tradesOrYear}`}
        label={FormStrings[FormFields.tradesOrYear]}
        options={TradesOrYears}
        defaultOption={
          props.form.initialValues[`${props.fieldName}-${FormFields.tradesOrYear}`]
            ? props.form.initialValues[`${props.fieldName}-${FormFields.tradesOrYear}`]
            : TradesOrYears[0].value
        }
      />
    </Col>
  </Row>
);

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.investorExperience);
  const formState = getFormState(state, InitialValues);
  const solutions = useSelector((state: any) => state.solutions);

  const onSubmit = (_values: any) => {
    if (props.onContinue) props.onContinue();
  };

  const hasInvestmentStrategy: boolean =
    solutions['tradingBlock'] &&
    solutions['tradingBlock']['products'] &&
    solutions['tradingBlock']['products']['investmentStrategies'];

  const isUncoveredCallsIndexOptions: boolean =
    hasInvestmentStrategy &&
    solutions['tradingBlock']['products']['investmentStrategies']['uncoveredCallsIndexOptions'];

  const isOptionsSpreads: boolean =
    hasInvestmentStrategy &&
    (solutions['tradingBlock']['products']['investmentStrategies']['putWriting'] ||
      solutions['tradingBlock']['products']['investmentStrategies']['optionSpreads']);

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation(isUncoveredCallsIndexOptions ? 2 : isOptionsSpreads ? 1 : 0)}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <p className={FormText}>{FormStrings.formText}</p>
            {map(InitialValues, (value: boolean, key: string) => {
              return (
                <>
                  <Checkbox
                    {...form}
                    name={FormFields[key]}
                    label={FormStrings[key]}
                    checked={formState[FormFields[key]]}
                  />
                  {form.values[FormFields[key]] == true && (
                    <YearsTradingsComp fieldName={FormFields[key]} form={form} />
                  )}
                </>
              );
            })}
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
