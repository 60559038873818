import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  questionOne: 'questionOne',
  answerOne: 'answerOne',
  questionTwo: 'questionTwo',
  answerTwo: 'answerTwo',
  questionThree: 'questionThree',
  answerThree: 'answerThree',
};

export const FormFieldsTypes = {
  [FormFields.questionOne]: reviewFieldTypes.SelectOptions,
  [FormFields.answerOne]: reviewFieldTypes.RawString,
  [FormFields.questionTwo]: reviewFieldTypes.SelectOptions,
  [FormFields.answerTwo]: reviewFieldTypes.RawString,
  [FormFields.questionThree]: reviewFieldTypes.SelectOptions,
  [FormFields.answerThree]: reviewFieldTypes.RawString,
} as any;

export const FormStrings = {
  [FormFields.questionOne]: 'Question 1',
  [FormFields.answerOne]: 'Answer 1',
  [FormFields.questionTwo]: 'Question 2',
  [FormFields.answerTwo]: 'Answer 2',
  [FormFields.questionThree]: 'Question 3',
  [FormFields.answerThree]: 'Answer 3',
};
