import { css, cx } from 'emotion';
import { Typography, Color } from './../../../styles/index';

export const cardStyle = css`
  padding-top: 40px !important;
  padding-bottom: 18px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  .ant-card-body {
    padding: 0px !important;
  }
`;

export const imgStyle = css`
  padding-top: 2px !important;
  padding-bottom: 32px !important;
  display: block;
  margin: auto;
  width: 100%;
`;
export const SolutionName = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: ${Color.DeepBlack};
  margin-bottom: 14px;
`;

export const SolutionDescription = css`
  font-family: ${Typography.GillSans};
  font-size: 12px;
  line-height: 16px;
  color: #423c3a;
  margin-bottom: 24px;
  white-space: break-spaces;
`;

export const Border = css`
  border: 1px solid #e9e9e9 !Important;
`;

export const ButtonStyle = css`
  padding-top: 14px;
`;
