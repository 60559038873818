import { FormFields, FormStrings } from './constants';

export const FieldsOptions = {};

export const InitialValues = {
  [FormFields.income]: false,
  [FormFields.pensionOrRetirement]: false,
  [FormFields.fundsFromAnother]: false,
  [FormFields.gift]: false,
  [FormFields.saleOfBusiness]: false,
  [FormFields.insurancePayout]: false,
  [FormFields.inheritance]: false,
  [FormFields.socialsecurityBenefits]: false,
  [FormFields.homeEquityLineOfCredit]: false,
  [FormFields.other]: '',
};
