import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import InfoText from '../../../shared/InfoText/InfoText';
import * as Routes from '../../../../constants/routes';
import ReviewForm from './ReviewForm';
import StatementDeliveryConfig from '../StatementDelivery';

const Component = (props: TForm) => {
  const history = useHistory();

  const onContinue = () => {
    history.push(`${Routes.REGISTER_INFO}/${StatementDeliveryConfig.path}`);
  };

  return (
    <>
      <InfoText>Please review and confirm all information you’ve provided is correct.</InfoText>
      <ReviewForm />
      <ContinueAction id={'review'} disabled={false} values={null} onContinue={() => onContinue()} />
    </>
  );
};

export default Component;
