import * as Yup from 'yup';
import { FormFields } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const RiskTolerance: TSelectOption[] = [
  {
    label: 'Select Risk Tolerance',
    value: '-1',
  },
  {
    label: 'Conservative',
    value: 'CONSERVATIVE',
  },
  {
    label: 'Moderately Conservative',
    value: 'MODERATELY_CONSERVATIVE',
  },
  {
    label: 'Moderate',
    value: 'MODERATE',
  },
  {
    label: 'Moderately Aggressive',
    value: 'MODERATELY_AGGRESSIVE',
  },
  {
    label: 'Significant Risk',
    value: 'SIGNIFICANT_RISK',
  },
];

export const InitialValues = {
  [FormFields.crowdFundingRegCF]: RiskTolerance[0].value,
  [FormFields.iPOsAndOfferings]: RiskTolerance[0].value,
  [FormFields.discountTrading]: RiskTolerance[0].value,
  [FormFields.managedAccounts]: RiskTolerance[0].value,
};

export const FieldsOptions = {
  [FormFields.crowdFundingRegCF]: RiskTolerance,
  [FormFields.iPOsAndOfferings]: RiskTolerance,
  [FormFields.discountTrading]: RiskTolerance,
  [FormFields.managedAccounts]: RiskTolerance,
};
