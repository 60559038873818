import * as Yup from 'yup';
import { FormStrings, FormFields } from './constants';

export const validationMessages = {
  authorizedPersonIdRequired: `${FormStrings[FormFields.authorizedPersonsId]} is required`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.uploadLater]: Yup.boolean(),
  [FormFields.authorizedPersonsId]: Yup.array().when([FormFields.uploadLater], {
    is: true,
    then: Yup.array(),
    otherwise: Yup.array().of(
      Yup.object().shape({
        [FormFields.authorizedPersonId]: Yup.string().required(validationMessages.authorizedPersonIdRequired),
      }),
    ),
  }),
});

export default defaultValidation;
