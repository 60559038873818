import authSaga from './auth';
import accountSaga from './account';
import formsSaga from './forms';
import solutionsSaga from './solutions';
import intermediarySaga from './intermediary';
import navigationSaga from './navigation';
import externalAccountBalance from './plaidSettings';
import userManagement from './userManagement';

/**
 * Register all sagas
 */
export default [
  authSaga,
  accountSaga,
  formsSaga,
  solutionsSaga,
  intermediarySaga,
  navigationSaga,
  externalAccountBalance,
  userManagement,
];
