import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Popover, Icon } from 'antd';

import * as Styles from './Styles';
import solutions, { externalSolutions } from '../../../constants/soutions';
import SVGIcon from '../SVGIcon/SVGIcon';
import PlaidLinkModal from '../../PlaidLink';

type TConnectOtherAccountDropdown = {};

const ConnectOtherAccountDropdown: FunctionComponent<TConnectOtherAccountDropdown> = () => {
  const [visible, setVisible] = useState(false);
  const [plaidLinkVisible, setPlaidLinkVisible] = useState(false);

  const menu = (
    <div>
      <div className={Styles.blockCambria}>
        <div className={Styles.Category}>Cambria Account</div>
        <div className={Styles.Solutions}>
          {solutions.map(item => {
            return (
              <SVGIcon
                key={`${item.name}_icon`}
                height={18}
                className={Styles.CambriaSolution}
                onClick={() => console.log(`clicked ${item.name}`)}
                iconName={item.icon}
              />
            );
          })}
        </div>
      </div>
      <div className={Styles.block}>
        <div className={Styles.Category}>External Account</div>
        <div className={Styles.Solutions}>
          {externalSolutions.map(item => {
            return (
              <SVGIcon
                key={`${item.name}_icon`}
                height={14}
                className={Styles.ExternalSolution}
                onClick={() => {
                  setVisible(false);
                  setTimeout(() => setPlaidLinkVisible(true), 500);
                }}
                iconName={item.icon}
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        className={Styles.popoverStyle}
        placement="bottomLeft"
        content={menu}
        trigger="click"
        visible={visible}
        overlayClassName={Styles.popover}
        onVisibleChange={() => setVisible(!visible)}
      >
        <Button className={Styles.DropdownButton}>
          CONNECT ANOTHER ACCOUNT {visible ? <Icon type="up" /> : <Icon type="down" />}
        </Button>
      </Popover>
      <PlaidLinkModal visible={plaidLinkVisible} setVisible={setPlaidLinkVisible} />
    </>
  );
};

export default ConnectOtherAccountDropdown;
