import React from 'react';
import { Col, Layout, Row, } from 'antd';

import * as Styles from './Styles';
import solutions from '../../../constants/soutions';
import SVGIcon from '../SVGIcon/SVGIcon';
import Link from '../Link/Link';

const { Footer } = Layout;

export default () => {
  return (
    <Footer style={{ textAlign: 'center' }} className={Styles.Footer}>
      <div className={Styles.Head}>Cambria Investment Solutions</div>
      <Row type="flex" justify="center" gutter={12} className={Styles.RowSolutions}>
        {solutions.map(solution => {
          return (
            <SVGIcon
              height={20}
              className={Styles.CambriaSolution}
              onClick={() => console.log(`clicked ${solution.name}`)}
              iconName={solution.icon}
            />
          );
        })}
      </Row>
      <div>
        <Link className={Styles.LearnMore} label={'Learn More'} linkTo={'#'} />
      </div>
    </Footer>
  );
};
