import React from 'react';
import { Button, Form, Icon, Upload as AntDUpload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { HttpRequestHeader, UploadFile } from 'antd/lib/upload/interface';
import { FormItemProps } from 'antd/es/form';
import * as Styles from './Style';

export type UploadCompType = {
  name: string;
  action?: string;
  headers?: HttpRequestHeader;
  onChange?: (info: UploadChangeParam) => {};
  accept?: string;
  fileList?: Array<UploadFile>;
  label: string;
  errors?: any;
};

const Upload = (props: UploadCompType) => {
  const { name, action, headers, onChange, accept, fileList, label, errors } = props;

  const uploadProps = {
    name,
    action,
    headers,
    onChange,
    accept,
    fileList,
  };

  const formItemProps: FormItemProps = {
    hasFeedback: !!errors && !!errors[name],
    validateStatus: !!errors && errors[name] && 'error',
    help: !!errors && errors[name],
    label: label,
    colon: false,
    labelCol: { span: 12, className: Styles.Label },
    wrapperCol: { span: 12, className: Styles.WrapperCol },
  };

  return (
    <Form.Item {...formItemProps}>
      <AntDUpload {...uploadProps}>
        <Button className={Styles.Button}>
          <Icon type="upload" /> Upload
        </Button>
      </AntDUpload>
    </Form.Item>
  );
};

export default Upload;
