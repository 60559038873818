import React, { MouseEventHandler } from 'react';
import { DatePicker, Form, Icon, Input as AntInput } from 'antd';
import moment from 'moment';
import SVGIcon from '../SVGIcon/SVGIcon';

import * as Styles from './Style';

export type DatePickerType = {
  name: string;
  placeholder?: string;
  dateFormat?: string;
  defaultValue?: any;
  allowClear?: boolean;
  className?: string;
  open?: boolean;
  labelInline?: boolean;
  label?: string;
  errors?: any;
  handleSubmit?: Function;
  setFieldValue: Function;
  setFieldTouched?: Function;
  disabled?: boolean;
  disabledDate?: any;
};

const DatePickerComp = (props: DatePickerType) => {
  const {
    name,
    label,
    allowClear,
    className,
    defaultValue,
    disabled,
    placeholder,
    dateFormat,
    setFieldValue,
    errors,
    disabledDate,
  } = props;
  return (
    <Form.Item
      hasFeedback={!!errors && !!errors[name]}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && errors[name]}
      label={label}
      className="inputLabel"
      colon={false}
    >
      <DatePicker
        name={name}
        allowClear={allowClear}
        className={className ? className + ' ' : '' + Styles.DatePicker}
        disabled={disabled}
        onChange={(value, valueString) => setFieldValue(name, valueString)}
        placeholder={placeholder}
        defaultValue={!!defaultValue ? moment(defaultValue, dateFormat) : undefined}
        format={dateFormat}
        suffixIcon={<SVGIcon iconName="calendarIcon.svg" width={22} className={Styles.Icon} />}
        disabledDate={disabledDate ? disabledDate : null}
      />
    </Form.Item>
  );
};

export default DatePickerComp;
