import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  type: 'type',
  subtype: 'subtype',
  individual: 'individual',
  joint: 'joint',
  custodial: 'custodial',
  retirement: 'retirement',
  entity: 'entity',
} as any;

export const FormFieldsTypes = {
  type: reviewFieldTypes.RadioGroup,
  subtype: reviewFieldTypes.SelectOptions,
} as any;

export const FormStrings = {
  type: 'Account Type',
  subtype: 'Account Subtype',
  selectSubtype: 'Select Subtype',
  [FormFields.individual]: 'Individual',
  [FormFields.joint]: 'Joint',
  [FormFields.custodial]: 'Custodial',
  [FormFields.retirement]: 'Retirement',
  [FormFields.entity]: 'Entity',
} as any;
