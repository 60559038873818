import React, { useEffect } from 'react';
import * as Typography from '../../../../styles/typography';
import ExpandableReview from '../../../shared/ExpandableReview/ExpandableReview';
import ReviewDetail from './ReviewDetail';
import { FormFields, FormStrings } from './constants';
import * as Styles from './Style';
import { useDispatch } from 'react-redux';
import * as FormActions from '../../../../actions';

export type TJointAccounts = {
  state: any;
};

const JointAccountReview = (props: TJointAccounts) => {
  const { state } = props;
  const dispatch = useDispatch();

  if (state && state.length === 0) {
    return <></>;
  } else {
    const updateJointAccountsIntermediary = () => {
      dispatch(
        FormActions.addJointAccountsIntermediaryFormState({
          body: state ? [...state] : [],
        }),
      );
    };

    const persons =
      state &&
      state.map((item: any) => {
        return (
          <ReviewDetail
            label={`${
              item.accountOwnerInformation && item.accountOwnerInformation.firstname
                ? item.accountOwnerInformation.firstname
                : ''
            } ${
              item.accountOwnerInformation && item.accountOwnerInformation.lastname
                ? item.accountOwnerInformation.lastname
                : ''
            }`}
            sub={false}
            value={''}
          />
        );
      });

    return (
      <>
        <p className={Typography.CategoryReviewText}>{FormStrings.jointAccountsText}</p>
        <hr className={Typography.hr} />
        <ExpandableReview
          id={FormFields.jointAccountsEdit}
          title={FormStrings.jointAccountsText}
          onEdit={updateJointAccountsIntermediary}
        >
          {persons}
        </ExpandableReview>
      </>
    );
  }
};

export default JointAccountReview;
