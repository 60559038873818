import { css } from 'emotion';
import { Color, Typography } from '../../styles';

export const Body = css`
  background: ${Color.OffWhite} !important;
  min-height: 100vh;
`;
export const container = css`
  background: ${Color.OffWhite} !important;
`;

export const containerClass = css`
  background: ${Color.OffWhite} !important;
`;

export const headerTextContainer = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.396826px;
  color: #423c3a;
  margin-bottom: 29px;
`;

export const Border = css`
  border: 1px solid #e9e9e9 !Important;
`;

export const menuItem = css`
  position: absolute;
  width: 136px;
  height: 18px;
  left: 12px;
  top: 11px;

  font-family: Gill Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #0098ce;
`;

export const selectedTab = css`
  position: absolute;
  width: 193px;
  height: 27px;
  left: 167px;
  top: 152px;
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: #077cb6;
`;

export const unselectedTab = css`
  position: absolute;
  width: 137px;
  height: 27px;
  left: 412px;
  top: 152px;
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: #077cb6;
`;

export const description = css`
  font-family: GillSans;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.185185px;
  color: #423c3a;
`;

export const editFormDescription = css`
  font-family: GillSans;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.185185px;
  color: #423c3a;
  margin-bottom: 40px;
`;

export const alignLeft = css`
  float: left;
  line-height: 0px;
  font-family: Gill Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.35px;
  color: #222222;
`;

export const alignRight = css`
  float: right;
  line-height: 0px;
  font-family: Gill Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.35px;
  color: #222222;
`;

export const alignLeftModal = css`
  float: left;
  font-family: GillSans;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: #423c3a;
`;

export const alignRightModal = css`
  float: right;
  font-family: GillSans;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15873px;
  color: #423c3a;
`;

export const mainView = css`
  margin: 0px 10% 5px 10%;
`;

export const headerText = css`
  font-family: GillSans;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.35px;
  color: #545454;
`;

export const headerTextModal = css`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: #222222;
`;

export const spinnerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: auto !important;
  margin-bottom: 25px !important;
  margin-top: 25px !important;
`;

export const spinner = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
