import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const Footer = css`
  background-color: ${Color.OffWhite} !important;
  text-align: center !important;
  border: 1px solid ${Color.FogBlue} !important;
`;

export const Head = css`
  font-family: ${Typography.GillSans};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.35px;
  color: ${Color.DarkGrey};
`;

export const RowSolutions = css`
  padding: 8px !important;
`;

export const CambriaSolution = css`
  padding: 20px;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
`;

export const LearnMore = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.254546px !important;
  color: ${Color.FineBlue} !important;
`;
