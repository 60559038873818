import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const Card = css`
  padding: 48px 97px !important;
  width: 100% !important;
`;

const textStyle = css`
  font-family: ${Typography.GillSans} !Important;
  font-size: 14px !Important;
  line-height: 16px !Important;
  letter-spacing: 0.185185px !Important;
`;

export const Description = cx(
  textStyle,
  css`
    color: #423c3a;
  `,
);

export const SubTitle = css`
  font-family: ${Typography.CormorantGaramond};
  font-style: normal !Important;
  font-weight: 600 !Important;
  font-size: 14px !Important;
  line-height: 17px !Important;
  letter-spacing: 0.185185px !Important;
  color: #423c3a !Important;
  border-bottom: 1px solid #dfe5f0 !Important;
`;

export const Footer = cx(
  textStyle,
  css`
    margin-top: 40px !Important;
    color: ${Color.DarkGrey} !Important;
  `,
);

export const Key = css`
  font-family: ${Typography.CormorantGaramond} !Important;
  font-style: normal !Important;
  font-weight: 600 !Important;
  font-size: 18px !Important;
  line-height: 22px !Important;
  letter-spacing: 0.286364px !Important;
  color: ${Color.DeepBlack} !Important;
  margin-top: 16px !Important;
`;

export const Value = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.15873px !important;
  color: ${Color.DarkerMediumGrey} !important;
  margin-top: 4px !Important;
  margin-left: 8px !Important;
`;

export const Title = cx(
  Typography.Header,
  css`
    display: flex;
    justify-content: space-between;
    span {
      padding-top: 8px;
    }
    img {
      padding-bottom: 8px;
    }
  `,
);
