import React from 'react';
import { Card, Col, Row } from 'antd';
import SVGIcon from '../SVGIcon/SVGIcon';
import StatefulButton from '../StatefulButton/StatefulButton';
import * as Styles from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { addSolution, removeSolution } from '../../../actions/solutions';
import solutions from '../../../constants/soutions';

interface IProps {
  icon: string;
  solutionName: string;
  appName?: string;
  field: string;
  solutionDescription: string;
  buttonState?: boolean;
  setVisibleProductModal: Function;
}

const SolutionCardComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const { icon, solutionName, appName, solutionDescription, buttonState, field, setVisibleProductModal } = props;
  const solutions = useSelector((state: any) => state.solutions);
  const onSolutionButtonClick = () => {
    let action = null;
    if (field in solutions) {
      action = removeSolution({
        solutionCode: field,
      });
    } else {
      action = addSolution({
        solutionCode: field,
        body: {
          icon,
          name: solutionName,
          app: appName,
          description: solutionDescription,
          field,
        },
      });
      // show Trading Block product modal
      if (field === 'tradingBlock') {
        setVisibleProductModal();
      }
    }
    dispatch(action);
  };
  return (
    <Card className={Styles.cardStyle} key={field} bordered={false}>
      <Row>
        <Col span={24} style={{ alignItems: 'center', minHeight: '94px' }}>
          <SVGIcon iconName={icon} height={field === 'folio' ? 38 : 60} className={Styles.imgStyle} />
        </Col>
        <Col span={24}>
          <div className={Styles.SolutionName}>{solutionName}</div>
        </Col>
        <Col span={24} style={{ minHeight: '86px' }}>
          <div className={Styles.SolutionDescription}>{solutionDescription}</div>
        </Col>
        <Col span={24}>
          <hr className={Styles.Border} />
        </Col>
        <Col span={9} className={Styles.ButtonStyle}>
          <StatefulButton
            name={field ? field : solutionName}
            isActive={buttonState || false}
            onClick={() => {
              onSolutionButtonClick();
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SolutionCardComponent;
