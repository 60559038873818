export const filterBeneficiariesForReview = (state: any) => {
  const filteredState = state.filter(
    (item: any) =>
      item.sharePercentage &&
      item.sharePercentage !== null &&
      ((item.type === 'individual' && item.ssn !== null) || (item.type === 'trust' && item.trustTaxID !== null)),
  );
  let primary: any = [];
  let contingent: any = [];
  let primaryPercentage = 0;
  let contingentPercentage = 0;
  filteredState.map((item: any) => {
    let newItem: any = {};
    if (item.type === 'trust') {
      newItem.label = item.nameOfTrust;
    } else {
      newItem.label = item.firstName + ' ' + item.lastName;
    }
    newItem.value = item.sharePercentage;
    if (item.isContingent) {
      contingent.push(newItem);
      contingentPercentage += parseFloat(item.sharePercentage);
    } else {
      primary.push(newItem);
      primaryPercentage += parseFloat(item.sharePercentage);
    }
  });
  return {
    primary,
    contingent,
    primaryTotal: primaryPercentage.toFixed(1),
    contingentTotal: contingentPercentage.toFixed(1),
  };
};
