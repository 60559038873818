import * as Yup from 'yup';
import { FormFields } from './constants';

export const validationMessages = {
  jointAccountsMin: 'You need at least one joint account!',
};
export const defaultValidation = Yup.object().shape({
  [FormFields.jointAccounts]: Yup.array()
    .min(1, validationMessages.jointAccountsMin)
    .required(validationMessages.jointAccountsMin),
});
export default defaultValidation;
