import { css, cx } from 'emotion';
import { Typography, Color } from './../../styles';

export const spinnerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: auto !important;
  margin-bottom: 25px !important;
  margin-top: 25px !important;
`;

export const spinner = css`
  color: ${Color.TropicalBlue} !important;
`;

export const text = css`
  ${Typography.FormText};
`;

export const textEmphasised = css`
  ${Typography.FormText};
  color: ${Color.TropicalBlue} !important;
  font-size: 14px !important;
`;
