import React, { useEffect, useState } from 'react';
import * as Styles from './Style';
import SVGIcon from '../SVGIcon/SVGIcon';
import { useSelector } from 'react-redux';
import ColorThief from 'colorthief';
import { resolve } from 'url';
import { createLogger } from 'redux-logger';
import getAverageRGB from '../../../utils/getAverageRGB';
import { Col, Row } from 'antd';
import { Color } from '../../../styles';

type TSolutionCard = {
  solution: object;
  onClick?: Function;
};

const ConnectedSolutionCard: React.FC<TSolutionCard> = (props, { ...shared }) => {
  // const [visible, setVisible] = useState(initial);
  // const solutions = useSelector((state: any) => state.solutions);
  const { icon, height, formatedValue, time, color, isBase64Icon }: any = props.solution;
  // const get_average_rgb = (img: any) => {
  //   var context: any = document.createElement('canvas').getContext('2d');
  //   if (typeof img == 'string') {
  //     var src = img;
  //     img = new Image(200, 100);
  //     img.setAttribute('crossOrigin', '');
  //     img.src = src;
  //   }
  //   context.imageSmoothingEnabled = true;
  //   context.drawImage(img, 0, 0, 1, 1);
  //   return context.getImageData(1, 1, 1, 1).data.slice(0, 3);
  // };
  // let imageUrl = 'http://localhost:3000/myipo.svg';
  // // than create an image elm, the sizes are needed. 360x360 on this case
  // let image = new Image(100, 50);
  // image.setAttribute('crossOrigin', '');
  // image.src = imageUrl;
  // let color = getAverageRGB(imageUrl);
  // console.log(color);
  // const handleClick = () => {
  //   if (props.onClick) {
  //     props.onClick();
  //   }
  //   console.log('clicked solution');
  // };
  return (
    <>
      <div className={Styles.SolutionCard} style={{ borderLeftColor: color }}>
        <Row>
          <Col span={10} className={Styles.SolutionLogoContainer}>
            <SVGIcon iconName={icon} height={height} className={Styles.SolutionLogo} isBase64Icon={isBase64Icon} />
          </Col>
          <Col span={14} className={Styles.BalanceContainer}>
            <div className={Styles.BalanceAmount}>{formatedValue}</div>
            <div className={Styles.BalanceAmountUpdateTime}>{time}</div>
          </Col>
        </Row>
      </div>
    </>
  );
};
//<ExternalSolutionCard solution={{ icon: 'myipo.svg', height: 22, width: 45 }} />
export default ConnectedSolutionCard;
