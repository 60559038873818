import React from 'react';
import * as Typography from '../../../../styles/typography';
import ExpandableReview from '../../../shared/ExpandableReview/ExpandableReview';
import ReviewDetail from './ReviewDetail';
import { produceReviewDetailRow } from '../../../../utils/reviewFieldTransformers';
import reviewFieldTypes from '../../../../constants/reviewFieldTypes';
import categories from '../../../../constants/categories';

export type TReviewGroup = {
  groupName?: string;
  injectedForm?: any;
  forms?: any;
};

const ReviewGroup = (props: TReviewGroup) => {
  const { groupName, forms, injectedForm } = props;
  const subOptions = (
    subOptionsObj: any,
    formStrings: any,
    formFieldsTypes: any,
    formFieldOptions: any,
    fieldName: string,
    value: any,
    form?: any,
  ) => {
    return Object.keys(subOptionsObj).map((fieldName, index) => {
      const reviewDetailRow = produceReviewDetailRow(
        formStrings,
        formFieldsTypes,
        formFieldOptions,
        fieldName,
        value[fieldName],
        form,
      );
      if (typeof reviewDetailRow.label !== 'undefined') {
        return <ReviewDetail label={reviewDetailRow.label} sub={reviewDetailRow.sub} value={reviewDetailRow.value} />;
      }
    });
  };
  const reviewOptions = (injectedForm: any, form: any) => {
    return Object.keys(form).map((fieldName, index) => {
      if (
        fieldName !== 'undefined' &&
        fieldName !== null &&
        form[fieldName] !== null &&
        fieldName !== '' &&
        !fieldName.includes('-')
      ) {
        if (typeof form[fieldName] === 'object' && form[fieldName].constructor !== Array) {
          let subOptionsObj = form[fieldName];
          return subOptions(
            subOptionsObj,
            injectedForm.formStrings,
            injectedForm.formFieldsTypes,
            injectedForm.formFieldOptions,
            fieldName,
            form[fieldName],
            form,
          );
        }
        const reviewDetailRow = produceReviewDetailRow(
          injectedForm.formStrings,
          injectedForm.formFieldsTypes,
          injectedForm.formFieldOptions,
          fieldName,
          form[fieldName],
          form,
        );
        return (
          <ReviewDetail
            label={reviewDetailRow.label}
            sub={reviewDetailRow.value !== 'No' && reviewDetailRow.sub}
            value={reviewDetailRow.value}
          />
        );
      }
    });
  };

  return (
    <>
      <p className={Typography.CategoryReviewText}>{groupName}</p>
      <hr className={Typography.hr} />
      {injectedForm.map((form: any) => {
        if (groupName == categories.entityInformation) {
          if (forms.entity[form.name] && Object.keys(forms.entity[form.name]).length > 0) {
            return (
              <ExpandableReview id={form.name} title={form.reviewHeader ? form.reviewHeader : form.header}>
                {reviewOptions(form, forms.entity[form.name])}
              </ExpandableReview>
            );
          }
        } else if (forms[form.name] && Object.keys(forms[form.name]).length > 0) {
          return (
            <ExpandableReview id={form.name} title={form.reviewHeader ? form.reviewHeader : form.header}>
              {reviewOptions(form, forms[form.name])}
            </ExpandableReview>
          );
        }
      })}
    </>
  );
};

export default ReviewGroup;
