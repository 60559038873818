import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { AuthFields, AuthStrings } from './constants';
import { InitialValues, SignUpParams, CountryDialCodesOptions } from './schema';
import * as Styles from '../Style';
import { defaultValidation } from './validations';
import Input, { MaskType } from '../../shared/Inputs/Input';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import SelectInput from '../../shared/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import * as AuthActions from '../../../actions';
import { TAuth } from '../authType';
import FormContainer from '../../shared/FormContainer/FormContainer';
import { DirectToLogin } from '../AuthAction';

const Component = (props: TAuth) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.register);

  useEffect(() => {
    dispatch(
      AuthActions.clearRegister({
        id: 'clearError',
        body: null,
      }),
    );
  }, []);

  const onSubmit = (values: any) => {
    const composePhoneNumber = (dialCode: any, phoneNumber: any) => {
      return `${dialCode || '+1'}${phoneNumber.replace(/[-()]/g, '').replace(/^0+/, '')}`;
    };

    const signupInfo: SignUpParams = {
      username: values.email,
      password: values.password,
      attributes: {
        phone_number: composePhoneNumber(values.dialCode, values.phoneNumber),
      },
    };
    dispatch(
      AuthActions.register({
        id: 'register',
        body: signupInfo,
      }),
    );
  };

  return (
    <FormContainer title={'Register'}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={values => onSubmit(values)}
        validationSchema={defaultValidation}
      >
        {form => {
          return (
            <>
              <span className={Styles.errorText}>{state.error}</span>
              <Input
                {...form}
                name={AuthFields.email}
                label={AuthStrings[AuthFields.email]}
                value={InitialValues[AuthFields.email]}
                type={'email'}
              />
              <Row>
                <Col span={8}>
                  <SelectInput
                    {...form}
                    name={AuthFields.dialCode}
                    label={AuthStrings[AuthFields.dialCode]}
                    options={CountryDialCodesOptions}
                    defaultOption={form.initialValues[AuthFields.dialCode]}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={15}>
                  <Input
                    {...form}
                    name={AuthFields.phoneNumber}
                    label={AuthStrings[AuthFields.phoneNumber]}
                    value={form.initialValues[AuthFields.phoneNumber]}
                    mask={MaskType.Phone}
                  />
                </Col>
              </Row>
              <Input
                {...form}
                name={AuthFields.password}
                label={AuthStrings[AuthFields.password]}
                value={form.initialValues[AuthFields.password]}
                type={'password'}
              />
              <Input
                {...form}
                name={AuthFields.repeatPassword}
                label={AuthStrings[AuthFields.repeatPassword]}
                value={form.initialValues[AuthFields.repeatPassword]}
                type={'password'}
              />
              <Button
                disabled={!form.isValid}
                onClick={form.submitForm}
                label={'Sign Up'}
                type={ButtonType.Thick}
                loading={!!state.__requested ? true : false}
              />
              <Row>
                <Col span={24} className={Styles.SignInTextCSS}>
                  <span>Already have an account? </span>
                  <DirectToLogin />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default Component;
