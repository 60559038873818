import FormComponent from './FormComponent';
import Validations from './validations';
import { FieldsOptions, InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import categories from '../../../../constants/categories';

const name = 'accountOwnerInformation';
const path = 'accountOwnerInformation';
const header = 'Account Owner Information';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  category: categories.accountInformation,
  backToReview: true,
  whenAddJointAccount: true,
};

export default config;
