import React, { useEffect, useState } from 'react';
import _, { get } from 'lodash';

import { Spin, Icon } from 'antd';

import { usePlaidLink } from 'react-plaid-link';
import { connect, useDispatch } from 'react-redux';
import Modal from '../shared/Modal';

import { getPlaidBalance } from '../../actions';
import { PLAID_LINK_TOKEN_URL } from '../../constants/url';

import { CloseButtonStyle, HeaderStyle, ModalStyle, ParentStyle } from './style';
import Button, { ButtonType } from '../shared/Buttons/Button';

type TProps = {
  visible: boolean;
  setVisible: Function;
  externalAccountBalance: any;
};

const PlaidHookedComp = (props: { plaidLinkToken: string; onSuccess: Function }) => {
  const plaidLinkConfig = {
    token: props.plaidLinkToken,
    onSuccess: props.onSuccess,
  };

  const { open, ready } = usePlaidLink(plaidLinkConfig);

  return (
    <div className={ParentStyle}>
      <div className={HeaderStyle}>Connect other external account</div>
      <div style={{ padding: '10px 0px' }}>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque et justo sapien. Nunc eu egestas nibh.
          Suspendisse nec gravida magna. Donec sed maximus mi, dignissim laoreet nunc.
        </span>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Button loading={!ready} onClick={() => open()} label={'CONTINUE'} type={ButtonType.Thick} />
      </div>
    </div>
  );
};

const PlaidLinkComp = (props: TProps) => {
  const [loading, setLoading] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const [hasSubmissionError, setHasSubmissionError] = useState(false);

  const resetState = () => {
    setLoading(false);
    setPlaidLinkToken('');
    setHasSubmissionError(false);
  };

  useEffect(() => {
    if (loading) {
      if (!_.isEmpty(props.externalAccountBalance.plaidResponse)) {
        const response = props.externalAccountBalance.plaidResponse;
        if (response.responseStatus === 200) {
          props.setVisible(false);
          resetState();
        } else {
          setHasSubmissionError(true);
          setLoading(false);
        }
      }
    }
  }, [props.externalAccountBalance]);

  useEffect(() => {
    if (_.isEmpty(plaidLinkToken) && !loading) {
      setLoading(true);
      // TODO: Remove
      console.log('PLAID_LINK_TOKEN_URL', PLAID_LINK_TOKEN_URL);
      fetch(PLAID_LINK_TOKEN_URL)
        .then(results => {
          return results.json();
        })
        .then(data => {
          setPlaidLinkToken(data.link_token);
          setLoading(false);
        })
        .catch(() => setHasSubmissionError(true));
      setLoading(false);
    }
  });

  const onPlaidSuccess = (publicToken: string) => {
    setLoading(true);
    dispatch(getPlaidBalance({ public_token: publicToken }));
  };

  const dispatch = useDispatch();

  return (
    <Modal
      afterClose={resetState}
      centered
      className={ModalStyle}
      closable={!loading}
      closeIcon={<span className={CloseButtonStyle}>Close</span>}
      footer={null}
      onCancel={() => props.setVisible(false)}
      mask
      maskClosable={!loading}
      visible={props.visible}
      destroyOnClose
    >
      {!_.isEmpty(plaidLinkToken) && !loading && !hasSubmissionError && (
        <PlaidHookedComp plaidLinkToken={plaidLinkToken} onSuccess={onPlaidSuccess} />
      )}
      {loading && !hasSubmissionError && (
        <div className={ParentStyle} style={{ textAlign: 'center' }}>
          <div>Retrieving some information</div>
          <Spin style={{ marginTop: 15 }} />
        </div>
      )}
      {!loading && hasSubmissionError && (
        <div className={ParentStyle} style={{ textAlign: 'center' }}>
          <Icon type="exclamation-circle" style={{ color: '#FF244E' }} />
          <div style={{ color: '#FF244E', marginTop: 5, paddingBottom: 10 }}>
            Something went wrong and the process could not be completed successfully.
            <br />
            Please close this modal and retry linking your account!
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  externalAccountBalance: get(state, 'externalAccountBalance'),
});

const mapDispatchToProps = {
  getPlaidBalance,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaidLinkComp);
