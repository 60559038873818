import { css } from 'emotion';

export const textBottomPadding = css`
  margin-bottom: 44px !Important;
`;

export const ContinueWithNoJointAccounts = css`
  text-align: center !Important;
  margin-top: 36px !Important;
`;
