import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { AuthFields, AuthStrings } from './constants';
import { InitialValues } from './schema';
import * as Styles from '../Style';
import { defaultValidation } from './validations';
import Input from '../../shared/Inputs/Input';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import * as AuthActions from '../../../actions';
import FormContainer from '../../shared/FormContainer/FormContainer';
import { DirectToLogin } from '../AuthAction';

const Component = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.confirmEmail);
  const stateAuthInfo = useSelector((state: any) => state.authInfo);
  const { username } = stateAuthInfo.token || '';
  const { error } = state || '';

  const [resent, setResent] = useState(false);
  const [err, setErr] = useState('');

  useEffect(() => {
    if (state.__succeeded) {
      dispatch(
        AuthActions.login({
          id: 'loginAfterConfirmedEmail',
          body: { email: stateAuthInfo.token.username, ...stateAuthInfo.token },
        }),
      );
    }
  }, [state]);

  const onSubmit = async (values: any) => {
    dispatch(
      AuthActions.confirmEmail({
        id: 'confirmEmail',
        body: values,
      }),
    );
  };

  const resend = (username: string) => {
    Auth.resendSignUp(username)
      .then(() => setResent(true))
      .catch(err => setErr(err.message));
  };

  return (
    <FormContainer title={'Confirm email'}>
      <Formik
        enableReinitialize
        initialValues={{ ...InitialValues, username }}
        validationSchema={defaultValidation}
        onSubmit={values => onSubmit(values)}
      >
        {form => {
          return (
            <>
              {resent && (
                <Row>
                  <Col>
                    <span className={Styles.resent}>A new code has been sent to your email address!</span>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <span className={Styles.errorText}>{error}</span>
                </Col>
                <Col>
                  <span className={Styles.errorText}>{err}</span>
                </Col>
              </Row>
              <Input
                {...form}
                name={AuthFields.username}
                label={AuthStrings[AuthFields.username]}
                value={form.initialValues['username']}
                type={'email'}
                disabled
              />
              <Input
                {...form}
                name={AuthFields.code}
                label={AuthStrings[AuthFields.code]}
                value={InitialValues[AuthFields.code]}
              />
              <Row>
                <Col span={24} style={{ marginBottom: 24, textAlign: 'center' }}>
                  <span>Lost your code? </span>
                  <Button onClick={() => resend(form.values.username)} label={'Resend code'} type={ButtonType.Link} />
                </Col>
              </Row>
              <Button
                disabled={!form.isValid}
                onClick={form.submitForm}
                label={'Confirm'}
                type={ButtonType.Thick}
                loading={!!state.__requested ? true : false}
              />
              <Row>
                <Col span={24} style={{ marginTop: 24, textAlign: 'center' }}>
                  <span>Back to </span>
                  <DirectToLogin />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default Component;
