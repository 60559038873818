import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';

import { TForm } from '../../../formTypes';
import { ContinueIntermediaryEntityAuthorizedAction } from '../../../FormActions';
import Input from '../../../../shared/Inputs/Input';
import * as Constants from '../../../../../constants/dates';
import DatePickerInput from '../../../../shared/DatePicker/DatePicker';
import { useHistory } from 'react-router';
import { getFormState } from '../../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.intermediary.entityAuthorizedPersonsIntermediary);
  const history: any = useHistory();
  const [key, setKey] = useState(
    Number(
      history.location.state && typeof history.location.state.id !== 'undefined'
        ? history.location.state.id
        : !!state && state.length > 0
        ? state.reduce((prev: any, current: any) => (prev.id > current.id ? prev : current)).id + 1
        : 0,
    ),
  );

  const formState = getFormState(state ? state[key] : {}, InitialValues);
  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue({ ...values, id: key });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.firstName}
              label={FormStrings[FormFields.firstName]}
              value={form.values[FormFields.firstName]}
            />
            <Input
              {...form}
              name={FormFields.middleInitial}
              label={FormStrings[FormFields.middleInitial]}
              value={form.values[FormFields.middleInitial]}
              short
            />
            <Input
              {...form}
              name={FormFields.lastName}
              label={FormStrings[FormFields.lastName]}
              value={form.values[FormFields.lastName]}
            />
            <DatePickerInput
              {...form}
              name={FormFields.birthday}
              label={FormStrings[FormFields.birthday]}
              dateFormat={Constants.DATE_YEAR_FORMAT}
              defaultValue={form.values[FormFields.birthday]}
            />
            <ContinueIntermediaryEntityAuthorizedAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
