import React from 'react';
import { useHistory } from 'react-router';
import { useField } from 'formik';
import { FormFields, FormStrings } from './constants';

import Button, { ButtonType } from '../../../../shared/Buttons/Button';
import { Col, Row, Upload } from 'antd';
import * as Styles from './Style';
import './style.css';

export const UploadDoc = ({ form, item }: any) => {
  const history = useHistory();

  const [completedField, completedMeta, completedHelpers] = useField({
    name: `${FormFields.documents}[${item}]`,
    type: 'boolean',
  });
  const [contentField, contentMeta, contentHelpers] = useField(`${FormFields.documents}[${item}]`);
  const onContentInput = (e: any) => {
    contentHelpers.setValue(e);
    completedHelpers.setValue(e);
    completedHelpers.setTouched(true);
  };

  const removeDoc = () => {
    completedHelpers.setValue(null);
    onContentInput(null);
  };

  return (
    <>
      {!completedField.value ? (
        <Row className={Styles.MarginBottom8}>
          <Col span={16} className={Styles.AuthPerson}>
            {FormStrings[item]}
          </Col>
          <Col span={8}>
            <Upload
              name={`${FormFields.documents}[${item}]`}
              showUploadList={false}
              beforeUpload={(file: any) => {
                const localImageUrl = window.URL.createObjectURL(file);
                onContentInput(localImageUrl);
                return false;
                // const reader = new FileReader();
                // reader.readAsDataURL(file);
                // reader.onload = (e: any) => {
                //   onContentInput(e.target.result);
                // };
                // // Prevent upload
                // return false;
              }}
              listType={'picture'}
              style={{ cursor: 'pointer' }}
            >
              <Button onClick={() => {}} type={ButtonType.Thin} label={'UPLOAD'}></Button>
            </Upload>
          </Col>
          {!!contentMeta.error && (
            <Col span={24}>
              <div className={`${Styles.Red} ant-form-explain`}>{contentMeta.error}</div>
            </Col>
          )}
        </Row>
      ) : (
        <Row className={Styles.MarginBottom8}>
          <Col span={16} className={Styles.UploadedId}>
            {FormStrings[item]}
          </Col>
          <Col span={8}>
            <Button onClick={() => removeDoc()} type={ButtonType.Thin} label={'REMOVE'}></Button>
          </Col>
        </Row>
      )}
    </>
  );
};
