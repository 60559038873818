import { css, cx } from 'emotion';
import * as Typography from '../../../../../styles/typography';
import * as Color from '../../../../../styles/color';

export const MarginBottom8 = css`
  margin-bottom: 8px !Important;
`;

export const AuthPerson = css`
  font-family: GillSans;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15873px;
  color: #423c3a;
  margin-top: 5px;
`;

export const TrashButton = css`
  display: inline-block !important;
  float: right !important;
  min-width: 24px !important;
  padding-top: 3px !important;
  background-color: rgba(255, 36, 78, 0.1) !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  &:hover {
    background-color: rgba(255, 36, 78, 1) !important;
    border-color: rgba(255, 36, 78, 1) !important;
    .svg-icon path {
      fill: white;
    }
  }
`;

export const EditButton = css`
  display: inline-block !important;
  float: right !important;
  min-width: 24px !important;
  padding-top: 3px !important;
  background-color: rgba(0, 152, 206, 0.1) !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  margin-right: 8px;
  &:hover {
    background-color: rgba(0, 152, 206, 1) !important;
    border-color: rgba(0, 152, 206, 1) !important;
    .svg-icon path {
      fill: white;
    }
  }
`;

export const AddAuthorizedPersons = css`
  height: 17px !important;
  margin-top: 36px !important;
  margin-bottom: 36px !important;
  line-height: 14px !important;
`;
