import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const InitialValues = {
  [FormFields.OfficerBoardMemberOr10HolderPublicCompany]: false,
  [FormFields.CompanyTicker]: [''],

  [FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange]: false,
  [FormFields.FirmOrExchangeName]: '',

  [FormFields.FamilyMember]: false,
  [FormFields.FamilyMemberDetails]: [{}],

  [FormFields.SeniorForeignGovernmentOfficial]: false,
  [FormFields.SeniorForeignGovernmentOfficialDetails]: [{}],

  [FormFields.IAmNotAssociatedWithAForeignShellBank]: false,
};

export const FieldsOptions = {};
