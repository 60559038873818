import { css } from 'emotion';

export const TrashButton = css`
  display: inline-block !important;
  float: right !important;
  min-width: 24px !important;
  padding-top: 3px !important;
  background-color: rgba(255, 36, 78, 0.1) !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  &:hover {
    background-color: rgba(255, 36, 78, 1) !important;
    border-color: rgba(255, 36, 78, 1) !important;
    .svg-icon path {
      fill: white;
    }
  }
`;

export const Container = css`
  margin: 8px 0px !important;
  padding: 0 !important;
  display: inline-block !important;
  width: 100% !important;
`;
