import * as Yup from 'yup';

const validationMessages = {
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Last Name is required',
  middleInitialInvalid: 'Middle name initial is invalid',
  phoneNumberRequired: 'Phone Number is required',
  phoneNumberInvalid: 'Phone Number format is invalid e.x:(555-555-5555)',
};

export const accountOwnerValidation = Yup.object().shape({
  firstName: Yup.string().required(validationMessages.firstNameRequired),
  middleInitial: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]$/, validationMessages.middleInitialInvalid)
    .notRequired(),
  lastName: Yup.string().required(validationMessages.lastNameRequired),
  phoneNumber: Yup.string()
    .matches(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, validationMessages.phoneNumberInvalid)
    .required(validationMessages.phoneNumberRequired),
});
