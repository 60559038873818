import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';

import { FormText } from '../../../../styles/typography';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import Input from '../../../shared/Inputs/Input';
import { stateOptions } from '../MailingAddress/schema';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.trustedContactAddress);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.addressLine1}
              label={FormStrings[FormFields.addressLine1]}
              value={formState[FormFields.addressLine1]}
            />
            <Input
              {...form}
              name={FormFields.addressLine2}
              label={FormStrings[FormFields.addressLine2]}
              value={formState[FormFields.addressLine2]}
            />
            <Input
              {...form}
              name={FormFields.city}
              label={FormStrings[FormFields.city]}
              value={formState[FormFields.city]}
            />
            <SelectInput
              {...form}
              name={FormFields.state}
              label={FormStrings[FormFields.state]}
              options={stateOptions}
            />
            <Input
              {...form}
              name={FormFields.zipCode}
              label={FormStrings[FormFields.zipCode]}
              value={formState[FormFields.zipCode]}
              short={true}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
