import React from 'react';
import { Form, Select } from 'antd';

import * as Styles from './SelectStyle';
import './SelectStyle.css';
import { TSelectOption } from '../../../types/commonTypes';
import SVGIcon from '../SVGIcon/SVGIcon';

const { Option } = Select;

export type SelectPropsType = {
  name: any;
  label?: any;
  labelInline?: boolean;
  errors?: any;
  showSearch?: boolean;
  placeholder?: string;
  options: TSelectOption[];
  defaultOption?: any;
  touched?: any;
  setFieldValue: Function;
  setFieldTouched: Function;
  values: any;
  disabledOnValueProvided?: boolean;
  initialValues?: any;
  allowClear?: boolean;
  onChangeHook?: Function;
  bindValue?: any;
};

const SelectInput = (props: SelectPropsType) => {
  const {
    name,
    labelInline,
    label,
    errors,
    showSearch,
    placeholder,
    options,
    defaultOption,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    disabledOnValueProvided = false,
    initialValues,
    allowClear,
    onChangeHook,
    bindValue,
  } = props;
  if (defaultOption && !values[name]) {
    setFieldValue(name, defaultOption);
  }

  const onChange = (value: any) => {
    setFieldValue(name, value);
    if (onChangeHook) {
      onChangeHook(value);
    }
  };

  const conditionalProps: any = {};
  if (bindValue) conditionalProps.value = values[name];

  return (
    <Form.Item
      hasFeedback={!!errors && !!errors[name]}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && errors[name]}
      label={label}
      className={Styles.label}
      colon={false}
    >
      <Select
        className={Styles.select}
        allowClear={allowClear}
        defaultValue={defaultOption || initialValues[name]}
        disabled={disabledOnValueProvided ? (initialValues[name] ? true : false) : false}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={(value: string) => onChange(value)}
        onBlur={() => setFieldTouched(name)}
        notFoundContent="No options provided"
        onSearch={() => {}}
        suffixIcon={<SVGIcon iconName="dropDownIcon.svg" width={15} />}
        {...conditionalProps}
      >
        {options.map((option: TSelectOption) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectInput;
