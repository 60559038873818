import * as Yup from 'yup';
import { AuthFields, AuthStrings } from './constants';

export const validationMessages = {
  emailNameRequired: `${AuthStrings[AuthFields.email]} is required`,
  emailNameInvalid: `${AuthStrings[AuthFields.email]} is invalid`,
  passwordInitialRequired: `${AuthStrings[AuthFields.password]} is required`,
  passwordInitialInvalid: `${AuthStrings[AuthFields.password]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [AuthFields.email]: Yup.string()
    .required(validationMessages.emailNameRequired)
    .email(validationMessages.emailNameInvalid),
  [AuthFields.password]: Yup.string().required(validationMessages.passwordInitialRequired),
});

export default defaultValidation;
