import { createAction, Type, State } from './utils';
import { AnyAction } from 'redux';

export const updateContactInformation = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_CONTACT_INFO), payload);

export const getContactInformation = (): AnyAction => createAction(State.actionRequested(Type.GET_CONTACT_INFO), {});
export const getPersonalInformation = (): AnyAction => createAction(State.actionRequested(Type.GET_PERSONAL_INFO), {});
export const updatePersonalInformation = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_PERSONAL_INFORMATION), payload);
export const getAccountInformation = (): AnyAction => createAction(State.actionRequested(Type.GET_ACCOUNT_INFO), {});
export const updateAccountInformation = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_ACCOUNT_INFORMATION), payload);
