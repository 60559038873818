import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const defaultValidation = Yup.object().shape({
  [FormFields.uploadLater]: Yup.boolean(),
  [FormFields.identification]: Yup.string().when([FormFields.uploadLater], {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required(),
  }),
});

export default defaultValidation;
