import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FormText } from '../../../../styles/typography';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, CountryOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import { getFormState, jointAccountsIntermediaryFilter, findHistoryLocationId } from '../../../../utils/forms';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';

const Component = (props: TForm) => {
  // const history: any = useHistory();
  // const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key = findHistoryLocationId(history.location);
  // const key =
  //   history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
  //
  // const state = useSelector((state: any) =>
  //   key && addingJointAccount ? jointAccountsIntermediaryFilter(state, props.id) : state.forms.countryOfResidence,
  // );
  // const formState = getFormState(state, InitialValues);
  // const hasKey = key && addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(props.id, InitialValues, history);
  const solutions = Object.keys(useSelector((state: any) => state.solutions));

  const onSubmit = (values: any) => {
    if (props.onContinue && hasKey) {
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      props.onContinue(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation(solutions)}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <p className={FormText}>{FormStrings.formText}</p>
            <SelectInput
              {...form}
              name={FormFields.country}
              label={FormStrings[FormFields.country]}
              options={CountryOptions}
              defaultOption={formState[FormFields.country]}
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
