import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  nameRequired: `${FormStrings[FormFields.name]} is required`,
  nameInvalid: `${FormStrings[FormFields.name]} is invalid`,
  emailRequired: `${FormStrings[FormFields.email]} is required`,
  phoneNumberRequired: `${FormStrings[FormFields.phoneNumber]} is required`,
  phoneNumberInvalid: `${FormStrings[FormFields.phoneNumber]} format is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.name]: Yup.string().required(validationMessages.nameRequired),
  [FormFields.email]: Yup.string().required(validationMessages.emailRequired),
  [FormFields.phoneNumber]: Yup.string()
    .matches(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, validationMessages.phoneNumberInvalid)
    .required(validationMessages.phoneNumberRequired),
});

export default defaultValidation;
