import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

const validationMessages = {
  typeRequired: `${FormStrings[FormFields.type]} is required`,
};

const defaultValidation = Yup.object().shape({
  [FormFields.type]: Yup.string().required(validationMessages.typeRequired),
});

export default defaultValidation;
