import React, { useState } from 'react';

import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';

import { TForm } from '../../../formTypes';
import { ContinueRetirementAction, ContinueIntermediaryRetirementAction } from '../../../FormActions';

import Input, { MaskType } from '../../../../shared/Inputs/Input';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../../utils/forms';
import { useHistory } from 'react-router';

const Component = (props: TForm) => {
  const history: any = useHistory();
  const key = history.location.state.id;
  const state = useSelector((state: any) =>
    state.intermediary.retirementIntermediary.filter((item: any) => item.id === key),
  );
  const formState = getFormState(state[0], InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue({ ...values, id: key });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.sharePercentage}
              label={FormStrings[FormFields.sharePercentage]}
              value={formState[FormFields.sharePercentage]}
              short
              type={'number'}
              min={0}
              max={100}
              step={0.1}
            />
            <ContinueIntermediaryRetirementAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values, id: key }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
