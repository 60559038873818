export const FormFields = {
  identificationText: 'identificationText',
  uploadLater: 'uploadLater',
  identification: 'identification',
};

export const FormStrings = {
  [FormFields.identificationText]: 'Driver’s License and Passport ID are valid forms of identification.',
  [FormFields.uploadLater]: 'I will send my ID to Cambria at a later time.',
  [FormFields.identification]: 'Click here to upload Identification',
};
