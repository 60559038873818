import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';

import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import {
  EmployedInitialValues,
  UnemployedInitialValues,
  UnemployedJointInitialValues,
  AnnualIncomeOptions,
  EmploymentStatusOptions,
  StatesOptions,
  TaxRateOptions,
  EmploymentStatusValues,
} from './schema';
import { defaultValidation, addingJointValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import Input from '../../../shared/Inputs/Input';
import { useSelector } from 'react-redux';
import { getFormState, jointAccountsIntermediaryFilter } from '../../../../utils/forms';
import useEffectHookState from '../../../../hooks/useEffectHookState';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';
import { InitialValues } from '../CountryOfResidence/schema';

const Component = (props: TForm) => {
  // const history: any = useHistory();
  const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key =
  //   history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
  //
  // const state = useSelector((state: any) =>
  //   history.location.state && typeof history.location.state.id !== 'undefined' && addingJointAccount
  //     ? jointAccountsIntermediaryFilter(state.intermediary.jointAccountsIntermediary, props.id)
  //     : state.forms.employmentInfo,
  // );
  // let addingJointAccount = false;
  // const addingJointAccountState = useSelector((state: any) => state.navigation.addingJointAccount);
  // useEffectHookState(addingJointAccount, addingJointAccountState);
  // let formState = getFormState(state, addingJointAccount ? UnemployedJointInitialValues : UnemployedInitialValues);
  //
  // const hasKey = key || key === 0 || addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(
    props.id,
    addingJointAccount ? UnemployedJointInitialValues : UnemployedInitialValues,
    history,
  );
  const [initialValues, setInitialValues] = useState(formState);

  const onSubmit = (values: any) => {
    if (props.onContinue && hasKey) {
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      props.onContinue(values);
    }
  };

  const isEmployed = (value: string) =>
    value === EmploymentStatusValues.Employed || value === EmploymentStatusValues['Self-Employed'];

  const onEmploymentStatusChange = (status: string, formValues: any) => {
    const _isEmployed = isEmployed(status);
    let initialValuesResult = {};
    const extensionValues = addingJointAccount
      ? { [FormFields.employmentStatus]: status }
      : {
          [FormFields.employmentStatus]: status,
          [FormFields.taxRate]: formValues[FormFields.taxRate],
          [FormFields.annualIncome]: formValues[FormFields.annualIncome],
        };
    if (_isEmployed) {
      initialValuesResult = EmployedInitialValues;
    } else {
      initialValuesResult = UnemployedInitialValues;
    }
    setInitialValues({
      ...initialValuesResult,
      ...extensionValues,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addingJointAccount ? addingJointValidation : defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        const _isEmployed = isEmployed(form.values[FormFields.employmentStatus]);
        return (
          <>
            <SelectInput
              {...form}
              name={FormFields.employmentStatus}
              label={FormStrings[FormFields.employmentStatus]}
              options={EmploymentStatusOptions}
              onChangeHook={(value: string) => onEmploymentStatusChange(value, form.values)}
            />
            {_isEmployed && (
              <>
                <Input
                  {...form}
                  name={FormFields.employerName}
                  label={FormStrings[FormFields.employerName]}
                  value={form.values[FormFields.employerName]}
                />
                <Input
                  {...form}
                  name={FormFields.employerPosition}
                  label={FormStrings[FormFields.employerPosition]}
                  value={form.values[FormFields.employerPosition]}
                />
                <Input
                  {...form}
                  name={FormFields.yearsEmployed}
                  label={FormStrings[FormFields.yearsEmployed]}
                  value={form.values[FormFields.yearsEmployed]}
                  type={'number'}
                  min={0}
                  step={1}
                />
                <Input
                  {...form}
                  name={FormFields.employerAddressLine1}
                  label={FormStrings[FormFields.employerAddressLine1]}
                  value={form.values[FormFields.employerAddressLine1]}
                />
                <Input
                  {...form}
                  name={FormFields.employerAddressLine2}
                  label={FormStrings[FormFields.employerAddressLine2]}
                  value={form.values[FormFields.employerAddressLine2]}
                />
                <Input
                  {...form}
                  name={FormFields.employerCity}
                  label={FormStrings[FormFields.employerCity]}
                  value={form.values[FormFields.employerCity]}
                />
                <SelectInput
                  {...form}
                  name={FormFields.employerState}
                  label={FormStrings[FormFields.employerState]}
                  options={StatesOptions}
                  defaultOption={
                    form.values[FormFields.employerState]
                      ? form.values[FormFields.employerState]
                      : StatesOptions[0].value
                  }
                />
                <Input
                  {...form}
                  name={FormFields.employerZip}
                  label={FormStrings[FormFields.employerZip]}
                  value={form.values[FormFields.employerZip]}
                  short
                />
              </>
            )}
            {!(hasKey && addingJointAccount) && (
              <SelectInput
                {...form}
                name={FormFields.annualIncome}
                label={FormStrings[FormFields.annualIncome]}
                options={AnnualIncomeOptions}
                defaultOption={form.values[FormFields.annualIncome]}
              />
            )}
            {!(hasKey && addingJointAccount) && (
              <SelectInput
                {...form}
                name={FormFields.taxRate}
                label={FormStrings[FormFields.taxRate]}
                options={TaxRateOptions}
                defaultOption={form.values[FormFields.taxRate]}
              />
            )}
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
