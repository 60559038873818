import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const SecurityQuestionsOptions: TSelectOption[] = [
  {
    label: 'Select Question',
    value: '-1',
  },
  {
    label: 'What was the name of your childhood pet?',
    value: 1,
  },
  {
    label: 'What is your mother’s maiden name?',
    value: 2,
  },
  {
    label: 'In what city were you born?',
    value: 3,
  },
  {
    label: 'What is the name of your favorite sports team?',
    value: 4,
  },
  {
    label: 'What is your paternal Grandmother’s first name?',
    value: 5,
  },
  {
    label: 'As a child, what did you want to be when you grew up?',
    value: 6,
  },
  {
    label: 'What is your favorite book?',
    value: 7,
  },
  {
    label: 'What are the last 4 characters of your driver’s license?',
    value: 8,
  },
  {
    label: 'What is your father’s middle name?',
    value: 9,
  },
];

export const InitialValues = {
  [FormFields.questionOne]: SecurityQuestionsOptions[0].value,
  [FormFields.answerOne]: '',
  [FormFields.questionTwo]: SecurityQuestionsOptions[0].value,
  [FormFields.answerTwo]: '',
  [FormFields.questionThree]: SecurityQuestionsOptions[0].value,
  [FormFields.answerThree]: '',
};

export const FieldsOptions = {
  [FormFields.questionOne]: SecurityQuestionsOptions,
  [FormFields.questionTwo]: SecurityQuestionsOptions,
  [FormFields.questionThree]: SecurityQuestionsOptions,
};
