import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  stock: 'stock',
  option: 'option',
  commodity: 'commodity',
  bond: 'bond',
  margin: 'margin',
  mutualFundOrETF: 'mutualFundOrETF',
  securityFuture: 'securityFuture',
  annuity: 'annuity',
  alternative: 'alternative',
  reit: 'reit',
  yearsTrading: 'yearsTrading',
  tradesOrYear: 'tradesOrYears',
} as any;

export const FormFieldsTypes = {
  [FormFields.stock]: reviewFieldTypes.CheckSelect,
  [FormFields.option]: reviewFieldTypes.CheckSelect,
  [FormFields.commodity]: reviewFieldTypes.CheckSelect,
  [FormFields.bond]: reviewFieldTypes.CheckSelect,
  [FormFields.margin]: reviewFieldTypes.CheckSelect,
  [FormFields.mutualFundOrETF]: reviewFieldTypes.CheckSelect,
  [FormFields.securityFuture]: reviewFieldTypes.CheckSelect,
  [FormFields.annuity]: reviewFieldTypes.CheckSelect,
  [FormFields.alternative]: reviewFieldTypes.CheckSelect,
  [FormFields.reit]: reviewFieldTypes.CheckSelect,
  [FormFields.yearsTrading]: reviewFieldTypes.SelectOptions,
  [FormFields.tradesOrYear]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  formText:
    'Click on the checkboxes for asset types you are currently trading or have traded. This may include invesments and transactions performed by you or on your behalf.',
  [FormFields.stock]: 'Stocks',
  [FormFields.option]: 'Options',
  [FormFields.commodity]: 'Commodities',
  [FormFields.bond]: 'Bonds',
  [FormFields.margin]: 'Margins',
  [FormFields.mutualFundOrETF]: 'Mutual Funds/ETFs',
  [FormFields.securityFuture]: 'Securities Futures',
  [FormFields.annuity]: 'Annuities',
  [FormFields.alternative]: 'Alternative (structured products, hedge funds, etc.)',
  [FormFields.reit]: 'REITs',
  [FormFields.yearsTrading]: 'Years trading',
  [FormFields.tradesOrYear]: 'Trades / Years',
} as any;
