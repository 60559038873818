export const FormFields = {
  myIpo: 'myIpo',
  readyRound: 'readyRound',
  tradingBlock: 'tradingBlock',
  folio: 'folio',
};

export const FormStrings = {
  pageHeader: 'Wealth Management Solution',
};
