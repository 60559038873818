import { AuthFields } from './constants';
import { countryDialCodes } from 'aws-amplify-react/src/Auth/common/country-dial-codes';
import { TSelectOption } from '../../../types/commonTypes';

export const InitialValues = {
  [AuthFields.email]: '',
  [AuthFields.dialCode]: '-1',
  [AuthFields.phoneNumber]: '',
  [AuthFields.password]: '',
  [AuthFields.repeatPassword]: '',
};

export interface SignUpParams {
  username: string;
  password: string;
  attributes?: object;
  clientMetadata?: {
    [key: string]: string;
  };
}

export const CountryDialCodesOptions: TSelectOption[] = [
  {
    label: 'Dial Code',
    value: '-1',
  },
  ...countryDialCodes.map(item => {
    return { label: item, value: item };
  }),
];
