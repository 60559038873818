import * as Yup from 'yup';
import Moment from 'moment';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  firstNameRequired: `${FormStrings[FormFields.firstname]} is required`,
  firstNameInvalid: `${FormStrings[FormFields.firstname]} is invalid`,
  middleInitialRequired: `${FormStrings[FormFields.middleInitial]} is required`,
  middleInitialInvalid: `${FormStrings[FormFields.middleInitial]} is invalid`,
  lastNameRequired: `${FormStrings[FormFields.lastname]} is required`,
  lastNameInvalid: `${FormStrings[FormFields.lastname]} is invalid`,
  phoneNumberRequired: `${FormStrings[FormFields.phonenumber]} is required`,
  phoneNumberInvalid: `${FormStrings[FormFields.phonenumber]} format is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.firstname]: Yup.string().required(validationMessages.firstNameRequired),
  [FormFields.middleInitial]: Yup.string().notRequired(),
  [FormFields.middleInitial]: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]$/, validationMessages.middleInitialInvalid)
    .notRequired(),
  [FormFields.lastname]: Yup.string().required(validationMessages.lastNameRequired),
  [FormFields.phonenumber]: Yup.string()
    .matches(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, validationMessages.phoneNumberInvalid)
    .required(validationMessages.phoneNumberRequired),
});

export default defaultValidation;
