import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  subtypeRequired: `${FormStrings[FormFields.subtype]} is required`,
  subtypeInvalid: `${FormStrings[FormFields.subtype]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.type]: Yup.string().required(),
  [FormFields.subtype]: Yup.string().when([FormFields.type], {
    is: val => val === FormFields.individual || val === FormFields.custodial,
    then: Yup.string(),
    otherwise: Yup.string()
      .required(validationMessages.subtypeRequired)
      .notOneOf(['-1'], validationMessages.subtypeInvalid),
  }),
});

export default defaultValidation;
