import React from 'react';
import { Icon } from 'antd';
import * as Style from './Style';
import { css, cx } from 'emotion';

interface SVGIconType {
  iconName: string;
  width?: number;
  height?: number;
  className?: string;
  onClick?: Function;
  isBase64Icon?: boolean;
}

const SVGIcon: React.FunctionComponent<SVGIconType> = (props: SVGIconType) => {
  const { width, height, iconName, className, onClick, isBase64Icon } = props;
  const classNames = cx(className, Style.icon);
  return (
    <Icon
      className={classNames}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (onClick) onClick();
      }}
      component={() => (
        <img
          src={isBase64Icon ? `data:image/jpeg;charset=utf-8;base64,${iconName}` : `/img/${iconName}`}
          width={width}
          height={height}
        />
      )}
    />
  );
};

export default SVGIcon;
