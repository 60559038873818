import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueEntityAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Input, { MaskType } from '../../../../shared/Inputs/Input';
import SelectInput from '../../../../shared/Select/Select';
import { StateOptions } from '../../PhysicalAddress/schema';

const Component = (props: TForm) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => (state.forms.entity ? state.forms.entity.entityMailingAddress : null));
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.addressLineOne}
              label={FormStrings[FormFields.addressLineOne]}
              value={formState[FormFields.addressLineOne]}
            />
            <Input
              {...form}
              name={FormFields.addressLineTwo}
              label={FormStrings[FormFields.addressLineTwo]}
              value={formState[FormFields.addressLineTwo]}
            />
            <Input
              {...form}
              name={FormFields.city}
              label={FormStrings[FormFields.city]}
              value={formState[FormFields.city]}
            />
            <SelectInput
              {...form}
              name={FormFields.state}
              label={FormStrings[FormFields.state]}
              options={StateOptions}
              defaultOption={formState[FormFields.state]}
            />
            <Input
              {...form}
              name={FormFields.zipCode}
              label={FormStrings[FormFields.zipCode]}
              value={formState[FormFields.zipCode]}
            />
            <ContinueEntityAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
