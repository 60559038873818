/**
 * Effects action states
 */
export const stateTypes = {
  REQUESTED: 'Requested',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
};

/**
 * All Application action types are defined here
 */
export const CLEAR_LOGIN = 'Login/Remove';
export const LOGIN = 'Login';
export const REGISTER = 'Register';
export const CONFIRM_EMAIL = 'ConfirmEmail';
export const FORGOT_PASSWORD = 'ForgotPassword';
export const CLEAR_FORGOT_PASSWORD = 'ForgotPassword/Remove';
export const LOGOUT = 'Logout';
export const SET_AUTH_TOKEN = 'AuthToken/Set';
export const REMOVE_AUTH_TOKEN = 'AuthToken/Remove';
export const CLEAR_REGISTRATION = 'Register/Clear';
export const RESETPASSWORD = 'ResetPassword';
export const CHANGE_PASSWORD = 'ChangePassword';

export const SAVE_ACCOUNT = 'SaveAccount';

/**
 * Forms actions
 */
export const FORM_STATE = 'Forms';
export const SAVE_FORM_STATE = `${FORM_STATE}/Save`;
export const SAVE_RETIREMENT_FORM_STATE = `${FORM_STATE}/Retirement/Save`;
export const DELETE_RETIREMENT_FORM_STATE = `${FORM_STATE}/Retirement/Delete`;
export const SAVE_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/MailingAddress/Save`;
export const DELETE_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/MailingAddress/Delete`;

/**
 * Entity actions
 */
export const ENTITY_STATE = 'Entity';
export const SAVE_ENTITY_FORM_STATE = `${FORM_STATE}/${ENTITY_STATE}/Save`;
export const DELETE_ENTITY_FORM_STATE = `${FORM_STATE}/${ENTITY_STATE}/Save`;
export const SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/${ENTITY_STATE}/MailingAddress/Save`;
export const DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/${ENTITY_STATE}/MailingAddress/Delete`;

/**
 * Joint Account actions
 */
export const JOINT_STATE = 'Joint';
export const SAVE_JOINT_FORM_STATE = `${FORM_STATE}/${JOINT_STATE}/Save`;
export const DELETE_JOINT_FORM_STATE = `${FORM_STATE}/${JOINT_STATE}/Save`;
export const SAVE_JOINT_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/${JOINT_STATE}/MailingAddress/Save`;
export const DELETE_JOINT_MAILING_ADDRESS_FORM_STATE = `${FORM_STATE}/${JOINT_STATE}/MailingAddress/Delete`;

/**
 * Intermediary actions
 */
export const INTERMEDIARY = 'Intermediary';
export const SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/RetirementIntermediary/Save`;
export const DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/RetirementIntermediary/Delete`;
export const SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/Entity/AuthorisedPerson/Intermediary/Save`;
export const DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/Entity/AuthorisedPerson/Delete`;
export const SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/Joint/Accounts/Save`;
export const DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE = `${INTERMEDIARY}/Joint/Accounts/Delete`;
/**
 * Solution actions
 */
export const ADD_SOLUTION = 'Solutions/Add';
export const REMOVE_SOLUTION = 'Solutions/Remove';
export const GET_SOLUTIONS = 'Solutions/Get';
/**
 * Sub Solution actions
 */
export const ADD_SUB_SOLUTION = 'Solutions/Sub/Add';
export const REMOVE_SUB_SOLUTION = 'Solutions/Sub/Remove';
export const GET_SUB_SOLUTIONS = 'Solutions/Sub/Get';
/**
 * Suitability Info actions
 */
export const REMOVE_SUITABILITY_INFO = 'SuitabilityInfo/RemoveSolution';
/**
 * Navigation actions
 */
export const BACK_TO = 'Back_To';
export const BACK_TO_REVIEW = `${BACK_TO}/Review/Save`;
export const ADDING_JOINT_ACCOUNT = `Adding/Joint/Save`;
/**
 * Plaid Settings
 */
export const GET_PLAID_BALANCE = `PlaidBalance/Get`;
export const GET_PLAID_LINK_TOKEN = `PlaidLinkToken/Get`;

/**
 * User Management actions
 */
export const ACCOUNT_ERROR_CLEAR = 'Account/Error/Clear';

export const GET_CONTACT_INFO = 'ContactInfo/Get';
export const UPDATE_CONTACT_INFO = 'ContactInfo/Patch';
export const UPDATE_CONTACT_INFO_CLEAR = 'ContactInfo/Patch/Clear';

export const GET_PERSONAL_INFO = 'PersonalInfo/Get';
export const UPDATE_PERSONAL_INFORMATION = 'PersonalInfo/Patch';
export const UPDATE_PERSONAL_INFORMATION_CLEAR = 'PersonalInfo/Patch/Clear';

export const GET_ACCOUNT_INFO = 'AccountInfo/Get';
export const UPDATE_ACCOUNT_INFORMATION = 'AccountInfo/Patch';
export const UPDATE_ACCOUNT_INFORMATION_CLEAR = 'AccountInfo/Patch/Clear';
