import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  annualExpenses: 'annualExpenses',
  specialExpenses: 'specialExpenses',
  timeFrameSpecialExpenses: 'timeFrameSpecialExpenses',
};

export const FormFieldsTypes = {
  [FormFields.annualExpenses]: reviewFieldTypes.SelectOptions,
  [FormFields.specialExpenses]: reviewFieldTypes.SelectOptions,
  [FormFields.timeFrameSpecialExpenses]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.annualExpenses]: 'Annual Expenses',
  [FormFields.specialExpenses]: 'Special Expenses',
  [FormFields.timeFrameSpecialExpenses]: 'Time Frame of Special Expenses',
  annualExpensesDisclaimer:
    'Recurring: Annual expenses might include mortgage payments, rent, long-term debts, utilities, child support, alimony, etc.',
  specialExpensesDisclaimer:
    'Non-Recurring: Special expenses might include a home purchase, remodeling a home, a car purchase, education, medical expenses, etc.',
};
