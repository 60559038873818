import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues, FieldsOptions } from './schema';
import { TFormConfig } from '../../../formTypes';
import { FormFieldsTypes, FormStrings } from './constants';
import { ENTITY } from '../../../../../constants/accountTypes';

export const name = 'authorizedPersonTradeExperience';
const path = 'authorizedPersonTradeExperience';
const header = 'Authorized Person Trade Experience';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  cleanForm: false,
  disabled: false,
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  accountType: ENTITY,
};

export default config;
