import { FormFields, FormStrings } from './constants';

export const InitialValues = {
  [FormFields.Corporation]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.certificateOfIncorporation]: '',
    [FormFields.corporateAgreement]: '',
  },
  [FormFields.Partnership]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.certificateOfPartnership]: '',
    [FormFields.partnershipAgreement]: '',
  },
  [FormFields.Trust]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.trusteeCertification]: '',
    [FormFields.trustAgreement]: '',
  },
  [FormFields.LLC]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.certificateOfFormation]: '',
    [FormFields.operatingAgreement]: '',
  },
  [FormFields.Investment_Club]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.memberValidID]: '',
    [FormFields.investmentClubAgreement]: '',
  },
  [FormFields.Sole_Proprietorship]: {
    [FormFields.articlesOfIncorporation]: '',
    [FormFields.proprietorshipAgreement]: '',
  },
  [FormFields.uploadLater]: false,
};
