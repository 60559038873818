import { State, Type } from '../actions/utils';
import { put, takeEvery, delay, actionChannel } from 'redux-saga/effects';

import { TReduxAction } from '../types/commonTypes';

// TODO: Replace actual fetch logic here
export function* getContactInformation(_action: TReduxAction) {
  try {
    const user = {
      firstName: 'Joel',
      lastName: 'Vanderhoof',
      email: 'joelvanderhoof@cambriacaptial.com',
      homePhoneNumber: '555-555-5555',
      mobilePhoneNumber: null,
      workPhoneNumber: null,
    };
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.GET_CONTACT_INFO), payload: user });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.GET_CONTACT_INFO), error: error.message });
  }
}

// TODO: Replace actual patch logic here
export function* updateContactInformation(_action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    yield put({ type: State.actionSucceeded(Type.UPDATE_CONTACT_INFO_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.UPDATE_CONTACT_INFO), payload: true });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.UPDATE_CONTACT_INFO), error: error.message });
  }
}

// TODO: Replace actual fetch logic here
export function* getPersonalInformation(_action: TReduxAction) {
  try {
    const personalInfo = {
      cardId: 'https://www.sawasdeeamerica.com/wp-content/uploads/2019/02/id-card-1080x708.jpg',
      countryOfResidence: 'USA',
      physicalAddress: {
        addressLineOne: 'Address line 1 goes here',
        city: 'New York',
        state: 'NY',
        zipCode: '2001',
        mailingSame: true,
        citizenship: 'us',
        permanentUsResident: true,
      },
      mailingAddress: {
        addressLineOne: 'Address line 1 goes here',
        city: 'Sacramento',
        state: 'CA',
        zipCode: '1000',
      },
    };
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.GET_PERSONAL_INFO), payload: personalInfo });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.GET_PERSONAL_INFO), error: error.message });
  }
}

// TODO: Replace actual patch logic here
export function* updatePersonalInformation(_action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    yield put({ type: State.actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.UPDATE_PERSONAL_INFORMATION), payload: true });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.UPDATE_PERSONAL_INFORMATION), error: error.message });
  }
}

// TODO: Replace actual fetch logic here
export function* getAccountInformation(_action: TReduxAction) {
  try {
    const accountInfo = {
      accountType: {
        type: 'Trust',
      },
      accountOwnerInfo: {
        firstName: 'Joel',
        middleInitial: 'J',
        lastName: 'Vanderhoof',
        phoneNumber: '555-555-5555',
      },
    };
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.GET_ACCOUNT_INFO), payload: accountInfo });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.GET_ACCOUNT_INFO), error: error.message });
  }
}

// TODO: Replace actual patch logic here
export function* updateAccountInformation(_action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.ACCOUNT_ERROR_CLEAR) });
    yield put({ type: State.actionSucceeded(Type.UPDATE_ACCOUNT_INFORMATION_CLEAR) });
    // TODO: Remove this delay in real fetch
    yield delay(4000);
    yield put({ type: State.actionSucceeded(Type.UPDATE_ACCOUNT_INFORMATION), payload: true });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.UPDATE_ACCOUNT_INFORMATION), error: error.message });
  }
}

/**
 * UserManagement sagas
 */
export default function* saga() {
  yield takeEvery(State.actionRequested(Type.GET_CONTACT_INFO), getContactInformation);
  yield takeEvery(State.actionRequested(Type.GET_PERSONAL_INFO), getPersonalInformation);
  yield takeEvery(State.actionRequested(Type.GET_ACCOUNT_INFO), getAccountInformation);
  yield takeEvery(State.actionRequested(Type.UPDATE_CONTACT_INFO), updateContactInformation);
  yield takeEvery(State.actionRequested(Type.UPDATE_PERSONAL_INFORMATION), updatePersonalInformation);
  yield takeEvery(State.actionRequested(Type.UPDATE_ACCOUNT_INFORMATION), updateAccountInformation);
}
