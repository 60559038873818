export const FormFields = {
  retirementEdit: 'beneficiariesSharedPercentageReview',
  authorizedPersonEdit: 'authorizedPersonsReview',
  jointAccountsEdit: 'jointAccountsReview',
};

export const FormStrings = {
  beneficiariesText: 'Beneficiaries',
  authorizedPersonText: 'Authorized Persons',
  beneficiariesPrimaryText: 'Primary',
  beneficiariesContingentText: 'Contingent',
  beneficiariesPercentageLabel: 'Share Percentage: ',
  jointAccountsText: 'Joint Accounts',
};
