import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';

import { TForm } from '../../formTypes';
import { Col, Row } from 'antd';
import InfoText from '../../../shared/InfoText/InfoText';
import SVGIcon from '../../../shared/SVGIcon/SVGIcon';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import { ContinueAction } from '../../FormActions';

import { FormFields, FormStrings } from './constants';
import * as Routes from '../../../../constants/routes';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import SignatureComp from './Signature';
import { useSelector } from 'react-redux';

const Component = (props: TForm) => {
  const history = useHistory();
  const accountOwnerInfoState = useSelector((state: any) => state.forms.accountOwnerInformation);
  const solutionsState = useSelector((state: any) => state.solutions);
  // const solutionsFormState = getFormState(solutionsState, InitialValues);

  const onSubmit = (values: any) => {
    history.replace(Routes.ACCOUNT_CREATING);
  };

  let initials = Object.keys(solutionsState);
  if (FormFields.tradingBlock in solutionsState) {
    const tradingBlock = solutionsState[FormFields.tradingBlock];
    if ('products' in tradingBlock && 'accountTypes' in tradingBlock.products) {
      let res = Object.keys(tradingBlock.products.accountTypes).filter(val => tradingBlock.products.accountTypes[val]);
      res.map(val => {
        if (val in FormFields) {
          initials.push(val);
        }
      });
      initials.push(FormFields.apex);
    }
  }
  if (
    (initials.includes(FormFields.myIpo) || initials.includes(FormFields.readyRound)) &&
    !initials.includes(FormFields.folio)
  ) {
    initials.push(FormFields.folio);
  }

  return (
    <Formik initialValues={InitialValues} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        const termsList = initials.map(val => {
          return (
            <Row justify={'space-between'}>
              <Col span={20}>
                <Checkbox {...form} name={val} label={FormStrings[val].name} />
              </Col>
              <Col span={4}>
                <div style={{ float: 'right' }}>
                  <a href={FormStrings[val].link} download>
                    <SVGIcon iconName={'download-file.svg'} />
                  </a>
                </div>
              </Col>
            </Row>
          );
        });
        return (
          <>
            <div>
              <InfoText>
                Click the text links below to view the agreements and disclosures which govern this TradingBlock
                account. By clicking the checkbox next to each respective document, you agree to the terms and
                conditions stated therein, and to receive the document via electronic delivery. Click the download
                button to save a PDF copy of each document for your records.
              </InfoText>
              <InfoText>
                (Note: If you are unable to view any of the electronic documents or do not agree with the terms and
                conditions therein, please exit this form and contact Customer Service.)
              </InfoText>
            </div>
            <Row justify={'space-between'}>
              <Col span={20}>
                <Checkbox {...form} name={FormFields.cambriaCapital} label={FormStrings.cambriaCapital.name} />
              </Col>
              <Col span={4}>
                <div style={{ float: 'right' }}>
                  <a href={FormStrings.cambriaCapital.link} download>
                    <SVGIcon iconName={'download-file.svg'} />
                  </a>
                </div>
              </Col>
            </Row>
            {termsList}
            {accountOwnerInfoState && (
              <SignatureComp
                {...form}
                name={'signature'}
                label={'Please select a signature to use'}
                firstName={accountOwnerInfoState.firstname}
                lastName={accountOwnerInfoState.lastname}
              />
            )}
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={onSubmit}
              isLastContinueStep
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
