import _ from 'lodash';

import Input from '../../../shared/Inputs/Input';
import SelectInput from '../../../shared/Select/Select';
import UploadDraggable from '../../../shared/Upload/UploadDraggable';
import { CountriesOfResidence as countriesOfResidence } from '../../../../constants/countriesOfResidence';
import { states } from '../../../../constants/states';
import { CitizenshipOptions as citizenshipOptions } from '../../../../constants/citizenships';

import Checkbox from '../../../shared/Checkbox/Checkbox';

export default (personalInformation: any): any => {
  const countryOfResidence: any = _.find(
    countriesOfResidence,
    country => country.value === (!_.isEmpty(personalInformation) ? personalInformation.countryOfResidence : null),
  );
  const physicalAddressState: any = _.find(
    states,
    state => state.value === (!_.isEmpty(personalInformation) ? personalInformation.physicalAddress.state : null),
  );

  const mailingAddressState: any = personalInformation.physicalAddress.mailingSame
    ? _.find(
        states,
        state => state.value === (!_.isEmpty(personalInformation) ? personalInformation.physicalAddress.state : null),
      )
    : _.find(
        states,
        state => state.value === (!_.isEmpty(personalInformation) ? personalInformation.mailingAddress.state : null),
      );

  const physicalAddressCitizenship: any = _.find(
    citizenshipOptions,
    citizenship =>
      citizenship.value === (!_.isEmpty(personalInformation) ? personalInformation.physicalAddress.citizenship : null),
  );

  return {
    cardId: {
      component: UploadDraggable,
      props: {
        name: 'cardId',
        value: !_.isEmpty(personalInformation) ? personalInformation.cardId : null,
        disabled: false,
        uploadHint: 'Upload your ID Card image',
        multiple: false,
        listType: 'picture',
        showUploadList: false,
        changeFileText: 'Change Image',
      },
    },
    countryOfResidence: {
      viewLabel: 'Country',
      displayValue: !_.isEmpty(countryOfResidence) ? personalInformation.countryOfResidence : '-',
      showDisplayValue: true,
      component: SelectInput,
      props: {
        name: 'countryOfResidence',
        label: 'Country',
        options: countriesOfResidence,
        defaultOption: !_.isEmpty(countryOfResidence) ? countryOfResidence.label : null,
        value: !_.isEmpty(countryOfResidence) ? countryOfResidence.value : null,
        disabled: false,
      },
    },
    physicalAddressAddressLineOne: {
      viewLabel: 'Address Line 1',
      displayValue: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.addressLineOne : '-',
      showDisplayValue: true,
      component: Input,
      props: {
        name: 'physicalAddressAddressLineOne',
        label: 'Address Line 1',
        value: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.addressLineOne : null,
        disabled: false,
      },
    },
    physicalAddressAddressLineTwo: {
      viewLabel: 'Address Line 2',
      displayValue: !_.isEmpty(personalInformation.physicalAddress.addressLineTwo)
        ? personalInformation.physicalAddress.addressLineTwo
        : '-',
      showDisplayValue: true,
      component: Input,
      props: {
        name: 'physicalAddressAddressLineTwo',
        label: 'Address Line 2',
        value: !_.isEmpty(personalInformation.physicalAddress.addressLineTwo)
          ? personalInformation.physicalAddress.addressLineTwo
          : null,
        disabled: false,
      },
    },
    physicalAddressCity: {
      viewLabel: 'City',
      displayValue: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.city : '-',
      showDisplayValue: true,
      component: Input,
      props: {
        name: 'physicalAddressCity',
        label: 'City',
        value: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.city : null,
        disabled: false,
      },
    },
    physicalAddressState: {
      viewLabel: 'State',
      displayValue: !_.isEmpty(physicalAddressState) ? physicalAddressState.value : '-',
      showDisplayValue: true,
      component: SelectInput,
      props: {
        name: 'physicalAddressState',
        label: 'State',
        options: states,
        defaultOption: !_.isEmpty(physicalAddressState) ? physicalAddressState.label : null,
        value: !_.isEmpty(physicalAddressState) ? physicalAddressState.value : null,
        disabled: false,
      },
    },
    physicalAddressZipCode: {
      viewLabel: 'Zip Code',
      displayValue: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.zipCode : '-',
      showDisplayValue: true,
      component: Input,
      props: {
        name: 'physicalAddressZipCode',
        label: 'Zip Code',
        value: !_.isEmpty(personalInformation) ? personalInformation.physicalAddress.zipCode : null,
        disabled: false,
      },
    },
    physicalAddressSameAsMailing: {
      displayValue: personalInformation.physicalAddress.mailingSame ? 'Same as my physical address' : 'Other',
      viewLabel: 'Mailing address is the same as my physical address',
      showDisplayValue: true,
      component: Checkbox,
      props: {
        name: 'physicalAddressSameAsMailing',
        label: 'Mailing address is the same as my physical address',
        checked: personalInformation.physicalAddress.mailingSame ? true : false,
        value: personalInformation.physicalAddress.mailingSame ? true : false,
        disabled: false,
      },
    },
    physicalAddressCitizenship: {
      viewLabel: 'Citizenship',
      displayValue: !_.isEmpty(physicalAddressCitizenship) ? physicalAddressCitizenship.label : '-',
      showDisplayValue: true,
      isReadOnly: false,
      component: SelectInput,
      props: {
        name: 'physicalAddressCitizenship',
        label: 'Citizenship',
        options: citizenshipOptions,
        defaultOption: !_.isEmpty(physicalAddressCitizenship) ? physicalAddressCitizenship.label : null,
        value: !_.isEmpty(physicalAddressCitizenship) ? physicalAddressCitizenship.value : null,
        disabled: false,
      },
    },
    physicalAddressPermanentUsResident: {
      showDisplayValue: false,
      component: Checkbox,
      props: {
        name: 'physicalAddressPermanentUsResident',
        label: 'Please check if you are a permanent U.S. resident',
        checked: personalInformation.physicalAddress.permanentUsResident ? true : false,
        value: personalInformation.physicalAddress.permanentUsResident ? true : false,
        disabled: false,
      },
    },

    mailingAddressAddressLineOne: {
      viewLabel: personalInformation.physicalAddress.mailingSame ? 'Mailing address' : 'Address Line 1',
      displayValue: !_.isEmpty(personalInformation)
        ? personalInformation.physicalAddress.mailingSame
          ? 'Same as my physical address'
          : personalInformation.mailingAddress.addressLineOne
        : '-',
      showDisplayValue: true,
      component: Input,
      props: {
        name: 'mailingAddressAddressLineOne',
        label: 'Address Line 1',
        disabled: personalInformation.physicalAddress.mailingSame ? true : false,
        value: !_.isEmpty(personalInformation)
          ? personalInformation.physicalAddress.mailingSame
            ? personalInformation.physicalAddress.addressLineOne
            : personalInformation.mailingAddress.addressLineOne
          : null,
      },
    },
    mailingAddressAddressLineTwo: {
      viewLabel: 'Address Line 2',
      displayValue: !_.isEmpty(personalInformation)
        ? personalInformation.physicalAddress.mailingSame
          ? personalInformation.physicalAddress.addressLineTwo
          : personalInformation.mailingAddress.addressLineTwo
        : '-',
      showDisplayValue: personalInformation.physicalAddress.mailingSame ? false : true,
      component: Input,
      props: {
        name: 'mailingAddressAddressLineTwo',
        label: 'Address Line 2',
        disabled: personalInformation.physicalAddress.mailingSame ? true : false,
        value: !_.isEmpty(personalInformation)
          ? personalInformation.physicalAddress.mailingSame
            ? personalInformation.physicalAddress.addressLineTwo
            : personalInformation.mailingAddress.addressLineTwo
          : null,
      },
    },
    mailingAddressCity: {
      viewLabel: 'City',
      displayValue: !_.isEmpty(personalInformation)
        ? personalInformation.physicalAddress.mailingSame
          ? personalInformation.physicalAddress.city
          : personalInformation.mailingAddress.city
        : '-',
      showDisplayValue: personalInformation.physicalAddress.mailingSame ? false : true,

      component: Input,
      props: {
        name: 'mailingAddressCity',
        label: 'City',
        disabled: personalInformation.physicalAddress.mailingSame ? true : false,
        value: !_.isEmpty(personalInformation)
          ? personalInformation.physicalAddress.mailingSame
            ? personalInformation.physicalAddress.city
            : personalInformation.mailingAddress.city
          : null,
      },
    },
    mailingAddressState: {
      viewLabel: 'State',
      displayValue: !_.isEmpty(personalInformation)
        ? personalInformation.physicalAddress.mailingSame
          ? personalInformation.physicalAddress.state
          : personalInformation.mailingAddress.state
        : '-',
      showDisplayValue: personalInformation.physicalAddress.mailingSame ? false : true,
      component: SelectInput,
      props: {
        name: 'mailingAddressState',
        label: 'State',
        disabled: personalInformation.physicalAddress.mailingSame ? true : false,
        options: states,
        defaultOption: !_.isEmpty(mailingAddressState) ? mailingAddressState.label : null,
        value: !_.isEmpty(mailingAddressState) ? mailingAddressState.value : null,
      },
    },
    mailingAddressZipCode: {
      viewLabel: 'Zip Code',
      displayValue: !_.isEmpty(personalInformation)
        ? personalInformation.physicalAddress.mailingSame
          ? personalInformation.physicalAddress.zipCode
          : personalInformation.mailingAddress.zipCode
        : '-',
      showDisplayValue: personalInformation.physicalAddress.mailingSame ? false : true,
      component: Input,
      props: {
        name: 'mailingAddressZipCode',
        label: 'Zip Code',
        disabled: personalInformation.physicalAddress.mailingSame ? true : false,
        value: !_.isEmpty(personalInformation)
          ? personalInformation.physicalAddress.mailingSame
            ? personalInformation.physicalAddress.zipCode
            : personalInformation.mailingAddress.zipCode
          : null,
      },
    },
  };
};
