import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const popoverStyle = css`
  .ant-popover-content .ant-popover-inner .ant-popover-title {
    border-bottom: none !important;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .ant-popover-arrow {
    display: none;
  }
`;

export const solutionMenu = css`
  height: 97px;
  width: 155px;
  cursor: pointer;
  border: 1px solid ${Color.FogBlue};
  text-align: center;
  vertical-align: middle;
  padding: 41px;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const solutionImage = css`
  margin-left: auto;
  margin-right: auto;
  margin: 0;
`;
