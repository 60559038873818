import React, { useEffect } from 'react';
import * as Typography from '../../../../styles/typography';
import ExpandableReview from '../../../shared/ExpandableReview/ExpandableReview';
import ReviewDetail from './ReviewDetail';
import { produceReviewDetailRow } from '../../../../utils/reviewFieldTransformers';
import { FormFields, FormStrings } from './constants';
import { filterBeneficiariesForReview } from './helpers';
import * as Styles from './Style';
import { useDispatch, useSelector } from 'react-redux';
import * as FormActions from '../../../../actions';

export type TBeneficiaries = {
  state: any;
};

const BeneficiariesReview = (props: TBeneficiaries) => {
  const { state } = props;
  const dispatch = useDispatch();
  const filteredBeneficiaries = filterBeneficiariesForReview(state);

  const primary = (
    <>
      <div className={Styles.BeneficiariesGroup}>
        {FormStrings.beneficiariesPrimaryText}{' '}
        <span className={Styles.BeneficiariesTotalPercentage}>{filteredBeneficiaries.primaryTotal}%</span>{' '}
      </div>
      {filteredBeneficiaries.primary.map((item: any) => {
        return (
          <div className={Styles.BeneficiariesPersonGroup}>
            <div className={Styles.BeneficiariesLabel}>{item.label}</div>
            <div>{FormStrings.beneficiariesPercentageLabel + ' ' + item.value}%</div>
          </div>
        );
      })}
    </>
  );

  const contingent = (
    <>
      <div className={Styles.BeneficiariesGroup}>
        {FormStrings.beneficiariesContingentText}{' '}
        <span className={Styles.BeneficiariesTotalPercentage}>{filteredBeneficiaries.contingentTotal}%</span>{' '}
      </div>
      {filteredBeneficiaries.contingent.length > 0 &&
        filteredBeneficiaries.contingent.map((item: any) => {
          return (
            <div className={Styles.BeneficiariesPersonGroup}>
              <div className={Styles.BeneficiariesLabel}>{item.label}</div>
              <div>{FormStrings.beneficiariesPercentageLabel + ' ' + item.value}%</div>
            </div>
          );
        })}
    </>
  );
  const updateRetirementIntermediary = () => {
    dispatch(
      FormActions.saveRetirementIntermediaryFormState({
        body: [...state],
      }),
    );
  };
  return (
    <>
      <p className={Typography.CategoryReviewText}>{FormStrings.beneficiariesText}</p>
      <hr className={Typography.hr} />
      <ExpandableReview
        id={FormFields.retirementEdit}
        title={FormStrings.beneficiariesText}
        onEdit={updateRetirementIntermediary}
      >
        {primary}
        {filteredBeneficiaries.contingent.length > 0 && <hr className={Styles.BeneficiariesDivider} />}
        {filteredBeneficiaries.contingent.length > 0 && contingent}
      </ExpandableReview>
    </>
  );
};

export default BeneficiariesReview;
