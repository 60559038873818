import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Url from '../constants/url';
import { Type, State } from '../actions/utils';
import { HttpClient } from '../helpers/httpClient';
import { TReduxAction } from '../types/commonTypes';

export function* addBackToReview(action: TReduxAction) {
  try {
    // if (!action.payload.solutionCode) throw new Error('Solution code not found!');
    yield put({ type: State.actionSucceeded(Type.BACK_TO_REVIEW), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.BACK_TO_REVIEW), error: error.message });
  }
}

export function* addingJointAccount(action: TReduxAction) {
  try {
    yield put({ type: State.actionSucceeded(Type.ADDING_JOINT_ACCOUNT), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.ADDING_JOINT_ACCOUNT), error: error.message });
  }
}

/**
 * Solutions sagas
 */
export default function* saga() {
  yield takeEvery(State.actionRequested(Type.BACK_TO_REVIEW), addBackToReview);
  yield takeEvery(State.actionRequested(Type.ADDING_JOINT_ACCOUNT), addingJointAccount);
}
