import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  deliveryType: 'deliveryType',
} as any;

export const FormFieldsTypes = {
  [FormFields.deliveryType]: reviewFieldTypes.SelectOptions,
} as any;

export const FormStrings = {
  [FormFields.deliveryType]: 'Individual',
  electronic: 'Electronic',
  paper: 'Paper',
} as any;
