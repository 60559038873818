import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues, FieldsOptions } from './schema';
import { TFormConfig } from '../../formTypes';
import formMetadata from './formMetadata';
import { FormFieldsTypes, FormStrings } from './constants';
import categories from '../../../../constants/categories';

const name = 'selectYourRiskTolerance';
const path = 'selectYourRiskTolerance';
const header = 'Select Your Risk Tolerance';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations(InitialValues),
  FormComponent: FormComponent,
  disabled: false,
  twoSideFormMetadata: formMetadata(header),
  formStrings: FormStrings,
  formFieldsTypes: FormFieldsTypes,
  formFieldOptions: FieldsOptions,
  category: categories.suitabilityInformation,
  backToReview: true,
};

export default config;
