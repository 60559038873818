import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  nameOfTrust: 'nameOfTrust',
  dateOfTrust: 'dateOfTrust',
  trustTaxID: 'trustTaxID',
};
export const FormFieldsTypes = {
  [FormFields.nameOfTrust]: reviewFieldTypes.RawString,
  [FormFields.dateOfTrust]: reviewFieldTypes.RawString,
  [FormFields.trustTaxID]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.nameOfTrust]: 'Name of Trust',
  [FormFields.dateOfTrust]: 'Date of Trust',
  [FormFields.trustTaxID]: 'Trust Tax ID',
};
