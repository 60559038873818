import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { conditionValidation } from '../SelectInvestmentObjectives/validations';

export const defaultValidation = (minYears?: number) => {
  const validationMessages = {
    stockRequired: `${FormStrings[FormFields.stock]} is required`,
    optionRequired: `${FormStrings[FormFields.option]} is required`,
    commodityRequired: `${FormStrings[FormFields.commodity]} is required`,
    bondRequired: `${FormStrings[FormFields.bond]} is required`,
    marginRequired: `${FormStrings[FormFields.margin]} is required`,
    mutualFundOrETFRequired: `${FormStrings[FormFields.mutualFundOrETF]} is required`,
    securityFutureRequired: `${FormStrings[FormFields.securityFuture]} is required`,
    annuityRequired: `${FormStrings[FormFields.annuity]} is required`,
    alternativeRequired: `${FormStrings[FormFields.alternative]} is required`,
    reitRequired: `${FormStrings[FormFields.reit]} is required`,

    yearsTradingRequired: `${FormStrings[FormFields.yearsTrading]} is required!`,
    yearsTradingInvalid: `${minYears} ${
      !minYears
        ? `${FormStrings[FormFields.yearsTrading]} is`
        : minYears > 1
        ? `${FormStrings[FormFields.yearsTrading]} are`
        : 'Year trading is'
    }   required!`,
    tradesOrYearRequired: `${FormStrings[FormFields.tradesOrYear]} is required!`,
    tradesOrYearInvalid: `${FormStrings[FormFields.tradesOrYear]} is invalid!`,
  };

  return Yup.object().shape({
    [FormFields.stock]: Yup.boolean().required(validationMessages.stockRequired),
    [`${FormFields.stock}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.stock], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.stock}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.stock], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.option]: Yup.boolean().required(validationMessages.optionRequired),
    [`${FormFields.option}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.option], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.option}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.option], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.commodity]: Yup.boolean().required(validationMessages.commodityRequired),
    [`${FormFields.commodity}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.commodity], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.commodity}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.commodity], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.bond]: Yup.boolean().required(validationMessages.bondRequired),
    [`${FormFields.bond}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.bond], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.bond}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.bond], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.margin]: Yup.boolean().required(validationMessages.marginRequired),
    [`${FormFields.margin}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.margin], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.margin}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.margin], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.mutualFundOrETF]: Yup.boolean().required(validationMessages.mutualFundOrETFRequired),
    [`${FormFields.mutualFundOrETF}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.mutualFundOrETF], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.mutualFundOrETF}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.mutualFundOrETF], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.securityFuture]: Yup.boolean().required(validationMessages.securityFutureRequired),
    [`${FormFields.securityFuture}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.securityFuture], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.securityFuture}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.securityFuture], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.annuity]: Yup.boolean().required(validationMessages.annuityRequired),
    [`${FormFields.annuity}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.annuity], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.annuity}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.annuity], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.alternative]: Yup.boolean().required(validationMessages.alternativeRequired),
    [`${FormFields.alternative}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.alternative], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.alternative}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.alternative], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),

    [FormFields.reit]: Yup.boolean().required(validationMessages.reitRequired),
    [`${FormFields.reit}-${FormFields.yearsTrading}`]: Yup.number().when([FormFields.reit], {
      is: true,
      then: Yup.number()
        .required(validationMessages.yearsTradingRequired)
        .min(minYears ? minYears : 0, validationMessages.yearsTradingInvalid)
        .max(100, validationMessages.yearsTradingInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
    [`${FormFields.reit}-${FormFields.tradesOrYear}`]: Yup.number().when([FormFields.reit], {
      is: true,
      then: Yup.number()
        .required(validationMessages.tradesOrYearRequired)
        .notOneOf([-1], validationMessages.tradesOrYearRequired)
        .oneOf([1, 2, 3], validationMessages.tradesOrYearInvalid),
      otherwise: Yup.number()
        .default(null)
        .nullable(),
    }),
  });
};

export default defaultValidation;
