import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  type: 'type',
  individual: 'individual',
  trust: 'trust',
};

export const FormFieldsTypes = {
  [FormFields.type]: reviewFieldTypes.RadioGroup,
} as any;

export const FormStrings = {
  [FormFields.individual]: 'Individual',
  [FormFields.trust]: 'Trust',
  [FormFields.type]: 'Beneficiary type',
  formText: 'Please select if this beneficiary is individual or trust.',
};
