import * as ActionTypes from '../constants/actionTypes';
import * as StateTypes from './types';

/**
 * Gets a redux action from params
 */
export const createAction = (type: string, payload: any) => ({
  type,
  payload,
});

/**
 * Application ActionTypes
 */
export const Type = ActionTypes;
/**
 * Action States to decorate primitive types
 */
export const State = StateTypes;
