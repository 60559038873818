import React, { FunctionComponent, useState } from 'react';
import { Modal, Button } from 'antd';
import TwoSidedFormContainer from '../../../shared/TwoSidedFormContainer/TwoSidedFormContainer';
import TradingBlockProducts from '../../Registration/TradingBlockProducts';
import { TFormConfig } from '../../formTypes';
import { TBPModal } from './style';

const TradingBlockProductsModal: FunctionComponent<{ visible?: boolean; changeModalState: Function }> = props => {
  const { visible, changeModalState } = props;
  const [currentForm, setCurrentForm] = useState<TFormConfig>(TradingBlockProducts);
  const { name, path, FormComponent } = currentForm;

  return (
    <div>
      <Modal title={null} footer={null} visible={visible} closable={false} className={TBPModal} centered={true}>
        <TwoSidedFormContainer {...currentForm.twoSideFormMetadata}>
          <FormComponent onSubmit={() => changeModalState()} />
        </TwoSidedFormContainer>
      </Modal>
    </div>
  );
};

export default TradingBlockProductsModal;
