import { css, cx } from 'emotion';
import { Typography } from '../../../../../styles';

export const Title = cx(
  Typography.Header,
  css`
    border-bottom: 1px solid #e8e8e8 !important;
    padding-top: 24px !important;
    margin-bottom: 24px !important;
    display: flex;
    justify-content: space-between;
    span {
      padding-top: 8px;
    }
    img {
      padding-bottom: 8px;
    }
  `,
);
