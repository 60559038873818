import React from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, MartialStatusOptions, NoDependentsOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import * as Constants from '../../../../constants/dates';

import { ContinueAction } from '../../FormActions';
import DatePickerInput from '../../../shared/DatePicker/DatePicker';
import Input, { MaskType } from '../../../shared/Inputs/Input';
import { useSelector } from 'react-redux';
import { getFormState, jointAccountsIntermediaryFilter } from '../../../../utils/forms';
import { useHistory } from 'react-router';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';

const Component = (props: TForm) => {
  // const history: any = useHistory();
  // const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key =
  //   history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
  //
  // const state = useSelector((state: any) =>
  //   history.location.state && typeof history.location.state.id !== 'undefined' && addingJointAccount
  //     ? jointAccountsIntermediaryFilter(state.intermediary.jointAccountsIntermediary, props.id)
  //     : state.forms.personalInfo,
  // );
  // const formState = getFormState(state, InitialValues);
  // const hasKey = key || key === 0 || addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(props.id, InitialValues, history);
  const onSubmit = (values: any) => {
    if (props.onContinue && hasKey) {
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      props.onContinue(values);
    }
  };

  const disabledDate = (current: any) => {
    return current && current > moment().subtract(18, 'y');
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <DatePickerInput
              {...form}
              name={FormFields.birthday}
              label={FormStrings[FormFields.birthday]}
              dateFormat={Constants.DATE_YEAR_FORMAT}
              disabledDate={disabledDate}
              defaultValue={formState[FormFields.birthday]}
            />
            <Input
              {...form}
              name={FormFields.ssn}
              label={FormStrings[FormFields.ssn]}
              mask={MaskType.Ssn}
              value={formState[FormFields.ssn]}
            />
            <SelectInput
              {...form}
              name={FormFields.martialStatus}
              label={FormStrings[FormFields.martialStatus]}
              options={MartialStatusOptions}
              defaultOption={
                formState[FormFields.martialStatus]
                  ? formState[FormFields.martialStatus]
                  : MartialStatusOptions[0].value
              }
            />
            <SelectInput
              {...form}
              name={FormFields.noDependents}
              label={FormStrings[FormFields.noDependents]}
              options={NoDependentsOptions}
              defaultOption={
                formState[FormFields.noDependents] ? formState[FormFields.noDependents] : NoDependentsOptions[0].value
              }
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
