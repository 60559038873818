import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.citizenship]: '-1',
  [FormFields.stateOfResidency]: '-1',
};
const ResidencyOptions: TSelectOption[] = [
  {
    label: 'Select a state',
    value: '-1',
  },
  {
    label: 'United States of America',
    value: 'us',
  },
  {
    label: 'Other',
    value: 'x',
  },
];

const CitizenshipOptions: TSelectOption[] = [
  {
    label: 'Select a citizenship status',
    value: '-1',
  },
  {
    label: 'U.S. Citizen',
    value: 'us',
  },
  {
    label: 'Resident Alien',
    value: 'ra',
  },
];
export const FieldsOptions = {
  [FormFields.stateOfResidency]: ResidencyOptions,
  [FormFields.citizenship]: CitizenshipOptions,
};
