export const AuthFields = {
  code: 'code',
  password: 'password',
  repeatPassword: 'repeatPassword',
};

export const AuthStrings = {
  [AuthFields.code]: 'Code',
  [AuthFields.password]: 'New Password',
  [AuthFields.repeatPassword]: 'Repeat Password',
};
