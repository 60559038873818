import React from 'react';
import { Col, Layout, Row } from 'antd';

import * as Styles from './style';
import Page from '../layout/Page/Page';
import { getGreetingTime } from '../../utils/greetingTime';
import SolutionCard from '../shared/SolutionCard/SolutionCard';
import solutions, { externalSolutions } from '../../constants/soutions';
import ExternalSolutionCard from '../shared/ExternalSolutionCard/ExternalSolutionCard';

const { Content } = Layout;

export default () => {
  const accountName = 'Joel';
  return (
    <Page>
      <Content className={Styles.Container}>
        <div className={Styles.SolutionsContainer}>
          <div className={Styles.Greetings}>{getGreetingTime() + ' ' + accountName}</div>
          <div className={Styles.BlockHelper}>
            Connect your investment accounts to viewing <br />
            all your accounts in one location
          </div>
          <Row type="flex" justify="center" gutter={12} className={Styles.RowSolutions}>
            {solutions.map(solution => {
              return (
                <Col xs={24} sm={24} md={6} lg={6} xl={6} className={Styles.CardCenter}>
                  <SolutionCard solution={solution} />
                </Col>
              );
            })}
          </Row>
          <div className={Styles.OrCondition}>or</div>
          <div className={Styles.Greetings}>get started investing with a Cambria investment solution.</div>
        </div>
        <div className={Styles.ExternalSolutionsContainer}>
          <div className={Styles.BlockHelperExternal}>Connect other external accounts</div>
          <div className={Styles.HelperExternal}>
            Have account with other external investment groups? Cambria Connect <br />
            can help you track the value of all your external accounts as well.
          </div>
          <Row type="flex" justify="center" gutter={12} className={Styles.RowExternalSolutions}>
            {externalSolutions.map(solution => {
              return (
                <Col xs={24} sm={24} md={3} lg={3} xl={3} className={Styles.CardCenter}>
                  <ExternalSolutionCard solution={solution} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Content>
      {/*<Footer />*/}
    </Page>
  );
};
