import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', height = '100%', className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15.4142 1.75735L14.2427 0.585785C13.4616 -0.195246 12.1953 -0.195277 11.4142 0.585785L0.401171 11.5988L0.00470128 15.167C-0.0483613 15.6447 0.355327 16.0484 0.832952 15.9953L4.40121 15.5988L15.4143 4.58579C16.1953 3.80472 16.1953 2.53838 15.4142 1.75735V1.75735ZM5.14643 10.8535C5.34171 11.0488 5.65827 11.0488 5.85352 10.8535L11.5 5.20707L12.4393 6.14641L5.43749 13.1483V12.0625H3.93749V10.5625H2.8517L9.85356 3.56063L10.7929 4.49997L5.14643 10.1464C4.95115 10.3417 4.95115 10.6583 5.14643 10.8535ZM3.94208 14.6437L2.09849 14.8485L1.15145 13.9015L1.3563 12.0579L1.9767 11.4375H3.06249V12.9375H4.56249V14.0233L3.94208 14.6437V14.6437ZM14.7071 3.87866L13.1464 5.43932L10.5607 2.85354L12.1213 1.29288C12.5121 0.902098 13.1447 0.902036 13.5355 1.29288L14.7071 2.46444C15.0979 2.85526 15.0979 3.48779 14.7071 3.87866V3.87866Z"
    />
  </svg>
);

export default SVG;
