import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const DropdownButton = css`
  background: ${Color.FineBlue}!important;
  border: 1px solid ${Color.FineBlue}!important;
  border-radius: 20px !important;
  font-family: ${Typography.GillSans} !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.162037px !important;
  color: ${Color.PureWhite} !important;
  height: 40px !ipmortant;
`;

export const popoverStyle = css`
  .ant-popover-content .ant-popover-inner .ant-popover-title {
    border-bottom: none !important;
  }
`;

export const popover = css`
  background: ${Color.PureWhite} !important;
  border: 1px solid ${Color.FogBlue} !important;
  box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.15) !important;
  border-radius: 2px !important;
  min-width: 424px !important;
  min-height: 167px !important;
  padding: 0 !important;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
`;

export const Category = css`
  font-family: ${Color.FineBlue}!important;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.162037px;
  text-transform: uppercase;
  color: ${Color.TropicalBlue} !important;
  font-weight: 600;
  padding: 8px 0;
`;

export const Solutions = css`
  display: block;
`;

export const CambriaSolution = css`
  padding-right: 20px;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
`;

export const ExternalSolution = css`
  padding-right: 10px;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
  &:last-child > img {
    vertical-align: -webkit-baseline-middle;
  }
`;

export const blockCambria = css`
  min-height: 83px;
  padding: 18px 24px !important;
  border-bottom: 1px solid ${Color.FogBlue} !important;
`;

export const block = css`
  min-height: 83px;
  padding: 18px 24px !important;
`;
