import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  email: 'email',
};

export const FormFieldsTypes = {
  [FormFields.email]: reviewFieldTypes.RawString,
} as any;

export const FormStrings = {
  [FormFields.email]: 'Email',
};
