import { ObjectSchema } from 'yup';

export type TAuth = {
  id: string;
  onContinue?: Function;
};

export type TAuthInjector = {
  [key: string]: TAuthConfig;
};

export type TAuthConfig = {
  name: string;
  path: string;
  header?: string;
  initial?: any;
  authStrings?: any;
  validations?: ObjectSchema;
  AuthComponent: React.FunctionComponent<TAuth & any>;
  disabled?: boolean;
  validateRender?: Function;
  cleanForm?: boolean;
  validAuthStates: string[];
};

export enum AuthState {
  SIGNED_IN = 'signedIn',
  SIGN_UP = 'signUp',
  CONFIRM_SIGN_UP = 'confirmSignUp',
  SIGN_IN = 'signIn',
  SIGNED_OUT = 'signedOut',
  SIGNED_UP = 'signedUp',
  FORGOT_PASSWORD = 'forgotPassword',
  SUBMIT_PASSWORD = 'submitPassword',
}
