import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Routes from '../constants/routes';
import { isLoggedInSelector } from '../selectors/auth';
interface IProps {
  component: any;
  key: string;
  path: string;
  exact?: boolean;
  children?: any;
}

const UnconnectedPrivateRoute = ({ component, ...rest }: IProps) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          return <Route component={component} {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: `${Routes.AUTH}/login`,
            }}
          />
        );
      }}
    />
  );
};

export default UnconnectedPrivateRoute;
