import React, { FunctionComponent, useState } from 'react';
import { Button, Col, Popover, Row } from 'antd';

import * as Styles from './Styles';
import solutions from '../../../constants/soutions';
import SVGIcon from '../SVGIcon/SVGIcon';

const SolutionsButton: FunctionComponent<{ initial?: boolean }> = ({ initial = false }) => {
  const [visible, setVisible] = useState(initial);
  const menu = (
    <Row>
      {solutions.map((item: any) => {
        return (
          <Col
            key={item.field}
            span={12}
            className={Styles.solutionMenu}
            onClick={() => {
              console.log(item.name);
            }}
          >
            <SVGIcon iconName={item.icon} width={item.width} height={item.height} className={Styles.solutionImage} />
          </Col>
        );
      })}
    </Row>
  );
  return (
    <>
      <Popover
        className={Styles.popoverStyle}
        overlayClassName={Styles.popoverStyle}
        placement="bottomRight"
        content={menu}
        trigger="click"
        visible={visible}
        onVisibleChange={() => setVisible(!visible)}
      >
        <Button icon="menu" />
      </Popover>
    </>
  );
};

export default SolutionsButton;
