import { css } from 'emotion';
import { Typography, Color } from '../../styles';

export const ParentStyle = css`
  font-family: ${Typography.GillSans};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  z-index: 99999 !important;
  background-color: white;
  border: 1px solid #dfe5f0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 50px;
  margin-top: 15px;
`;

export const PlaidStyle = css`
  background: ${Color.FineBlue}!important;
  border: 1px solid ${Color.FineBlue}!important;
  border-radius: 20px !important;
  font-family: ${Typography.GillSans} !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.162037px !important;
  color: ${Color.PureWhite} !important;
  height: 40px !ipmortant;
`;

export const CloseButtonStyle = css`
  color: #077cb6;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
  font-size: 12px;
  margin-right: 25px;
`;

export const HeaderStyle = css`
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  font-family: Gill sans-serif;
  text-align: center;
`;

export const ModalStyle = css`
  div.ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
`;
