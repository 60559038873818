import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  addressLine1Required: `${FormStrings[FormFields.addressLine1]} is required`,
  addressLine1Invalid: `${FormStrings[FormFields.addressLine1]} is invalid`,
  addressLine2Required: `${FormStrings[FormFields.addressLine2]} is required`,
  addressLine2Invalid: `${FormStrings[FormFields.addressLine2]} is invalid`,
  cityRequired: `${FormStrings[FormFields.city]} is required`,
  cityInvalid: `${FormStrings[FormFields.city]} is invalid`,
  stateRequired: `${FormStrings[FormFields.state]} is required`,
  stateInvalid: `${FormStrings[FormFields.state]} is invalid`,
  zipCodeRequired: `${FormStrings[FormFields.zipCode]} is required`,
  zipCodeInvalid: `${FormStrings[FormFields.zipCode]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.addressLine1]: Yup.string()
    .required(validationMessages.addressLine1Required)
    .max(100, validationMessages.addressLine2Invalid),
  [FormFields.addressLine2]: Yup.string().max(100, validationMessages.addressLine2Invalid),

  [FormFields.city]: Yup.string()
    .required(validationMessages.cityRequired)
    .max(100, validationMessages.cityInvalid),
  [FormFields.state]: Yup.string()
    .required(validationMessages.stateRequired)
    .notOneOf(['-1'], validationMessages.stateRequired),
  [FormFields.zipCode]: Yup.number()
    .required(validationMessages.zipCodeRequired)
    .moreThan(0, validationMessages.zipCodeInvalid)
    .lessThan(100000, validationMessages.zipCodeInvalid),
});

export default defaultValidation;
