import React, { useEffect } from 'react';
import { message } from 'antd';
import { Provider } from 'react-redux';
import _ from 'lodash';

import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import AppRoutes from './containers/Routes';
import DevTools from './containers/DevTools';

import store, { history, persistedStore } from './store';
import './App.css';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
/**
 * Application entrypoint
 */

const App: React.FC = () => {
  useEffect(() => {
    message.config({
      top: 15,
      duration: 7,
      maxCount: 1,
    });

    document.addEventListener('click', function(event: any) {
      if (event.target !== null) {
        if (event.target.classList.value.indexOf('ant-message-custom-content') >= 0) {
          message.destroy();
        }
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistedStore}>
        <Router history={history}>
          <AppRoutes />
        </Router>
        {process.env.NODE_ENV === 'development' && <DevTools />}
      </PersistGate>
    </Provider>
  );
};

export default App;
