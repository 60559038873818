import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  totalNetWorth: 'totalNetWorth',
  liquidNetWorth: 'liquidNetWorth',
};

export const FormFieldsTypes = {
  [FormFields.totalNetWorth]: reviewFieldTypes.SelectOptions,
  [FormFields.liquidNetWorth]: reviewFieldTypes.SelectOptions,
};

export const FormStrings = {
  [FormFields.totalNetWorth]: 'Total Net Worth',
  [FormFields.liquidNetWorth]: 'Liquid Net Worth',
};
