import { FormFields, FormStrings } from './constants';

const defaultSubtype = {
  label: FormStrings.selectSubtype,
  value: '-1',
};

export const AccountTypes = [
  {
    label: FormStrings.individual,
    value: 'individual',
  },
  {
    label: FormStrings.joint,
    value: 'joint',
  },
  {
    label: FormStrings.custodial,
    value: 'custodial',
  },
  {
    label: FormStrings.retirement,
    value: 'retirement',
  },
  {
    label: FormStrings.entity,
    value: 'entity',
  },
];

export const AccountTypesValues = {
  individual: 'individual',
  joint: 'joint',
  custodial: 'custodial',
  retirement: 'retirement',
  entity: 'entity',
};

export const AccountTypeOptions = {
  [FormFields.individual]: [
    defaultSubtype,
    {
      label: 'Individual',
      value: 'I',
    },
  ],
  [FormFields.joint]: [
    defaultSubtype,
    {
      label: 'Joint – Rights of Survivorship',
      value: 'J',
    },
    {
      label: 'Joint – Tenants in Common',
      value: 'K',
    },
    {
      label: 'Joint – Community Property',
      value: 'L',
    },
    {
      label: 'Joint – Tenants by Entirety',
      value: 'M',
    },
  ],
  [FormFields.retirement]: [
    defaultSubtype,
    {
      label: 'SIMPLE IRA',
      value: '9',
    },
    {
      label: 'SEP IRA',
      value: 'P',
    },
    {
      label: 'IRA Rollover',
      value: 'Q',
    },
    {
      label: 'Traditional IRA',
      value: 'R',
    },
    {
      label: 'Roth IRA',
      value: 'S',
    },
  ],
  [FormFields.entity]: [
    defaultSubtype,
    {
      label: 'Corporation',
      value: 'Corporation',
    },
    {
      label: 'Partnership',
      value: 'Partnership',
    },
    {
      label: 'Trust',
      value: 'Trust',
    },
    {
      label: 'LLC',
      value: 'LLC',
    },
    {
      label: 'Investment Club',
      value: 'Investment Club',
    },
    {
      label: 'Sole Proprietorship',
      value: 'Sole Proprietorship',
    },
  ],
};

const merged = [].concat.apply([], Object.values(AccountTypeOptions) as any);
export const FieldsOptions = { subtype: [...merged], type: [...AccountTypes] };

export const InitialValues = {
  [FormFields.type]: AccountTypesValues.individual,
  [FormFields.subtype]: '-1',
};

export const IndividualInitialValues = {
  [FormFields.type]: AccountTypesValues.individual,
};
