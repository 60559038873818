import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  firstName: 'firstName',
  middleInitial: 'middleInitial',
  lastName: 'lastName',
  birthday: 'birthday',
};
export const FormFieldsTypes = {
  [FormFields.firstName]: reviewFieldTypes.RawString,
  [FormFields.middleInitial]: reviewFieldTypes.RawString,
  [FormFields.lastName]: reviewFieldTypes.RawString,
  [FormFields.birthday]: reviewFieldTypes.DateString,
};

export const FormStrings = {
  [FormFields.firstName]: 'First Name',
  [FormFields.middleInitial]: 'Middle Initial',
  [FormFields.lastName]: 'Last Name',
  [FormFields.birthday]: 'Birthday',
};
