import { Col, Divider, message, Row, Spin } from 'antd';

import _, { get } from 'lodash';
import { connect } from 'react-redux';

import React, { useEffect, useState } from 'react';

import FormComponent from '../FormComponent';

import { alignLeft, alignRight, editFormDescription, headerTextModal, spinner, spinnerContainer } from '../../style';

import getFields from './fields';
import { getContactInformation, updateContactInformation } from '../../../../actions/index';
import validationSchema from './validations';

type ContactInformationProps = {
  contactInformation: any;
  contactInformationPatch?: boolean;
  error: string;
  getContactInformation: Function;
  updateContactInformation: Function;
};

const ContactInformationView: React.FC<ContactInformationProps> = ({
  contactInformation,
  contactInformationPatch,
  error,
  getContactInformation,
  updateContactInformation,
}: ContactInformationProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (_.isEmpty(contactInformation) && _.isEmpty(error) && !isFetching) {
      setIsFetching(true);
      getContactInformation();
    }
  }, []);

  useEffect(() => {
    if (!isSubmitting && isFetching) {
      setIsFetching(false);
    } else if (isSubmitting && isFetching) {
      setIsFetching(false);
      setIsSubmitting(false);
      setIsModalVisible(false);
      message.success({ content: 'Information successfully updated ' });
    }
    return () => {
      message.destroy();
    };
  }, [contactInformation]);

  useEffect(() => {
    if (isSubmitting) {
      if (contactInformationPatch === false) {
        setIsSubmitting(false);
        setIsModalVisible(false);

        message.error({ content: 'Something went wrong and the edited information could not be saved.' });
      } else if (contactInformationPatch === true) {
        // TODO: Comment out on real fetch
        // setIsFetching(true);
        // getContactInformation();

        // TODO: Remove on real fetch
        setIsSubmitting(false);
        setIsModalVisible(false);
        message.success({ content: 'Information successfully updated ' });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [contactInformationPatch]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      // Fetch Error
      if (!isSubmitting && isFetching) {
        setIsFetching(false);
        message.error({ content: error });
      }
      // Patch Error
      if (isSubmitting && !isFetching) {
        setIsSubmitting(false);
        setIsModalVisible(false);
        message.error({ content: error });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [error]);

  const onSubmit = async (values: any) => {
    let updatedData = { ...contactInformation, ...values };
    setIsSubmitting(true);
    await updateContactInformation(updatedData);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  if ((isFetching && !isSubmitting) || _.isEmpty(contactInformation)) {
    return (
      <div className={spinnerContainer}>
        <div className={spinner}>
          <Spin />
        </div>
      </div>
    );
  }

  const fields: any = getFields(contactInformation);

  return (
    <>
      <Row>
        <p className={headerTextModal}>Contact Information</p>
      </Row>

      <Row>
        <Col span={24}>
          <p className={editFormDescription}>
            In order to help keep your information secure we don’t allow updates to the name or email address associated
            with your account. If you need to update this information please{' '}
            <a href="#" style={{ color: '#0098CE' }}>
              contact us
              {/* TODO: Add proper redirect here */}
            </a>{' '}
            directly so we can assist you in the process.
          </p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <span className={alignLeft} style={{ color: '#989898' }}>
            Contact Information
          </span>
        </Col>

        <Col span={12}>
          {!_.isEmpty(contactInformation) && (
            <a
              className={alignRight}
              type="primary"
              onClick={() => setIsModalVisible(true)}
              style={{ color: '#0098CE' }}
            >
              Edit
            </a>
          )}
        </Col>
        <Divider />
      </Row>

      {Object.keys(fields).map(key => {
        return (
          <Row key={`${key}_item`}>
            <Col span={12}>
              <span className={alignLeft}>{fields[key].props.label}</span>
            </Col>

            <Col span={12}>
              <span className={alignRight}>{_.isEmpty(fields[key].displayValue) ? '-' : fields[key].displayValue}</span>
            </Col>
            <Divider />
          </Row>
        );
      })}
      <FormComponent
        isModalVisible={isModalVisible}
        fields={fields}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        onCancel={onCancel}
        section={{
          sectionLabel: 'Contact Information',
          validationSchema: validationSchema,
          sectionDescription: (
            <>
              {' '}
              In order to help keep your information secure we don’t allow updates to the name or email address
              associated with your account. If you need to update this information please{' '}
              <a href="#" style={{ color: '#0098CE' }}>
                contact us
                {/* TODO: Add proper redirect here */}
              </a>{' '}
              directly so we can assist you in the process.
            </>
          ),
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  contactInformation: get(state, 'account.contactInformation'),
  contactInformationPatch: get(state, 'account.contactInformationPatch'),
  error: get(state, 'account.error'),
});

const mapDispatchToProps = {
  getContactInformation,
  updateContactInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformationView);
