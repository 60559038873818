import React, { useEffect } from 'react';
import * as Typography from '../../../../styles/typography';
import ExpandableReview from '../../../shared/ExpandableReview/ExpandableReview';
import ReviewDetail from './ReviewDetail';
import { produceReviewDetailRow } from '../../../../utils/reviewFieldTransformers';
import { FormFields, FormStrings } from './constants';
import { filterBeneficiariesForReview } from './helpers';
import * as Styles from './Style';
import { useDispatch, useSelector } from 'react-redux';
import * as FormActions from '../../../../actions';

export type TAuthorizedPerson = {
  state: any;
};

const AuthorizedPersonReview = (props: TAuthorizedPerson) => {
  const { state } = props;
  const dispatch = useDispatch();

  if (state.authoriszedPersons && state.authoriszedPersons.length === 0) {
    return <></>;
  } else {
    const updateAuthorizedPersonIntermediary = () => {
      dispatch(
        FormActions.saveEntityAuthorisedPersonIntermediaryFormState({
          body: state.authorizedPersons ? [...state.authorizedPersons] : [],
        }),
      );
    };

    const persons =
      state.authorizedPersons &&
      state.authorizedPersons.map((item: any) => {
        return <ReviewDetail label={item.firstName + ' ' + item.lastName} sub={false} value={''} />;
      });

    return (
      <>
        <p className={Typography.CategoryReviewText}>{FormStrings.authorizedPersonText}</p>
        <hr className={Typography.hr} />
        <ExpandableReview
          id={FormFields.authorizedPersonEdit}
          title={FormStrings.authorizedPersonText}
          onEdit={updateAuthorizedPersonIntermediary}
        >
          {persons}
        </ExpandableReview>
      </>
    );
  }
};

export default AuthorizedPersonReview;
