import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';
import formMetadata from './formMetadata';

const name = 'tradingBlockProducts';
const path = 'tradingBlockProducts';
const header = 'TradingBlock Products';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  twoSideFormMetadata: formMetadata(header),
};

export default config;
