import produce from 'immer';
import { requestedDefaults, succeededDefaults, failedDefaults } from './utils';
import { Type, State } from '../actions/utils';
import { actionSucceeded } from '../actions/types';
import initialState from './initial';

export const login = (state: any = initialState.login, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CLEAR_LOGIN):
      return produce((draftState: any) => {
        draftState.error = null;
      })(state);
    case State.actionRequested(Type.LOGIN):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.LOGIN):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.LOGIN):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const register = (state: any = initialState.register, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CLEAR_REGISTRATION):
      return produce((draftState: any) => {
        draftState.error = null;
        draftState.__suceeded = null;
      })(state);
    case State.actionRequested(Type.REGISTER):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.REGISTER):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.REGISTER):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const confirmEmail = (state: any = initialState.confirmEmail, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CONFIRM_EMAIL):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CONFIRM_EMAIL):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CONFIRM_EMAIL):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const forgotPassword = (state: any = initialState.forgotPassword, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CLEAR_FORGOT_PASSWORD):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CLEAR_FORGOT_PASSWORD):
      return produce((draftState: any) => {
        draftState.error = null;
      })(state);
    case State.actionFailed(Type.CLEAR_FORGOT_PASSWORD):
      return failedDefaults(state, action);
    case State.actionRequested(Type.FORGOT_PASSWORD):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.FORGOT_PASSWORD):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.FORGOT_PASSWORD):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const changePassword = (state: any = initialState.changePassword, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CHANGE_PASSWORD):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CHANGE_PASSWORD):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CHANGE_PASSWORD):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const authInfo = (state: any = initialState.authInfo, action: any) => {
  switch (action.type) {
    case actionSucceeded(Type.SET_AUTH_TOKEN):
      return produce((draftState: any, action?: any) => {
        draftState.token = action.payload;
      })(state, action);
    case actionSucceeded(Type.REMOVE_AUTH_TOKEN):
      return produce((draftState: any) => {
        draftState.token = null;
      })(state);
    default:
      return state;
  }
};
