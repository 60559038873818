import { css } from 'emotion';
import * as Colors from './color';

export const CormorantGaramond: string = 'Cormorant Garamond';
export const GillSans: string = 'Gill Sans';

export const Header = css`
  font-family: ${CormorantGaramond} !important;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.35px;
  color: ${Colors.DeepBlack};
`;

export const Header2 = css`
  font-family: ${CormorantGaramond} !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.286364px;
  color: ${Colors.DeepBlack};
`;

export const Paragraph = css`
  font-family: ${GillSans} !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15873px;
  color: #c4c4c4;
`;

export const Body = css`
  font-family: ${GillSans} !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15873px;
  color: #423c3a;
`;

export const InputLabel = css`
  font-family: ${GillSans} !important;
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: ${Colors.DeepBlack};
`;

export const InputText = css`
  font-family: ${GillSans} !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.25px !important;
  color: ${Colors.DeepBlack} !important;
`;

export const ButtonText = css`
  font-family: ${GillSans} !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.162037px !important;
  color: ${Colors.PureWhite} !important;
`;

export const Link = css`
  font-family: ${GillSans} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${Colors.FineBlue} !important;
`;

export const LinkClickable = css`
  cursor: pointer;
  font-family: ${GillSans} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${Colors.FineBlue} !important;
`;

export const FormText = css`
  font-family: ${GillSans} !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.15873px !important;
  color: ${Colors.DarkerMediumGrey} !important;
`;

export const hr = css`
  border: 1px solid #e9e9e9 !important;
  transform: rotate(180deg) !important;
`;

export const CategoryReviewText = css`
  font-family: ${CormorantGaramond} !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.185185px !important;
  color: ${Colors.DarkerMediumGrey} !important;
  margin-bottom: 3px !important;
`;
