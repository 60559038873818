import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import React from 'react';

export const validationMessages = {
  countryRequired: `${FormStrings[FormFields.country]} is required`,
  countryInvalid: `${FormStrings[FormFields.country]} is invalid, only US allowed`,
};

export const defaultValidation = (solutions: any) => {
  if (Object.keys(solutions).length === 1 && solutions[0] === 'tradingBlock') {
    return Yup.object().shape({
      [FormFields.country]: Yup.string().required(validationMessages.countryRequired),
    });
  } else {
    return Yup.object().shape({
      [FormFields.country]: Yup.string()
        .required(validationMessages.countryRequired)
        .oneOf(['us'], validationMessages.countryInvalid),
    });
  }
};
export default defaultValidation;
