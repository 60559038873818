import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  firstname: 'firstname',
  middleInitial: 'middleInitial',
  lastname: 'lastname',
  phonenumber: 'phonenumber',
};

export const FormFieldsTypes = {
  [FormFields.firstname]: reviewFieldTypes.RawString,
  [FormFields.middleInitial]: reviewFieldTypes.RawString,
  [FormFields.lastname]: reviewFieldTypes.RawString,
  [FormFields.phonenumber]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.firstname]: 'First Name',
  [FormFields.middleInitial]: 'Middle Initial',
  [FormFields.lastname]: 'Last Name',
  [FormFields.phonenumber]: 'Phone Number',
};
