import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  taxIDRequired: `${FormStrings[FormFields.taxId]} is required`,
  taxIDInvalid: `${FormStrings[FormFields.taxId]} is invalid`,
  taxIDTypeRequired: `${FormStrings[FormFields.taxIdType]} is required`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.taxIdType]: Yup.string()
    .required(validationMessages.taxIDTypeRequired)
    .notOneOf(['-1'], validationMessages.taxIDTypeRequired),
  [FormFields.taxId]: Yup.string()
    .when([FormFields.taxIdType], {
      is: 'TIN',
      then: Yup.string().matches(
        /^(9\d{2})([ \-]?)([7]\d|8[0-8])([ \-]?)(\d{4}|XXX-XX-XXXX)$/,
        validationMessages.taxIDInvalid,
      ),
    })
    .when([FormFields.taxIdType], {
      is: 'SSN',
      then: Yup.string().matches(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/, validationMessages.taxIDInvalid),
    })
    .required(validationMessages.taxIDRequired),
});

export default defaultValidation;
