import { applyMiddleware, createStore, compose, StoreEnhancer, Middleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import createSagaMonitor from '@clarketm/saga-monitor';

import reducer from './reducers';
import initialRootReducer from './reducers/initial';
import sagas from './sagas';
// import DevTools from './containers/DevTools';

export const history = createBrowserHistory();

/**
 * React router reducer
 */
export const rootReducer = combineReducers({
  ...reducer,
  router: connectRouter(history),
});

/**
 * Redux persistance configuration
 */
const persistConfig = {
  key: 'cambriaconnectwebfunnel',
  storage,
  blacklist: [
    // 'register'
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Saga middleware configuration
 */
const sagaMonitorConfig = {
  level: 'log',
  effectTrigger: true,
  effectResolve: true,
  actionDispatch: true,
};

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: createSagaMonitor(sagaMonitorConfig),
});

/**
 * Definition of cross-environment middlewares
 */
const middleware: Middleware[] = [sagaMiddleware, routerMiddleware(history)];

/**
 * Development environment middlewares
 */
if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({ collapsed: true }));
}

/**
 * Middleware enhancer composition
 * TODO: If want to use DevTools container switch comments
 */
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose;

const enchancer: StoreEnhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // DevTools.instrument(),
);

/**
 * Create redux-store with
 * - reducers
 * - initialReducers
 * - enhancers
 */
const store = createStore(persistedReducer, enchancer);

/**
 * Run all sagas
 */
if (sagas && sagas.length)
  sagas.forEach(saga => {
    sagaMiddleware.run(saga);
  });

export const persistedStore = persistStore(store);
export const initialReducer = initialRootReducer;

export default store;
