import React from 'react';
import { Form, Checkbox as AntCheckbox } from 'antd';

import './Checkbox.css';

export type CheckboxCompType = {
  id?: string;
  name: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  errors?: any;
  setFieldValue: Function;
  onChange?: Function;
};

const Checkbox = ({ id, name, label, checked, disabled, errors, setFieldValue, onChange }: CheckboxCompType) => (
  <Form.Item
    hasFeedback={!!errors && !!errors[name]}
    validateStatus={!!errors && errors[name] && 'error'}
    help={!!errors && errors[name]}
    colon={false}
  >
    <AntCheckbox
      id={!!id ? id : name}
      name={name}
      defaultChecked={checked}
      onChange={event => {
        setFieldValue(name, event.target.checked);
        if (onChange) onChange(event.target.checked);
      }}
      disabled={disabled}
    >
      {label}
    </AntCheckbox>
  </Form.Item>
);

export default Checkbox;
