import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Routes from '../../../../../constants/routes';

import { FieldArray, Formik } from 'formik';
import Button, { ButtonType } from '../../../../shared/Buttons/Button';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';
import * as Styles from './Style';
import * as FormActions from '../../../../../actions';

import JointAccountDetail from '../JointAccountDetail';

import { ContinueIntermediaryJointAccountAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import { Button as AntButton, Col, Row } from 'antd';
import { Trash, Edit } from './../../../../shared/Icon/Index';
import { saveJointAccountFormState } from '../../../../../actions/forms';
import UploadIdentification from '../../UploadIdentification';
import InfoText from '../../../../shared/InfoText/InfoText';

const Component = (props: TForm) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.intermediary.jointAccountsIntermediary);
  const formState = getFormState(state, InitialValues);

  const onAddingJointAccountState = (state: boolean) => {
    dispatch(
      FormActions.addingJointAccountState({
        body: state,
      }),
    );
  };
  const onSubmit = (values: any) => {
    onAddingJointAccountState(false);
    dispatch(
      saveJointAccountFormState({
        id: FormFields.jointAccounts,
        body: state,
      }),
    );
    if (props.onContinue) props.onContinue();
    // const route = `${Routes.REGISTER_INFO}/${UploadIdentification.name}`;
    // history.push(route);
  };
  const onAddJointAccountClick = () => {
    onAddingJointAccountState(true);
    const route = `${Routes.REGISTER_INFO}/${JointAccountDetail.name}`;
    history.push(route);
  };

  const editJointAccount = (values: any) => {
    const route = `${Routes.REGISTER_INFO}/${JointAccountDetail.name}`;
    onAddingJointAccountState(true);
    history.push(route, { ...values, id: values.id } as any);
  };

  const removeJointAccount = (id: number) => {
    dispatch(
      FormActions.deleteJointAccountsIntermediaryFormState({
        id: id,
        body: { id: id },
      }),
    );
  };
  /*
   * Remove from store the beneficiaries created in tentative.
   */
  // useMemo(() => {
  //   state.map((item: any, index: number) => {
  //     if (!item.id || item.id === '') {
  //       removeJointAccount(item.id);
  //     }
  //   });
  // }, [state.length]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ [FormFields.jointAccounts]: formState }}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <FieldArray name={FormFields.jointAccounts} validateOnChange>
              {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                return values[FormFields.jointAccounts].map((item: any, index: number) => (
                  <Row className={Styles.MarginBottom8}>
                    <Col span={14} className={Styles.JointAccounts}>
                      {`${
                        item.accountOwnerInformation && item.accountOwnerInformation.firstname
                          ? item.accountOwnerInformation.firstname
                          : ''
                      } ${
                        item.accountOwnerInformation && item.accountOwnerInformation.lastname
                          ? item.accountOwnerInformation.lastname
                          : ''
                      }`}
                    </Col>
                    <Col span={10}>
                      <AntButton
                        className={Styles.TrashButton}
                        size="small"
                        onClick={() => removeJointAccount(item.id)}
                      >
                        <Trash width="14" height="16" viewBox="0 0 14 16" fill="#FF244E" />
                      </AntButton>
                      <AntButton
                        className={Styles.EditButton}
                        size="small"
                        onClick={() => {
                          editJointAccount(item);
                        }}
                      >
                        <Edit width="14" height="16" viewBox="0 0 14 16" fill="#0098CE" />
                      </AntButton>
                    </Col>
                  </Row>
                ));
              }}
            </FieldArray>
            {!form.isValid && <InfoText>{form.errors[FormFields.jointAccounts] as any}</InfoText>}
            <Button
              label="Click here to add additional joint account"
              type={ButtonType.Link}
              onClick={() => onAddJointAccountClick()}
              disabled={false}
              className={Styles.AddJointAccounts}
            />
            {console.log(form.values)}
            <ContinueIntermediaryJointAccountAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
