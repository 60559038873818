import { css } from 'emotion';

export const errorText = css`
  color: red;
`;

export const SignInTextCSS = css`
  margin-top: 24px;
  text-align: center;
`;

export const ForgotYourPasswordLink = css`
  margin-bottom: 24px;
  text-align: right;
`;

export const signUpTextCSS = css`
  margin-top: 24px;
  text-align: center;
`;

export const resent = css`
  color: blue;
`;
