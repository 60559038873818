import { css, cx } from 'emotion';
import { Typography, Color } from './../../styles';

export const Container = css`
  margin: 8px 0px !important;
  padding: 0 7% !important;
  display: inline-block !important;
  width: 100% !important;
  background-color: ${Color.OffWhite} !important;
`;

export const SolutionsContainer = css`
  font-family: ${Typography.GillSans};
  width: 100%;
  background: ${Color.PureWhite};
  border: 1px solid ${Color.FogBlue};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 40px 10px;
`;

export const ExternalSolutionsContainer = css`
  font-family: ${Typography.GillSans};
  width: 100%;
  padding: 40px 10px;
  text-align: center;
`;

export const Greetings = css`
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.35px;
  color: ${Color.TropicalBlue};
  margin: 8px 0;
`;

export const BlockHelper = css`
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.35px;
  color: ${Color.DeepBlack};
  margin: 8px 0;
`;

export const BlockHelperExternal = css`
  font-family: ${Typography.GillSans};
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.35px;
  color: ${Color.DarkGrey};
  margin: 8px 0;
`;

export const HelperExternal = css`
  font-family: ${Typography.GillSans};
  font-weight: 300 !important;
  padding-top: 15px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.254546px;
  color: ${Color.DeepBlack};
  margin: 8px 0;
`;

export const CardCenter = css`
  max-width: 192px !important;
  margin-bottom: 10px;
`;

export const RowSolutions = css`
  margin: 32px 0 !important;
`;

export const RowExternalSolutions = css`
  margin: 17px 0 !important;
`;

export const OrCondition = css`
  font-family: ${Typography.GillSans} !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  letter-spacing: 0.254546px !important;
  color: ${Color.DeepBlack} !important;
  margin-bottom: 24px !important;
`;
