import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', height = '100%', className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6.15803 0.0952606L6.68836 0.631785C6.81388 0.758769 6.81388 0.964664 6.68836 1.09167L2.53309 5.29545H11.6786C11.8561 5.29545 12 5.44105 12 5.62063V6.37939C12 6.55897 11.8561 6.70457 11.6786 6.70457H2.53309L6.68836 10.9084C6.81388 11.0354 6.81388 11.2413 6.68836 11.3683L6.15803 11.9048C6.03251 12.0317 5.82899 12.0317 5.70345 11.9048L0.0941385 6.22994C-0.0313795 6.10296 -0.0313795 5.89706 0.0941385 5.77005L5.70345 0.0952339C5.82897 -0.0317497 6.03248 -0.0317497 6.15803 0.0952606Z"
    />
  </svg>
);

export default SVG;
