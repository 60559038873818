import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FieldArray, Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';
import * as Styles from './Style';

import { ContinueIntermediaryEntityAuthorizedAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import { UploadPersonalId } from './UploadPersonalId';
import * as FormActions from '../../../../../actions';
import { object } from 'yup';

const Component = (props: TForm) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.intermediary.entityAuthorizedPersonsIntermediary);

  const formState = getFormState(
    {
      [FormFields.authorizedPersonsId]: state,
    },
    InitialValues,
  );

  const onSubmit = (values: any) => {
    dispatch(
      FormActions.saveEntityFormState({
        id: FormFields.authorizedPersons,
        body: values[FormFields.authorizedPersonsId],
      }),
    );
    if (props.onContinue) props.onContinue(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
      validateOnChange={true}
    >
      {form => {
        return (
          <>
            <FieldArray name={FormFields.authorizedPersonId} validateOnChange>
              {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                return values[FormFields.authorizedPersonsId].map((item: any, index: number) => (
                  <UploadPersonalId
                    key={index}
                    index={index}
                    item={item}
                    form={form}
                    name={`${item.firstName} ${item.lastName}`}
                  />
                ));
              }}
            </FieldArray>

            <div className={Styles.MarginCheckBox}>
              <Checkbox
                {...form}
                name={FormFields.uploadLater}
                label={FormStrings.uploadLater}
                checked={
                  formState[FormFields.uploadLater] ? formState[FormFields.uploadLater] : InitialValues.uploadLater
                }
              />
            </div>
            <ContinueIntermediaryEntityAuthorizedAction
              id={props.id}
              disabled={!form.isValid}
              values={{ ...form.values }}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
