import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { InvestmentObjectives } from './schema';
import { EmploymentStatusValues } from '../EmploymentInfo/schema';

export const validationMessages = {
  iPOsAndOfferingsRequired: `${FormStrings[FormFields.iPOsAndOfferings]} is required`,
  iPOsAndOfferingsInvalid: `${FormStrings[FormFields.iPOsAndOfferings]} must be ${InvestmentObjectives[4].label} or ${
    InvestmentObjectives[5].label
  }`,
  crowdFundingRegCFRequired: `${FormStrings[FormFields.crowdFundingRegCF]} is required`,
  crowdFundingRegCFInvalid: `${FormStrings[FormFields.crowdFundingRegCF]} must be ${InvestmentObjectives[5].label}`,
  discountTradingRequired: `${FormStrings[FormFields.discountTrading]} is required`,
  discountTradingInvalid: `${FormStrings[FormFields.discountTrading]} must be ${InvestmentObjectives[5].label}`,
  managedAccountsRequired: `${FormStrings[FormFields.managedAccounts]} is required`,
  managedAccountsInvalid: `${FormStrings[FormFields.managedAccounts]} is invalid`,
};

export const conditionValidation = (fieldName: any, uncoveredCallsIndexOptions: boolean) => {
  switch (fieldName) {
    case FormFields.iPOsAndOfferings:
      return {
        key: FormFields.iPOsAndOfferings,
        value: Yup.string()
          .required(validationMessages.iPOsAndOfferingsRequired)
          .notOneOf(['-1'], validationMessages.iPOsAndOfferingsRequired)
          .oneOf(
            [InvestmentObjectives[4].value, InvestmentObjectives[5].value],
            validationMessages.iPOsAndOfferingsInvalid,
          ),
      };
    case FormFields.crowdFundingRegCF:
      return {
        key: [FormFields.crowdFundingRegCF],
        value: Yup.string()
          .required(validationMessages.crowdFundingRegCFRequired)
          .notOneOf(['-1'], validationMessages.crowdFundingRegCFRequired)
          .oneOf([InvestmentObjectives[5].value], validationMessages.crowdFundingRegCFInvalid),
      };
    case FormFields.discountTrading:
      return {
        key: [FormFields.discountTrading],
        value: Yup.string()
          .required(validationMessages.discountTradingRequired)
          .notOneOf(['-1'], validationMessages.discountTradingRequired)
          .when(FormFields.crowdFundingRegCF, {
            is: value => uncoveredCallsIndexOptions === true,
            then: Yup.string()
              .required(validationMessages.discountTradingRequired)
              .oneOf([InvestmentObjectives[5].value], validationMessages.discountTradingInvalid),
          }),
      };
    case FormFields.managedAccounts:
      return {
        key: [FormFields.managedAccounts],
        value: Yup.string()
          .required(validationMessages.managedAccountsRequired)
          .notOneOf(['-1'], validationMessages.managedAccountsRequired),
      };
    default:
      return null;
  }
};

export const defaultValidation = (formState: any, uncoveredCallsIndexOptions: boolean) =>
  formState
    ? Yup.object().shape(
        Object.keys(formState)
          .map((key: any) => conditionValidation(key, uncoveredCallsIndexOptions))
          .reduce((obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }), {}),
      )
    : Yup.object().shape({});

export default defaultValidation;
