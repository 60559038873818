import * as AuthActions from './auth';
import * as AccountActions from './account';
import * as FormActions from './forms';
import * as IntermediaryActions from './intermediary';
import * as NavigationActions from './navigation';
import * as PlaidBalanceActions from './plaidSettings';
import * as UserManagementActions from './userManagement';

/**
 * Authentication
 */
export const login = AuthActions.login;
export const clearLogin = AuthActions.clearLogin;
export const logout = AuthActions.logout;
export const register = AuthActions.register;
export const clearRegister = AuthActions.clearRegister;
export const confirmEmail = AuthActions.confirmEmail;
export const resetPassword = AuthActions.resetPassword;
export const forgotPassword = AuthActions.forgotPassword;
export const clearForgotPassword = AuthActions.clearForgotPassword;
export const changePassword = AuthActions.changePassword;
export const setAuthToken = AuthActions.setAuthToken;

/**
 * Account forms
 */
export const saveAccount = AccountActions.saveAccount;

/**
 * Forms
 */
export const saveFormState = FormActions.saveFormState;
export const saveRetirementFormState = FormActions.saveRetirementFormState;
export const saveEntityFormState = FormActions.saveEntityFormState;

/**
 * Intermediary
 */
export const saveRetirementIntermediaryFormState = IntermediaryActions.saveRetirementIntermediaryFormState;
export const deleteRetirementIntermediaryFormState = IntermediaryActions.deleteRetirementIntermediaryFormState;
export const saveEntityAuthorisedPersonIntermediaryFormState =
  IntermediaryActions.saveEntityAuthorisedPersonIntermediaryFormState;
export const deleteEntityAuthorisedPersonIntermediaryFormState =
  IntermediaryActions.deleteEntityAuthorisedPersonIntermediaryFormState;
export const addJointAccountsIntermediaryFormState = IntermediaryActions.addJointAccountsIntermediaryFormState;
export const deleteJointAccountsIntermediaryFormState = IntermediaryActions.deleteJointAccountsIntermediaryFormState;

/**
 * Navigation
 */
export const addBackToReviewState = NavigationActions.addBackToReview;
export const addingJointAccountState = NavigationActions.addingJointAccount;

/**
 * PlaidBalance
 */
export const getPlaidBalance = PlaidBalanceActions.getPlaidBalance;

/**
 * UserManagement
 */
export const getContactInformation = UserManagementActions.getContactInformation;
export const updateContactInformation = UserManagementActions.updateContactInformation;
export const getPersonalInformation = UserManagementActions.getPersonalInformation;
export const updatePersonalInformation = UserManagementActions.updatePersonalInformation;
export const getAccountInformation = UserManagementActions.getAccountInformation;
export const updateAccountInformation = UserManagementActions.updateAccountInformation;
