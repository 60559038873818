import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  stateOfResidencyRequired: `${FormStrings[FormFields.stateOfResidency]} is required`,
  stateOfResidencyInvalid: `${FormStrings[FormFields.stateOfResidency]} is invalid`,
  citizenshipRequired: `${FormStrings[FormFields.citizenship]} is required`,
  citizenshipInvalid: `${FormStrings[FormFields.citizenship]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.stateOfResidency]: Yup.string()
    .required(validationMessages.stateOfResidencyRequired)
    .notOneOf(['-1'], validationMessages.stateOfResidencyRequired),
  [FormFields.citizenship]: Yup.string()
    .required(validationMessages.citizenshipRequired)
    .notOneOf(['-1'], validationMessages.citizenshipRequired),
});

export default defaultValidation;
