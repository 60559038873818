import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  firstName: 'firstName',
  middleInitial: 'middleInitial',
  lastName: 'lastName',
};

export const FormFieldsTypes = {
  [FormFields.firstName]: reviewFieldTypes.RawString,
  [FormFields.middleInitial]: reviewFieldTypes.RawString,
  [FormFields.lastName]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.firstName]: 'First Name',
  [FormFields.middleInitial]: 'Middle Initial',
  [FormFields.lastName]: 'Last Name',
};
