import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../../types/commonTypes';

export const BeneficiaryTypes = [
  {
    label: FormStrings.individual,
    value: FormFields.individual,
  },
  {
    label: FormStrings.trust,
    value: FormFields.trust,
  },
];

export const InitialValues = {
  [FormFields.type]: '',
};
