import * as Yup from 'yup';

const validationMessages = {
  addressLineOneRequired: 'Address Line One is required',
  addressLineOneInvalid: 'This field must be less than 100 characters',
  addressLineTwoInvalid: 'This field must be less than 100 characters',
  cityRequired: 'City is required',
  cityInvalid: 'This field must be less than 100 characters',
  stateRequired: 'State is required',
  zipCodeRequired: 'Zip Code is required',
  zipCodeInvalid: 'Zip Code value should be between 0 and 100000',
  citizenshipRequired: 'Citizenship is required',
  countryRequired: 'Country is required',
  cardIdRequired: 'An image needs to be uploaded',
};

export const physicalAddressValidation = Yup.object().shape({
  physicalAddressAddressLineOne: Yup.string()
    .required(validationMessages.addressLineOneRequired)
    .max(100, validationMessages.addressLineOneInvalid),
  physicalAddressAddressLineTwo: Yup.string()
    .max(100, validationMessages.addressLineTwoInvalid)
    .nullable(),
  physicalAddressCity: Yup.string()
    .required(validationMessages.cityRequired)
    .max(100, validationMessages.cityInvalid),
  physicalAddressState: Yup.string()
    .required(validationMessages.stateRequired)
    .notOneOf(['-1'], validationMessages.stateRequired)
    .nullable(),
  physicalAddressZipCode: Yup.number()
    .required(validationMessages.zipCodeRequired)
    .moreThan(0, validationMessages.zipCodeInvalid)
    .lessThan(100000, validationMessages.zipCodeInvalid),
  physicalAddressCitizenship: Yup.string()
    .required(validationMessages.citizenshipRequired)
    .notOneOf(['-1'], validationMessages.citizenshipRequired)
    .nullable(),
});

export const mailingAddressValidation = Yup.object().shape({
  mailingAddressAddressLineOne: Yup.string()
    .required(validationMessages.addressLineOneRequired)
    .max(100, validationMessages.addressLineOneInvalid),
  mailingAddressAddressLineTwo: Yup.string()
    .max(100, validationMessages.addressLineTwoInvalid)
    .nullable(),
  mailingAddressCity: Yup.string()
    .required(validationMessages.cityRequired)
    .max(100, validationMessages.cityInvalid),
  mailingAddressState: Yup.string()
    .required(validationMessages.stateRequired)
    .notOneOf(['-1'], validationMessages.stateRequired)
    .nullable(),
  mailingAddressZipCode: Yup.number()
    .required(validationMessages.zipCodeRequired)
    .moreThan(0, validationMessages.zipCodeInvalid)
    .lessThan(100000, validationMessages.zipCodeInvalid),
});

export const countryOfResidentsValidation = Yup.object().shape({
  countryOfResidence: Yup.string().required(validationMessages.countryRequired),
});

export const cardIdValidation = Yup.object().shape({
  cardId: Yup.string()
    .nullable()
    .required(validationMessages.cardIdRequired),
});
