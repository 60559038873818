import React, { useState } from 'react';
import { Button, Form, Icon } from 'antd';
import { FormItemProps } from 'antd/es/form';
import * as Styles from './Style';
import Dragger from 'antd/lib/upload/Dragger';
import _ from 'lodash';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

export type UploadDraggableProps = {
  name: string;
  errors?: any;
  value?: string;
  uploadHint?: string;
  multiple?: boolean;
  listType: 'text' | 'picture' | 'picture-card' | undefined;
  showUploadList?: boolean;
  changeFileText?: string;
  setFieldValue: Function;
  onChangeHook?: Function;
};

const UploadDraggable = (props: UploadDraggableProps) => {
  const {
    name,
    value,
    listType,
    showUploadList,
    uploadHint,
    multiple,
    errors,
    changeFileText,
    setFieldValue,
    onChangeHook,
  } = props;
  const [fileBase64OrUrl, setFileBase64OrUrl] = useState(value);

  const beforeUpload = (file: RcFile) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      setFieldValue(name, e.target.result);
      if (onChangeHook) {
        onChangeHook(e.target.result);
      }
      setFileBase64OrUrl(e.target.result);
    };
    return false;
  };

  const reset = () => {
    setFieldValue(name, '');
    if (onChangeHook) {
      onChangeHook('');
    }
    setFileBase64OrUrl('');
  };

  const uploadProps = {
    name,
    multiple,
    listType,
    showUploadList,
    beforeUpload,
  };

  const formItemProps: FormItemProps = {
    validateStatus: !!errors && errors[name] && 'error',
    help: !!errors && errors[name],
    colon: false,
  };

  return (
    <Form.Item {...formItemProps} className={Styles.UploadDraggableFormItem}>
      {!_.isEmpty(fileBase64OrUrl) ? (
        <div style={{ display: 'block', position: 'relative' }}>
          <div>
            <img src={fileBase64OrUrl} alt="uploaded_image" style={{ width: '100%' }} />
          </div>
          <div className={Styles.Overlay}>
            <div style={{ height: '100%', display: 'block', textAlign: 'center', padding: '16px 0px' }}>
              <Button type="primary" style={{ top: 'calc(50% - 16px)', backgroundColor: '#0098ce' }} onClick={reset}>
                <Icon type="sync" />
                {changeFileText}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Dragger {...uploadProps} style={{ cursor: 'pointer' }}>
          <div>
            <p>{uploadHint}</p>
            <br />
            <Button style={{ border: 'none', backgroundColor: 'rgba(255,255,255,.3)' }} className={Styles.BrowseButton}>
              <span style={{ color: '#077cb6' }}>Browse...</span>
            </Button>
          </div>
        </Dragger>
      )}
    </Form.Item>
  );
};

export default UploadDraggable;
