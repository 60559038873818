import React, { useState } from 'react';
import { Button, Row, Col, Popover, message } from 'antd';
import * as Styles from './Styles';
import SVGIcon from '../SVGIcon/SVGIcon';
import { removeSolution, removeSubSolution } from '../../../actions/solutions';
import { useDispatch } from 'react-redux';
import { Trash } from '../Icon/Index';

interface ActionItemProps {
  id: string;
  solutionName: string;
  solutionCode: string;
  icon?: string;
  isPrimary?: boolean;
  solutions?: any;
}

const ActionItem: React.FC<ActionItemProps> = (props, { ...shared }) => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const { id, solutionName, icon, isPrimary, solutionCode, solutions } = props;
  const style = {
    margin: '8px 0px',
    padding: '0',
    display: 'inline-block',
    width: '100%',
  };

  const onDelete = () => {
    if (isPrimary) {
      if (solutions && solutions.length > 1) {
        dispatch(
          removeSolution({
            solutionCode: solutionCode,
          }),
        );
      } else {
        message.info('In order to continue the account creation, at least one solution is required.');
      }
    } else {
      dispatch(
        removeSubSolution({
          solutionCode: solutionCode,
        }),
      );
    }
  };

  const popOverConfirmContent = () => {
    return (
      <Row style={{ maxWidth: '290px' }} gutter={[8, 16]}>
        <Col span={24}>
          <span>
            Are you sure you want to delete <b>{solutionName}</b>?
          </span>
        </Col>
        <Col span={8}>
          <Button
            className={Styles.deleteButton}
            onClick={() => {
              onDelete();
              setVisible(!visible);
            }}
          >
            Delete
          </Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => setVisible(!visible)} className={Styles.cancelButton}>
            Cancel
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div style={style}>
        <span className={isPrimary ? Styles.Primary : Styles.Secondary}>
          {solutionName}
          {isPrimary && <SVGIcon height={16} className={Styles.IconStyle} iconName={icon ? icon : ''} />}
        </span>
        <Popover
          content={popOverConfirmContent()}
          trigger="click"
          placement="bottom"
          visible={visible}
          onVisibleChange={() => setVisible(!visible)}
        >
          <Button className={Styles.TrashButton} size="small" onClick={() => setVisible(true)}>
            <Trash width="14" height="16" viewBox="0 0 14 16" fill="#FF244E" />
          </Button>
        </Popover>
      </div>
    </>
  );
};
export default ActionItem;
