export const FormFields = {
  sharePercentage: 'sharePercentage',
  percentagePrimary: 'percentagePrimary',
  percentageContingent: 'percentageContingent',
};

export const FormStrings = {
  [FormFields.sharePercentage]: 'Share Percentage ( 0.1 - 100 )',
  [FormFields.percentagePrimary]: 'Total Primary Beneficiary Share Percentage',
  [FormFields.percentageContingent]: 'Total Contingent Beneficiary Share Percentage',
};
