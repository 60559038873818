import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';
import { getRangeOptions } from './helpers';

export const AnnualExpensesOptions: TSelectOption[] = [
  {
    label: 'None',
    value: '-1',
  },
  ...getRangeOptions(),
];

export const SpecialExpensesOptions: TSelectOption[] = [
  {
    label: 'None',
    value: '-1',
  },
  ...getRangeOptions(),
];

export const TimeFrameSpecialExpenses: TSelectOption[] = [
  {
    label: 'Select Range',
    value: '-1',
  },
  {
    label: 'Within 2 years',
    value: 1,
  },
  {
    label: '3 - 5 years',
    value: 2,
  },
  {
    label: '6 - 10 years',
    value: 3,
  },
];

export const InitialValues = {
  [FormFields.annualExpenses]: AnnualExpensesOptions[0].value,
  [FormFields.specialExpenses]: SpecialExpensesOptions[0].value,
  [FormFields.timeFrameSpecialExpenses]: TimeFrameSpecialExpenses[0].value,
};

export const FieldsOptions = {
  [FormFields.annualExpenses]: AnnualExpensesOptions,
  [FormFields.specialExpenses]: SpecialExpensesOptions,
  [FormFields.timeFrameSpecialExpenses]: TimeFrameSpecialExpenses,
};
