import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import * as Styles from './Style';
import { cx } from 'emotion';
import SVGIcon from '../SVGIcon/SVGIcon';
import SolutionsButton from '../SolutionsButton/SolutionsButton';
import UserProfileDropdown from '../UserProfileDropdown/UserProfileDropdown';
import { LOGIN, REGISTER } from '../../../constants/routes';

import { history } from '../../../store';

const { Header } = Layout;

interface TopNavigationBarProps {
  topLinks?: boolean;
  temporaryLoggedIn?: boolean;
}

const TopNavigationBar: React.FC<TopNavigationBarProps> = (props, { ...shared }) => {
  const { topLinks, temporaryLoggedIn } = props;
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery: any) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <>
      {topLinks && (
        <div className={Styles.Apps}>
          <SVGIcon
            height={20}
            className={Styles.AppImage}
            onClick={() => console.log('Clicked MyIPO app')}
            iconName={'myipo.svg'}
          />
          <SVGIcon
            height={20}
            className={Styles.AppImage}
            onClick={() => console.log('Clicked ReadyRound app')}
            iconName={'ready-round.svg'}
          />
          <SVGIcon
            height={20}
            className={Styles.AppImage}
            onClick={() => console.log('Clicked Trading Block app')}
            iconName={'trading-block.svg'}
          />
          <SVGIcon
            height={20}
            className={Styles.AppImage}
            onClick={() => console.log('Clicked Folio app')}
            iconName={'folio.svg'}
          />
        </div>
      )}
      <Header className={Styles.Header}>
        <div className={Styles.HeaderLogo}>
          <img src={'/img/cclogo.png'} className={Styles.Logo} alt="logo" />
          {temporaryLoggedIn && (
            <div className={cx(Styles.Links, Styles.Solutions)}>
              <SolutionsButton />
            </div>
          )}
          {!temporaryLoggedIn && (
            <Button type="link" className={cx(Styles.Links, Styles.Solutions)}>
              Solutions
            </Button>
          )}
          {!temporaryLoggedIn && (
            <Button type="link" className={cx(Styles.Links, Styles.FAQ)}>
              FAQ
            </Button>
          )}
        </div>

        {(!isSmallScreen || isNavVisible) && (
          <nav className={Styles.Nav}>
            {isSmallScreen && temporaryLoggedIn && <SolutionsButton />}
            {isSmallScreen && !temporaryLoggedIn && (
              <Button type="link" className={Styles.Links}>
                Solutions
              </Button>
            )}
            {isSmallScreen && !temporaryLoggedIn && (
              <Button type="link" className={Styles.Links}>
                FAQ
              </Button>
            )}
            {!temporaryLoggedIn && (
              <Button type="link" className={Styles.Links} onClick={() => history.push(REGISTER)}>
                Create Account
              </Button>
            )}
            {!temporaryLoggedIn && (
              <Button type="link" className={Styles.Links} onClick={() => history.push(LOGIN)}>
                Login
              </Button>
            )}
            {temporaryLoggedIn && <UserProfileDropdown />}
          </nav>
        )}
        <Button onClick={toggleNav} className={Styles.Menu} icon="menu" size={'small'} />
      </Header>
    </>
  );
};
export default TopNavigationBar;
