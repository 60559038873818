import * as Yup from 'yup';
import { times } from 'lodash';
import Moment from 'moment';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';

export const InitialValues = {
  [FormFields.birthday]: undefined,
  [FormFields.ssn]: '',
  [FormFields.martialStatus]: '',
  [FormFields.noDependents]: 0,
};

export const MartialStatusOptions: TSelectOption[] = [
  {
    label: 'Select Status',
    value: '-1',
  },
  {
    label: 'Single',
    value: 's',
  },
  {
    label: 'Married',
    value: 'm',
  },
  {
    label: 'Divorced',
    value: 'd',
  },
  {
    label: 'Widow',
    value: 'w',
  },
];

export const NoDependentsOptions: TSelectOption[] = [
  {
    label: 'Select Dependents',
    value: 0,
  },
  ...times(9, x => {
    x++;
    return { label: x.toString(), value: x };
  }),
];

export const FieldsOptions = {
  [FormFields.martialStatus]: MartialStatusOptions,
  [FormFields.noDependents]: NoDependentsOptions,
};
