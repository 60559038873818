import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../../formTypes';
import { ENTITY } from '../../../../../constants/accountTypes';

const name = 'authorizedPersonsID';
export const path = 'authorizedPersonsID';
const header = 'Upload Authorized Persons ID';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  accountType: ENTITY,
  backToReview: true,
};

export default config;
