import produce from 'immer';
import initialState from './initial';
import { Type } from '../actions/utils';
import { actionFailed, actionSucceeded } from '../actions/types';
import { TReduxAction } from '../types/commonTypes';
import _ from 'lodash';
import { SolutionFormFieldsRelations, SuitabilityRelatedForms } from '../constants/suitabilityInfo';

export const forms = (state: any = initialState.forms, action: TReduxAction) => {
  switch (action.type) {
    case actionSucceeded(Type.SAVE_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.SAVE_RETIREMENT_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.retirement = [...action.payload.body];
      })(state, action);
    case actionSucceeded(Type.REMOVE_SUITABILITY_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        _.mapValues(SuitabilityRelatedForms, function(field) {
          if (draftState[field] && draftState[field][SolutionFormFieldsRelations[action.payload.solutionCode]]) {
            delete draftState[field][SolutionFormFieldsRelations[action.payload.solutionCode]];
          }
        });
      })(state, action);
    case actionFailed(Type.REMOVE_SUITABILITY_INFO):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.error = action.error;
      })(state, action);
    case actionSucceeded(Type.SAVE_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.DELETE_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        delete draftState[action.payload.id];
      })(state, action);
    case actionSucceeded(Type.SAVE_ENTITY_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        if (!draftState.entity) draftState.entity = {};
        draftState.entity[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.entity[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        delete draftState.entity[action.payload.id];
      })(state, action);
    case actionSucceeded(Type.SAVE_JOINT_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.SAVE_JOINT_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState[action.payload.id] = action.payload.body;
      })(state, action);
    case actionSucceeded(Type.DELETE_JOINT_MAILING_ADDRESS_FORM_STATE):
      return produce((draftState: any, action: TReduxAction) => {
        delete draftState[action.payload.id];
      })(state, action);
    default:
      return state;
  }
};
