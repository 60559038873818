import React, { useState } from 'react';
import { Form } from 'antd';
import MediaQuery from 'react-responsive';
import Button, { ButtonType } from '../../../shared/Buttons/Button';
import SignaturePad from 'signature_pad';
import getCanvas from 'html2canvas';

import * as Styles from './styles';
import './style.css';

export type SignatureCompType = {
  name: string;
  firstName: string;
  lastName: string;
  value?: string;
  handleSubmit?: Function;
  setFieldValue: Function;
  setFieldTouched?: Function;
  errors?: any;
  label: any;
};

const SignatureComp = (props: SignatureCompType) => {
  const { name, label, value, setFieldValue, errors, firstName, lastName } = props;
  const [selectedSignature, setSelectedSignature] = useState('');
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [signaturePreview, setSignaturePreview] = useState();
  const [signatureRef, setSignatureRef] = useState();
  const SIGNATURE_FONT_STYLES = ['Satisfy', 'Parisienne', 'Mrs Saint Delafield'];

  //   DESK: Desktop, TAB: Tablet, MOB: Mobile
  const LAYOUT = Object.freeze({ DESK: 1, TAB: 2, MOB: 3 });
  // Enums to control the minimum width at which a particular layout renders
  const MIN_WIDTH = Object.freeze({ DESK: 871, TAB: 480, MOB: 0 });

  let $canvas: any;
  let Signature: any;

  const initializeCanvas = (el: any) => {
    if (el) {
      $canvas = el;
      Signature = new SignaturePad($canvas);
    }
  };

  const resetCanvas = () => {
    if ($canvas) {
      initializeCanvas($canvas);
    }
  };

  const saveSignature = () => {
    if (Signature.isEmpty()) {
      return false;
    }
    const image = Signature.toDataURL();
    Signature.off();
    return image;
  };

  const setPreviewSignature = () => {
    const signature = saveSignature();
    setSignaturePreview(signature);
    setSelectedSignature('drawn');
    setShowSignaturePad(!showSignaturePad);
  };

  const setRef = (el: any) => {
    setSignatureRef(el);
  };

  const updateSelected = (selectedStyle: any) => {
    setSelectedSignature(selectedStyle);
     save();
  };

  const save = () => {
    getCanvas(signatureRef as any).then(canvas => {
      const img = canvas.toDataURL();
      setFieldValue(name, img);
    });
  };

  const signaturePad = (
    <div className="signatureInputScreen">
      <div className="signatureInputForm">
        <div
          onClick={() => setShowSignaturePad(!showSignaturePad)}
          onKeyPress={() => setShowSignaturePad(!showSignaturePad)}
          role="button"
          className="closeSignatureInput"
          tabIndex={0}
        >
          X
        </div>
        <div className="drawSuggestion">Draw your signature</div>
        <div className="instructions">Use your mouse, tablet, or smartphone</div>
        <MediaQuery minWidth={MIN_WIDTH.DESK}>
          <canvas ref={initializeCanvas} width="640px" height="260px" className="drawingPad" />
        </MediaQuery>
        <MediaQuery maxWidth={MIN_WIDTH.DESK - 1} minWidth={MIN_WIDTH.TAB} orientation="portrait">
          <canvas ref={initializeCanvas} width="640px" height="260px" className="drawingPad" />
        </MediaQuery>
        <MediaQuery maxWidth={MIN_WIDTH.DESK - 1} minWidth={MIN_WIDTH.TAB} orientation="landscape">
          <canvas ref={initializeCanvas} width="525px" height="140px" className="drawingPad" />
        </MediaQuery>
        <MediaQuery maxWidth={MIN_WIDTH.TAB - 1}>
          <canvas ref={initializeCanvas} width="320px" height="130px" className="drawingPad" />
        </MediaQuery>
        <div className="signatureInputButtons">
          <div className="buttonWidthRestriction">
            <Button onClick={resetCanvas} label={'Clear signature'} type={ButtonType.Thick} />
          </div>
          <div className="buttonWidthRestriction">
            <Button onClick={setPreviewSignature} label={'Save Signature'} type={ButtonType.Thick} />
          </div>
        </div>
      </div>
    </div>
  );
  const signatureOptions = SIGNATURE_FONT_STYLES.map(fontStyle => {
    const style = {
      width: '100%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      paddingLeft: '14px',
      fontSize: '28px',
      fontFamily: `'${fontStyle}', cursive`,
      border: '1px solid #c2d6e9',
      borderRadius: '2px',
      backgroundColor: 'white',
      cursor: 'pointer',
      outline: 'none',
      boxShadow: 'none',
      ':focus': {
        outline: 'none',
      },
      ':active': {
        outline: 'none',
      },
    };

    let setSignatureReference = (el: any) => {};
    setSignatureReference = setRef;
    if (fontStyle === selectedSignature) {
      style.border = '1px solid #4a87c0';
      style.boxShadow = '0px 4px 8px 0px #DFE9F3';
      setSignatureReference = setRef;
    }

    return (
      <div
        key={fontStyle}
        ref={setSignatureReference}
        style={style}
        onClick={() => {
          updateSelected(fontStyle);
        }}
        onKeyPress={() => {
          updateSelected(fontStyle);
        }}
        role="option"
        tabIndex={0}
        aria-selected
      >
        {`${firstName} ${lastName}`}
      </div>
    );
  });

  if (signaturePreview) {
    const style = {
      width: '100%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      paddingLeft: '14px',
      boxShadow: '0px 2px 4px 0px #DFE9F3',
      border: '1px solid #c2d6e9',
      borderRadius: '2px',
      backgroundColor: 'white',
      cursor: 'pointer',
      outline: 'none',
      ':focus': {
        outline: 'none',
      },
      ':active': {
        outline: 'none',
      },
    };

    let setSignatureReference = (el: any) => {};
    if (selectedSignature === 'drawn') {
      style.border = '1px solid #4a87c0';
      style.boxShadow = '0px 4px 8px 0px #DFE9F3';
      setSignatureReference = setRef;
    }

    const signaturePreviewDiv = (
      <div
        style={style}
        key="signaturePreview"
        onClick={() => {
          updateSelected('drawn');
        }}
        onKeyPress={() => {
          updateSelected('drawn');
        }}
        role="option"
        tabIndex={0}
        aria-selected
      >
        <img
          src={signaturePreview}
          alt="drawnSignature"
          className="signaturePreviewImage"
          ref={setSignatureReference}
        />
      </div>
    );

    signatureOptions.push(signaturePreviewDiv);
  }

  return (
    <Form.Item
      hasFeedback={!!errors && !!errors[name]}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && errors[name]}
      label={<div className={Styles.SelectSignature}>{label}</div>}
      colon={false}
      className={Styles.AlignCenter}
    >
      {showSignaturePad && signaturePad}
      {signatureOptions}
      <div className={Styles.Or}>OR</div>
      <Button
        label={'Draw your signature'}
        type={ButtonType.Link}
        onClick={() => setShowSignaturePad(!showSignaturePad)}
        className={Styles.DrawYourSignature}
      />
    </Form.Item>
  );
};

export default SignatureComp;
