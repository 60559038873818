import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
};

export const FormFieldsTypes = {
  [FormFields.name]: reviewFieldTypes.RawString,
  [FormFields.phoneNumber]: reviewFieldTypes.RawString,
  [FormFields.email]: reviewFieldTypes.RawString,
} as any;

export const FormStrings = {
  [FormFields.name]: 'Entity Name',
  [FormFields.phoneNumber]: 'Phone Number',
  [FormFields.email]: 'Email',
};
