import { FormFields, FormStrings } from './constants';

export const FieldsOptions = {};

export const InitialValues = {
  [FormFields.firstname]: '',
  [FormFields.middleInitial]: '',
  [FormFields.lastname]: '',
  [FormFields.phonenumber]: '',
};
