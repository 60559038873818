import React from 'react';
import { Formik } from 'formik';
import { map } from 'lodash';

import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, InvestmentRatioOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';
import { ContinueAction } from '../../FormActions';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import InfoText from '../../../shared/InfoText/InfoText';
import RadioGroup from '../../../shared/Radio/Radio';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.investmentStrategy);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (_values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <InfoText>{FormStrings.decisionMakingLabel}</InfoText>

            {map(InitialValues.decisionMaking, (value: boolean, key: string) => {
              return (
                <Checkbox
                  {...form}
                  name={`decisionMaking.${FormFields[key]}`}
                  label={FormStrings[key]}
                  checked={
                    formState.decisionMaking[FormFields[key]] ? formState.decisionMaking[FormFields[key]] : value
                  }
                />
              );
            })}

            <InfoText>{FormStrings.investmentRatioLabel}</InfoText>
            <RadioGroup
              {...form}
              radioOptions={InvestmentRatioOptions}
              name={FormFields.investmentRatio}
              defaultValue={
                formState[FormFields.investmentRatio]
                  ? formState[FormFields.investmentRatio]
                  : InitialValues.investmentRatio
              }
            />
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
