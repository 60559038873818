import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  totalNetWorthRequired: `${FormStrings[FormFields.totalNetWorth]} is required`,
  liquidNetWorthRequired: `${FormStrings[FormFields.liquidNetWorth]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.totalNetWorth]: Yup.number()
    .required(validationMessages.totalNetWorthRequired)
    .moreThan(0, validationMessages.totalNetWorthRequired),
  [FormFields.liquidNetWorth]: Yup.number()
    .required(validationMessages.liquidNetWorthRequired)
    .moreThan(0, validationMessages.liquidNetWorthRequired),
});

export default defaultValidation;
