import React from 'react';
import { Form, Radio as AntRadio } from 'antd';

import './Radio.css';

export type RadioOptions = {
  label: string;
  value: string;
};

export type RadioCompType = {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  errors?: any;
  setFieldValue: Function;
  radioOptions: RadioOptions[];
  onChangeHook?: Function;
};

const RadioGroup = (props: RadioCompType) => {
  const { id, name, label, defaultValue, disabled, errors, setFieldValue, radioOptions, onChangeHook } = props;

  const onChange = (value: any) => {
    setFieldValue(name, value);
    if (onChangeHook) {
      onChangeHook(value);
    }
  };

  return (
    <Form.Item
      hasFeedback={!!errors && !!errors[name]}
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && errors[name]}
      colon={false}
      label={label}
    >
      <AntRadio.Group
        onChange={event => onChange(event.target.value)}
        defaultValue={defaultValue}
        disabled={disabled}
        id={!!id ? id : name}
        name={name}
      >
        {radioOptions.map(item => {
          return <AntRadio value={item.value}>{item.label}</AntRadio>;
        })}
      </AntRadio.Group>
    </Form.Item>
  );
};

export default RadioGroup;
