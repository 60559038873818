import React, { FunctionComponent } from 'react';
import { Field, FieldArray, Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import defaultValidation from './validations';

import { addSubSolution, removeSubSolution } from '../../../../actions/solutions';
import { useDispatch, useSelector } from 'react-redux';
import Strings from '../../../../constants/strings';
import Button, { ButtonType } from '../../../shared/Buttons/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import * as Styles from '../../../shared/TwoSidedFormContainer/Style';
import { error as errorClass } from './Style';

const Component: FunctionComponent<{ onSubmit: Function }> = props => {
  const { onSubmit } = props;
  const dispatch = useDispatch();
  const solutions = useSelector((state: any) => state.solutions);

  const submit = (values: any) => {
    const action = addSubSolution({
      solutionCode: 'tradingBlock',
      body: values,
    });
    dispatch(action);
    onSubmit();
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={
        !!solutions['tradingBlock'] && !!solutions['tradingBlock'].products
          ? solutions['tradingBlock'].products
          : InitialValues
      }
      validationSchema={defaultValidation}
      onSubmit={values => submit(values)}
    >
      {form => {
        const renderCheckBoxes = (name: any) => (
          <>
            <FieldArray name={name}>
              {({ form: { values, setValues, validateForm }, ...fieldArrayHelpers }) => {
                return Object.keys(values[`${name}`]).map((item: any, index: number) => (
                  <Field name={`${name}.${item}`}>
                    {({ field, form, meta }: any) => (
                      <Checkbox
                        {...form}
                        name={`${name}.${item}`}
                        label={FormStrings[item]}
                        checked={form.values[`${name}`][item]}
                        errors={{ [`${name}.${item}`]: meta.error }}
                      />
                    )}
                  </Field>
                ));
              }}
            </FieldArray>
            <div className={errorClass}>{form.errors[name] && form.errors[name]}</div>
          </>
        );
        return (
          <>
            <div className={Styles.SubTitle}>{FormStrings[FormFields.accountTypes]}</div>
            <br />
            {renderCheckBoxes(FormFields.accountTypes)}
            <br />
            <br />
            <div className={Styles.SubTitle}>{FormStrings[FormFields.investmentStrategies]}</div>
            <br />
            {renderCheckBoxes(FormFields.investmentStrategies)}
            <br />
            <br />
            <Button
              disabled={!form.isValid}
              onClick={() => {
                submit(form.values);
              }}
              label={Strings.actions.continue}
              type={ButtonType.Thick}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
