import { useEffect } from 'react';

export default (formikRef: any, formState: any) => {
  useEffect(() => {
    const f = formikRef.current;
    f.setValues({});
    Object.keys(formState).map(key => {
      f.setFieldValue(key, f.values[key]);
    });
  }, [formState]);
};
