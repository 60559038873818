import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useField } from 'formik';
import { FormFields, FormStrings } from './constants';

import Button, { ButtonType } from '../../../../shared/Buttons/Button';
import { Col, Row, Upload, Popover } from 'antd';
import * as Styles from './Style';
import './style.css';

export const UploadPersonalId = ({ index, form, name, item }: any) => {
  const history = useHistory();

  const [completedField, completedMeta, completedHelpers] = useField({
    name: `${FormFields.authorizedPersonsId}[${index}].authorizedPersonId`,
    type: 'string',
  });
  const [contentField, contentMeta, contentHelpers] = useField(
    `${FormFields.authorizedPersonsId}[${index}][${FormFields.authorizedPersonId}]`,
  );
  const onContentInput = (result: any) => {
    completedHelpers.setValue(result);
    contentHelpers.setValue(result);
  };

  const removeID = () => {
    onContentInput('');
  };

  const popOverContent = <img src={item.authorizedPersonId} width={300} height={160} />;
  return (
    <>
      {/*{console.log('validateForm', validateForm)}*/}
      {!item.authorizedPersonId ? (
        <Row className={Styles.MarginBottom8}>
          <Col span={16} className={Styles.AuthPerson}>
            {name}
          </Col>
          <Col span={8}>
            <Upload
              name={`${FormFields.authorizedPersonsId}[${index}][${FormFields.authorizedPersonId}]`}
              showUploadList={false}
              beforeUpload={(file: any) => {
                // const localImageUrl = window.URL.createObjectURL(file);
                // onContentInput(localImageUrl);
                return false;
              }}
              onChange={(event: any) => {
                const localImageUrl = window.URL.createObjectURL(event.file);
                onContentInput(localImageUrl);
              }}
              listType={'picture'}
              style={{ cursor: 'pointer' }}
            >
              <Button onClick={() => {}} type={ButtonType.Thin} label={'UPLOAD'}></Button>
            </Upload>
          </Col>
          {!!contentMeta.error && (
            <Col span={24}>
              <div className={`${Styles.Red} ant-form-explain`}>{contentMeta.error}</div>
            </Col>
          )}
        </Row>
      ) : (
        <Row className={Styles.MarginBottom8}>
          <Col span={16} className={Styles.UploadedId}>
            <Popover content={popOverContent} trigger="hover">
              {name}
            </Popover>
          </Col>
          <Col span={8}>
            <Button onClick={() => removeID()} type={ButtonType.Thin} label={'REMOVE'}></Button>
          </Col>
        </Row>
      )}
    </>
  );
};
