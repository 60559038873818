import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const Apps = css`
  background: ${Color.OffWhite};
  height: 40px;
  padding: 0px 140px !important;
  display: block;

  @media (max-width: 700px) {
    padding: 0 20px !important;
    display: none !important;
  }

  @media (max-width: 1080px) and (min-width: 700px) {
    padding: 0 20px !important;
  }
`;

export const AppImage = css`
  height: 20px;
  margin-right: 30px;
  padding: 10px 0;
  cursor: pointer;
`;

export const Header = css`
  background: ${Color.PureWhite} !important;
  box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.15) !important;
  height: 80px !important;
  padding: 0px 140px !important;
  max-height: 80px;
  width: 100% !important;
  line-height: 80px !important;

  display: inline-grid;
  grid-template-areas: 'header-logo nav';
  grid-template-columns: auto max-content;
  grid-gap: 44px;
  margin-bottom: 32px;

  @media (max-width: 700px) {
    grid-template-areas: 'header-logo burger' 'nav nav';
    padding: 0 20px !important;
  }

  @media (max-width: 1080px) and (min-width: 700px) {
    padding: 0 20px !important;
  }
`;

export const LogoContainer = css`
  height: 100% !important;
  vertical-align: middle !important;
  white-space: nowrap;
  margin-right: 72px;
  padding: 20px 0;
  display: inline-block !important;
`;

// export const Logo = css`
//   position: relative;
//   width: 366px;
//   max-height: 80px;
//   height: 40px;
//   float: left;
//   vertical-align: middle !important;
// `;

export const HeaderLogo = css`
  grid-area: header-logo;
  height: 100% !important;
  vertical-align: middle !important;
`;

export const Logo = css`
  grid-area: logo;
  max-width: 366px;
  height: 40px;
  margin-right: 72px;
  @media (max-width: 700px) {
    height: 25px !important;
    margin-right: 0 !important;
  }
  @media (max-width: 1080px) and (min-width: 700px) {
    height: 25px !important;
    margin-right: 40px !important;
  }
`;

export const Nav = css`
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-items: center;
  justify-self: end;
  grid-gap: 44px;

  @media (max-width: 700px) {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    z-index: 999;
    width: 100%;
    padding: 30px 0 30px;
    background: ${Color.PureWhite};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Links = css`
  transition: 0.5s;
  padding: 0px !important;
  text-decoration: none;
  font-family: ${Typography.GillSans} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: ${Color.FineBlue} !important;
`;

export const Solutions = css`
  grid-area: solutions;
  display: inline !important;
  align-items: left;
  justify-items: left;
  @media (max-width: 700px) {
    display: none !important;
  }
`;

export const FAQ = css`
  grid-area: faq;
  display: inline;
  margin-left: 44px;
  @media (max-width: 700px) {
    display: none !important;
    margin: none;
  }
`;

// export const Menu = css`
//   position: relative;
//   display: inline-block !important;
//   line-height: 20px;
// `;

export const Menu = css`
  display: none !important;
  grid-area: burger;
  margin: 0 20px 0 0;
  padding: 0;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
  align-items: center;
  justify-items: center;
  justify-self: end;
  align-self: center;

  @media (max-width: 700px) {
    display: inline !important;
  }
`;

export const MenuLinks = css`
  font-family: ${Typography.GillSans} !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: ${Color.FineBlue} !important;
`;

export const Right = css`
  float: right;
`;
