import React from 'react';
import { Layout, Row } from 'antd';

import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';

import * as Style from './style';
import TopNavigationBar from '../../shared/TopNavigationBar/TopNavigationBar';

const { Content, Footer, Header } = Layout;

interface PageProp {
  headerText?: string;
  temporaryLoggedIn?: boolean;
}
// TODO: remove temporaryLoggedIn when the login process works and user detail exist in redux
const Page: React.FunctionComponent<RowProps & ColProps & PageProp> = (props: RowProps & ColProps & PageProp) => {
  const { children, headerText } = props;
  return (
    <Layout className={Style.page}>
      <TopNavigationBar temporaryLoggedIn />
      {children}
    </Layout>
  );
};

export default Page;
