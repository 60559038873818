export const AuthFields = {
  email: 'email',
  phoneNumber: 'phoneNumber',
  dialCode: 'dialCode',
  password: 'password',
  repeatPassword: 'repeatPassword',
};

export const AuthStrings = {
  [AuthFields.email]: 'Email',
  [AuthFields.dialCode]: 'Dial Code',
  [AuthFields.phoneNumber]: 'Phone Number',
  [AuthFields.password]: 'Password',
  [AuthFields.repeatPassword]: 'Repeat Password',
};
