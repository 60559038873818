import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <div>
              <b>How to use: </b>
              <ol>
                <li>
                  Copy and paste: /src/coponents/forms/Registration/_Dummy/ to a form name: (Use the cammel-cased title
                  convention as in CountryOfRegistration)
                </li>
                <li>Inject form by importing the component in: ../formInjector</li>
                <li>Add form components in: FormComponent.tsx</li>
                <li>Use schema.ts/initialValues for initial values</li>
                <li>Use validations.ts for for validations using YUP</li>
                <li>Tweak metadata in index.ts (Name, path, header and Index)</li>
              </ol>
            </div>
            <div>
              <b>Disable me:</b>
              <p>/src/coponents/forms/Registration/_Dummy/index disabled -&gt; true</p>
            </div>
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
