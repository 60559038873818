import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { AuthFields, AuthStrings } from './constants';
import { InitialValues } from './schema';
import * as Styles from '../Style';
import { defaultValidation } from './validations';
import Input from '../../shared/Inputs/Input';
import Button, { ButtonType } from '../../shared/Buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as AuthActions from '../../../actions';
import { TAuth } from '../authType';
import FormContainer from '../../shared/FormContainer/FormContainer';
import { DirectToRegister, DirectToForgotPassword } from '../AuthAction';

const Component = (props: TAuth) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.login);

  const onSubmit = (_values: any) => {
    dispatch(
      AuthActions.login({
        id: 'login',
        body: _values,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      AuthActions.clearLogin({
        id: 'clearError',
        body: null,
      }),
    );
  }, []);

  return (
    <FormContainer title={'Login'}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={values => {
          onSubmit(values);
        }}
        validationSchema={defaultValidation}
      >
        {form => {
          return (
            <>
              <span className={Styles.errorText}>{state.error}</span>
              <Input
                {...form}
                name={AuthFields.email}
                label={AuthStrings[AuthFields.email]}
                value={form.initialValues[AuthFields.email]}
              />
              <Input
                {...form}
                name={AuthFields.password}
                label={AuthStrings[AuthFields.password]}
                value={form.initialValues[AuthFields.password]}
                type={'password'}
              />
              <Row>
                <Col span={24} className={Styles.ForgotYourPasswordLink}>
                  <DirectToForgotPassword />
                </Col>
              </Row>
              <Button
                disabled={!form.isValid}
                onClick={form.submitForm}
                label={'LOG IN'}
                type={ButtonType.Thick}
                loading={!!state.__requested ? true : false}
              />
              <Row>
                <Col span={24} className={Styles.signUpTextCSS}>
                  <span>Don’t have an account? </span>
                  <DirectToRegister />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default Component;
