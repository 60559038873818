import { css, cx } from 'emotion';
import { Typography } from './../../../styles/index';

const buttonStyle = css`
  font-family: ${Typography.GillSans} !important;
  font-size: 12px !important;
  line-height: 14px !important;
  height: 32px !important;
  border-radius: 16px !important;
  text-align: center !important;
  width: 90% !important;

  margin: auto !important;
  display: block !important;
  &:disabled {
    background-color: #989898 !important;
    border: 1px solid #989898 !important;
    color: #ffffff !important;
    cursor: not-allowed;
  }
`;

export const button = cx(
  buttonStyle,
  css`
    height: 32px !important;
    color: #ffffff !important;
    background: #0098ce !important;
  `,
);

export const active = cx(
  buttonStyle,
  css`
    background: #ffffff !important;
    border: 1px solid #ff244e !important;
    color: #ff244e !important;

    &:hover:enabled {
      background-color: #ff244e !important;
      color: #ffffff !important;
    }
  `,
);
