import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import FullScreenForm from '../FullScreenForm/FullScreenForm';
import * as Colors from '../../styles/color';
import * as Routes from '../../constants/routes';
import { isLoggedInSelector, authStateSelector } from '../../selectors/auth';
import Login from './Login';
import Register from './Register';
import ConfirmEmail from './ConfirmEmail';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSubmit from './ForgotPasswordSubmit';
import { AuthState } from './authType';

export default () => {
  const history: any = useHistory();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authInfoState = useSelector(authStateSelector);

  //TODO: redirect to dashboard or to continue registration based on state
  const directToDashboard = () => history.replace(Routes.MAIN);

  /**
   *   Redirect to appropriate auth Form
   */
  useEffect(() => {
    if ([AuthState.SIGNED_IN].includes(authInfoState) && isLoggedIn) {
      directToDashboard();
    }
    if ([AuthState.CONFIRM_SIGN_UP].includes(authInfoState)) {
      history.push(Routes.CONFIRM_EMAIL);
    }
    if ([AuthState.SIGN_IN, AuthState.SIGNED_OUT, AuthState.SIGNED_UP].includes(authInfoState)) {
      history.push(Routes.LOGIN);
    }
    if ([AuthState.SUBMIT_PASSWORD].includes(authInfoState)) {
      history.push(Routes.SUBMIT_PASSWORD);
    }
  }, [authInfoState]);

  const routes = (
    <Switch>
      <Route exact key={Login.name} path={Routes.LOGIN} render={() => <Login.AuthComponent />} />
      <Route exact key={Register.name} path={Routes.REGISTER} render={() => <Register.AuthComponent />} />
      <Route exact key={ConfirmEmail.name} path={Routes.CONFIRM_EMAIL} render={() => <ConfirmEmail.AuthComponent />} />
      <Route
        exact
        key={ForgotPassword.name}
        path={Routes.FORGOT_PASSWORD}
        render={() => <ForgotPassword.AuthComponent />}
      />
      <Route
        exact
        key={ForgotPasswordSubmit.name}
        path={Routes.SUBMIT_PASSWORD}
        render={() => <ForgotPasswordSubmit.AuthComponent />}
      />
      <Redirect to={Routes.NOT_FOUND} />
    </Switch>
  );

  return (
    <FullScreenForm color={Colors.FogBlue} span={12}>
      {routes}
    </FullScreenForm>
  );
};
