import React from 'react';
import { Anchor, Col, Layout, Row, Select } from 'antd';

import * as Styles from './style';
import Page from '../layout/Page/Page';
import questions from './constant';

const { Content } = Layout;
const { Link } = Anchor;

export default () => {
  const section = (question: any) => {
    return (
      <div key={question.route} id={question.route}>
        <div className={Styles.SectionTitle}>{question.title}</div>
        <hr className={Styles.Hr} />
        {question.questions.map((item: any) => {
          return (
            <div className={Styles.QuestionBlock}>
              <div className={Styles.Question}>{item.q}</div>
              <div className={Styles.Answer}>{item.a}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Page>
      <Content className={Styles.Container}>
        <div className={Styles.PageHeaderText}>Frequently Asked Questions</div>
        <div className={Styles.PageHeaderInfoText}>
          Don’t see what you’re looking for here? Contact us at{' '}
          <a href="mailto:info@cambriacapital.com">info@cambriacapital.com</a>
        </div>
        <Row className={Styles.QuestionContainer}>
          <Col span={6}>
            <Anchor affix={false} className={Styles.Anchor}>
              {questions.map(question => {
                return <Link className={Styles.AnchorLink} href={'#' + question.route} title={question.title} />;
              })}
            </Anchor>
          </Col>
          <Col span={18}>
            {questions.map(question => {
              return section(question);
            })}
          </Col>
        </Row>
      </Content>
    </Page>
  );
};
