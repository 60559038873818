import React from 'react';
import { Card } from 'antd';

import './CardViewTitle.css';
import { CardProps } from 'antd/es/card';

interface IProps extends CardProps {
  title: string;
}

const CardViewTitle: React.FunctionComponent<IProps> = ({ children, ...shared }) => {
  return (
    <Card style={{ width: 580 }} {...shared}>
      {children}
    </Card>
  );
};

export default CardViewTitle;
