import { createAction, Type, State } from './utils';
import { AnyAction } from 'redux';

export const saveFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_FORM_STATE), payload);

export const saveRetirementFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_RETIREMENT_FORM_STATE), payload);
export const deleteRetirementFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_RETIREMENT_FORM_STATE), payload);

export const saveMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_MAILING_ADDRESS_FORM_STATE), payload);
export const deleteMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_MAILING_ADDRESS_FORM_STATE), payload);

export const saveEntityFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_ENTITY_FORM_STATE), payload);
export const deleteEntityFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_ENTITY_FORM_STATE), payload);
export const saveEntityMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_ENTITY_MAILING_ADDRESS_FORM_STATE), payload);
export const deleteEntityMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_ENTITY_MAILING_ADDRESS_FORM_STATE), payload);

export const saveJointAccountFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_JOINT_FORM_STATE), payload);
export const deleteJointAccountFormState = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_JOINT_FORM_STATE), payload);
export const saveJointAccountMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.SAVE_JOINT_MAILING_ADDRESS_FORM_STATE), payload);
export const deleteJointAccountMailingAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_JOINT_MAILING_ADDRESS_FORM_STATE), payload);
