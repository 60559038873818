import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  birthCountry: 'birthCountry',
  visaType: 'visaType',
  visaExpiration: 'visaExpiration',
  proofOfEmployment: 'proofOfEmployment',
};

export const FormFieldsTypes = {
  [FormFields.birthCountry]: reviewFieldTypes.SelectOptions,
  [FormFields.visaType]: reviewFieldTypes.SelectOptions,
  [FormFields.visaExpiration]: reviewFieldTypes.DateString,
  [FormFields.proofOfEmployment]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.birthCountry]: 'Birth Country',
  [FormFields.visaType]: 'Visa Type',
  [FormFields.visaExpiration]: 'Visa Expiration',
  [FormFields.proofOfEmployment]: 'Proof of Employment',
};

export const UploadStrings = {
  name: 'proofOfEmployment',
  label: 'Proof of Employment',
};
