import { call, put, takeEvery } from 'redux-saga/effects';
import * as Url from '../constants/url';
import { Type, State } from '../actions/utils';
import { HttpClient } from '../helpers/httpClient';

export function* saveAccount(action: any) {
  try {
    const p = 1;
    const requestBody = action.payload;
    const response = yield call(HttpClient, 'post', Url.ACCOUNT_URL, requestBody);
    yield put({ type: State.actionSucceeded(Type.REGISTER), payload: response.data });
    if (response.data.redirectUri) {
      window.location.href = response.data.redirectUri;
    }
  } catch (error) {
    yield put({ type: State.actionFailed(Type.REGISTER), error: error.message });
  }
}

/**
 * Authentication sagas
 */
export default function* saga() {
  yield takeEvery(State.actionRequested(Type.SAVE_ACCOUNT), saveAccount);
}
