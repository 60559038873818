export const FormFields = {
  cambriaCapital: 'cambriaCapital',
  myIpo: 'myIpo',
  folio: 'folio',
  readyRound: 'readyRound',
  tradingBlock: 'tradingBlock',
  options: 'options',
  margin: 'margin',
  apex: 'apex',
};

export const FormStrings = {
  [FormFields.cambriaCapital]: {
    name: 'Cambria Connect Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.myIpo]: {
    name: 'MyIPO Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.folio]: {
    name: 'Folio Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.readyRound]: {
    name: 'Ready Round Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.tradingBlock]: {
    name: 'Trading Block Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.options]: {
    name: 'Options Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.margin]: {
    name: 'Margins Agreement',
    link: '/dummy.pdf',
  },
  [FormFields.apex]: {
    name: 'APEX Agreement',
    link: '/dummy.pdf',
  },
};
