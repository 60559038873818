import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  mailAddress: 'mailAddress',
  citizenship: 'citizenship',
  permanentUSResident: 'permanentUSResident',
};

export const FormFieldsTypes = {
  [FormFields.addressLineOne]: reviewFieldTypes.RawString,
  [FormFields.addressLineTwo]: reviewFieldTypes.RawString,
  [FormFields.city]: reviewFieldTypes.RawString,
  [FormFields.state]: reviewFieldTypes.SelectOptions,
  [FormFields.zipCode]: reviewFieldTypes.RawString,
  [FormFields.mailAddress]: reviewFieldTypes.Boolean,
  [FormFields.citizenship]: reviewFieldTypes.SelectOptions,
  [FormFields.permanentUSResident]: reviewFieldTypes.RawString, //TODO: define right type later
};

export const FormStrings = {
  [FormFields.addressLineOne]: 'Address Line 1',
  [FormFields.addressLineTwo]: 'Address Line 2',
  [FormFields.city]: 'City',
  [FormFields.state]: 'State',
  [FormFields.zipCode]: 'Zip Code',
  [FormFields.mailAddress]: 'Mailing address is the same as my physical address',
  [FormFields.citizenship]: 'Citizenship',
  [FormFields.permanentUSResident]: 'Please check if you are a permanent U.S. resident',
};
