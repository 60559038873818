/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:4795e94f-8122-416a-8c69-0eedfefce270",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_gXI0j58hx",
    "aws_user_pools_web_client_id": "3t779ffoti0lp3velrnhrkn3a0",
    "oauth": {}
};


export default awsmobile;
