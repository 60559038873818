import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
};

export const FormFieldsTypes = {
  [FormFields.addressLine1]: reviewFieldTypes.RawString,
  [FormFields.addressLine2]: reviewFieldTypes.RawString,
  [FormFields.city]: reviewFieldTypes.RawString,
  [FormFields.state]: reviewFieldTypes.SelectOptions,
  [FormFields.zipCode]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.addressLine1]: 'Address Line 1',
  [FormFields.addressLine2]: 'Address Line 2 (Optional)',
  [FormFields.city]: 'City',
  [FormFields.state]: 'State',
  [FormFields.zipCode]: 'Zip Code',
};
