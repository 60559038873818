import { enabledInjectedForms } from '../components/forms/formInjector';
import * as Routes from '../constants/routes';

export const onSkipClick = (currentForm: any, history: any, isReview?: boolean, formState?: any) => {
  const targetForm = enabledInjectedForms.find(
    x => x.name === currentForm.skipTo(currentForm, history, isReview, formState),
  );
  if (targetForm) {
    // const route = `${Routes.REGISTER_INFO}/${targetForm.path}${isReview ? '?r=1' : ''}`;
    const route = `${Routes.REGISTER_INFO}/${targetForm.path}`;
    history.push(route);
  }
};
