import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';
import { AccountTypes } from '../AccountType/schema';

export const InitialValues = {
  [FormFields.relationship]: '-1',
};

const defaultRelationship = {
  label: FormStrings.relationshipLabel,
  value: '-1',
};

export const RelationshipOptions = [
  defaultRelationship,
  {
    label: 'Spouse',
    value: 'S',
  },
  {
    label: 'Financial Advisor',
    value: 'A',
  },
  {
    label: 'Guardian',
    value: 'G',
  },
  {
    label: 'Family Member',
    value: 'F',
  },
  {
    label: 'Lawyer/Attorney',
    value: 'L',
  },
  {
    label: 'Other',
    value: 'O',
  },
];

export const FieldsOptions = {
  [FormFields.relationship]: RelationshipOptions,
};
