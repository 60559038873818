import * as Yup from 'yup';
import { FormFields } from './constants';

export const InitialValues = {
  [FormFields.accountTypes]: {
    [FormFields.cash]: false,
    [FormFields.options]: false,
    [FormFields.margin]: false,
  },
  [FormFields.investmentStrategies]: {
    [FormFields.stocksETFsMutualFundsBonds]: false,
    [FormFields.longCallsPutsCoveredOptions]: false,
    [FormFields.optionSpreads]: false,
    [FormFields.putWriting]: false,
    [FormFields.uncoveredCallsIndexOptions]: false,
  },
};
