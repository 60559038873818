import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  annualExpensesRequired: `${FormStrings[FormFields.annualExpenses]} is required`,
  specialExpensesRequired: `${FormStrings[FormFields.specialExpenses]} is invalid`,
  timeFrameSpecialExpensesRequired: `${FormStrings[FormFields.timeFrameSpecialExpenses]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.annualExpenses]: Yup.number()
    .required(validationMessages.annualExpensesRequired)
    .moreThan(0, validationMessages.annualExpensesRequired),
  [FormFields.specialExpenses]: Yup.number()
    .required(validationMessages.specialExpensesRequired)
    .moreThan(0, validationMessages.specialExpensesRequired),
  [FormFields.timeFrameSpecialExpenses]: Yup.number()
    .required(validationMessages.timeFrameSpecialExpensesRequired)
    .moreThan(0, validationMessages.timeFrameSpecialExpensesRequired),
});

export default defaultValidation;
