import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles/index';

export const label = cx(
  css`
    & label {
      ${Typography.InputLabel}
    }
  `,
);

export const select = cx(
  Typography.InputLabel,
  css`
    div[aria-expanded='true'] span i {
      transform: rotate(180deg);
    }
    border: 1px solid ${Color.FogBlue} !important;
    border-radius: 2px !important;
    width: 100% !important;
    & .ant-select-selection {
      ${Typography.InputText};
      border: none !important;
      align-iterms: center !important;
      height: 38px !important;
      display: flex !important;
      & .ant-select-selection-selected-value {
        margin-top: 5px;
      }
    }
    & span.ant-select-arrow {
      color: ${Color.FineBlue} !important;
    }
  `,
);
