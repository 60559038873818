import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  birthday: 'birthday',
  ssn: 'ssn',
  martialStatus: 'martialStatus',
  noDependents: 'noDependents',
};

export const FormFieldsTypes = {
  [FormFields.birthday]: reviewFieldTypes.DateString,
  [FormFields.ssn]: reviewFieldTypes.SSNString,
  [FormFields.martialStatus]: reviewFieldTypes.SelectOptions,
  [FormFields.noDependents]: reviewFieldTypes.Number,
};

export const FormStrings = {
  [FormFields.birthday]: 'Birthday',
  [FormFields.ssn]: 'Social Security #',
  [FormFields.martialStatus]: 'Marital Status',
  [FormFields.noDependents]: 'Number of Dependents',
};
