import { css, cx } from 'emotion';
import { Typography, Color } from './../../styles';

export const container = css`
  background: ${Color.OffWhite} !important;
  min-height: 100vh !Important;
`;

export const logoContainer = css`
  justify-content: center;
  padding: 50px 0;
  display: flex;
`;

export const logo = css`
  width: 366px;
  height: 40px;
`;
