import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';

//TODO: Import from strings
export default (header: string) => {
  return {
    title: header,
    description: undefined,
    subTitle: undefined,
    formInformationKeyValue: [
      {
        key: 'Capital Preservation',
        value: ['Preservation of capital with a primary consideration on income.'],
      },
      {
        key: 'Income',
        value: ['A balance between capital appreciation and income with a primary consideration being income.'],
      },
      {
        key: 'Growth',
        value: ['Capital appreciation through quality equity investment and little or no income.'],
      },
      {
        key: 'Maximum Growth',
        value: ['Maximum capital appreciation with higher risk and little to no income. '],
      },
      {
        key: 'Speculation',
        value: [
          'Maximum total return potential, involving a higher degree of risk through investment in a broad spectrum of securities. ',
        ],
      },
    ],
    footer: undefined,
  };
};
