import React, { useEffect, useState } from 'react';
import * as Styles from './Style';
import SVGIcon from '../SVGIcon/SVGIcon';
import { useSelector } from 'react-redux';

type TSolutionCard = {
  solution: object;
  onClick?: Function;
};

const SolutionCard: React.FC<TSolutionCard> = (props, { ...shared }) => {
  // const [visible, setVisible] = useState(initial);
  // const solutions = useSelector((state: any) => state.solutions);
  const { icon, height }: any = props.solution;
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    console.log('clicked solution');
  };
  return (
    <>
      <div className={Styles.SolutionCard} onClick={handleClick}>
        <SVGIcon iconName={icon} height={height} className={Styles.SolutionLogo} />
      </div>
    </>
  );
};
//<ExternalSolutionCard solution={{ icon: 'myipo.svg', height: 22, width: 45 }} />
export default SolutionCard;
