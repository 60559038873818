import reviewFieldTypes from '../../../../../constants/reviewFieldTypes';

export const FormFields = {
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
};

export const FormFieldsTypes = {
  [FormFields.addressLineOne]: reviewFieldTypes.RawString,
  [FormFields.addressLineTwo]: reviewFieldTypes.RawString,
  [FormFields.city]: reviewFieldTypes.RawString,
  [FormFields.state]: reviewFieldTypes.RawString,
  [FormFields.zipCode]: reviewFieldTypes.RawString,
};

export const FormStrings = {
  [FormFields.addressLineOne]: 'Address Line 1',
  [FormFields.addressLineTwo]: 'Address Line 2 (Optional)',
  [FormFields.city]: 'City',
  [FormFields.state]: 'State',
  [FormFields.zipCode]: 'Zip Code',
};
