import React from 'react';

//TODO: Import from strings
export default (header: string) => {
  return {
    title: header,
    logo: 'trading-block.svg',
    description: (
      <>
        <p>Open a TradingBlock account to gain access to a wide variety of exchange-traded investment products.</p>
        <p>
          Please select the Account Types and Investment Strategies you wish to use with your account, and view the
          requirements below to ensure you meet TradingBlock's minimum requirements.
        </p>
      </>
    ),
    subTitle: 'Minimum Requirements',
    formInformationKeyValue: [
      {
        key: 'Stocks, ETFs, Mutual Funds, Bonds',
        value: ['No Requirements'],
      },
      {
        key: 'Long Calls/Puts, Covered Options',
        value: ['Beginner Investor'],
      },
      {
        key: 'Option Spreads',
        value: ['1 year of investment experience', '$2,000 Account Minimum for Margins'],
      },
      {
        key: 'Put Writing',
        value: [
          '1 year of investment experience',
          '$2,000 Account Minimum for Margins',
          '$50,000 Minimum Annual Income',
          '$50,000 Minimum Net Worth',
        ],
      },
      {
        key: 'Uncovered Calls, Index Options',
        value: [
          '2 years of investment experience',
          '$10,000 Account Minimum for Margins',
          '$100,000 Minimum Annual Income',
          '$100,000 Minimum Net Worth',
        ],
      },
    ],
    footer:
      '* Cambria Capital LLC has entered into a secondary clearing agreement with TradingBlock in which all accounts introduced by Cambria Capital will be custodied by Apex Clearing Corporation. TradingBlock is not affiliated with Cambria Capital, and does not control, review or endorse their services or products. TradingBlock and Cambria Capital LLC, are independently registered Broker-Dealers, FINRA and SIPC members and Registered Investment Advisers',
  };
};
