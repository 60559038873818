import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Formik } from 'formik';
import { FormText } from '../../../../../styles/typography';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';

import { TForm } from '../../../formTypes';
import Button, { ButtonType } from '../../../../shared/Buttons/Button';
import { ContinueAction } from '../../../FormActions';
import { onSkipClick } from '../../../../../utils/skipForm';
import currentForm from './index';
import * as Styles from './Style';

const Component = (props: TForm) => {
  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };
  const history = useHistory();

  return (
    <Formik initialValues={InitialValues} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        return (
          <>
            <div className={Styles.textBottomPadding}>
              {FormStrings.formText.map(text => {
                return <p className={FormText}>{text}</p>;
              })}
            </div>
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
              label="ADD NOW"
            />
            <div className={Styles.AddBeneficiariesLater}>
              <Button
                label="Add Beneficiaries Later"
                type={ButtonType.Link}
                onClick={() => {
                  onSkipClick(currentForm, history, false);
                }}
                disabled={false}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
