import React from 'react';
import { Button, Form, Input as AntInput, Popover } from 'antd';
import MaskedInput from 'antd-mask-input';
import './Input.css';
import { Trash } from '../Icon/Index';
import * as Styles from './Styles';

export enum MaskType {
  Ssn = 'SSN',
  Phone = 'PHONE',
}

interface InputType {
  name: string;
  labelInline?: boolean;
  label?: string;
  value?: any;
  errors?: any;
  handleSubmit?: Function;
  setFieldValue: Function;
  setFieldTouched: Function;
  handleBlur?: Function;
  onChangeFunction?: Function;
  type?: string;
  short?: boolean;
  disabled?: boolean;
  mask?: MaskType;
  bindValue?: any;
  values: any;
  step?: any;
  min?: number;
  max?: number;
}

interface IMaskDefinition {
  SSN: string;
  PHONE: string;
}

const MaskDefinition: IMaskDefinition = {
  SSN: '111-11-1111',
  PHONE: '(111) 111-1111',
};

const Input = (props: InputType) => {
  const {
    name,
    labelInline,
    label,
    value,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    onChangeFunction,
    type,
    short,
    disabled,
    mask,
    bindValue,
    values,
    step,
    min,
    max,
  } = props;
  const MaskedInputRef: any = !!mask ? React.createRef() : null;

  const conditionalProps: any = {};
  if (bindValue) conditionalProps.value = values[name];

  const onChange = (event: any) => {
    if (!!onChangeFunction) {
      onChangeFunction(event.target.value);
    }
    props.setFieldValue(props.name, event.target.value);
  };

  return (
    <>
      <Form.Item
        hasFeedback={!!errors && !!errors[name]}
        validateStatus={!!errors && errors[name] && 'error'}
        help={!!errors && errors[name]}
        className="inputLabel"
        label={label}
        colon={false}
      >
        {!!mask ? (
          <MaskedInput
            ref={MaskedInputRef}
            mask={MaskDefinition[mask]}
            name={name}
            style={short ? { width: '90px' } : undefined}
            className="input"
            placeholder=""
            type={type}
            disabled={disabled}
            defaultValue={!!value ? value : null}
            onChange={() => setFieldValue(name, MaskedInputRef.current.mask.getRawValue())}
            onBlur={() => setFieldTouched(name)}
            onPressEnter={() => handleSubmit}
            {...conditionalProps}
          />
        ) : (
          <AntInput
            name={props.name}
            style={props.short ? { width: '90px' } : undefined}
            className="input"
            placeholder=""
            type={props.type}
            disabled={props.disabled}
            defaultValue={!!value ? value : null}
            onChange={event => onChange(event)}
            onBlur={() => props.setFieldTouched(props.name)}
            onPressEnter={() => props.handleSubmit}
            {...conditionalProps}
            step={step}
            min={min}
            max={max}
          />
        )}
      </Form.Item>
    </>
  );
};

export default Input;
