export default {
  RawString: 'string',
  EmptiableString: 'estring',
  DateString: 'date',
  SSNString: 'ssn',
  Boolean: 'boolean',
  Number: 'number',
  SelectOptions: 'select',
  RadioGroup: 'radio',
  StringArray: 'sarray',
  CheckSelect: 'checkselect',
  ArrayObjects: 'oarray',
  Skip: 'skip',
};
