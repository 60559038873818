import { Action } from 'history';

export default {
  login: {},
  register: {},
  forgotPassword: {},
  changePassword: {},
  confirmEmail: {},
  authInfo: {
    token: { authState: 'signIn' },
  },
  forms: {
    accountType: { type: 'individual' },
  },
  solutions: {},
  router: {
    location: {
      pathname: '',
      search: '',
      state: '',
      hash: '',
      key: '',
    },
    action: 'PUSH' as Action,
  },
  intermediary: {},
  navigation: {
    backToReview: false,
    addingJointAccount: false,
  },
  externalAccountBalance: {
    plaidResponse: {
      responseMessage: '',
      responseStatus: '',
      institutionName: '',
      institutionLogo: '',
      value: 0,
    },
  },
  account: {
    contactInformation: {},
    contactInformationPatch: null,
    personalInformation: {},
    personalInformationPatch: null,
  },
};
