import React, { useState } from 'react';
import { Col, Layout, Row, Select } from 'antd';
import { ChartData, Doughnut } from 'react-chartjs-2';
import moment from 'moment';

import * as Styles from './style';
import Page from '../layout/Page/Page';
import { getGreetingTime } from '../../utils/greetingTime';
import solutions, { externalSolutions } from '../../constants/soutions';
import * as chartjs from 'chart.js';
import ConnectedSolutionCard from '../shared/ConnectedSolutionCard/ConnectedSolutionCard';
import ConnectOtherAccountDropdown from '../shared/ConnectOtherAccountDropdown/ConnectOtherAccountDropdown';
import Footer from '../shared/Footer/Footer';
import { useSelector } from 'react-redux';

const { Content } = Layout;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

type accountsAmount = {
  name: string;
  value: number;
  icon?: string;
  isBase64Icon?: boolean;
};

export default () => {
  const externalAccountBalance = useSelector((state: any) => state.externalAccountBalance.plaidResponse);
  const accountName = 'Joel';
  const lastUpdated = moment('2020-06-04 14:21:00').format('MMMM Do [at] H:mm');
  const lastUpdatedFilter = 'updated 34 minutes ago';

  let connectedSolutions: accountsAmount[] = [
    {
      name: 'My IPO',
      value: 37488.02,
    },
    {
      name: 'Trading Block',
      value: 7382.66,
    },
    {
      name: 'Charles Schwab',
      value: 23319,
    },
  ];
  //TODO: Get multiple connectedSolutions
  if (externalAccountBalance != null) {
    let external: accountsAmount = {
      name: externalAccountBalance.institutionName,
      value: externalAccountBalance.value,
      icon: externalAccountBalance.institutionLogo,
      isBase64Icon: true,
    };
    connectedSolutions.push(external);
  }
  const total = connectedSolutions.reduce((prev: number, cur: accountsAmount) => {
    return prev + cur.value;
  }, 0);
  const allConnectedSolutions = connectedSolutions.map((item: any) => {
    let solution = solutions.filter(solution => solution.app === item.name);
    let external = externalSolutions.filter(external => external.name === item.name);
    if (solution.length > 0) {
      return { ...solution[0], ...item, formatedValue: formatter.format(item.value), time: lastUpdatedFilter };
    } else if (external.length > 0) {
      return { ...external[0], ...item, formatedValue: formatter.format(item.value), time: lastUpdatedFilter };
    }
    return {
      ...item,
      color: '#545454',
      height: 25,
      formatedValue: formatter.format(item.value),
      time: lastUpdatedFilter,
    };
  });
  const data: ChartData<chartjs.ChartData> = {
    datasets: [
      {
        data: connectedSolutions.map(item => item.value),
        backgroundColor: allConnectedSolutions.map((item: any) => item.color),
      },
    ],
    labels: connectedSolutions.map(item => item.name),
  };
  const options: chartjs.ChartOptions = {
    responsive: false,
    legend: {
      display: false,
      position: 'right',
    },
    tooltips: {
      callbacks: {
        label(tooltipItem: any, data: any) {
          let solution = connectedSolutions[tooltipItem.index];
          return solution.name + ' ' + formatter.format(solution.value);
        },
      },
    },
    title: {
      display: false,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    aspectRatio: 1,
  };

  return (
    <Page>
      <Content className={Styles.Container}>
        <div className={Styles.ChartContainer}>
          <div className={Styles.Greetings}>{getGreetingTime() + ' ' + accountName}</div>
          <hr className={Styles.hr} />
          <Row className={Styles.ChartRow}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className={Styles.TotalContainer}>
              <div className={Styles.Total}>{formatter.format(total)}</div>
              <div className={Styles.TotalText}>Total Connected Account Value</div>
              <div className={Styles.TotalUpdatedText}>last updated on: {lastUpdated}</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Doughnut data={data} height={120} options={options} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className={Styles.Legend}>
                <ul className={Styles.LegendRow}>
                  {allConnectedSolutions.map(item => {
                    return (
                      <li key={item.name} className={Styles.LegendRowSolutionLine} style={{ color: item.color }}>
                        <span className={Styles.LegendRowSolutionName}>{item.name}</span>
                        <span className={Styles.LegendRowSolutionPercentage}>
                          {Math.floor((item.value / total) * 100)}%
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Col>
          </Row>
        </div>
        <div className={Styles.Filter}>Connected Accounts</div>
        <Row gutter={[12, 12]}>
          {allConnectedSolutions.map(item => {
            return (
              <Col key={item.name} xs={24} sm={24} md={12} lg={12} xl={12}>
                <ConnectedSolutionCard solution={item} />
              </Col>
            );
          })}
        </Row>
        <br />
        <Row>
          <ConnectOtherAccountDropdown />
        </Row>
      </Content>
      <Footer />
    </Page>
  );
};
