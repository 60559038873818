import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Dropdown, Icon, Menu, message, Row } from 'antd';
import { Logout } from '../../shared/Icon/Index';
import * as AuthActions from '../../../actions';
import { history } from '../../../store';

import * as Styles from './Style';
type TUserProfileDropdown = {
  initial?: boolean;
};

const UserProfileDropdown: FunctionComponent<TUserProfileDropdown> = ({ initial = false }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(initial);
  const fullname = 'Joel Vanderhoof';

  const handleMenuClick = ({ key }: any) => {
    console.log('click menu', key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item className={Styles.DropdownMenuList} key="1" onClick={() => history.push('/management')}>
        Personal Information
      </Menu.Item>
      <Menu.Item className={Styles.DropdownMenuList} key="2">
        Update Account
      </Menu.Item>
      <Menu.Item className={Styles.DropdownMenuList} key="3">
        Manage Connections
      </Menu.Item>
      <Menu.Item
        className={Styles.LogoutText}
        key="4"
        onClick={() => {
          dispatch(AuthActions.logout());
        }}
      >
        <Logout className={Styles.LogoutIcon} />
        Sign Out
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} placement="bottomRight" overlayClassName={Styles.DropdownOverlay}>
        <Button className={Styles.DropdownButton}>
          {fullname} <Icon type="down" />
        </Button>
      </Dropdown>
    </>
  );
};

export default UserProfileDropdown;
