import * as Yup from 'yup';
import { FormFields, FormStrings } from './constants';
import { TSelectOption } from '../../../../types/commonTypes';
import { Countries } from '../../../../constants/countries';

export const InitialValues = {
  [FormFields.birthCountry]: null,
  [FormFields.visaType]: null,
  [FormFields.visaExpiration]: null,
  [FormFields.proofOfEmployment]: null,
};

export const CountryOptions: TSelectOption[] = [...Countries];

export const VisaTypeOptions: TSelectOption[] = [
  {
    label: 'Select a type',
    value: null,
  },
  {
    label: 'E1',
    value: 'e1',
  },
];

export const FieldsOptions = {
  [FormFields.visaType]: VisaTypeOptions,
  [FormFields.birthCountry]: CountryOptions,
};
