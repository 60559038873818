import React, { useState } from 'react';

import SelectInput from '../shared/Select/Select';

import { FormStrings, SolutionFieldRelation } from './constants';

import { useSelector } from 'react-redux';

export type TProps = {
  form: any;
  options: any;
};

const Component = (props: TProps) => {
  const { form, options } = props;
  const solutions = useSelector((state: any) => state.solutions);
  const list = Object.keys(solutions).map((solution: string) => {
    const field: string = SolutionFieldRelation[solution];
    return <SelectInput {...form} name={field} label={FormStrings[field]} options={options} />;
  });

  return <>{list}</>;
};

export default Component;
