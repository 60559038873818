import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';
import { Row, Tooltip } from 'antd';

import { FormText } from '../../../../styles/typography';
import SelectInput from '../../../shared/Select/Select';

import { FormFields, FormStrings } from './constants';
import { InitialValues, RelationshipOptions } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';

import { ContinueAction } from '../../FormActions';
import Input, { MaskType } from '../../../shared/Inputs/Input';
import { Link } from '../../../../styles/typography';
import { useSelector } from 'react-redux';
import { getFormState } from '../../../../utils/forms';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => state.forms.trustedContactInfo);
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik initialValues={formState} validationSchema={defaultValidation} onSubmit={values => onSubmit(values)}>
      {form => {
        const relationshipOptions = RelationshipOptions;
        return (
          <>
            <Row>
              <Tooltip placement="topLeft" title={FormStrings.trustedText} arrowPointAtCenter>
                <span className={Link}>What is a trusted contact?</span>
              </Tooltip>
            </Row>
            <Input
              {...form}
              name={FormFields.firstName}
              label={FormStrings[FormFields.firstName]}
              value={formState[FormFields.firstName]}
            />
            <Input
              {...form}
              name={FormFields.lastName}
              label={FormStrings[FormFields.lastName]}
              value={formState[FormFields.lastName]}
            />
            <Input
              {...form}
              name={FormFields.phoneNumber}
              label={FormStrings[FormFields.phoneNumber]}
              value={formState[FormFields.phoneNumber]}
              mask={MaskType.Phone}
            />
            <Input
              {...form}
              name={FormFields.email}
              label={FormStrings[FormFields.email]}
              value={formState[FormFields.email]}
              type="email"
            />
            <SelectInput
              {...form}
              name={FormFields.relationship}
              label={FormStrings[FormFields.relationship]}
              options={relationshipOptions}
              defaultOption={
                formState[FormFields.relationship]
                  ? formState[FormFields.relationship]
                  : InitialValues[FormFields.relationship]
              }
            />{' '}
            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
