import React from 'react';
import { Formik, FieldArray } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../formTypes';
import * as Styles from './styles';

import { ContinueAction } from '../../FormActions';
import InfoText from '../../../shared/InfoText/InfoText';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import BindInput from '../../../shared/BindInputs/Input';
import Input from '../../../shared/Inputs/Input';
import Button, { ButtonType } from '../../../shared/Buttons/Button';
import { Typography } from '../../../../styles';
import { useSelector } from 'react-redux';
import { getFormState, jointAccountsIntermediaryFilter } from '../../../../utils/forms';
import { useHistory } from 'react-router';
import intermediaryJointStateHook from '../../../../hooks/intermediaryJointStateHook';

const Component = (props: TForm) => {
  // const history: any = useHistory();
  // const addingJointAccount = useSelector((state: any) => state.navigation.addingJointAccount);
  // const key =
  //   history.location.state && typeof history.location.state.id !== 'undefined' ? history.location.state.id : null;
  //
  // const state = useSelector((state: any) =>
  //   history.location.state && typeof history.location.state.id !== 'undefined' && addingJointAccount
  //     ? jointAccountsIntermediaryFilter(state.intermediary.jointAccountsIntermediary, props.id)
  //     : state.forms.regulatoryDisclosures,
  // );
  // const formState = getFormState(state, InitialValues);
  // const hasKey = key || key === 0 || addingJointAccount;
  const history: any = useHistory();
  const { key, formState, hasKey } = intermediaryJointStateHook(props.id, InitialValues, history);
  const onSubmit = (values: any) => {
    if (props.onContinue && hasKey) {
      props.onContinue({ ...values, id: key });
    } else if (props.onContinue) {
      props.onContinue(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <InfoText>{FormStrings.CheckBoxesOnlyIfApplicableLabel}</InfoText>
            <Checkbox
              {...form}
              name={FormFields.OfficerBoardMemberOr10HolderPublicCompany}
              label={FormStrings[FormFields.OfficerBoardMemberOr10HolderPublicCompany]}
              checked={formState[FormFields.OfficerBoardMemberOr10HolderPublicCompany]}
            />
            {form.values[FormFields.OfficerBoardMemberOr10HolderPublicCompany] === true && (
              <div className={Styles.container}>
                <FieldArray
                  name={FormFields.CompanyTicker}
                  render={(arrayHelpers: any) => (
                    <div>
                      {form.values[FormFields.CompanyTicker]
                        ? (form.values[FormFields.CompanyTicker] as string[]).map((value, index) => (
                            <div key={index}>
                              {index !== 0 && <hr className={Typography.hr} />}
                              <BindInput
                                {...form}
                                name={`${FormFields.CompanyTicker}.${index}`}
                                label={FormStrings[FormFields.CompanyTicker]}
                                value={value}
                                onDelete={() => {
                                  arrayHelpers.remove(index);
                                }}
                                short
                              />
                            </div>
                          ))
                        : arrayHelpers.push('')}
                      <Button
                        label={'Add another company'}
                        type={ButtonType.Link}
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      />
                    </div>
                  )}
                />
                <br />
              </div>
            )}

            <Checkbox
              {...form}
              name={FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange}
              label={FormStrings[FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange]}
              checked={formState[FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange]}
            />
            {form.values[FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange] == true && (
              <Input
                {...form}
                name={FormFields.FirmOrExchangeName}
                label={FormStrings[FormFields.FirmOrExchangeName]}
                value={formState[FormFields.FirmOrExchangeName]}
              />
            )}

            <InfoText>{FormStrings.SeniorForeignGovernmentOfficialFamilyMemberOrCloseAssociateLabel}</InfoText>

            <Checkbox
              {...form}
              name={FormFields.FamilyMember}
              label={FormStrings[FormFields.FamilyMember]}
              checked={formState[FormFields.FamilyMember]}
            />
            {form.values[FormFields.FamilyMember] == true && (
              <div className={Styles.container}>
                <FieldArray
                  name={FormFields.FamilyMemberDetails}
                  render={(arrayHelpers: any) => (
                    <div>
                      {form.values[FormFields.FamilyMemberDetails]
                        ? (form.values[FormFields.FamilyMemberDetails] as any).map((value: any, index: number) => (
                            <div key={index}>
                              {index !== 0 && <hr className={Typography.hr} />}
                              <BindInput
                                {...form}
                                name={`${FormFields.FamilyMemberDetails}.${index}.FamilyMemberFullName`}
                                label={FormStrings[FormFields.FamilyMemberFullName]}
                                value={value.FamilyMemberFullName}
                                onDelete={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <BindInput
                                {...form}
                                name={`${FormFields.FamilyMemberDetails}.${index}.FamilyMemberRelationship`}
                                label={FormStrings[FormFields.FamilyMemberRelationship]}
                                value={value.FamilyMemberRelationship}
                              />
                            </div>
                          ))
                        : arrayHelpers.push('')}
                      <Button
                        label={'Add another family member'}
                        type={ButtonType.Link}
                        onClick={() => {
                          arrayHelpers.push({});
                        }}
                      />
                    </div>
                  )}
                />
                <br />
              </div>
            )}

            <Checkbox
              {...form}
              name={FormFields.SeniorForeignGovernmentOfficial}
              label={FormStrings[FormFields.SeniorForeignGovernmentOfficial]}
              checked={formState[FormFields.SeniorForeignGovernmentOfficial]}
            />
            {form.values[FormFields.SeniorForeignGovernmentOfficial] == true && (
              <div className={Styles.container}>
                <FieldArray
                  name={FormFields.SeniorForeignGovernmentOfficialDetails}
                  render={(arrayHelpers: any) => (
                    <div>
                      {form.values[FormFields.SeniorForeignGovernmentOfficialDetails]
                        ? (form.values[FormFields.SeniorForeignGovernmentOfficialDetails] as any).map(
                            (value: any, index: number) => (
                              <div key={index}>
                                {index !== 0 && <hr className={Typography.hr} />}
                                <BindInput
                                  {...form}
                                  name={`${FormFields.SeniorForeignGovernmentOfficialDetails}.${index}.SeniorForeignGovernmentOfficialPoliticalOrganization`}
                                  label={FormStrings[FormFields.SeniorForeignGovernmentOfficialPoliticalOrganization]}
                                  value={value.SeniorForeignGovernmentOfficialPoliticalOrganization}
                                  onDelete={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                />
                                <BindInput
                                  {...form}
                                  name={`${FormFields.SeniorForeignGovernmentOfficialDetails}.${index}.SeniorForeignGovernmentOfficialFullName`}
                                  label={FormStrings[FormFields.SeniorForeignGovernmentOfficialFullName]}
                                  value={value.SeniorForeignGovernmentOfficialFullName}
                                />
                              </div>
                            ),
                          )
                        : arrayHelpers.push('')}
                      <Button
                        label={'Add another senior foreign government official'}
                        type={ButtonType.Link}
                        onClick={() => {
                          arrayHelpers.push({});
                        }}
                      />
                    </div>
                  )}
                />
                <br />
              </div>
            )}

            <Checkbox
              {...form}
              name={FormFields.IAmNotAssociatedWithAForeignShellBank}
              label={FormStrings[FormFields.IAmNotAssociatedWithAForeignShellBank]}
              checked={formState[FormFields.IAmNotAssociatedWithAForeignShellBank]}
            />

            <ContinueAction
              id={props.id}
              disabled={!form.isValid}
              values={hasKey ? { ...form.values, id: key } : form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
