import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Formik } from 'formik';
import _ from 'lodash';

import formInjector from '../../formInjector';
import { useSelector } from 'react-redux';
import ReviewGroup from './ReviewGroup';
import BeneficiariesReview from './BeneficiariesReview';
import AuthorizedPersonReview from './AuthorizedPersonReview';
import JointAccountReview from './JointAccountReview';
import categories from '../../../../constants/categories';

const ReviewForm = () => {
  const forms = useSelector((state: any) => state.forms);
  const formInjectorValues = Object.values(formInjector);
  const accountType = forms.accountType && forms.accountType.type ? forms.accountType.type : null;

  const custodial = !!forms.custodialMinorName;
  const retirement = forms.retirement && forms.retirement.length > 0 ? forms.retirement : null;
  const entity = forms.entity && forms.entity.entityInformation ? forms.entity : null;
  const joint =
    accountType && accountType === 'joint' && forms.jointAccounts && forms.jointAccounts.length > 0
      ? forms.jointAccounts
      : null;

  var grouped = _.mapValues(_.groupBy(formInjectorValues, 'category'), forms =>
    forms.map(car => _.omit(car, 'category')),
  );
  if (!custodial) {
    delete grouped[categories.custodialInformation];
  }
  if (!entity) {
    delete grouped[categories.entityInformation];
  }
  // const expandableReviewLists =
  const groupedReviewer = Object.keys(grouped).map(key => {
    if (key !== 'undefined') {
      return <ReviewGroup injectedForm={grouped[key]} groupName={key} forms={forms}></ReviewGroup>;
    }
  });
  return (
    <>
      {groupedReviewer}
      {retirement && <BeneficiariesReview state={retirement} />}
      {entity && <AuthorizedPersonReview state={entity} />}
      {joint && <JointAccountReview state={joint} />}
    </>
  );
};

export default ReviewForm;
