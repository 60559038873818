export const getGreetingTime = () => {
  let today = new Date();
  let curHr = today.getHours();
  // ~5:00 to ~12:00: Good morning.
  // ~12:00 to ~ 17:00: Good afternoon.
  // ~17:00 to ~5:00: Good evening.
  if (curHr >= 5 && curHr < 12) {
    return 'Good Morning';
  } else if (curHr >= 12 && curHr < 17) {
    return 'Good Afternoon ';
  } else {
    return 'Good Evening ';
  }
};
