import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FormFields, FormStrings } from './constants';
import { InitialValues } from './schema';
import { defaultValidation } from './validations';
import { TForm } from '../../../formTypes';

import { ContinueEntityAction } from '../../../FormActions';
import { getFormState } from '../../../../../utils/forms';
import Input, { MaskType } from '../../../../shared/Inputs/Input';

const Component = (props: TForm) => {
  const state = useSelector((state: any) => (state.forms.entity ? state.forms.entity.entityInformation : null));
  const formState = getFormState(state, InitialValues);

  const onSubmit = (values: any) => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formState}
      validationSchema={defaultValidation}
      onSubmit={values => onSubmit(values)}
    >
      {form => {
        return (
          <>
            <Input
              {...form}
              name={FormFields.name}
              label={FormStrings[FormFields.name]}
              value={formState[FormFields.name]}
            />
            <Input
              {...form}
              name={FormFields.phoneNumber}
              label={FormStrings[FormFields.phoneNumber]}
              value={formState[FormFields.phoneNumber]}
              mask={MaskType.Phone}
            />
            <Input
              {...form}
              name={FormFields.email}
              label={FormStrings[FormFields.email]}
              value={formState[FormFields.email]}
              type="email"
            />
            <ContinueEntityAction
              id={props.id}
              disabled={!form.isValid}
              values={form.values}
              onContinue={form.handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Component;
