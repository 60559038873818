import Validations from './validations';
import { InitialValues } from './schema';
import { TFormConfig } from '../../formTypes';

//TODO: Import from strings
export default (header: string) => {
  return {
    title: header,
    description: undefined,
    subTitle: undefined,
    formInformationKeyValue: [
      {
        key: 'Conservative',
        value: [
          'I want to preserve my initial principal in this account, with minimal risk, even if that means this account does not generate significant income or returns and may not keep pace with inflation.',
        ],
      },
      {
        key: 'Moderately Conservative',
        value: [
          'I am willing to accept a low risk to my initial principal, including low volatility, to seek a modest level of portfolio returns.',
        ],
      },
      {
        key: 'Moderate',
        value: [
          'I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns and understand I could lose a portion of the money invested.',
        ],
      },
      {
        key: 'Moderately Aggressive',
        value: [
          'I am willing to accept a high risk to my initial principal, including high volatility, to seek high returns over time, and understand I could lose a substantial amount of the money invested.',
        ],
      },
      {
        key: 'Significant Risk',
        value: [
          'I am willing to accept the maximum risk to my initial principal to aggressively seek maximum returns and understand I could lose most, or all, of the money invested.',
        ],
      },
    ],
    footer: undefined,
  };
};
