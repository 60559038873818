import * as Yup from 'yup';
import { FormStrings, FormFields } from './constants';

export const validationMessages = {
  articlesOfIncorporationRequired: `${FormStrings[FormFields.articlesOfIncorporation]} is required`,
  certificateOfIncorporationRequired: `${FormStrings[FormFields.certificateOfIncorporation]} is required`,
  corporateAgreementRequired: `${FormStrings[FormFields.corporateAgreement]} is required`,
  certificateOfPartnershipRequired: `${FormStrings[FormFields.certificateOfPartnership]} is required`,
  partnershipAgreementRequired: `${FormStrings[FormFields.partnershipAgreement]} is required`,
  trusteeCertificationRequired: `${FormStrings[FormFields.trusteeCertification]} is required`,
  trustAgreementRequired: `${FormStrings[FormFields.trustAgreement]} is required`,
  certificateOfFormationRequired: `${FormStrings[FormFields.certificateOfFormation]} is required`,
  operatingAgreementRequired: `${FormStrings[FormFields.operatingAgreement]} is required`,
};

export const defaultValidation = {
  [FormFields.Corporation]: Yup.object().shape({
    [FormFields.uploadLater]: Yup.boolean(),
    [FormFields.documents]: Yup.object().when([FormFields.uploadLater], {
      is: true,
      then: Yup.object(),
      otherwise: Yup.object().shape({
        [FormFields.articlesOfIncorporation]: Yup.string().required(validationMessages.articlesOfIncorporationRequired),
        [FormFields.certificateOfIncorporation]: Yup.string().required(
          validationMessages.certificateOfIncorporationRequired,
        ),
        [FormFields.corporateAgreement]: Yup.string().required(validationMessages.corporateAgreementRequired),
      }),
    }),
  }),
  [FormFields.Partnership]: Yup.object().shape({
    [FormFields.uploadLater]: Yup.boolean(),
    [FormFields.documents]: Yup.object().when([FormFields.uploadLater], {
      is: true,
      then: Yup.object(),
      otherwise: Yup.object().shape({
        [FormFields.articlesOfIncorporation]: Yup.string().required(validationMessages.articlesOfIncorporationRequired),
        [FormFields.certificateOfPartnership]: Yup.string().required(
          validationMessages.certificateOfPartnershipRequired,
        ),
        [FormFields.partnershipAgreement]: Yup.string().required(validationMessages.partnershipAgreementRequired),
      }),
    }),
  }),
  [FormFields.Trust]: Yup.object().shape({
    [FormFields.uploadLater]: Yup.boolean(),
    [FormFields.documents]: Yup.object().when([FormFields.uploadLater], {
      is: true,
      then: Yup.object(),
      otherwise: Yup.object().shape({
        [FormFields.articlesOfIncorporation]: Yup.string().required(validationMessages.articlesOfIncorporationRequired),
        [FormFields.trusteeCertification]: Yup.string().required(validationMessages.trusteeCertificationRequired),
        [FormFields.trustAgreement]: Yup.string().required(validationMessages.operatingAgreementRequired),
      }),
    }),
  }),
  [FormFields.LLC]: Yup.object().shape({
    [FormFields.uploadLater]: Yup.boolean(),
    [FormFields.documents]: Yup.object().when([FormFields.uploadLater], {
      is: true,
      then: Yup.object(),
      otherwise: Yup.object().shape({
        [FormFields.articlesOfIncorporation]: Yup.string().required(validationMessages.articlesOfIncorporationRequired),
        [FormFields.certificateOfFormation]: Yup.string().required(validationMessages.certificateOfFormationRequired),
        [FormFields.operatingAgreement]: Yup.string().required(validationMessages.trustAgreementRequired),
      }),
    }),
  }),
};

export default defaultValidation;
