import { css } from 'emotion';

export const error = css`
  color: #f5222d !Important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif!Important;
  font-size: 12px!Important;
  margin-top: 4px!Important;
  min-height: 22px!Important;
  line-height: 1.5!Important;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) !Important;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) !Important;
  display: block!Important;
`;
