import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Url from '../constants/url';
import { Type, State } from '../actions/utils';
import { HttpClient } from '../helpers/httpClient';
import { TReduxAction } from '../types/commonTypes';
import { removeSuitabilityInfo } from './forms';

export function* addSolution(action: TReduxAction) {
  try {
    if (!action.payload.solutionCode) throw new Error('Solution code not found!');
    yield put({ type: State.actionSucceeded(Type.ADD_SOLUTION), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.ADD_SOLUTION), error: error.message });
  }
}

export function* removeSolution(action: TReduxAction) {
  try {
    if (!action.payload.solutionCode) throw new Error("Can't find solution to remove!");
    yield put({ type: State.actionSucceeded(Type.REMOVE_SOLUTION), payload: action.payload });
    yield call(removeSuitabilityInfo, {
      type: State.actionRequested(Type.REMOVE_SUITABILITY_INFO),
      payload: action.payload,
    });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.REMOVE_SOLUTION), error: error.message });
  }
}

export function* getSolutions(action: TReduxAction) {
  try {
    const solutions = yield select();
    yield put({ type: State.actionSucceeded(Type.GET_SOLUTIONS), payload: solutions });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.GET_SOLUTIONS), error: error.message });
  }
}

export function* addSubSolution(action: TReduxAction) {
  try {
    if (!action.payload.solutionCode) throw new Error('Sub Solution code not found!');
    yield put({ type: State.actionSucceeded(Type.ADD_SUB_SOLUTION), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.ADD_SUB_SOLUTION), error: error.message });
  }
}

export function* removeSubSolution(action: TReduxAction) {
  try {
    if (!action.payload.solutionCode) throw new Error("Can't find sub solution to remove!");
    yield put({ type: State.actionSucceeded(Type.REMOVE_SUB_SOLUTION), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.REMOVE_SUB_SOLUTION), error: error.message });
  }
}

export function* getSubSolutions(action: TReduxAction) {
  try {
    const solutions = yield select();
    yield put({ type: State.actionSucceeded(Type.GET_SUB_SOLUTIONS), payload: solutions });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.GET_SUB_SOLUTIONS), error: error.message });
  }
}

/**
 * Solutions sagas
 */
export default function* saga() {
  yield takeEvery(State.actionRequested(Type.ADD_SOLUTION), addSolution);
  yield takeEvery(State.actionRequested(Type.REMOVE_SOLUTION), removeSolution);
  yield takeEvery(State.actionRequested(Type.GET_SOLUTIONS), getSolutions);
  yield takeEvery(State.actionRequested(Type.ADD_SUB_SOLUTION), addSubSolution);
  yield takeEvery(State.actionRequested(Type.REMOVE_SUB_SOLUTION), removeSubSolution);
  yield takeEvery(State.actionRequested(Type.GET_SUB_SOLUTIONS), getSubSolutions);
}
