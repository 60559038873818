import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useField } from 'formik';
import * as Routes from '../../../../../constants/routes';
import { path as AddTrustPath } from '../TrustInformation';
import { path as BeneficiaryInformation } from '../BeneficiaryInformation';
import { FormFields, FormStrings } from './constants';
import Input from '../../../../shared/BindInputs/Input';

import Button, { ButtonType } from '../../../../shared/Buttons/Button';
import { Trash } from '../../../../shared/Icon/Index';
import { Button as AntButton } from 'antd';
import * as Styles from './Style';
import './../../../../shared/Inputs/Input.css';

export const BeneficiaryPercentage = ({ index, form, name, item, updateTotalPercentage, removeBeneficiary }: any) => {
  const history = useHistory();
  const [completedField, completedMeta, completedHelpers] = useField({
    name: `${FormFields.sharePercentage}[${index}].sharePercentage`,
    type: 'number',
  });
  const [contentField, contentMeta, contentHelpers] = useField(
    `${FormFields.sharePercentage}[${index}].sharePercentage`,
  );
  const onContentInput = (e: any) => {
    contentHelpers.setValue(e);
    completedHelpers.setValue(e);
    completedHelpers.setTouched(true);
  };

  useEffect(() => {
    updateTotalPercentage();
  }, [completedField.value]);

  const editBeneficiary = (values: any) => {
    let route = '';
    if (values.type === 'trust') {
      route = `${Routes.REGISTER_INFO}/${AddTrustPath}`;
    } else {
      route = `${Routes.REGISTER_INFO}/${BeneficiaryInformation}`;
    }
    history.push(route, { ...values, id: values.id } as any);
  };

  return (
    <>
      <div className={Styles.Container}>
        <div className={Styles.BeneficiaryPerson}>
          {name}
          <Button
            label="Edit"
            type={ButtonType.Link}
            onClick={() => editBeneficiary(item)}
            className={Styles.EditLinkButton}
          />
        </div>
        <AntButton className={Styles.TrashButton} size="small" onClick={() => removeBeneficiary()}>
          <Trash width="14" height="16" viewBox="0 0 14 16" fill="#FF244E" />
        </AntButton>
      </div>
      <Input
        {...form}
        errors={{ [completedField.name]: completedMeta.error }}
        label={FormStrings[FormFields.sharePercentage]}
        name={completedField.name}
        value={completedField.value}
        short
        type={'number'}
        min={0.1}
        max={100}
        step={0.1}
        onChangeFunction={(value: number) => onContentInput(value)}
        bindValue={true}
      />
    </>
  );
};
