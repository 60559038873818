import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const Primary = css`
  display: inline-block !important;
  padding-right: 20px !important;
  font-family: Gill Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.308642px;
  color: #423c3a;
`;
export const Secondary = css`
  padding-left: 24px !important;
  padding-right: 20px !important;
  display: inline-block !important;
  font-family: GillSans;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.26455px;
  color: #423c3a;
`;
export const IconStyle = css`
  max-height: 16px !important;
  padding-left: 8px !important;
`;

export const popoverStyle = css`
  float: right;
  .ant-popover-content .ant-popover-inner .ant-popover-title {
    border-bottom: none !important;
  }
`;
export const title = css`
  font-family: Cormorant Garamond !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 27px !important;
  letter-spacing: 0.291005px !important;
  color: #423c3a !important;
`;

export const TrashButton = css`
  display: inline-block !important;
  float: right !important;
  min-width: 24px !important;
  padding-top: 3px !important;
  background-color: rgba(255, 36, 78, 0.1) !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  &:hover {
    background-color: rgba(255, 36, 78, 1) !important;
    border-color: rgba(255, 36, 78, 1) !important;
    .svg-icon path {
      fill: white;
    }
  }
`;

const sharedPropButton = css`
  width: 100% !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  align-self: center !important;
  &:hover {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015) !important;
  }
`;
export const deleteButton = cx(
  sharedPropButton,
  css`
    background: #ff244e !important;
    color: #ffffff !important;
  `,
);

export const cancelButton = cx(
  sharedPropButton,
  css`
    background: #white !important;
    color: #0098ce !important;
  `,
);

export const EditSolutions = css`
  background: #ffffff !Important;
  border-radius: 20px !Important;
  font-family: Gill Sans !Important;
  font-style: normal !Important;
  font-weight: normal !Important;
  font-size: 14px !Important;
  line-height: 16px !Important;
  color: ${Color.TropicalBlue} !Important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
`;
