import { toCurrency } from '../../../../utils/currencyUtils';

export const ranges = [
  [-1, 50000, 1],
  [50000, 100000, 2],
  [100000, 250000, 3],
  [250000, 500000, 4],
  [500000, -1, 5],
];

export const getRangeOptions = () => {
  return ranges.map(i => {
    let label =
      i[0] === -1 && i[1] !== -1
        ? `$0 to ${toCurrency(i[1] as number)}`
        : `${toCurrency((i[0] + 1) as number)} to  ${toCurrency(i[1] as number)}`;
    if (i[1] === -1) {
      label = `Greater than  ${toCurrency((i[0] + 1) as number)}`;
    }
    return {
      label,
      value: i[2],
    };
  });
};
