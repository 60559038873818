import * as React from 'react';
import { connect } from 'react-redux';

import Button, { ButtonType } from '../shared/Buttons/Button';
import { logout } from '../../actions/auth';
import { isLoggedInSelector } from '../../selectors/auth';

type Props = {
  logout: Function;
  isLoggedIn: boolean;
};

type State = {};

class Component extends React.Component<Props, State> {
  readonly state: State = {};

  logout() {
    this.props.logout();
  }

  render() {
    return (
      <React.Fragment>
        <h1>Home</h1>
        {this.props.isLoggedIn && <Button onClick={() => this.logout()} label={'LogOut'} type={ButtonType.Thick} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoggedIn: isLoggedInSelector(state),
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
