import { Col, Divider, Row, Spin, message, Icon } from 'antd';
import _, { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
import { alignLeft, alignRight, headerTextModal, spinner, spinnerContainer } from '../../style';
import Tooltip, { TooltipPosition } from '../../../shared/Tooltip';
import FormComponent from '../FormComponent';
import { getAccountInformation, updateAccountInformation } from '../../../../actions/index';
import { accountOwnerValidation } from './validations';

import getFields from './fields';

type AccountInformationProps = {
  accountInformation: any;
  accountInformationPatch?: boolean;
  error?: string;
  getAccountInformation: Function;
  updateAccountInformation: Function;
};

const AccountInformationComp: React.FC<AccountInformationProps> = ({
  accountInformation,
  accountInformationPatch,
  error,
  getAccountInformation,
  updateAccountInformation,
}: AccountInformationProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pickedSectionForEditing, setPickedSectionForEditing] = useState({});

  useEffect(() => {
    if (_.isEmpty(accountInformation) && _.isEmpty(error) && !isFetching) {
      setIsFetching(true);
      getAccountInformation();
    }
  }, []);

  useEffect(() => {
    if (!isSubmitting && isFetching) {
      setIsFetching(false);
    } else if (isSubmitting && isFetching) {
      setIsFetching(false);
      setIsSubmitting(false);
      setIsModalVisible(false);
      setPickedSectionForEditing({});
      message.success({ content: 'Information successfully updated ' });
    }
    return () => {
      message.destroy();
    };
  }, [accountInformation]);

  useEffect(() => {
    if (isSubmitting) {
      if (accountInformationPatch === false) {
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});

        message.error({ content: 'Something went wrong and the edited information could not be saved.' });
      } else if (accountInformationPatch === true) {
        // TODO: Comment out on real fetch
        // setIsFetching(true);
        // getAccountInformation();

        // TODO: Remove on real fetch
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});
        message.success({ content: 'Information successfully updated ' });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [accountInformationPatch]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      // Fetch Error
      if (!isSubmitting && isFetching) {
        setIsFetching(false);
        message.error({ content: error });
      }
      // Patch Error
      if (isSubmitting && !isFetching) {
        setIsSubmitting(false);
        setIsModalVisible(false);
        setPickedSectionForEditing({});
        message.error({ content: error });
      }
      // Cleanup after unmounting
      return () => {
        message.destroy();
      };
    }
  }, [error]);

  useEffect(() => {
    if (!_.isEmpty(pickedSectionForEditing)) {
      setIsModalVisible(true);
    }
  }, [pickedSectionForEditing]);

  const onSubmit = async (values: any) => {
    let updatedData = { ...accountInformation, ...values };
    setIsSubmitting(true);
    await updateAccountInformation(updatedData);
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setPickedSectionForEditing({});
  };

  if ((isFetching === true && !isSubmitting) || _.isEmpty(accountInformation)) {
    return (
      <div className={spinnerContainer}>
        <div className={spinner}>
          <Spin />
        </div>
      </div>
    );
  }

  const fields: any = getFields(accountInformation);

  const sections: Array<any> = [
    {
      sectionLabel: 'Account Owner Information',
      fieldKeys: ['firstName', 'middleInitial', 'lastName', 'phoneNumber'],
      sectionDescription: '',
      validationSchema: accountOwnerValidation,
    },
  ];

  return (
    <>
      <Row>
        <p className={headerTextModal}>Account Information</p>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Col span={12}>
          <span className={alignLeft} style={{ color: '#989898' }}>
            Account Type
          </span>
        </Col>
        <Col span={12}>
          <Tooltip placement={TooltipPosition.Top} title={'Account Type cannot be edited'}>
            <span className={alignRight} style={{ color: '#0098CE' }}>
              <Icon type="info-circle" style={{ color: 'rgb(0, 152, 206)', cursor: 'pointer' }} />
            </span>
          </Tooltip>
        </Col>
        <Divider />
      </Row>

      <Row>
        <Col span={12}>
          <span className={alignLeft}>{fields['accountType'].viewLabel}</span>
        </Col>

        <Col span={12}>
          <span className={alignRight}>
            {_.isEmpty(fields['accountType'].displayValue) ? '-' : fields['accountType'].displayValue}
          </span>
        </Col>
        <Divider />
      </Row>

      {sections.map(section => (
        <Fragment key={`${_.snakeCase(section.sectionLabel)}_fragment`}>
          <Row key={`${_.snakeCase(section.sectionLabel)}_section`} style={{ marginTop: '20px' }}>
            <Col span={12}>
              <span className={alignLeft} style={{ color: '#989898' }}>
                {section.sectionLabel}
              </span>
            </Col>

            <Col span={12}>
              <a
                className={alignRight}
                type="primary"
                onClick={() => {
                  setPickedSectionForEditing(section);
                }}
                style={{ color: '#0098CE' }}
              >
                Edit
              </a>
            </Col>
            <Divider />
          </Row>
          {Object.keys(fields)
            .filter(key => section.fieldKeys.includes(key))
            .map(key => {
              return (
                <Row key={`${key}_input`}>
                  <Col span={12}>
                    <span className={alignLeft}>{fields[key].viewLabel}</span>
                  </Col>

                  <Col span={12}>
                    <span className={alignRight}>
                      {_.isEmpty(fields[key].displayValue) ? '-' : fields[key].displayValue}
                    </span>
                  </Col>
                  <Divider />
                </Row>
              );
            })}
        </Fragment>
      ))}
      {
        <FormComponent
          isModalVisible={isModalVisible && !_.isEmpty(pickedSectionForEditing)}
          fields={Object.keys(fields)
            .filter(key => {
              if (_.isEmpty(pickedSectionForEditing)) {
                return false;
              }
              const section: any = pickedSectionForEditing;
              return section.fieldKeys.includes(key);
            })
            .map(key => ({ key: key, value: fields[key] }))
            .reduce(
              (obj, keyValuePair) => ({
                ...obj,
                [keyValuePair.key]: keyValuePair.value,
              }),
              {},
            )}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={onCancel}
          section={pickedSectionForEditing}
        />
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  accountInformation: get(state, 'account.accountInformation'),
  accountInformationPatch: get(state, 'account.accountInformationPatch'),
  error: get(state, 'account.error'),
});

const mapDispatchToProps = {
  getAccountInformation,
  updateAccountInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformationComp);
