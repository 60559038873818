import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { isLoggedInSelector } from '../../selectors/auth';
import TopNavigationBar from '../shared/TopNavigationBar/TopNavigationBar';
import AccountInformation from './AccountInformation';
import * as Style from './style';
import Authentication from './Authentication';
import { getRelativePathName } from '../../utils/routerUtils';

import { MANAGEMENT_ACCOUNT_INFORMATION, MANAGEMENT_AUTHENTICATION } from '../../constants/routes';

const { TabPane } = Tabs;

const Management: React.FC = (props: any) => {
  const internalRoutes: any = {
    'account-information': {
      path: MANAGEMENT_ACCOUNT_INFORMATION,
      component: AccountInformation,
      tabName: 'Account Information',
    },
    authentication: {
      path: MANAGEMENT_AUTHENTICATION,
      component: Authentication,
      tabName: 'Authentication',
    },
  };

  const stringMatchedPath = getRelativePathName(props.match.path, internalRoutes) ?? 'account-information';
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [currentTab, setCurrentTab] = useState(`${stringMatchedPath}_tab`);

  const TabStyle = {
    fontFamily: 'Cormorant Garamond',
    fontWeight: 300,
    fontSize: '22px',
    lineHeight: '18px',
    letterSpacing: '0.35px',
    color: '#077CB6',
  };

  const SelectedTabStyle = {
    fontFamily: 'Cormorant Garamond',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '18px',
    letterSpacing: '0.35px',
    color: '#077CB6',
  };

  return (
    <div className={Style.Body}>
      <TopNavigationBar topLinks temporaryLoggedIn={isLoggedIn} />

      <div className={Style.mainView}>
        <Tabs defaultActiveKey={stringMatchedPath} onChange={key => setCurrentTab(`${key}_tab`)}>
          {Object.keys(internalRoutes).map(routeMetaKey => {
            const Comp = internalRoutes[routeMetaKey].component;
            return (
              <TabPane
                key={routeMetaKey}
                tab={
                  <span style={currentTab === `${routeMetaKey}_tab` ? SelectedTabStyle : TabStyle}>
                    {internalRoutes[routeMetaKey].tabName}
                  </span>
                }
              >
                <Comp match={props.match} />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Management;
