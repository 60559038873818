import { css, cx } from 'emotion';
import { Typography, Color } from '../../../styles';

export const SolutionCard = css`
  max-width: 180px;
  max-height: 100px;
  width: 180px;
  height: 100px;
  background: ${Color.PureWhite};
  border: 1.0403px solid ${Color.FogBlue};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 2.0806px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
`;

export const SolutionLogo = css`
  line-height: 100px !important;
  display: block !important;
  margin: auto;
`;
