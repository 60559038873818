import { formInjector } from './formInjector';
import ReviewForm from './Registration/Review';

const f = {
  ...formInjector,
};

export const formDependencyMapping: any = {
  // [f.Assets.name]: [],
  // [f.EmploymentInfo.name]: [],
};

export const getNextReviewForm = (currentFormId: string, parentFormId: string) => {
  const formDependencies = formDependencyMapping[parentFormId];
  let form: any = null;
  if (formDependencies && formDependencies.length) {
    if (currentFormId === parentFormId) {
      form = formDependencies[0];
    } else {
      const currentFormIndex = formDependencies.findIndex((x: any) => x.id === currentFormId);
      const nextForm = formDependencies[currentFormIndex + 1];
      if (nextForm) {
        form = nextForm;
      }
    }
    if (form) {
      return f[`${form.id.charAt(0).toUpperCase()}${form.id.substr(1)}`];
    }
  } else {
    form = formInjector[ReviewForm.path];
  }
  return form;
};
