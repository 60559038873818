import { put, takeEvery } from 'redux-saga/effects';
import { Type, State } from '../actions/utils';

export function* saveRetirementIntermediaryFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE), error: error.message });
  }
}
export function* deleteRetirementIntermediaryFormState(action: any) {
  try {
    yield put({ type: State.actionSucceeded(Type.DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE), payload: action.payload });
  } catch (error) {
    yield put({ type: State.actionFailed(Type.DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE), error: error.message });
  }
}

export function* saveEntityAuthorisedPersonIntermediaryFormState(action: any) {
  try {
    yield put({
      type: State.actionSucceeded(Type.SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: State.actionFailed(Type.SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
      error: error.message,
    });
  }
}
export function* deleteEntityAuthorisedPersonIntermediaryFormState(action: any) {
  try {
    yield put({
      type: State.actionSucceeded(Type.DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: State.actionFailed(Type.DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
      error: error.message,
    });
  }
}

export function* addJointAccountsIntermediaryFormState(action: any) {
  try {
    yield put({
      type: State.actionSucceeded(Type.SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE),
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: State.actionFailed(Type.SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE),
      error: error.message,
    });
  }
}
export function* deleteJointAccountsIntermediaryFormState(action: any) {
  try {
    yield put({
      type: State.actionSucceeded(Type.DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE),
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: State.actionFailed(Type.DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE),
      error: error.message,
    });
  }
}

/**
 * Forms sagas
 */
export default function* saga() {
  yield takeEvery(
    State.actionRequested(Type.SAVE_RETIREMENT_INTERMEDIARY_FORM_STATE),
    saveRetirementIntermediaryFormState,
  );
  yield takeEvery(
    State.actionRequested(Type.DELETE_RETIREMENT_INTERMEDIARY_FORM_STATE),
    deleteRetirementIntermediaryFormState,
  );
  yield takeEvery(
    State.actionRequested(Type.SAVE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
    saveEntityAuthorisedPersonIntermediaryFormState,
  );
  yield takeEvery(
    State.actionRequested(Type.DELETE_ENTITY_AUTHORIZED_PERSON_INTERMEDIARY_FORM_STATE),
    deleteEntityAuthorisedPersonIntermediaryFormState,
  );
  yield takeEvery(
    State.actionRequested(Type.SAVE_JOINT_ACCOUNTS_INTERMEDIARY_FORM_STATE),
    addJointAccountsIntermediaryFormState,
  );
  yield takeEvery(
    State.actionRequested(Type.DELETE_JOINT_ACCOUNT_INTERMEDIARY_FORM_STATE),
    deleteJointAccountsIntermediaryFormState,
  );
}
