import FormComponent from './FormComponent';
import Validations from './validations';
import { InitialValues, FieldsOptions } from './schema';
import { TFormConfig } from '../../formTypes';

const name = 'statementDelivery';
const path = 'statementDelivery';
const header = 'Statement Delivery';

const config: TFormConfig = {
  name,
  path,
  header,
  initial: InitialValues,
  validations: Validations,
  FormComponent: FormComponent,
  disabled: false,
  formFieldOptions: FieldsOptions,
};

export default config;
