import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import * as Routes from '../constants/routes';
import PrivateRoute from './PrivateRoute';
import MainComponent from '../components/Main';
import NotFoundComponent from '../components/NotFound';
import HomeComponent from '../components/Home';
import AuthRedirection from '../components/AuthRedirection';
import { ResetPasswordComponent } from '../components/ResetPassword';
import SetNewPassword from '../components/SetNewPassword';
import DynamicFormManager from '../components/forms/DynamicFormManager';
import AuthManager from '../components/Auth/DynamicAuthManager';
import SolutionManagement from '../components/forms/Registration/SolutionsManagement';
import AccountCreating from '../components/AccountCreating';
import NoConnectedAccounts from '../components/NoConnectedAccounts';
import ConnectedAccounts from '../components/ConnectedAccounts';
import Faq from '../components/Faq';
import UserManagement from '../components/UserManagement';
import { Red } from '../components/forms/Registration/Entity/UploadEntityDocuments/Style';

const userManagementRoutes = [
  Routes.MANAGEMENT,
  Routes.MANAGEMENT_ACCOUNT_INFORMATION,
  Routes.MANAGEMENT_AUTHENTICATION,
  Routes.MANAGEMENT_ACCOUNT_INFORMATION_CONTACT_INFO,
  Routes.MANAGEMENT_ACCOUNT_INFORMATION_PERSONAL_INFO,
  Routes.MANAGEMENT_ACCOUNT_INFORMATION_ACCOUNT_INFO,
];

export default () => (
  <Switch>
    {/* Public routes */}
    {/* TODO: - Review */}
    <Route key={Routes.AUTH} path={Routes.AUTH} component={AuthManager} />
    <Route key={Routes.MAIN} path={Routes.MAIN} exact component={SolutionManagement.FormComponent} />
    {/* <Route key={Routes.LOGIN} path={Routes.LOGIN} component={LoginComponent} /> */}
    {/* <Route key={Routes.REGISTER} path={Routes.REGISTER} component={RegisterComponent} /> */}
    {/* <Route key={Routes.CONFIRM_EMAIL} path={Routes.CONFIRM_EMAIL} component={ConfirmEmailComponent} /> */}
    {/* <Route key={Routes.FORGOT_PASSWORD} path={Routes.FORGOT_PASSWORD} component={ForgotPasswordComponent} /> */}
    {/* <Route key={Routes.SUBMIT_PASSWORD} path={Routes.SUBMIT_PASSWORD} component={ForgotPasswordResetComponent} /> */}
    <PrivateRoute key={Routes.REGISTER_INFO} path={Routes.REGISTER_INFO} component={DynamicFormManager} />
    <PrivateRoute key={Routes.ACCOUNT_CREATING} path={Routes.ACCOUNT_CREATING} component={AccountCreating} />
    {/* <Route key={Routes.RESETPASSWORD} path={Routes.RESETPASSWORD} component={ResetPasswordComponent} /> */}
    {/* <Route key={Routes.SETPASSWORD} path={Routes.SETPASSWORD} component={SetNewPassword} /> */}
    {/* <Route key={Routes.ACCOUNT_FORMS} path={Routes.ACCOUNT_FORMS} component={AccountFormsComponent} /> */}
    {/* <Route key={Routes.REDIRECT} path={Routes.REDIRECT} component={AuthRedirection} /> */}
    <Route key={Routes.NOT_FOUND} path={Routes.NOT_FOUND} component={NotFoundComponent} />
    <Route key={Routes.FAQ} path={Routes.FAQ} component={Faq} />
    <PrivateRoute
      key={Routes.NO_CONNECTED_ACCOUNTS}
      path={Routes.NO_CONNECTED_ACCOUNTS}
      component={NoConnectedAccounts}
    />
    <PrivateRoute key={Routes.CONNECTED_ACCOUNTS} path={Routes.CONNECTED_ACCOUNTS} component={ConnectedAccounts} />
    <PrivateRoute key={Routes.HOME} path={Routes.HOME} component={HomeComponent} />
    {userManagementRoutes.map(route => (
      <PrivateRoute exact key={route} path={route} component={UserManagement} />
    ))}

    <Route key="*">
      <Redirect to={Routes.NOT_FOUND} />
    </Route>
  </Switch>
);
