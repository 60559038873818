import produce from 'immer';

export const requestedDefaults = produce((draftState: any, action?: any) => {
  draftState.__requested = new Date();
  draftState.__succeeded = null;
  draftState.__failed = null;
  draftState.data = null;
  draftState.error = null;
});

export const succeededDefaults = produce((draftState: any, action?: any) => {
  draftState.__requested = null;
  draftState.__succeeded = new Date();
  draftState.__failed = null;
  draftState.data = action.payload;
  draftState.error = null;
});

export const failedDefaults = produce((draftState: any, action?: any) => {
  draftState.__requested = null;
  draftState.__succeeded = null;
  draftState.__failed = new Date();
  draftState.data = null;
  draftState.error = action.error;
});
