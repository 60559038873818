import { API_BASE_URL } from '../config';

/**
 * Application URL's
 */
export const LOGIN_URL = `${API_BASE_URL}/auth/login`;
export const REGISTER_URL = `${API_BASE_URL}/auth/signup`;
export const RESETPASSWORD_URL = `${API_BASE_URL}/auth/reset-password`;

export const ACCOUNT_URL = `${API_BASE_URL}/account`;

export const PLAID_BALANCE_URL = `${API_BASE_URL}/external-account/investments/{public_token}`;
export const PLAID_LINK_TOKEN_URL = `${API_BASE_URL}/external-account/link-token`;
