import reviewFieldTypes from '../../../../constants/reviewFieldTypes';

export const FormFields = {
  OfficerBoardMemberOr10HolderPublicCompany: 'OfficerBoardMemberOr10HolderPublicCompany',
  CompanyTicker: 'CompanyTicker',

  WorkForAFINRAOrNFAMemberFirmOrAnExchange: 'WorkForAFINRAOrNFAMemberFirmOrAnExchange',
  FirmOrExchangeName: 'FirmOrExchangeName',

  FamilyMember: 'FamilyMember',
  FamilyMemberDetails: 'FamilyMemberDetails',
  FamilyMemberFullName: 'FamilyMemberFullName',
  FamilyMemberRelationship: 'FamilyMemberRelationship',

  SeniorForeignGovernmentOfficial: 'SeniorForeignGovernmentOfficial',
  SeniorForeignGovernmentOfficialDetails: 'SeniorForeignGovernmentOfficialDetails',
  SeniorForeignGovernmentOfficialFullName: 'SeniorForeignGovernmentOfficialFullName',
  SeniorForeignGovernmentOfficialPoliticalOrganization: 'SeniorForeignGovernmentOfficialPoliticalOrganization',

  IAmNotAssociatedWithAForeignShellBank: 'IAmNotAssociatedWithAForeignShellBank',
};

export const FormFieldsTypes = {
  [FormFields.OfficerBoardMemberOr10HolderPublicCompany]: reviewFieldTypes.Boolean,
  [FormFields.CompanyTicker]: reviewFieldTypes.StringArray,

  [FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange]: reviewFieldTypes.Boolean,
  [FormFields.FirmOrExchangeName]: reviewFieldTypes.RawString,

  [FormFields.FamilyMember]: reviewFieldTypes.ArrayObjects,
  [FormFields.FamilyMemberDetails]: reviewFieldTypes.Skip, //TODO : define a proper type later [{}]

  [FormFields.SeniorForeignGovernmentOfficial]: reviewFieldTypes.ArrayObjects,
  [FormFields.SeniorForeignGovernmentOfficialDetails]: reviewFieldTypes.Skip, //TODO : define a proper type later [{}],

  [FormFields.IAmNotAssociatedWithAForeignShellBank]: reviewFieldTypes.Boolean,
};

export const FormStrings = {
  CheckBoxesOnlyIfApplicableLabel: 'Check the boxes only if applicable.',
  [FormFields.OfficerBoardMemberOr10HolderPublicCompany]:
    'I am an officer/board member or 10% holder of a public company?',
  [FormFields.WorkForAFINRAOrNFAMemberFirmOrAnExchange]: 'I work for a FINRA or NFA member firm or an exchange?',
  SeniorForeignGovernmentOfficialFamilyMemberOrCloseAssociateLabel:
    'I am a senior foreign government official, a family member of such an official or a close associate of such an official?',
  [FormFields.FamilyMember]: 'Family Member',
  [FormFields.SeniorForeignGovernmentOfficial]: 'Senior Foreign Government Official',
  [FormFields.IAmNotAssociatedWithAForeignShellBank]: 'I am not associated with a foreign shell bank',
  [FormFields.CompanyTicker]: 'Company Ticker',
  [FormFields.FirmOrExchangeName]: 'Firm or Exchange Name',
  [FormFields.FamilyMemberFullName]: 'Full Name',
  [FormFields.FamilyMemberRelationship]: 'Relationship',
  [FormFields.SeniorForeignGovernmentOfficialFullName]: 'Full Name',
  [FormFields.SeniorForeignGovernmentOfficialPoliticalOrganization]: 'Political Organization',
};
