import * as Yup from 'yup';
import Moment from 'moment';
import { FormFields, FormStrings } from './constants';

export const validationMessages = {
  birthdayRequired: `${FormStrings[FormFields.birthday]} is required`,
  birthdayInvalid: `${FormStrings[FormFields.birthday]} is invalid`,
  ssnRequired: `${FormStrings[FormFields.ssn]} is required`,
  ssnInvalid: `${FormStrings[FormFields.ssn]} format is invalid`,
  martialStatusRequired: `${FormStrings[FormFields.martialStatus]} is required`,
  martialStatusInvalid: `${FormStrings[FormFields.martialStatus]} is invalid`,
  noDependentsRequired: `${FormStrings[FormFields.noDependents]} is required`,
  noDependentsInvalid: `${FormStrings[FormFields.noDependents]} is invalid`,
};

export const defaultValidation = Yup.object().shape({
  [FormFields.birthday]: Yup.string().required(validationMessages.birthdayRequired),
  [FormFields.ssn]: Yup.string()
    .matches(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/, validationMessages.ssnInvalid)
    .required(validationMessages.ssnRequired),
  [FormFields.martialStatus]: Yup.string()
    .required(validationMessages.martialStatusRequired)
    .notOneOf(['-1'], validationMessages.martialStatusInvalid),
  [FormFields.noDependents]: Yup.number()
    .required(validationMessages.noDependentsRequired)
    .moreThan(-1, validationMessages.noDependentsInvalid),
});

export default defaultValidation;
